import {
  GET_HEALTH_STATUS_ERROR,
  GET_HEALTH_STATUS_REQUEST,
  GET_HEALTH_STATUS_SUCCESS,
  GET_X10_HEALTH_STATUS_ERROR,
  GET_X10_HEALTH_STATUS_REQUEST,
  GET_X10_HEALTH_STATUS_SUCCESS,
  IGetHealthStatusErrorAction,
  IGetHealthStatusRequestAction,
  IGetHealthStatusSuccessAction,
  IGetX10HealthStatusErrorAction,
  IGetX10HealthStatusRequestAction,
  IGetX10HealthStatusSuccessAction
} from "./types";

export const getHealthStatus = (): IGetHealthStatusRequestAction => ({
  type: GET_HEALTH_STATUS_REQUEST
});

export const getHealthStatusError = (): IGetHealthStatusErrorAction => ({
  type: GET_HEALTH_STATUS_ERROR
});

export const getHealthStatusSuccess = (
  payload: IGetHealthStatusSuccessAction["payload"]
): IGetHealthStatusSuccessAction => ({
  type: GET_HEALTH_STATUS_SUCCESS,
  payload
});

export const getX10HealthStatus = (): IGetX10HealthStatusRequestAction => ({
  type: GET_X10_HEALTH_STATUS_REQUEST
});

export const getX10HealthStatusError = (): IGetX10HealthStatusErrorAction => ({
  type: GET_X10_HEALTH_STATUS_ERROR
});

export const getX10HealthStatusSuccess = (
  payload: IGetX10HealthStatusSuccessAction["payload"]
): IGetX10HealthStatusSuccessAction => ({
  type: GET_X10_HEALTH_STATUS_SUCCESS,
  payload
});

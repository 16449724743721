import { IUser } from "../../services/jcore";
import { GET_ME, GET_ME_SUCCESS, IGetMe, IGetMeSuccess, ILogout, LOGOUT } from "./types";

export const getMe = (): IGetMe => ({
  type: GET_ME
});

export const getMeSuccess = (payload: IUser): IGetMeSuccess => ({
  type: GET_ME_SUCCESS,
  payload
});

export const logout = (): ILogout => ({ type: LOGOUT });

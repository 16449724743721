import { DangerButton } from "@ddm-design-system/button";
import { MenuItem } from "@ddm-design-system/menu";
import { Description } from "@ddm-design-system/typography";
import { NavButton } from "@ddm-design-system/nav-button";
import styled, { css } from "styled-components";

export const ConfirmButton = styled(DangerButton)(
  ({ theme }) => css`
    color: ${theme.colors.alert.alert100};
  `
);

export const ProfileMenuDescription = styled(Description)(
  ({ theme }) => css`
    color: ${theme.colors.grey.grey100};
  `
);

export const ProfileMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    cursor: default;
    min-width: 300px;
    padding: ${theme.spaceUnit.lg};

    &:hover {
      background-color: ${theme.colors.white};
    }

    > * {
      display: flex;
      flex-direction: column;
    }
  `
);

export const ProfileMenuLogout = styled(MenuItem)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.colors.grey.grey25};
    padding: ${theme.spaceUnit.xs} ${theme.spaceUnit.lg};

    &:hover {
      color: ${theme.colors.alert.alert100};
    }
  `
);

export const ProfileNavButton = styled(NavButton)(
  () => css`
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    width: 100%;
  `
);

import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Subtitle } from "@ddm-design-system/typography";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { associateDistributorOutlets } from "../../store/distributors/actions";
import { getDistributors, getIsCreatingDistributor } from "../../store/distributors/selectors";
import { IDistributor } from "../../store/distributors/types";
import { getOutlets } from "../../store/outlets/selectors";
import { ILocation } from "../../store/outlets/types";
import { CloseIcon } from "../common/icon/CloseIcon.styles";
import Select from "../common/select/Select";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import "./associateDistributorForm.scss";

interface IProps {
  distributor?: IDistributor;
  visible: boolean;
  onClose: () => void;
}

const AssociateDistributorForm: React.FC<IProps> = ({ distributor, visible, onClose }) => {
  const dispatch = useDispatch();
  const { common, distributors: content } = useContent();
  const distributors = useSelector(getDistributors);
  const outlets = useSelector(getOutlets);
  const isCreatingDistributor = useSelector(getIsCreatingDistributor);
  const [selectedDistributor, setSelectedDistributor] = useState<IDistributor & { text: string }>();
  const [selectedOutlet, setSelectedOutlet] = useState<ILocation & { text: string }>();

  // set initial selected distributor if applicable
  useEffect(() => {
    if (distributor && !selectedDistributor) {
      setSelectedDistributor({ ...distributor, text: distributor.name });
    }
  }, [distributor]);

  // Clean up form if sidebar is closed
  useEffect(() => {
    if (!visible) {
      setSelectedDistributor(distributor ? { ...distributor, text: distributor.name } : undefined);
      setSelectedOutlet(undefined);
    }
  }, [visible]);

  const distributorsOptions = useMemo(
    () => distributors.map(distributor => ({ ...distributor, text: distributor.name })),
    [distributors]
  );

  const outletsOptions = useMemo(
    () =>
      outlets
        // .filter(
        //   outlet =>
        //     !outlet.distributors?.length || outlet.distributors?.[0]?.id === selectedDistributor?.id
        // )
        .map(outlet => ({
          ...outlet,
          text: outlet.name
        })),
    [outlets, selectedDistributor]
  );

  const handleAssociateDistributor = () => {
    dispatch(
      associateDistributorOutlets({
        distributorId: selectedDistributor?.id as string,
        outletIds: selectedOutlet?.id as string
      })
    );
  };

  return (
    <SidebarModal className="associate-distributor-outlets" visible={visible} onClose={onClose}>
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col">
          <div className="flex justify-between mb-xxxl">
            <Subtitle>{content.distributors_associate_long}</Subtitle>
            <CloseIcon className="mt-sm" onClick={onClose} />
          </div>
          <form className="flex flex-col">
            <Select
              search
              className="grow mb-lg"
              style={{ flex: 2 }}
              label={common.common_distributor}
              placeholder={common.common_search_distributor_placeholder}
              selectedOption={selectedDistributor}
              options={distributorsOptions}
              onOptionSelected={option => setSelectedDistributor(option)}
              disabled={!!distributor}
            />
            <Select
              search
              className="grow"
              style={{ flex: 2 }}
              label={common.common_outlet}
              placeholder={common.common_search_outlet_placeholder}
              selectedOption={selectedOutlet}
              options={outletsOptions}
              onOptionSelected={option => setSelectedOutlet(option)}
            />
          </form>
        </div>
        <div className="flex justify-end">
          <SecondaryButton className="mr-lg" onClick={onClose}>
            {common.common_cancel}
          </SecondaryButton>
          <PrimaryButton
            disabled={isCreatingDistributor || !selectedDistributor || !selectedOutlet}
            onClick={handleAssociateDistributor}
          >
            {isCreatingDistributor ? common.common_loading : common.common_associate_button_label}
          </PrimaryButton>
        </div>
      </div>
    </SidebarModal>
  );
};

export default AssociateDistributorForm;

import { call, put, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import {
  getExtra10OutletInfo,
  getExtra10OutletInfoSuccess,
  getExtra10OutletInstallationSuccess,
  getExtra10OutletsError,
  getExtra10OutletsSuccess,
  setComponentBeverageError
} from "../store/extra10outlets/actions";
import {
  GET_X10_OUTLET_INFO,
  GET_X10_OUTLET_INSTALLATION,
  GET_X10_OUTLETS_REQUEST,
  IGetExtra10OutletInfoAction,
  IGetExtra10OutletInstallationAction,
  ISetComponentBeverageAction,
  SET_COMPONENT_BEVERAGE
} from "../store/extra10outlets/types";
import { getComponentInfo } from "../store/components/actions";

export function* getOutlets(jCoreService: IJCoreService) {
  try {
    const result = yield call([jCoreService, jCoreService.fetchExtra10Locations]);

    yield put(getExtra10OutletsSuccess(result));
  } catch {
    yield put(getExtra10OutletsError());
  }
}

export function* getOutletInfo(jCoreService: IJCoreService, action: IGetExtra10OutletInfoAction) {
  try {
    const result = yield call([jCoreService, jCoreService.getExtra10OutletInfo], action.payload);

    yield put(
      getExtra10OutletInfoSuccess({
        ...result,
        id: action.payload
      })
    );
  } catch {
    // do nothing
  }
}

export function* getOutletInstallation(
  jCoreService: IJCoreService,
  action: IGetExtra10OutletInstallationAction
) {
  try {
    const result = yield call(
      [jCoreService, jCoreService.getExtra10OutletInstallation],
      action.payload
    );

    yield put(getExtra10OutletInstallationSuccess({ installation: result, id: action.payload }));
  } catch {
    // do nothing
  }
}

export function* setComponentBeverage(
  jCoreService: IJCoreService,
  action: ISetComponentBeverageAction
) {
  try {
    const result = yield call(
      [jCoreService, jCoreService.setComponentBeverage],
      action.payload.thingId,
      action.payload.beverageId
    );

    yield put(getComponentInfo(action.payload.componentId));
  } catch (e) {
    yield put(setComponentBeverageError());
  }
}
export function* outletsWatcher(context: IAppContext) {
  yield takeLatest(GET_X10_OUTLETS_REQUEST, getOutlets, context.jCoreService);
  yield takeLatest(GET_X10_OUTLET_INFO, getOutletInfo, context.jCoreService);
  yield takeLatest(GET_X10_OUTLET_INSTALLATION, getOutletInstallation, context.jCoreService);
  yield takeLatest(SET_COMPONENT_BEVERAGE, setComponentBeverage, context.jCoreService);
}

export default outletsWatcher;

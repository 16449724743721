import styled, { css } from "styled-components";
import { EBreakpoints } from "@ddm-design-system/tokens";
import ScrollToTop from "../common/scroll_to_top/ScrollToTop";
import NavigationMenu from "../common/navigation_menu/NavigationMenu";

export const HomeContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  min-height: 100vh;
`;

export const NavigationMenuStyled = styled(NavigationMenu)`
  flex: 0;
`;

export const ScrollToTopStyled = styled(ScrollToTop)(
  ({ theme }) => css`
    flex: 1;
    padding: ${theme.spaceUnit.lg} ${theme.spaceUnit.lg} 0;
    overflow: auto;
    width: 100vw;
    box-sizing: border-box;

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      padding: ${theme.spaceUnit.lg} ${theme.spaceUnit.md} ${theme.spaceUnit.md}
        ${theme.spaceUnit.md};
      overflow: unset;
    }
  `
);

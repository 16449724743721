import { translateString, translate, translateBoolean } from "./translate";
import { IEntries } from "./index";

export interface ICustomAttribute {
  name: string;
  placeholder: string;
  required: boolean;
}

export default function mapForm(entries: IEntries, lang = process.env.REACT_APP_DEFAULT_LANG) {
  const forms: any = {};

  entries.form.ordered.forEach(form => {
    const name = translateString(form.fields.title);
    const fields: any[] = translate(form.fields.fields) || [];
    const parsedFields: ICustomAttribute[] = fields.map(attribute => {
      return {
        name: translateString(attribute.fields.name, lang),
        placeholder: translateString(attribute.fields.placeholder, lang),
        required: translateBoolean(attribute.fields.required) || false,
        translations: attribute.fields.translations
      };
    });

    forms[name] = parsedFields;
  });

  return { forms };
}

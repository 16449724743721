import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import AnchorContainer from "../../common/anchor_container/AnchorContainer";
import { getHealthStatus, getX10HealthStatus } from "../../../store/healthStatus/actions";
import { getCurrentLanguage } from "../../../store/content/selectors";
import {
  getHealthStatusesByOutlet,
  getIsLoadingHealthStatus,
  getIsX10LoadingHealthStatus,
  getX10HealthStatusesByOutlet
} from "../../../store/healthStatus/selectors";
import DataTable from "../../common/data_table/DataTable";
import { IHealthStatus } from "../../../store/healthStatus/types";

interface IProps {
  outletId: string;
}

const OutletHealthMonitor: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { common, healthMonitor: healthContent } = useContent();
  const language = useSelector(getCurrentLanguage);
  const isLoadingHealthStatus = useSelector(getIsX10LoadingHealthStatus);
  const healthStatuses = useSelector(getX10HealthStatusesByOutlet(outletId));

  useEffect(() => {
    dispatch(getX10HealthStatus());
  }, [dispatch]);

  const sortLevel = useCallback(
    (
      { chipSelectors: [{ sort: chipA }] }: IHealthStatus,
      { chipSelectors: [{ sort: chipB }] }: IHealthStatus
    ) => (chipA as number) - (chipB as number),
    []
  );

  const formatTime = useCallback(
    ({ createDateTime }: IHealthStatus) => {
      const date = new Date(createDateTime).toLocaleDateString(language, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const columns = useMemo(
    () => [
      {
        name: healthContent.healthMonitor_impact,
        selector: (row: IHealthStatus) => row.levelContent,
        sortable: true,
        sortFunction: sortLevel,
        selectorId: "levelContent"
      },
      {
        name: common.common_component,
        selector: (row: IHealthStatus) => row.targetContent,
        sortable: true
      },
      {
        name: healthContent.healthMonitor_alert_type,
        selector: (row: IHealthStatus) => row.typeContent,
        sortable: true
      },
      {
        name: common.common_info,
        selector: (row: IHealthStatus) => row.info,
        sortable: false,
        grow: 2
      },
      {
        format: formatTime,
        name: common.common_time,
        selector: (row: IHealthStatus) => row.createDateTime,
        sortable: true
      }
    ],
    [
      common.common_component,
      common.common_info,
      common.common_time,
      healthContent.healthMonitor_alert_type,
      healthContent.healthMonitor_impact,
      formatTime,
      sortLevel
    ]
  );

  const list = useMemo(() => {
    return [
      {
        title: healthContent.healthMonitor_alerts,
        href: "alerts",
        renderContent: () => (
          <div>
            <DataTable
              columns={columns}
              data={healthStatuses}
              defaultSortAsc={false}
              defaultSortField="createDateTime"
              isLoading={isLoadingHealthStatus}
              title=""
              pagination
              paginationPerPage={12}
              enableActions={false}
            />
          </div>
        )
      }
    ];
  }, [healthContent.healthMonitor_alerts, columns, healthStatuses, isLoadingHealthStatus]);

  return (
    <div>
      <AnchorContainer list={list} loading={isLoadingHealthStatus} />
    </div>
  );
};

export default OutletHealthMonitor;

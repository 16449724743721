import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import DataTable from "../../../../common/data_table/DataTable";
import useContent from "../../../../../hooks/useContent";
import { IEventHistory } from "../../../../../store/components/types";
import { getCurrentLanguage } from "../../../../../store/content/selectors";

interface IProps {
  statusHistory?: IEventHistory[];
}

interface IStatusHistory {
  type: string;
  date: string;
}

const StatusHistory: React.FC<IProps> = ({ statusHistory }) => {
  const { common, healthMonitor: content } = useContent();
  const language = useSelector(getCurrentLanguage);

  const formatDate = useCallback(
    ({ date }: IStatusHistory) => {
      const dateFormatted = date
        ? new Date(date).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short"
          })
        : "--";

      return <span title={dateFormatted}>{dateFormatted}</span>;
    },
    [language]
  );

  const renderComponentStatusHistory = useCallback(() => {
    const columns = [
      {
        name: common.common_status,
        selector: (row: IStatusHistory) =>
          content[`healthMonitor_${row.type?.toLowerCase()}`] || row.type || "--",
        sortable: true
      },
      {
        format: formatDate,
        name: common.common_time,
        selector: (row: IStatusHistory) => row.date,
        sortable: true
      }
    ];

    return (
      <DataTable columns={columns} data={statusHistory || []} title="" enableActions={false} />
    );
  }, [statusHistory, common, content, formatDate]);

  return renderComponentStatusHistory();
};

export default StatusHistory;

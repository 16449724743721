import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { BeverageTranslation } from "../../store/beverages/types";
import { ICheckbox } from "../common/checkbox_group/CheckboxGroup";
import { DetailPage } from "../common/DetailPage/DetailPage";
import { DetailPageContent } from "../common/DetailPage/DetailPageContent";
import Header from "../common/header/Header";
import Filter, { IFilterCategory, IFilterState } from "../common/old_filter/Filter";
import BeverageCard from "./BeverageCard";
import { getBeverages } from "../../store/beverages/selectors";

interface ICategory extends IFilterCategory {
  approved?: boolean;
}

const Beverages: React.FC<RouteComponentProps> = ({ history }) => {
  const {
    controlTowerBeveragesPage: content,
    controlTowerCountries: countries,
    common: contentCommon,
    languageInfo: languages
  } = useContent();
  const sortOptions = useMemo(
    () => ({
      name: contentCommon ? contentCommon.common_name : "",
      approval_asc: content ? `${content.control_tower_approval_status} ↑` : "",
      approval_desc: content ? `${content.control_tower_approval_status} ↓` : ""
    }),
    [content, contentCommon]
  );

  const sortOptionsFlat: string[] = [
    sortOptions.name,
    sortOptions.approval_asc,
    sortOptions.approval_desc
  ];

  const requestingBeverages = useSelector(
    (state: IAppState) => state.beverages.requestingBeverages
  );
  const requestingTranslations = useSelector(
    (state: IAppState) => state.beverages.requestingTranslations
  );
  const requestingBeveragesError = useSelector(
    (state: IAppState) => state.beverages.requestingBeveragesError
  );
  const requestingTranslationsError = useSelector(
    (state: IAppState) => state.beverages.requestingTranslationsError
  );

  const me = useSelector((state: IAppState) => state.authReducer.me);

  const beverages: BeverageTranslation[] = useSelector(getBeverages);

  const [filterState, setFilterState] = useState<IFilterState<any>>();

  const beverageApprovalCategories: ICategory[] = [
    {
      id: "1",
      text: content ? content.control_tower_in_review : "",
      approved: false
    },
    {
      id: "2",
      text: content ? content.control_tower_approved : "",
      approved: true
    }
  ];

  const beverageCountryList: ICategory[] = useMemo(
    () =>
      beverages.reduce((agg: any, val) => {
        const countriesArray = val.countries;
        const agg2 = countriesArray.filter(c => !agg.map((c2: any) => c2.id).includes(c.code));

        return agg.concat(
          agg2.map(c => ({
            id: c.code,
            text: countries[`countries_${c.code.toLowerCase()}`] || c.code,
            selected: me && me.country.code === c.code
          }))
        );
      }, []),
    [beverages, countries, me]
  );

  const beverageLanguageList: ICategory[] = useMemo(
    () =>
      languages?.map(l => ({
        id: l.code,
        text: l.name
      })),
    [languages]
  );

  const handleFilterChange = useCallback(
    (state: IFilterState<any>) => {
      if (state !== filterState) {
        setFilterState(state);
      }
    },
    [filterState]
  );

  const filteredBeverages = useMemo(() => {
    if (!filterState) {
      return beverages;
    }

    const { text, sort, lists } = filterState;

    return beverages
      .filter(beverage => !text || beverage.name.toLowerCase().includes(text.toLowerCase()))
      .filter(beverage => {
        let approved = true;

        if (lists) {
          const approvalList = lists[0];
          const selectedApproval = approvalList.options.filter((c: ICheckbox) => c.selected);

          if (
            selectedApproval.length === 0 ||
            selectedApproval.length === approvalList.options.length
          ) {
            // return true;
          } else {
            approved = selectedApproval.some(
              (category: any) => category.approved === Boolean(beverage.active)
            );

            if (!approved) {
              return approved;
            }
          }

          const languageList = lists[1];

          if (languageList) {
            const selectedLanguages = languageList.options.filter((c: ICheckbox) => c.selected);

            if (
              selectedLanguages.length === 0 ||
              selectedLanguages.length === languageList.options.length
            ) {
              // return true;
            } else {
              approved = selectedLanguages.some((category: any) =>
                beverage.localePercentage(category.id)
              );

              if (!approved) {
                return approved;
              }
            }
          }

          const countryList = lists[2];

          if (countryList) {
            const selectedCountries = countryList.options.filter((c: ICheckbox) => c.selected);

            if (
              selectedCountries.length === 0 ||
              selectedCountries.length === countryList.options.length
            ) {
              // return true;
            } else {
              approved = selectedCountries.some((category: any) =>
                beverage.countries.map(c => c.code).includes(category.id)
              );

              if (!approved) {
                return approved;
              }
            }
          }
        }

        return approved;
      })
      .sort((a, b) => {
        let aVal: string | number = "";
        let bVal: string | number = "";

        if (sort === sortOptions.name) {
          aVal = b.name.toLowerCase();
          bVal = a.name.toLowerCase();
        } else if (sort === sortOptions.approval_asc) {
          aVal = (b.active ? "   " : "") + b.name.toLowerCase();
          bVal = (a.active ? "   " : "") + a.name.toLowerCase();
        } else if (sort === sortOptions.approval_desc) {
          aVal = (!b.active ? "   " : "") + b.name.toLowerCase();
          bVal = (!a.active ? "   " : "") + a.name.toLowerCase();
        }

        return aVal < bVal ? 1 : -1;
      });
  }, [beverages, filterState, sortOptions]);

  const finalList = [
    {
      title: content.control_tower_approval_status,
      options: beverageApprovalCategories
    }
  ];

  if (beverageLanguageList.length > 0) {
    finalList.push({
      title: content.control_tower_languages,
      options: beverageLanguageList
    });
  }

  if (beverageCountryList.length > 0) {
    finalList.push({
      title: contentCommon.common_countries,
      options: beverageCountryList
    });
  }

  const headerActions = [
    {
      label: content.control_tower_create_new_beverage,
      onClick: () => history.push(Routes.newBeverage)
    }
  ];

  return !beverages && (requestingBeverages || requestingTranslations) ? (
    <div className="beverages-requesting">{content.control_tower_requesting_beverages}</div>
  ) : requestingBeveragesError || requestingTranslationsError ? (
    <div className="beverages-requesting">{content.control_tower_error_loading_beverages}</div>
  ) : me ? (
    <DetailPage className="page-list">
      <Header title={content.control_tower_beverages} actions={headerActions} />
      <DetailPageContent>
        <div className="beverages-page">
          <Filter
            textPlaceholder={content.beverages_search_placeholder}
            categories={[]}
            lists={finalList}
            sort={sortOptions.name}
            sortOptions={sortOptionsFlat}
            onFilterChange={handleFilterChange}
          >
            {filteredBeverages.map(d => (
              <Link
                key={d.id}
                to={Routes.beverageDetail.replace(":id", d.id)}
                className="beverage-card"
              >
                <BeverageCard me={me} beverage={d} />
              </Link>
            ))}
          </Filter>
        </div>
      </DetailPageContent>
    </DetailPage>
  ) : (
    <span>{content.control_tower_error_loading_beverages}</span>
  );
};

export default withRouter(Beverages);

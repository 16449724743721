import { useSelector } from "react-redux";
import { useMemo } from "react";
import useContent from "./useContent";
import { getCountriesForMe } from "../store/outlets/selectors";
import { ILocation } from "../store/outlets/types";

export enum QuestionType {
  INPUT,
  DROPDOWN,
  RADIO
}

export interface IQuestion {
  question: string;
  placeholder?: string;
  field: string;
  options?: any;
  hint?: string;
  type: QuestionType;
  disabled?: boolean;
}

const useGetQuestionnaire = (outlet: ILocation | undefined, values: any) => {
  const { outlets: content, common } = useContent();
  const countryList = useSelector(getCountriesForMe);

  const steps: Array<IQuestion[]> = useMemo(() => {
    const questions = [];
    const firstScreen: IQuestion[] = [
      {
        question: content.outlets_questionnaire_name_question,
        placeholder: content.outlets_outlet_name,
        field: "name",
        hint: content.outlets_questionnaire_write_hint,
        type: QuestionType.INPUT,
        disabled: outlet?.isImported
      }
    ];
    if (!outlet && countryList.length > 1) {
      firstScreen.push({
        question: content.outlets_questionnaire_country_question,
        placeholder: content.outlets_choose_country,
        field: "countryId",
        hint: content.outlets_questionnaire_select_hint,
        type: QuestionType.DROPDOWN,
        options: countryList
      });
    }
    questions.push(firstScreen);
    questions.push([
      {
        question: content.outlets_questionnaire_categories_question,
        field: "category",
        hint: content.outlets_questionnaire_select_hint,
        type: QuestionType.RADIO,
        options: [
          {
            text: content.outlets_questionnaire_categories_answer_bar,
            id: "Bar/Restaurant"
          },
          {
            text: content.outlets_questionnaire_categories_answer_casino,
            id: "Casino"
          },
          {
            text: content.outlets_questionnaire_categories_answer_karaoke,
            id: "Karaoke"
          },
          {
            text: content.outlets_questionnaire_categories_answer_sports,
            id: "SportsClub"
          },
          {
            text: content.outlets_questionnaire_categories_answer_music,
            id: "LiveMusic"
          },
          {
            text: content.outlets_questionnaire_categories_answer_festival,
            id: "Festival/Events"
          },
          {
            text: content.outlets_questionnaire_categories_answer_stadium,
            id: "Stadium"
          },
          {
            text: content.outlets_questionnaire_categories_answer_hotel5,
            id: "5Hotel"
          },
          {
            text: content.outlets_questionnaire_categories_answer_hotel4,
            id: "4Hotel"
          },
          {
            text: content.outlets_questionnaire_categories_answer_motel,
            id: "Motel"
          },
          {
            text: content.outlets_questionnaire_categories_answer_lounge,
            id: "Lounge"
          }
        ]
      }
    ]);
    if (!values.category || values.category !== "Bar/Restaurant") {
      return questions;
    }

    questions.push([
      {
        question: content.outlets_questionnaire_brands_question,
        field: "brandsOver10",
        hint: content.outlets_questionnaire_select_hint,
        type: QuestionType.RADIO,
        options: [
          {
            text: content.outlets_questionnaire_brands_answer_2,
            id: true
          },
          {
            text: content.outlets_questionnaire_brands_answer_1,
            id: false
          }
        ]
      }
    ]);

    if (values.brandsOver10) {
      return questions;
    }

    questions.push([
      {
        question: "Is their outlet usually also open after midnight into the early hours?",
        field: "openLate",
        hint: content.outlets_questionnaire_select_hint,
        type: QuestionType.RADIO,
        options: [
          {
            text: common.common_yes,
            id: true
          },
          {
            text: common.common_no,
            id: false
          }
        ]
      }
    ]);

    if (values.openLate) {
      return questions;
    }

    questions.push([
      {
        question: content.outlets_questionnaire_serving_question,
        field: "outletFoodLed",
        hint: content.outlets_questionnaire_select_hint,
        type: QuestionType.RADIO,
        options: [
          {
            text: content.outlets_questionnaire_serving_answer_1,
            id: true
          },
          {
            text: content.outlets_questionnaire_serving_answer_2,
            id: false
          }
        ]
      }
    ]);
    questions.push([
      {
        question: content.outlets_questionnaire_price_question,
        field: "priceOver5",
        hint: content.outlets_questionnaire_select_hint,
        type: QuestionType.RADIO,
        options: [
          {
            text: content.outlets_questionnaire_select_answer_1,
            id: true
          },
          {
            text: content.outlets_questionnaire_select_answer_2,
            id: false
          }
        ]
      }
    ]);

    if (values.outletFoodLed) {
      if (values.priceOver5) {
        questions.push([
          {
            question: content.outlets_questionnaire_michelin_question,
            field: "hasMichelinStar",
            hint: content.outlets_questionnaire_select_hint,
            type: QuestionType.RADIO,
            options: [
              {
                text: common.common_yes,
                id: true
              },
              {
                text: common.common_no,
                id: false
              }
            ]
          }
        ]);
      } else {
        questions.push([
          {
            question: content.outlets_questionnaire_waiter_question,
            field: "isWaiterServing",
            hint: content.outlets_questionnaire_select_hint,
            type: QuestionType.RADIO,
            options: [
              {
                text: common.common_yes,
                id: true
              },
              {
                text: common.common_no,
                id: false
              }
            ]
          }
        ]);
        if (values.isWaiterServing) {
          questions.push([
            {
              question: content.outlets_questionnaire_busy_question,
              field: "busierLunchtime",
              hint: content.outlets_questionnaire_select_hint,
              type: QuestionType.RADIO,
              options: [
                {
                  text: content.outlets_questionnaire_busy_answer_1,
                  id: true
                },
                {
                  text: content.outlets_questionnaire_busy_answer_2,
                  id: false
                }
              ]
            }
          ]);
        }
      }
    } else {
      questions.push([
        {
          question: content.outlets_questionnaire_revenue_question,
          field: "revenueFromDrinks",
          hint: content.outlets_questionnaire_select_hint,
          type: QuestionType.RADIO,
          options: [
            {
              text: common.common_yes,
              id: true
            },
            {
              text: common.common_no,
              id: false
            }
          ]
        }
      ]);
    }

    if (
      ((values.revenueFromDrinks && !values.outletFoodLed) ||
        (values.hasMichelinStar === false && values.outletFoodLed)) &&
      values.priceOver5
    ) {
      questions.push([
        {
          question: content.outlets_questionnaire_customer_question,
          field: "isCustomersYoung",
          hint: content.outlets_questionnaire_select_hint,
          type: QuestionType.RADIO,
          options: [
            {
              text: common.common_yes,
              id: true
            },
            {
              text: common.common_no,
              id: false
            }
          ]
        }
      ]);
    }

    return questions;
  }, [content, common, outlet, countryList, values]);

  return steps;
};

export default useGetQuestionnaire;

import styled, { css } from "styled-components";
import { EBreakpoints } from "@ddm-design-system/tokens";
import Search from "../search/Search";

export const StyledSearch = styled(Search)(
  ({ theme }) => css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0px ${theme.spaceUnit.md};

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      flex-basis: 100%;
      margin-bottom: ${theme.spaceUnit.sm};
    }
  `
);

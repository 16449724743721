import classnames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Icon } from "@ddm-design-system/icon";
import useContent from "../../hooks/useContent";
import { IUser } from "../../services/jcore";
import { IAppState } from "../../store";
import { setCurrentBeverage } from "../../store/beverages/actions";
import { BeverageTranslation, ICountry } from "../../store/beverages/types";
import { getCurrentContent } from "../../store/content/selectors";
import { ILanguageInfo } from "../../store/content/types";
import "./beverage-card.scss";

interface IProps extends RouteComponentProps {
  beverage: BeverageTranslation;
  me: IUser;
}

export const BeverageCard: React.FC<IProps> = ({ beverage, me, history }) => {
  const languages = useSelector(
    (state: IAppState) => getCurrentContent(state) && getCurrentContent(state).languageInfo
  );
  const dispatch = useDispatch();

  return (
    <div
      className="beverage-card-inner"
      onClick={() => {
        dispatch(setCurrentBeverage(beverage));
        history.push(`/beverage/${beverage.id}`);
      }}
    >
      <div
        className={classnames("beverage-card-header", {
          "no-logo": !beverage.logoKey(),
          "with-logo": beverage.logoKey()
        })}
        style={{
          backgroundImage: beverage.logoKey()
            ? `linear-gradient(90deg, #000000 1.71%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, #000000 1.71%, rgba(0, 0, 0, 0) 100%),  url("${beverage.logoUrl()}")`
            : "none"
        }}
      >
        <div className="beverage-brand">{beverage.brand}</div>
        <h2 className="beverage-subbrand">{beverage.subbrand}</h2>
      </div>
      <div className="beverage-card-content">
        <BeverageApprovedStatus beverage={beverage} />
        <BeverageCountryList myCountry={me.country} countries={beverage.countries} />
        <LanguageCompletion languages={languages} beverage={beverage} />
      </div>
    </div>
  );
};

interface IBeverageProps {
  beverage: BeverageTranslation;
  languages?: ILanguageInfo[];
}

export const BeverageApprovedStatus: React.FC<IBeverageProps> = ({ beverage }) => {
  const { controlTowerBeveragesPage: content } = useContent();

  return (
    <div className="beverage-approved-status">
      {beverage.active ? (
        <>
          <Icon name="Ok" style={{ marginRight: "var(--space-unit-xs)" }} />
          <span>{content.control_tower_approved}</span>
        </>
      ) : (
        <>
          <Icon
            name="Warning"
            color="var(--color-warning-warning100)"
            style={{ marginRight: "var(--space-unit-xs)" }}
          />
          <span>{content.control_tower_in_review_extended}</span>
        </>
      )}
    </div>
  );
};

export const LanguageCompletion: React.FC<IBeverageProps> = ({ languages, beverage }) => {
  /* Check TranslatedBeverage properties */
  const { controlTowerBeveragesPage: content } = useContent();

  return (
    <div className="language-translation-container">
      <span className="language-translation-progress-label">{content.control_tower_languages}</span>
      {languages &&
        languages
          .filter((l: ILanguageInfo) => l.code && beverage.localePercentage(l.code) > 0)
          .map((l: ILanguageInfo) => {
            return (
              <LanguageTranslationProgress
                key={l.code}
                pct={beverage.localePercentage(l.code)}
                langCode={l.name.substr(0, 3).toUpperCase()}
              />
            );
          })}
    </div>
  );
};

interface ILanguageTranslationProps {
  langCode: string;
  pct: number;
}

export const LanguageTranslationProgress: React.FC<ILanguageTranslationProps> = ({
  langCode,
  pct
}) => {
  return (
    <div className="language-translation-progress">
      <div className="language-translation-label">{langCode}</div>
      <div className="language-translation-progress-background" />
      <div
        className="language-translation-progress-bar"
        style={{ width: `${(pct * 30) / 100}px` }}
      />
    </div>
  );
};

interface IBveverageCountryListProps {
  countries: ICountry[];
  myCountry?: ICountry;
  extended?: boolean;
}

export const BeverageCountryList: React.FC<IBveverageCountryListProps> = ({
  myCountry,
  countries,
  extended
}) => {
  const { controlTowerCountries: content } = useContent();

  if (myCountry) {
    const myCountryTranslated = {
      ...myCountry,
      name: content[`countries_${myCountry.code.toLocaleLowerCase()}`]
        ? content[`countries_${myCountry.code.toLocaleLowerCase()}`]
        : myCountry.name
    };

    const isInMyCountry = countries.some(f => f.code === myCountry.code);
    const otherCountries = countries
      .filter(f => f.code !== myCountry.code)
      .map(d => ({
        ...d,
        name: content[`countries_${d.code.toLocaleLowerCase()}`]
          ? content[`countries_${d.code.toLocaleLowerCase()}`]
          : d.name
      }));

    return (
      content && (
        <div
          className={classnames("beverage-country-list overflow-auto scrollbar-hide", {
            "extended-country": extended
          })}
        >
          {!extended && (
            <span className="beverage-country-list-label">
              {extended ? "Countries where it is in use" : "Countries"}
            </span>
          )}
          {isInMyCountry && (
            <div className="country-flag-container" title={myCountryTranslated.name}>
              <div
                className={classnames(
                  "flag",
                  "flag-icon",
                  "flag-icon-squared",
                  `flag-icon-${myCountryTranslated.code.toLowerCase().replace("uk", "gb")}`
                )}
              />
              {extended && <div className="country-flag-label">{myCountryTranslated.name}</div>}
            </div>
          )}
          {otherCountries
            .filter(lang => lang.code.toLowerCase() !== "xx")
            .map(lang => (
              <div key={lang.code} className="country-flag-container" title={lang.name}>
                <div
                  className={classnames(
                    "flag",
                    "flag-icon",
                    "flag-icon-squared",
                    `flag-icon-${lang.code.toLowerCase()}`
                  )}
                />
                {extended && <div className="country-flag-label">{lang.name}</div>}
              </div>
            ))}
        </div>
      )
    );
  }

  return <span />;
};

export default withRouter(BeverageCard);

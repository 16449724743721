import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconNames } from "@ddm-design-system/icon";
import { Description, Label } from "@ddm-design-system/typography";
import Routes from "../../../routes";
import useContent from "../../../hooks/useContent";
import { Container, PressureChamberIcon, UnitLink } from "./installation.styles";
import { Link } from "../../common/link/link.styles";
import AnchorContainer, { IAnchorItem } from "../../common/anchor_container/AnchorContainer";
import {
  getOutlet,
  getOutletBDStatuses,
  getOutletCompressorStatuses,
  getOutletCoolUStatuses,
  getOutletCUStatuses,
  getOutletPCStatuses,
  getOutletSKStatuses
} from "../../../store/outlets/selectors";
import DataTable from "../../common/data_table/DataTable";
import {
  ComponentLevelStatus,
  IBeerDriveStatus,
  ICompressorStatus,
  IControlUnitStatus,
  ICoolingUnitStatus,
  IPressureChamberStatus,
  ISteelKegUnitStatus
} from "../../../store/outlets/types";
import { getOutletInstallation } from "../../../store/outlets/actions";
import { hexToDec } from "../../../helpers";

interface IProps {
  outletId: string;
}

const Installation: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { common, outletDetails: content } = useContent();
  const outlet = useSelector(getOutlet(outletId));
  const pressureChambers = useSelector(getOutletPCStatuses(outletId));
  const steelKegUnits = useSelector(getOutletSKStatuses(outletId));
  const beerDrives = useSelector(getOutletBDStatuses(outletId));
  const controlUnits = useSelector(getOutletCUStatuses(outletId));
  const coolingUnits = useSelector(getOutletCoolUStatuses(outletId));
  const compressors = useSelector(getOutletCompressorStatuses(outletId));

  const formatName = useCallback(
    name =>
      ({ position, id }: { position: number; id: string }) =>
        (
          <Link
            to={Routes.outletComponentDetails.replace(":id", outletId).replace(":cid", id)}
            title={`${name} ${position}`}
          >
            {`${name} ${position}`}
          </Link>
        ),
    [outletId]
  );

  const formatUnit = useCallback(({ unit, id }) => {
    return <UnitLink to={`units#${id}`}>{unit}</UnitLink>;
  }, []);

  const formatSN = useCallback(({ boardSerialNumber }) => {
    return (
      <div className="flex flex-col">
        <Description>
          {boardSerialNumber !== "--" ? hexToDec(boardSerialNumber) : boardSerialNumber}
        </Description>
        <Label className="text-grey-grey100 mt-xs">({boardSerialNumber})</Label>
      </div>
    );
  }, []);

  const formatVolume = useCallback(
    ({ volume, hasPressureSwitcher }) =>
      hasPressureSwitcher ? <span>{volume}L</span> : <span>--</span>,
    []
  );
  const formatPressureSwitcher = useCallback(
    ({ hasPressureSwitcher }) => (
      <span>{hasPressureSwitcher ? common.common_yes : common.common_no}</span>
    ),
    [common]
  );

  const formatStatus = useCallback(
    ({ status }) => (
      <>
        {(status || []).map(
          (s: { level: ComponentLevelStatus; icon: IconNames; title: string }) => {
            return s.icon ? (
              <div title={s.title}>
                <PressureChamberIcon key={s.icon} size={16} name={s.icon} level={s.level} />
              </div>
            ) : (
              <></>
            );
          }
        )}
      </>
    ),
    []
  );
  const formatTemp = useCallback(
    (selector: "temp1" | "temp2" | "temp3") => (coolingUnit: ICoolingUnitStatus) =>
      <span>{coolingUnit?.[selector] || "--"}ºC</span>,
    []
  );

  const renderPressureChamberTable = useCallback(() => {
    const columns = [
      {
        format: formatName(common.common_pressureChamber),
        name: common.common_number,
        selector: (row: IPressureChamberStatus) => row.position,
        sortable: true
      },
      {
        format: formatSN,
        name: common.common_serial_number,
        selector: (row: IPressureChamberStatus) => row.boardSerialNumber
      },
      {
        format: formatUnit,
        name: common.common_unit,
        selector: (row: IPressureChamberStatus) => row.unit,
        sortable: true
      },
      {
        name: common.common_taps,
        selector: (row: IPressureChamberStatus) => row.tapsPositions,
        sortable: true
      },
      {
        name: common.common_brand,
        selector: (row: IPressureChamberStatus) => row.brand as any,
        sortable: false,
        isImageSelector: true
      },
      {
        format: formatVolume,
        name: common.common_volume,
        selector: (row: IPressureChamberStatus) => row.volume,
        sortable: true
      },
      {
        format: formatPressureSwitcher,
        name: common.common_pressure_switcher,
        selector: (row: IPressureChamberStatus) => row.hasPressureSwitcher
      },
      {
        format: formatStatus,
        name: common.common_status,
        selector: (row: IPressureChamberStatus) => row.status as any
      }
    ];

    return <DataTable columns={columns} data={pressureChambers} title="" enableActions={false} />;
  }, [
    formatName,
    formatStatus,
    formatSN,
    formatUnit,
    formatVolume,
    formatPressureSwitcher,
    pressureChambers,
    common
  ]);

  const renderSteelKegUnitTable = useCallback(() => {
    const columns = [
      {
        format: formatName(common.common_steelKegUnit),
        name: common.common_number,
        selector: (row: ISteelKegUnitStatus) => row.position,
        sortable: true
      },
      {
        format: formatSN,
        name: common.common_serial_number,
        selector: (row: ISteelKegUnitStatus) => row.boardSerialNumber
      },
      {
        format: formatUnit,
        name: common.common_unit,
        selector: (row: ISteelKegUnitStatus) => row.unit,
        sortable: true
      },
      {
        name: common.common_taps,
        selector: (row: ISteelKegUnitStatus) => row.tapsPositions,
        sortable: true
      },
      {
        name: common.common_brand,
        selector: (row: ISteelKegUnitStatus) => row.brand as any,
        sortable: false,
        isImageSelector: true
      },
      {
        format: formatStatus,
        name: common.common_status,
        selector: (row: ISteelKegUnitStatus) => row.status as any
      }
    ];

    return <DataTable columns={columns} data={steelKegUnits} title="" enableActions={false} />;
  }, [formatName, formatStatus, formatSN, formatUnit, steelKegUnits, common]);

  const renderBeerDriveTable = useCallback(() => {
    const columns = [
      {
        format: formatName(common.common_beerDrive),
        name: common.common_number,
        selector: (row: IBeerDriveStatus) => row.position,
        sortable: true
      },
      {
        format: formatSN,
        name: common.common_serial_number,
        selector: (row: IBeerDriveStatus) => row.boardSerialNumber
      },
      {
        format: formatUnit,
        name: common.common_unit,
        selector: (row: IBeerDriveStatus) => row.unit,
        sortable: true
      },
      {
        name: common.common_taps,
        selector: (row: IBeerDriveStatus) => row.tapsPositions,
        sortable: true
      },
      {
        name: common.common_brand,
        selector: (row: IBeerDriveStatus) => row.brand as any,
        sortable: false,
        isImageSelector: true
      },
      {
        format: formatVolume,
        name: common.common_volume,
        selector: (row: IBeerDriveStatus) => row.volume,
        sortable: true
      },
      {
        format: formatStatus,
        name: common.common_status,
        selector: (row: IBeerDriveStatus) => row.status as any
      }
    ];

    return <DataTable columns={columns} data={beerDrives} title="" enableActions={false} />;
  }, [beerDrives, formatName, formatSN, formatUnit, formatStatus, formatVolume, common]);

  const renderControlUnitTable = useCallback(() => {
    const columns = [
      {
        format: formatName(common.common_controlUnit),
        name: "Number",
        selector: (row: IControlUnitStatus) => row.position,
        sortable: true
      },
      {
        format: formatSN,
        name: common.common_serial_number,
        selector: (row: IControlUnitStatus) => row.boardSerialNumber
      },
      {
        format: formatUnit,
        name: common.common_unit,
        selector: (row: IControlUnitStatus) => row.unit,
        sortable: true
      },
      {
        name: common.common_taps,
        selector: (row: IControlUnitStatus) => row.affectedTapsPositions,
        sortable: true
      },
      {
        format: formatStatus,
        name: common.common_status,
        selector: (row: IControlUnitStatus) => row.status as any
      }
    ];

    return <DataTable columns={columns} data={controlUnits} title="" enableActions={false} />;
  }, [controlUnits, formatName, formatSN, formatUnit, formatStatus, common]);

  const renderCoolingUnitTable = useCallback(() => {
    const columns = [
      {
        format: formatName(common.common_coolingUnit),
        name: "Number",
        selector: (row: ICoolingUnitStatus) => row.position,
        sortable: true
      },
      {
        format: formatSN,
        name: common.common_serial_number,
        selector: (row: ICoolingUnitStatus) => row.boardSerialNumber
      },
      {
        format: formatUnit,
        name: common.common_unit,
        selector: (row: ICoolingUnitStatus) => row.unit,
        sortable: true
      },
      {
        format: formatTemp("temp1"),
        name: `${common.common_temperature} 1`,
        selector: (row: ICoolingUnitStatus) => row.temp1 || 0,
        sortable: true
      },
      {
        format: formatTemp("temp2"),
        name: `${common.common_temperature} 2`,
        selector: (row: ICoolingUnitStatus) => row.temp2 || 0,
        sortable: true
      },
      {
        format: formatTemp("temp3"),
        name: `${common.common_temperature} 3`,
        selector: (row: ICoolingUnitStatus) => row.temp3 || 0,
        sortable: true
      },
      {
        format: formatStatus,
        name: common.common_status,
        selector: (row: ICoolingUnitStatus) => row.status as any
      }
    ];

    return <DataTable columns={columns} data={coolingUnits} title="" enableActions={false} />;
  }, [coolingUnits, formatName, formatSN, formatUnit, formatStatus, formatTemp, common]);

  const renderCompressorTable = useCallback(() => {
    const columns = [
      {
        format: formatName(common.common_compressor),
        name: "Number",
        selector: (row: ICompressorStatus) => row.position,
        sortable: true
      },
      {
        format: formatSN,
        name: common.common_serial_number,
        selector: (row: ICompressorStatus) => row.boardSerialNumber
      },
      {
        format: formatUnit,
        name: common.common_unit,
        selector: (row: ICompressorStatus) => row.unit,
        sortable: true
      },
      {
        format: formatStatus,
        name: common.common_status,
        selector: (row: ICompressorStatus) => row.status as any
      }
    ];

    return <DataTable columns={columns} data={compressors} title="" enableActions={false} />;
  }, [compressors, formatName, formatSN, formatUnit, formatStatus, common]);

  const list = useMemo(() => {
    if (!outlet) {
      return [];
    }

    const contentList: IAnchorItem[] = [];

    if (pressureChambers?.length) {
      contentList.push({
        title: content.outlet_details_pressure_chambers,
        href: "pressureChambers",
        renderContent: () => renderPressureChamberTable()
      });
    }

    if (steelKegUnits?.length) {
      contentList.push({
        title: content.outlet_details_steel_keg_units,
        href: "steelKegUnits",
        renderContent: () => renderSteelKegUnitTable()
      });
    }

    if (beerDrives?.length) {
      contentList.push({
        title: content.outlet_details_beer_drives,
        href: "beerDrives",
        renderContent: () => renderBeerDriveTable()
      });
    }

    if (controlUnits?.length) {
      contentList.push({
        title: content.outlet_details_control_units,
        href: "controlUnits",
        renderContent: () => renderControlUnitTable()
      });
    }

    if (coolingUnits?.length) {
      contentList.push({
        title: content.outlet_details_cooling_units,
        href: "coolingUnits",
        renderContent: () => renderCoolingUnitTable()
      });
    }

    if (compressors?.length) {
      contentList.push({
        title: content.outlet_details_compressors,
        href: "compressors",
        renderContent: () => renderCompressorTable()
      });
    }

    return contentList;
  }, [
    outlet,
    pressureChambers,
    steelKegUnits,
    coolingUnits,
    controlUnits,
    beerDrives,
    compressors,
    renderPressureChamberTable,
    renderSteelKegUnitTable,
    renderBeerDriveTable,
    renderControlUnitTable,
    renderCoolingUnitTable,
    renderCompressorTable,
    content
  ]);

  useEffect(() => {
    dispatch(getOutletInstallation(outletId));
  }, [dispatch, outletId]);

  return (
    <Container>
      <AnchorContainer
        list={list}
        emptyString={content.outlet_details_no_components}
        loading={!outlet?.installation}
      />
    </Container>
  );
};

export default Installation;

import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { getHealthStatuses, getIsLoadingHealthStatus } from "../../store/healthStatus/selectors";
import {
  EHealthStatusLevel,
  EHealthStatusTarget,
  EHealthStatusType,
  IHealthStatus
} from "../../store/healthStatus/types";
import DataTable from "../common/data_table/DataTable";
import { IFilter, IFilterCheckbox } from "../common/filter/Filter";
import { Link } from "../common/link/link.styles";
import { getCurrentLanguage } from "../../store/content/selectors";

const Alerts: React.FC = () => {
  const { common, healthMonitor: content } = useContent();
  const language = useSelector(getCurrentLanguage);
  const isLoadingHealthStatus = useSelector(getIsLoadingHealthStatus);
  const healthStatuses = useSelector(getHealthStatuses);

  const getFilterOptions = useCallback(
    (id: "level" | "target" | "type", options: string[]) => {
      const filterOptions = options.map(
        (option: string): IFilterCheckbox => ({
          id: option,
          label:
            id === "target"
              ? common[`common_${option}`]
              : content[`healthMonitor_${id === "level" ? `level_${option}` : option}`]
        })
      );

      return id === "level"
        ? filterOptions
        : filterOptions.sort(
            ({ label: labelA }: IFilterCheckbox, { label: labelB }: IFilterCheckbox) =>
              labelA > labelB ? 1 : -1
          );
    },
    [common, content]
  );

  const filters = useMemo<IFilter[]>(
    () => [
      {
        id: "level",
        options: getFilterOptions("level", Object.values(EHealthStatusLevel)),
        title: content.healthMonitor_impact
      },
      {
        id: "target",
        options: getFilterOptions("target", Object.values(EHealthStatusTarget)),
        title: common.common_component
      },
      {
        id: "type",
        options: getFilterOptions("type", Object.values(EHealthStatusType)),
        title: content.healthMonitor_alert_type
      }
    ],
    [
      common.common_component,
      content.healthMonitor_alert_type,
      content.healthMonitor_impact,
      getFilterOptions
    ]
  );

  const outletCell = useCallback(
    ({ locationId, locationName }: IHealthStatus) => (
      <Link to={Routes.outletDetails.replace(":id", locationId)} title={locationName || locationId}>
        {locationName || locationId}
      </Link>
    ),
    []
  );

  const sortLevel = useCallback(
    (
      { chipSelectors: [{ sort: chipA }] }: IHealthStatus,
      { chipSelectors: [{ sort: chipB }] }: IHealthStatus
    ) => (chipA as number) - (chipB as number),
    []
  );

  const formatTime = useCallback(
    ({ createDateTime }: IHealthStatus) => {
      const date = new Date(createDateTime).toLocaleDateString(language, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const columns = useMemo(
    () => [
      {
        cell: outletCell,
        name: common.common_outlet,
        selector: (row: IHealthStatus) => row.locationName,
        sortable: true
      },
      {
        name: content.healthMonitor_impact,
        selector: (row: IHealthStatus) => row.levelContent,
        sortable: true,
        sortFunction: sortLevel,
        selectorId: "levelContent"
      },
      {
        name: common.common_component,
        selector: (row: IHealthStatus) => row.targetContent,
        sortable: true
      },
      {
        name: content.healthMonitor_alert_type,
        selector: (row: IHealthStatus) => row.typeContent,
        sortable: true
      },
      {
        name: common.common_info,
        selector: (row: IHealthStatus) => row.info,
        sortable: false,
        grow: 2
      },
      {
        format: formatTime,
        name: common.common_time,
        selector: (row: IHealthStatus) => row.createDateTime,
        sortable: true
      }
    ],
    [
      common.common_component,
      common.common_info,
      common.common_outlet,
      common.common_time,
      content.healthMonitor_alert_type,
      content.healthMonitor_impact,
      outletCell,
      formatTime,
      sortLevel
    ]
  );

  return (
    <DataTable
      columns={columns}
      data={healthStatuses}
      defaultSortAsc={false}
      defaultSortField="createDateTime"
      filters={filters}
      isLoading={isLoadingHealthStatus}
      searchPlaceholder={content.healthMonitor_alerts_search}
      searchSelector="locationName"
      title={content.healthMonitor_alerts}
      pagination
      paginationPerPage={100}
    />
  );
};

export default Alerts;

import classnames from "classnames";
import React from "react";
import "./detailpage.scss";

interface IProps {
  className?: string;
}

export class DetailPage extends React.PureComponent<IProps> {
  render() {
    return (
      <div className={classnames("detail-page", this.props.className)}>{this.props.children}</div>
    );
  }
}

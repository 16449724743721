import produce from "immer";
import { Reducer } from "redux";
import {
  CREATE_OUTLET,
  CREATE_OUTLET_ERROR,
  CREATE_OUTLET_SUCCESS,
  EDIT_OUTLET,
  EDIT_OUTLET_ERROR,
  EDIT_OUTLET_SUCCESS,
  RESET_OUTLET_FORM_STATE,
  GET_COUNTRIES_SUCCESS,
  GET_OUTLET_INFO_SUCCESS,
  GET_OUTLET_INSTALLATION_SUCCESS,
  GET_OUTLET_LINES_SUCCESS,
  GET_OUTLET_NOTES_SUCCESS,
  GET_OUTLET_TECHNICIANS_SUCCESS,
  GET_OUTLET_UNITS_SUCCESS,
  GET_OUTLETS_ERROR,
  GET_OUTLETS_REQUEST,
  GET_OUTLETS_SUCCESS,
  IOutletsState,
  OutletsActionTypes
} from "./types";

const initalState: IOutletsState = {
  outlets: [],
  countries: [],
  requestingOutlets: false,
  requestOutletsError: false,
  creatingOutlet: false,
  creatingOutletError: false,
  editingOutlet: false,
  editingOutletError: false
};

const reducer: Reducer<IOutletsState, OutletsActionTypes> = (
  state = initalState,
  action: OutletsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_OUTLETS_REQUEST:
        draft.requestingOutlets = true;
        draft.requestOutletsError = false;
        break;
      case GET_OUTLETS_SUCCESS:
        draft.requestingOutlets = false;
        draft.outlets = action.payload.sort((a, b) => (a.name < b.name ? -1 : 1));
        break;
      case GET_OUTLETS_ERROR:
        draft.requestOutletsError = true;
        draft.requestingOutlets = false;
        break;
      case GET_OUTLET_INFO_SUCCESS:
      case GET_OUTLET_LINES_SUCCESS:
      case GET_OUTLET_INSTALLATION_SUCCESS:
      case GET_OUTLET_UNITS_SUCCESS:
      case GET_OUTLET_TECHNICIANS_SUCCESS:
      case GET_OUTLET_NOTES_SUCCESS:
        draft.outlets = state.outlets.map(o =>
          o.id === action.payload.id
            ? {
                ...o,
                ...action.payload
              }
            : o
        );
        break;
      case GET_COUNTRIES_SUCCESS:
        draft.countries = action.payload.sort((a, b) => (a.name < b.name ? -1 : 1));
        break;
      case CREATE_OUTLET:
        draft.creatingOutlet = true;
        draft.creatingOutletError = false;
        break;
      case CREATE_OUTLET_SUCCESS:
        draft.creatingOutlet = false;
        draft.creatingOutletError = false;
        break;
      case CREATE_OUTLET_ERROR:
        draft.creatingOutlet = false;
        draft.creatingOutletError = true;
        break;
      case EDIT_OUTLET:
        draft.editingOutlet = true;
        draft.editingOutletError = false;
        break;
      case EDIT_OUTLET_SUCCESS:
        draft.editingOutlet = false;
        draft.editingOutletError = false;
        break;
      case EDIT_OUTLET_ERROR:
        draft.editingOutlet = false;
        draft.editingOutletError = true;
        break;
      case RESET_OUTLET_FORM_STATE:
        draft.editingOutlet = false;
        draft.editingOutletError = false;
        draft.creatingOutlet = false;
        draft.creatingOutletError = false;
        break;
      default:
        break;
    }
  });

export default reducer;

import classnames from "classnames";
import React, { FC, useMemo } from "react";
import { IBadge } from "../../../store/outlets/types";
import "./badge.scss";

const getColor = (type: string) => {
  const map: { [key: string]: string } = {
    default: "var(--color-grey-grey100)",
    success: "var(--color-success-success100)",
    warning: "var(--color-warning-warning100)",
    danger: "var(--color-alert-alert100)",
    success50: "var(--color-success-success50)",
    warning50: "var(--color-warning-warning50)",
    danger50: "var(--color-alert-alert50)"
  };

  return map[type];
};

interface IProps extends IBadge {
  onClick?: () => void;
}

const Badge: FC<IProps> = ({ onClick, outline = true, fill, fillOpacity, count, text, type }) => {
  const containerStyles = useMemo(
    () => ["container box-border", { containerOutlined: outline }, { containerFilled: fill }],
    [fill, outline]
  );

  const textStyles = useMemo(() => ["badgeText"], []);

  return (
    <div
      className={classnames(containerStyles)}
      style={{
        borderColor: outline || fill ? getColor(fillOpacity ? `${type}50` : type) : "inherit",
        backgroundColor: fill ? getColor(fillOpacity ? `${type}50` : type) : "inherit",
        cursor: onClick ? "pointer" : "initial"
      }}
      onClick={onClick}
    >
      <span
        className={classnames(textStyles)}
        style={{
          color: getColor(type),
          ...(outline && {
            color: type === "warning" ? "var(--color-grey-grey100)" : getColor(type)
          }),
          ...(fill && { color: "var(--color-text)" })
        }}
      >
        {count !== undefined ? (
          <>
            {count}
            <span className="countSeparator">&nbsp;•&nbsp;</span>
          </>
        ) : (
          ""
        )}
        {text}
      </span>
    </div>
  );
};

export default Badge;

import produce from "immer";
import { Reducer } from "redux";
import {
  BeveragesActionTypes,
  GET_BEVERAGE_DETAIL,
  GET_BEVERAGE_DETAIL_ERROR,
  GET_BEVERAGE_DETAIL_SUCCESS,
  GET_BEVERAGES,
  GET_BEVERAGES_ERROR,
  GET_BEVERAGES_SUCCESS,
  GET_BEVERAGES_TRANSLATIONS,
  GET_BEVERAGES_TRANSLATIONS_ERROR,
  GET_BEVERAGES_TRANSLATIONS_SUCCESS,
  IBeveragesState,
  SET_CURRENT_BEVERAGE
} from "./types";

const initalState: IBeveragesState = {
  beverages: [],
  beveragesX10: [],
  translations: [],
  requestingBeverages: false,
  requestingBeveragesError: false,
  requestingTranslations: false,
  requestingTranslationsError: false,
  requestingBeverageDetail: false,
  requestingBeverageDetailError: false
};

const reducer: Reducer<IBeveragesState, BeveragesActionTypes> = (
  state = initalState,
  action: BeveragesActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_BEVERAGES:
        draft.requestingBeverages = true;
        draft.requestingBeveragesError = false;
        break;
      case GET_BEVERAGES_SUCCESS:
        draft.requestingBeverages = false;
        draft.requestingBeveragesError = false;
        draft.beverages = action.payload.beverages;
        draft.beveragesX10 = action.payload.beveragesX10;
        break;
      case GET_BEVERAGES_ERROR:
        draft.requestingBeverages = false;
        draft.requestingBeveragesError = true;
        break;
      case GET_BEVERAGES_TRANSLATIONS:
        draft.requestingTranslations = true;
        draft.requestingTranslationsError = false;
        break;
      case GET_BEVERAGES_TRANSLATIONS_SUCCESS:
        draft.requestingTranslations = false;
        draft.requestingTranslationsError = false;
        draft.translations = action.payload;
        break;
      case GET_BEVERAGES_TRANSLATIONS_ERROR:
        draft.requestingTranslations = false;
        draft.requestingTranslationsError = true;
        break;
      case GET_BEVERAGE_DETAIL:
        // delete draft.currentBeverage;
        draft.requestingBeverageDetail = true;
        draft.requestingBeverageDetailError = false;
        break;
      case GET_BEVERAGE_DETAIL_SUCCESS:
        draft.currentBeverage = action.payload;
        draft.requestingBeverageDetail = false;
        draft.requestingBeverageDetailError = false;
        break;
      case GET_BEVERAGE_DETAIL_ERROR:
        draft.requestingBeverageDetail = false;
        draft.requestingBeverageDetailError = true;
        break;
      case SET_CURRENT_BEVERAGE:
        draft.currentBeverage = action.payload;
        draft.requestingBeverageDetail = false;
        draft.requestingBeverageDetailError = false;
        break;
      default:
        break;
    }
  });

export default reducer;

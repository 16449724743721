import {
  ASSOCIATE_DISTRIBUTOR_OUTLETS,
  ASSOCIATE_DISTRIBUTOR_OUTLETS_ERROR,
  ASSOCIATE_DISTRIBUTOR_OUTLETS_SUCCESS,
  CREATE_DISTRIBUTOR,
  CREATE_DISTRIBUTOR_ERROR,
  CREATE_DISTRIBUTOR_SUCCESS,
  EDIT_DISTRIBUTOR,
  EDIT_DISTRIBUTOR_ERROR,
  EDIT_DISTRIBUTOR_SUCCESS,
  GET_DISTRIBUTORS_ERROR,
  GET_DISTRIBUTORS_REQUEST,
  GET_DISTRIBUTORS_SUCCESS,
  IAssociateDistributorOutletsAction,
  IAssociateDistributorOutletsErrorAction,
  IAssociateDistributorOutletsSuccessAction,
  ICreateDistributorAction,
  ICreateDistributorErrorAction,
  ICreateDistributorObject,
  ICreateDistributorSuccessAction,
  IDistributor,
  IEditDistributorAction,
  IEditDistributorErrorAction,
  IEditDistributorSuccessAction,
  IGetDistributorsErrorAction,
  IGetDistributorsRequestAction,
  IGetDistributorsSuccessAction
} from "./types";

export const getDistributors = (): IGetDistributorsRequestAction => ({
  type: GET_DISTRIBUTORS_REQUEST
});

export const getDistributorsSuccess = (payload: IDistributor[]): IGetDistributorsSuccessAction => ({
  type: GET_DISTRIBUTORS_SUCCESS,
  payload
});

export const getDistributorsError = (): IGetDistributorsErrorAction => ({
  type: GET_DISTRIBUTORS_ERROR
});

export const createDistributor = (payload: ICreateDistributorObject): ICreateDistributorAction => ({
  type: CREATE_DISTRIBUTOR,
  payload
});

export const createDistributorSuccess = (
  payload: IDistributor
): ICreateDistributorSuccessAction => ({
  type: CREATE_DISTRIBUTOR_SUCCESS,
  payload
});

export const createDistributorError = (): ICreateDistributorErrorAction => ({
  type: CREATE_DISTRIBUTOR_ERROR
});

export const editDistributor = (payload: {
  data: ICreateDistributorObject;
  distributorId: string;
}): IEditDistributorAction => ({
  type: EDIT_DISTRIBUTOR,
  payload
});

export const editDistributorSuccess = (payload: IDistributor): IEditDistributorSuccessAction => ({
  type: EDIT_DISTRIBUTOR_SUCCESS,
  payload
});

export const editDistributorError = (): IEditDistributorErrorAction => ({
  type: EDIT_DISTRIBUTOR_ERROR
});

export const associateDistributorOutlets = (payload: {
  distributorId: string;
  outletIds: string | string[];
}): IAssociateDistributorOutletsAction => ({
  type: ASSOCIATE_DISTRIBUTOR_OUTLETS,
  payload
});

export const associateDistributorOutletsSuccess = (
  payload: IDistributor
): IAssociateDistributorOutletsSuccessAction => ({
  type: ASSOCIATE_DISTRIBUTOR_OUTLETS_SUCCESS,
  payload
});

export const associateDistributorOutletsError = (): IAssociateDistributorOutletsErrorAction => ({
  type: ASSOCIATE_DISTRIBUTOR_OUTLETS_ERROR
});

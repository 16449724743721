import { createSelector } from "reselect";
import { IAppState } from "..";
import {
  EExtra10OutletStatusBadges,
  EExtra10OutletStatusLevel,
  IExtra10,
  IExtra10Location,
  IExtra10OutletStatus,
  IExtra10Status
} from "./types";
import { getCurrentContent } from "../content/selectors";
import { getComponentStatus, getStatusChipSelector } from "./helpers";
import { BeverageTranslation, IBeverageTranslation } from "../beverages/types";
import { getBeverages } from "../beverages/selectors";

export const getExtra10Outlets = (state: IAppState) => state.extra10outlet.outlets;

export const getErrorChangingBeverage = (state: IAppState) =>
  state.extra10outlet.errorChangingBeverage;

export const getIsRequestingOutlets = (state: IAppState) => state.extra10outlet.requestingOutlets;

export const getIsLoadingOutlets = (state: IAppState) => state.extra10outlet.requestingOutlets;

export const getExtra10Outlet = (id: string) =>
  createSelector(getExtra10Outlets, (outlets: IExtra10Location[]) =>
    outlets.find(outlet => outlet.id === id)
  );

export const getExtra10OutletStatuses = createSelector(
  getCurrentContent,
  getExtra10Outlets,
  (currentContent, locations: IExtra10Location[]) => {
    const { outlets: content = {} } = currentContent || {};

    return locations.map((location: IExtra10Location): IExtra10OutletStatus => {
      const status = location.status?.[0];
      const statusBadge: EExtra10OutletStatusBadges = (status.level ===
        EExtra10OutletStatusLevel.NEUTRAL || status.level === EExtra10OutletStatusLevel.SUCCESS
        ? status.message
        : status.level) as unknown as EExtra10OutletStatusBadges;

      return {
        id: location.id,
        name: location.name,
        chipSelectors: [getStatusChipSelector(statusBadge)],
        status: statusBadge,
        statusContent: content[`outlets_status_${statusBadge.toLocaleLowerCase()}`],
        components: location.components,
        lastEdit: location.updateDateTime ? +new Date(location.updateDateTime) : undefined,
        createDateTime: location.createDateTime ? +new Date(location.createDateTime) : undefined
      };
    });
  }
);

export const getOutletExtra10Statuses = (id: string) =>
  createSelector(
    getCurrentContent,
    getExtra10Outlet(id),
    getBeverages,
    (currentContent, location: IExtra10Location | undefined, beverages: BeverageTranslation[]) => {
      const { common = {} } = currentContent || {};

      return (location?.installation?.x10s || []).map((x10: IExtra10): IExtra10Status => {
        const bev = beverages.find(b => b.id === x10.beverageId);

        return {
          id: x10.id,
          name: x10.name,
          boardSerialNumber: x10.boardSerialNumber || "--",
          brand: [
            {
              photo: bev?.logoUrl() || "",
              title: bev?.name || ""
            }
          ],
          volume: ((x10.volumeLeft || 0) / 1000).toFixed(2),
          status: getComponentStatus(x10.status)
        };
      });
    }
  );

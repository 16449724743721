import { Checkbox } from "@material-ui/core";
import classnames from "classnames";
import { Divider } from "@ddm-design-system/divider";
import { useOnClickOutside } from "@ddm-design-system/hooks";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import useContent from "../../../hooks/useContent";
import Icon from "../icon/Icon";
import "./simple_dropdown.scss";

export interface IItem {
  id: string;
  text: string;
  inactive?: boolean;
  selected?: boolean;
  preventClose?: boolean;
}

interface IProps<T extends IItem> {
  items: T[];
  selectedItem?: T;
  renderItem: (item: T, index?: number) => React.ReactNode;
  onItemSelected: (item: T) => void;
  className?: string;
  classNameText?: string;
  title?: string;
  style?: React.CSSProperties;
  onClose?: (items: T[]) => void;
  onSelectAll?: () => void;
  multiple?: boolean;
  placeholder?: string;
  allDisplayText?: string;
  disabled?: boolean;
}

interface IHandle {
  close: () => void;
}

export const SimpleDropdown: React.RefForwardingComponent<IHandle, IProps<IItem>> = (
  {
    items,
    selectedItem,
    renderItem,
    onItemSelected,
    className,
    classNameText,
    title,
    placeholder,
    multiple,
    onClose,
    onSelectAll,
    allDisplayText,
    style = {},
    disabled = false
  },
  ref
) => {
  const { common: contentCommon } = useContent();
  const [open, setOpen] = useState(false);

  const handleClickOutside = useCallback(() => {
    setOpen(false);

    if (onClose) {
      onClose(items);
    }
  }, [items, onClose]);
  const toggleList = useCallback(() => setOpen(!open), [open]);

  const itemsSelected = useMemo(() => {
    return items.filter(s => s.selected);
  }, [items]);

  const allSelected = useMemo(() => {
    return itemsSelected.length === items.length || itemsSelected.length === 0;
  }, [items, itemsSelected]);

  useEffect(() => {
    if (open && selectedItem) {
      const element = document.querySelector(".dd-list");

      if (element) {
        element.scrollTop = items.findIndex(i => i.id === selectedItem.id) * 40;
      }
    }
  }, [items, open, selectedItem]);

  const refObject = useRef(null);

  useOnClickOutside([refObject], () => {
    setOpen(false);
  });

  useImperativeHandle(ref, () => ({
    close: () => {
      setOpen(false);
    }
  }));

  return (
    <div style={{ display: "flex", alignItems: "center" }} ref={refObject}>
      {title && <div className={classnames("dd-title", classNameText, { disabled })}>{title}</div>}
      <div className="dd-wrapper-container" style={{ ...style }}>
        <div className={classnames("dd-wrapper", className, { open, disabled })}>
          <div
            className={classnames("dd-header", { open })}
            onClick={() => {
              if (!disabled) {
                toggleList();
              }
            }}
          >
            <div className={classnames("dd-header-title", { disabled })}>
              {(!multiple
                ? selectedItem && selectedItem.text
                : (allSelected && allDisplayText) ||
                  `${itemsSelected.length} ${contentCommon.common_selected}`) || placeholder}
            </div>
            <Icon name="arrow" className={classnames({ open, disabled })} />
          </div>
          {open && (
            <div className="dd-list">
              {multiple && (
                <>
                  <Divider className="dd-list-divider" />
                  <div
                    className={classnames("dd-list-item", {
                      selected: allSelected
                    })}
                    onClick={() => {
                      if (onSelectAll) {
                        onSelectAll();
                      }
                    }}
                  >
                    <Checkbox disableRipple checked={allSelected} />
                    <span className="dd-list-item-text">
                      {allDisplayText || contentCommon.common_all}
                    </span>
                  </div>
                </>
              )}
              <Divider className="dd-list-divider" />
              {items.map((item: IItem, index: number) => (
                <div
                  className={classnames("dd-list-item", {
                    "all-selected": multiple && allSelected,
                    selected: (selectedItem && selectedItem.id === item.id) || item.selected
                  })}
                  key={item.id}
                  onClick={() => {
                    onItemSelected(item);

                    if (multiple || item.preventClose) {
                      return;
                    }

                    setOpen(false);

                    if (onClose) {
                      onClose(items);
                    }
                  }}
                  title={item.text}
                >
                  {multiple && <Checkbox disableRipple checked={item.selected} />}
                  <span className="dd-list-item-text">{renderItem(item, index)}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {open && <div className="dd-overlay" onClick={() => handleClickOutside()} />}
      </div>
    </div>
  );
};

export default forwardRef(SimpleDropdown);

import { IUser } from "../../services/jcore";

export const LOGOUT = "auth/LOGOUT";
export const GET_ME = "auth/GET_ME";
export const GET_ME_SUCCESS = "auth/GET_ME_SUCCESS";

export interface ILogout {
  type: typeof LOGOUT;
}

export interface IGetMe {
  type: typeof GET_ME;
}

export interface IGetMeSuccess {
  type: typeof GET_ME_SUCCESS;
  payload: IUser;
}

export interface IAuthState {
  me?: IUser;
}

export type AuthActionTypes = ILogout | IGetMe | IGetMeSuccess;

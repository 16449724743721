import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Body, ButtonText, SectionTitle } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { useIsTablet } from "@ddm-design-system/hooks";
import { Menu } from "@ddm-design-system/menu";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { SecondaryButton } from "@ddm-design-system/button";
import useContent from "../../../hooks/useContent";
import { getUser } from "../../../store/auth/selectors";
import { IUser } from "../../../services/jcore";
import Routes from "../../../routes";
import history from "../../../history";
import {
  ProfileMenuItem,
  ProfileMenuDescription,
  ProfileMenuLogout,
  ProfileNavButton,
  ConfirmButton
} from "./profile.styles";

export const Profile: React.FC = () => {
  const isTablet = useIsTablet();

  const loggedUser: IUser | undefined = useSelector(getUser);

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState<boolean>();
  const [isSidebarProfileOpen, setIsSidebarProfileOpen] = useState<boolean>();

  const {
    loginControlTower: {
      control_tower_logout_message: logoutMessage,
      control_tower_logout_confirm: confirmLogout
    },
    sidebar: { sidebar_logout: sidebarLogout },
    common: { common_cancel: cancel }
  } = useContent();

  const handleLogoutComfirm = () => {
    if (!history) return;

    history.push(Routes.logout);
    setIsLogoutModalVisible(false);
  };

  const renderMenuHeader = () => (
    <ProfileNavButton
      data-hj-surpress
      icon="User"
      selected={isSidebarProfileOpen}
      isTablet={isTablet}
    >
      {loggedUser?.name || loggedUser?.username || ""}
    </ProfileNavButton>
  );

  return (
    <>
      <Menu
        renderHeader={renderMenuHeader}
        direction="top"
        onHide={() => setIsSidebarProfileOpen(false)}
        onShow={() => setIsSidebarProfileOpen(true)}
      >
        <ProfileMenuItem>
          <>
            <SectionTitle>{loggedUser?.name || loggedUser?.username}</SectionTitle>
            <ProfileMenuDescription>{loggedUser?.username}</ProfileMenuDescription>
          </>
        </ProfileMenuItem>
        <ProfileMenuLogout icon="Exit" onClick={() => setIsLogoutModalVisible(true)}>
          <ButtonText>{sidebarLogout}</ButtonText>
        </ProfileMenuLogout>
      </Menu>
      <Modal isOpen={isLogoutModalVisible} onRequestClose={() => setIsLogoutModalVisible(false)}>
        <ModalHeader icon={<Icon name="Exit" />} headerTitle={sidebarLogout} />
        <ModalBody>
          <Body>{logoutMessage}</Body>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={() => setIsLogoutModalVisible(false)}>{cancel}</SecondaryButton>
          <ConfirmButton onClick={handleLogoutComfirm}>{confirmLogout}</ConfirmButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Profile;

export const GET_USERS_REQUEST = "users/GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "users/GET_USERS_SUCCESS";

export const GET_USER_REQUEST = "users/GET_USER_REQUEST";
export const GET_USER_SUCCESS = "users/GET_USER_SUCCESS";

export const CREATE_USER = "users/CREATE_USER";
export const CREATE_USER_SUCCESS = "users/CREATE_USER_SUCCESS";

export const EDIT_USER = "users/EDIT_USER";

export const DELETE_USER = "users/DELETE_USER";
export const DELETE_USER_SUCCESS = "users/DELETE_USER_SUCCESS";

export enum Roles {
  ROLE_SUPERVISOR = "Supervisor",
  ROLE_MATRIX = "Sales Rep",
  ROLE_SALES_REP = "Sales Rep"
}

export interface IInvitedUser {
  id: string;
  username: string;
  role: string;
  name: string;
  permissions: any;
  locations: any[];
  createDateTime: string;
  emailDigestFrequencies: any[];
  allLocationsVisibility: boolean;
}
export interface IInvitedUserParams {
  username: string;
  allVisibility: boolean;
  locationIds?: string[];
  role: string;
}
export interface IInvitedUserEditParams {
  userId: string;
  allVisibility: boolean;
  locationIds: string[];
  role: string;
}
export interface IUsersState {
  users: IInvitedUser[];
  isLoadingUsers: boolean;
  isLoadingUser: boolean;
  selectedUser?: IInvitedUser;
  userWasDeleted: boolean;
}
export interface IGetUsersRequest {
  type: typeof GET_USERS_REQUEST;
}
export interface IGetUsersSuccess {
  type: typeof GET_USERS_SUCCESS;
  payload: IInvitedUser[];
}
export interface IGetUserRequest {
  type: typeof GET_USER_REQUEST;
  payload: string;
}
export interface IGetUserSuccess {
  type: typeof GET_USER_SUCCESS;
  payload: IInvitedUser;
}
export interface ICreateUser {
  type: typeof CREATE_USER;
  payload: IInvitedUserParams;
}
export interface ICreateUserSuccess {
  type: typeof CREATE_USER_SUCCESS;
}
export interface IEditUser {
  type: typeof EDIT_USER;
  payload: IInvitedUserEditParams;
}
export interface IDeleteUser {
  type: typeof DELETE_USER;
  payload: string;
}
export interface IDeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS;
}
export type UsersActionTypes =
  | IGetUsersRequest
  | IGetUserRequest
  | IGetUsersSuccess
  | IGetUserSuccess
  | ICreateUser
  | ICreateUserSuccess
  | IDeleteUserSuccess;

import produce from "immer";
import { Reducer } from "redux";
import {
  GET_COMPONENT_INFO,
  GET_COMPONENT_INFO_SUCCESS,
  GET_COMPONENT_INFO_ERROR,
  GET_COMPONENT_LINES,
  GET_COMPONENT_LINES_SUCCESS,
  GET_COMPONENT_LINES_ERROR,
  GET_COMPONENT_HEALTH_STATUS,
  GET_COMPONENT_HEALTH_STATUS_SUCCESS,
  GET_COMPONENT_HEALTH_STATUS_ERROR,
  IComponentState,
  ComponentActionTypes
} from "./types";

const initalState: IComponentState = {
  currentComponent: undefined,
  componentLines: undefined,
  componentHealthStatus: undefined,
  requestingComponent: false,
  requestingComponentLines: false,
  requestingComponentHealthStatus: false,
  requestComponentError: false,
  requestLinesError: false,
  requestHealthStatusError: false
};

const reducer: Reducer<IComponentState, ComponentActionTypes> = (
  state = initalState,
  action: ComponentActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_COMPONENT_INFO:
        draft.requestingComponent = true;
        draft.requestComponentError = false;
        break;
      case GET_COMPONENT_INFO_SUCCESS:
        draft.requestingComponent = false;
        draft.currentComponent = action.payload;
        break;
      case GET_COMPONENT_INFO_ERROR:
        draft.requestingComponent = false;
        draft.requestComponentError = true;
        draft.currentComponent = undefined;
        break;
      case GET_COMPONENT_LINES:
        draft.requestingComponentLines = true;
        draft.requestComponentError = false;
        break;
      case GET_COMPONENT_LINES_SUCCESS:
        draft.requestingComponentLines = false;
        draft.componentLines = action.payload.lines;
        break;
      case GET_COMPONENT_LINES_ERROR:
        draft.requestingComponentLines = false;
        draft.requestLinesError = true;
        break;
      case GET_COMPONENT_HEALTH_STATUS:
        draft.requestingComponentHealthStatus = true;
        draft.requestComponentError = false;
        break;
      case GET_COMPONENT_HEALTH_STATUS_SUCCESS:
        draft.requestingComponentHealthStatus = false;
        draft.componentHealthStatus = action.payload;
        break;
      case GET_COMPONENT_HEALTH_STATUS_ERROR:
        draft.requestingComponentHealthStatus = false;
        draft.requestHealthStatusError = true;
        break;
      default:
        break;
    }
  });

export default reducer;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { createUser, getUsers } from "../../store/users/actions";
import { IInvitedUser, IInvitedUserParams, Roles } from "../../store/users/types";
import Badge from "../common/badge/Badge";
import Header from "../common/header/Header";
import DataTable from "../common/data_table/DataTable";
import { getCurrentLanguage } from "../../store/content/selectors";
import { getIsLoadingUsers } from "../../store/users/selectors";
import CreateUserForm from "./CreateUserForm";

export const Users: React.FC = () => {
  const { common: contentCommon, controlTowerInvitedUsers: content } = useContent();
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);
  const dispatch = useDispatch();
  const language = useSelector(getCurrentLanguage);
  const isLoading = useSelector(getIsLoadingUsers);
  const users = useSelector((state: IAppState) => state.users.users);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const headerActions = [
    {
      label: `+ ${content.control_tower_invited_users_create_new}`,
      onClick: () => setVisibleCreateModal(true)
    }
  ];

  const formatUser = useCallback(
    ({ id, username }: IInvitedUser) => {
      return (
        <Link to={Routes.usersDetail.replace(":id", id)} title={username}>
          {username}
        </Link>
      );
    },
    [content]
  );

  const formatTimeCreate = useCallback(
    ({ createDateTime }: IInvitedUser) => {
      const date = createDateTime
        ? new Date(createDateTime).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const formatOutlets = useCallback(
    ({ allLocationsVisibility, locations, role }: IInvitedUser) => {
      return (
        <div className="flex flex-col gap-xs py-xs w-full">
          <Badge
            id="invited"
            text={
              allLocationsVisibility || Object(Roles)[role] === Roles.ROLE_SUPERVISOR
                ? content.control_tower_invited_users_has_all
                : (content.control_tower_invited_users_has_outlets || "").replace(
                    "%OUTLETS%",
                    locations.length.toString()
                  )
            }
            count={contentCommon.common_manager_app}
            type="success"
            fill
            fillOpacity
          />

          {Object(Roles)[role] === Roles.ROLE_SUPERVISOR && (
            <Badge
              id="common"
              text={contentCommon.common_control_tower}
              type="warning"
              fill
              fillOpacity
            />
          )}
        </div>
      );
    },
    [content, contentCommon]
  );

  const columns = useMemo(
    () => [
      {
        format: formatUser,
        name: contentCommon.common_form_email_placeholder,
        selector: (row: IInvitedUser) => row.username,
        sortable: true
      },
      {
        name: contentCommon.common_name,
        selector: (row: IInvitedUser) => row.name,
        sortable: true
      },
      {
        format: formatOutlets,
        name: contentCommon.common_outlets,
        selector: (row: IInvitedUser) => row.allLocationsVisibility
      },
      {
        format: formatTimeCreate,
        name: contentCommon.common_date_created,
        selector: (row: IInvitedUser) => row.createDateTime || 0,
        sortable: true
      }
    ],
    [formatUser, content, contentCommon, formatTimeCreate, formatOutlets]
  );

  const inviteUser = (user: IInvitedUserParams) => {
    dispatch(createUser(user));
    setVisibleCreateModal(false);
  };

  return (
    content && (
      <div>
        <Header title={content.control_tower_invited_users_title} actions={headerActions} />
        <DataTable
          columns={columns}
          data={users}
          defaultSortAsc={false}
          defaultSortField="username"
          isLoading={isLoading}
          searchPlaceholder={content.control_tower_invited_users_search_placeholder}
          searchSelector={["username", "name"]}
          pagination
          paginationPerPage={100}
          title=""
        />

        <CreateUserForm
          visible={visibleCreateModal}
          onCreateUser={(newUser: IInvitedUserParams) => {
            setVisibleCreateModal(false);
            inviteUser(newUser);
          }}
          onClose={() => setVisibleCreateModal(false)}
        />
      </div>
    )
  );
};

export default Users;

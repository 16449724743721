import React from "react";
import { Link } from "react-router-dom";
import {
  BreadcrumbsContainer,
  BreadcrumbsSeparator,
  Breadcrumb,
  LastBreadcrumb
} from "./breadcrumbs.styles";

export interface IBreadCrumb {
  link: string;
  name: string;
}

interface IProps {
  current?: string;
  items?: IBreadCrumb[];
}

const Breadcrumbs: React.FC<IProps> = ({ current, items, ...props }) => (
  <BreadcrumbsContainer {...props}>
    {items?.map((item: IBreadCrumb, itemIndex: number) => (
      <React.Fragment key={itemIndex}>
        <Breadcrumb as={Link} to={item.link}>
          {item.name}
        </Breadcrumb>
        <BreadcrumbsSeparator />
      </React.Fragment>
    ))}
    <LastBreadcrumb>{current}</LastBreadcrumb>
  </BreadcrumbsContainer>
);

export default Breadcrumbs;

import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes";
import Header from "../common/header/Header";
import useContent from "../../hooks/useContent";
import Tabs, { ITab } from "../common/tabs/Tabs";
import { getIsLoadingOutlets, getOutlet } from "../../store/outlets/selectors";
import Installation from "./installation/Installation";
import Info from "./info/Info";
import Lines from "./lines/Lines";
import { day8 } from "../../lib/Time/TimeFunctions";
import ExportDataModal from "./ExportDataModal";
import Time from "../../lib/Time/Time";
import { exportData } from "../../store/outlets/actions";
import { LoadingIcon } from "../common/data_table/dataTable.styles";
import Units from "./units/Units";
import AssociatedTechnicians from "./associated-technicians/AssociatedTechnicians";
import OutletHealthMonitor from "./health-monitor/OutletHealthMonitor";
import Notes from "./notes/Notes";

interface IProps {
  outletId: string;
}

const OutletDetails: React.FC<IProps> = ({ outletId }) => {
  const { common, outletDetails: content } = useContent();
  const dispatch = useDispatch();
  const [showExportDataModal, setShowExportDataModal] = useState(false);
  const outlet = useSelector(getOutlet(outletId));
  const loading = useSelector(getIsLoadingOutlets);

  const onExportData = useCallback(
    (start, end) => {
      setShowExportDataModal(false);

      if (!outlet) return;

      const timezone = outlet?.locationData?.timezone;

      if (timezone) {
        Time.timezone = timezone;
      }

      const startDate = +day8(new Time(start).add({ day: 1 }));
      const endDate = +day8(new Time(end).add({ day: 2 }));

      dispatch(exportData(outletId, outlet?.name, startDate, endDate));
    },
    [dispatch, outlet, outletId]
  );

  const headerActions = useMemo(
    () =>
      outlet && [
        {
          label: common.common_export_data,
          onClick: () => {
            setShowExportDataModal(true);
          }
        },
        {
          label: common.common_manager_app_page,
          onClick: () =>
            window.open(
              `https://${process.env.REACT_APP_MANAGER_APP}/overview/${outlet.id}`,
              "_blank"
            )
        }
      ],
    [outlet, common]
  );

  const tabs = useMemo<ITab[]>(
    () => [
      {
        content: <Info outletId={outletId} />,
        label: common.common_info,
        route: Routes.outletDetailsInfo.replace(":id", outletId)
      },
      {
        content: <Installation outletId={outletId} />,
        label: common.common_installation,
        route: Routes.outletDetailsInstallation.replace(":id", outletId)
      },
      {
        content: <Units outletId={outletId} />,
        label: common.common_units,
        route: Routes.outletDetailsUnits.replace(":id", outletId)
      },
      {
        content: <Lines outletId={outletId} />,
        label: common.common_lines,
        route: Routes.outletDetailsLines.replace(":id", outletId)
      },
      {
        content: <AssociatedTechnicians outletId={outletId} />,
        label: common.common_technicians,
        route: Routes.outletDetailsAssociatedTechnicians.replace(":id", outletId)
      },
      {
        content: <OutletHealthMonitor outletId={outletId} />,
        label: common.common_health_monitor,
        route: Routes.outletDetailsHealthMonitor.replace(":id", outletId)
      },
      {
        content: <Notes outletId={outletId} />,
        label: common.common_notes,
        route: Routes.outletDetailsNotes.replace(":id", outletId)
      }
    ],
    [outletId, common]
  );

  return (
    <div>
      <Header
        title={outlet?.name}
        breadcrumbs={[{ link: Routes.outlets, name: content.outlet_details_back_text }]}
        actions={headerActions}
      />
      <div style={{ overflow: "auto", flex: 1 }}>
        {loading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingIcon />
          </div>
        ) : outlet ? (
          <Tabs tabs={tabs} />
        ) : (
          <div>{content.outlet_details_not_found.replace("%ID%", outletId)}</div>
        )}
      </div>

      {outlet && (
        <ExportDataModal
          visible={showExportDataModal}
          onClose={() => setShowExportDataModal(false)}
          onSubmit={onExportData}
        />
      )}
    </div>
  );
};

export default OutletDetails;

import { translate, translateImage, translateString } from "./translate";
import { IEntries } from "./index";

export function mapMediaContainer(entries: IEntries) {
  let mediaContainer = entries.mediaContainer.ordered;

  mediaContainer = mediaContainer.filter(m => {
    const app = translateString(m.fields.app);

    return app === "Control Tower";
  });

  const media = translate(mediaContainer[0]?.fields.media);

  return {
    mediaContainer: media.map(
      (d: any) => translateImage(d, process.env.REACT_APP_DEFAULT_LANG, true).path
    )
  };
}

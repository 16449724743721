import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";

export const DataTableContainer = styled.div``;

export const DataTableHeader = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spaceUnit.md};
    margin-bottom: ${theme.spaceUnit.lg};
  `
);

export const DataTableHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const DataTableFilterBar = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.spaceUnit.lg};
  `
);

export const LoadingIcon = styled(Icon).attrs({ name: "DmLogo" })(
  ({ theme }) => css`
    animation: ${theme.animations.rotate} 1s infinite;
    height: ${theme.spaceUnit.xxl};
    margin: ${theme.spaceUnit.xl} 0;
    width: ${theme.spaceUnit.xxl};
  `
);

import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnalyticsContext } from "../../../services/analytics";
import { IAppState } from "../../../store";
import { getCurrentContent } from "../../../store/content/selectors";
import { IContent } from "../../../store/content/types";
import { ITechnician } from "../../../store/technicians/types";
import Button from "../button/Button";
import Select from "../select/Select";
import { ILocation } from "../../../store/outlets/types";

export interface IProps {
  technicians?: ITechnician[];
  outlets?: ILocation[];
  technician?: ITechnician;
  outlet?: ILocation;
  content?: IContent;
  onSubmit?: (technician: ITechnician, outlet: ILocation) => void;
  onChange?: (technician?: ITechnician, outlet?: ILocation) => void;
  associate?: (technicianId: string, outletId: string) => void;
  buttonLabel?: string;
}

export const AssociateTechnicianForm: React.FC<IProps> = ({
  technicians = [],
  outlets = [],
  technician,
  outlet,
  onChange,
  onSubmit,
  content,
  buttonLabel = content ? content.common_associate_button_label : ""
}) => {
  const analytics = useContext(AnalyticsContext);
  const [techniciansOptions, setTechniciansOptions] = useState(
    technicians.map(t => ({
      text: t.name,
      ...t
    }))
  );
  const [outletsOptions, setOutletsOptions] = useState(
    outlets.map(o => ({
      text: o.name,
      ...o
    }))
  );

  const [startDate, setStartDate] = useState<Date>();
  const [selectedTechnician, selectTechnician] = useState(
    technician ? techniciansOptions.find(t => t.id === technician.id) : undefined
  );
  const [selectedOutlet, selectOutlet] = useState(
    outlet ? outletsOptions.find(o => o.id === outlet.id) : undefined
  );

  useEffect(() => {
    const options = technicians.map(t => ({
      text: t.name,
      ...t
    }));

    setTechniciansOptions(options);
    selectTechnician(technician ? options.find(t => t.id === technician.id) : undefined);
  }, [technician, technicians]);

  useEffect(() => {
    const options = outlets.map(o => ({
      text: o.name,
      ...o
    }));

    setOutletsOptions(options);
    selectOutlet(outlet ? options.find(o => o.id === outlet.id) : undefined);
  }, [outlet, outlets]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedTechnician, selectedOutlet);
    }
  }, [onChange, selectedTechnician, selectedOutlet]);

  const associateTechnician = () => {
    if (!selectedTechnician || !selectedOutlet) {
      return;
    }

    const date = startDate || new Date();

    analytics.logEvent(
      "TECHNICIAN_ASSOCIATED",
      selectedTechnician.name,
      selectedOutlet.name,
      (new Date().getTime() - date.getTime()) / 1000
    );
    setStartDate(undefined);

    if (onSubmit) {
      onSubmit(selectedTechnician, selectedOutlet);
    }
  };

  const updateStartDate = (force?: boolean) => {
    if (force || startDate === undefined) {
      setStartDate(new Date());
    }
  };

  return content ? (
    <div className="associate-technician flex">
      <Select
        search
        className="grow mr-lg"
        style={{ flex: 2 }}
        label="Technician"
        placeholder={content.common_search_technician_placeholder}
        selectedOption={selectedTechnician}
        options={techniciansOptions}
        onOptionSelected={option => selectTechnician(option)}
        onShow={updateStartDate}
      />
      <Select
        search
        className="grow mr-lg"
        style={{ flex: 2 }}
        label="Outlet"
        placeholder={content.common_search_outlet_placeholder}
        selectedOption={selectedOutlet}
        options={outletsOptions}
        onOptionSelected={option => selectOutlet(option)}
        onShow={updateStartDate}
      />
      <Button
        disabled={!selectedTechnician || !selectedOutlet}
        secondary
        style={{ flex: 1, minWidth: "unset" }}
        onClick={() => associateTechnician()}
      >
        {buttonLabel}
      </Button>
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);

  return {
    content: content ? content.common : null,
    technicians: state.technician.technicians,
    outlets: state.outlet.outlets
  };
};

export default connect(mapStateToProps, null)(AssociateTechnicianForm);

import React from "react";
import Button from "../button/Button";

interface IProps {
  question: string;
  cancelCallback?: () => void;
  confirmCallback?: () => void;
}
export const ConfirmationModal: React.FC<IProps> = ({
  question,
  cancelCallback,
  confirmCallback
}) => {
  return (
    <div className="confirmation-modal">
      <p className="confirmation-modal-question">{question}</p>
      <div className="confirmation-modal-options">
        <Button
          className="confirmation-modal-options-cancel"
          inline
          onClick={() => cancelCallback && cancelCallback()}
        >
          Cancel
        </Button>
        <Button error onClick={() => confirmCallback && confirmCallback()}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
export default ConfirmationModal;

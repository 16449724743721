import styled, { css } from "styled-components";
import { Flipper } from "react-flip-toolkit";
import "flag-icon-css/css/flag-icon.css";

export const ExtendedFlagContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spaceUnit.md} ${theme.spaceUnit.xxl};
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  `
);

export const FlagElementContainer = styled.div<{ selected?: boolean }>(
  ({ selected, theme }) => css`
    align-items: center;
    border: 1px solid ${theme.colors.grey.grey50};
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    gap: ${theme.spaceUnit.md};
    padding: ${theme.spaceUnit.xs} ${theme.spaceUnit.md};

    ${selected &&
    css`
      border-color: ${theme.colors.black};
    `}
  `
);

export const FlagContainer = styled.div`
  display: flex;
  position: relative;
`;

export const FlagChoices = styled(Flipper)<{ expandLeft?: boolean; isHidden?: boolean }>(
  ({ expandLeft, isHidden, theme }) => css`
    display: flex;
    gap: ${theme.spaceUnit.xs};
    left: calc(${theme.spaceUnit.xl} + ${theme.spaceUnit.xs});
    position: absolute;
    background-color: ${theme.colors.grey.grey25};
    padding: ${theme.spaceUnit.xs};
    margin-top: -${theme.spaceUnit.xs};
    border-radius: 10px;

    ${expandLeft &&
    css`
      left: unset;
      right: calc(${theme.spaceUnit.xl} + ${theme.spaceUnit.xs});
    `}

    ${isHidden &&
    css`
      left: 0;

      ${Flag} {
        left: 0;
        position: absolute;
      }
    `}
  `
);

interface IFlagProps {
  country?: string;
}

export const Flag = styled.div.attrs<IFlagProps>(({ country }) => ({
  className: `flag-icon flag-icon-squared flag-icon-${country}`
}))<IFlagProps>(
  ({ theme }) => css`
    border-radius: 50%;
    cursor: pointer;
    height: ${theme.spaceUnit.xl} !important;
    line-height: unset;
    width: ${theme.spaceUnit.xl} !important;
  `
);

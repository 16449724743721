import produce from "immer";
import { Reducer } from "redux";
import {
  DELETE_TECHNICIAN_SUCCESS,
  EDIT_TECHNICIAN_ERROR,
  EDIT_TECHNICIAN_REQUEST,
  EDIT_TECHNICIAN_SUCCESS,
  GET_TECHNICIAN_DEVICE_CODE_ERROR,
  GET_TECHNICIAN_DEVICE_CODE_REQUEST,
  GET_TECHNICIAN_DEVICE_CODE_SUCCESS,
  GET_TECHNICIAN_REQUEST,
  GET_TECHNICIAN_SUCCESS,
  GET_TECHNICIANS_ERROR,
  GET_TECHNICIANS_REQUEST,
  GET_TECHNICIANS_SUCCESS,
  ITechnicianState,
  NEW_TECHNICIAN_ERROR,
  NEW_TECHNICIAN_REQUEST,
  NEW_TECHNICIAN_SUCCESS,
  RESET_TECHNICIAN_ID,
  RESET_TECHNICIANS,
  TechnicianActionTypes,
  SET_TECHNICIAN_INTERNATIONAL_REQUEST,
  SET_TECHNICIAN_INTERNATIONAL_SUCCESS,
  SET_TECHNICIAN_INTERNATIONAL_ERROR
} from "./types";

export const initialState: ITechnicianState = {
  technicians: [],
  requestTechniciansError: false,
  requestingTechnicians: false,
  requestingCurrentTechnician: false,
  currentTechnician: undefined,
  requestingTechnicianDeviceCode: false,
  technicianWasDeleted: false,
  newTechnicianId: ""
};

const reducer: Reducer<ITechnicianState, TechnicianActionTypes> = (
  state = initialState,
  action: TechnicianActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_TECHNICIANS_REQUEST:
        draft.requestingTechnicians = true;
        break;
      case GET_TECHNICIANS_ERROR:
        draft.requestingTechnicians = false;
        draft.requestTechniciansError = true;
        break;
      case GET_TECHNICIANS_SUCCESS:
        draft.requestingTechnicians = false;
        draft.technicians = action.payload;
        break;
      case NEW_TECHNICIAN_REQUEST:
        draft.requestingCreateTechnician = true;
        draft.requestingCreateTechnicianError = false;
        break;
      case NEW_TECHNICIAN_SUCCESS:
        draft.newTechnicianId = action.payload.id;
        draft.requestingCreateTechnician = false;
        draft.requestingCreateTechnicianError = false;
        break;
      case NEW_TECHNICIAN_ERROR:
        draft.requestingCreateTechnician = false;
        draft.requestingCreateTechnicianError = true;
        break;
      case GET_TECHNICIAN_REQUEST:
        draft.requestingCurrentTechnician = true;
        break;
      case GET_TECHNICIAN_SUCCESS:
        draft.requestingCurrentTechnician = false;
        draft.technicianWasDeleted = false;
        draft.currentTechnician = action.payload;
        break;
      case RESET_TECHNICIAN_ID:
        draft.newTechnicianId = "";
        break;
      case GET_TECHNICIAN_DEVICE_CODE_REQUEST:
        draft.technicianDeviceCodeError = false;
        draft.requestingTechnicianDeviceCode = true;
        break;
      case GET_TECHNICIAN_DEVICE_CODE_SUCCESS:
        if (draft.currentTechnician?.id === action.payload.id) {
          draft.currentTechnician = {
            ...draft.currentTechnician,
            registerCode: action.payload.registerCode
          };
        }

        draft.technicianDeviceCode = action.payload.registerCode;
        draft.technicianDeviceCodeError = false;
        draft.requestingTechnicianDeviceCode = false;
        break;
      case GET_TECHNICIAN_DEVICE_CODE_ERROR:
        draft.technicianDeviceCodeError = true;
        break;
      case DELETE_TECHNICIAN_SUCCESS:
        draft.technicianWasDeleted = true;
        break;
      case EDIT_TECHNICIAN_REQUEST:
        draft.requestingEditTechnician = true;
        break;
      case EDIT_TECHNICIAN_SUCCESS:
        draft.requestingEditTechnician = false;
        draft.technicians = draft.technicians.map(o =>
          o.id === action.payload.technicianId
            ? {
                ...o,
                name: action.payload.params.name,
                phoneNumber: action.payload.params.phoneNumber,
                email: action.payload.params.email
              }
            : o
        );
        break;
      case EDIT_TECHNICIAN_ERROR:
        draft.requestingEditTechnician = false;
        break;
      case SET_TECHNICIAN_INTERNATIONAL_REQUEST:
        draft.requestingSetTechnicianInternational = true;
        draft.requestSetTechnicianInternationalError = false;
        break;
      case SET_TECHNICIAN_INTERNATIONAL_SUCCESS:
        draft.requestingSetTechnicianInternational = false;
        break;
      case SET_TECHNICIAN_INTERNATIONAL_ERROR:
        draft.requestingSetTechnicianInternational = false;
        draft.requestSetTechnicianInternationalError = true;
        break;
      case RESET_TECHNICIANS:
        draft.technicians = [];
        draft.currentTechnician = undefined;
        draft.requestTechniciansError = false;
        draft.requestingTechnicians = false;
        draft.requestingCurrentTechnician = false;
        draft.currentTechnician = undefined;
        draft.requestingTechnicianDeviceCode = false;
        draft.technicianWasDeleted = false;
        draft.newTechnicianId = "";
        break;
      default:
        break;
    }
  });

export default reducer;

import {
  GET_CELOCATIONS_ERROR,
  GET_CELOCATIONS_REQUEST,
  GET_CELOCATIONS_SUCCESS,
  ICELocation,
  ICELocationAddressEdit,
  IGetCeLocationsError,
  IGetCeLocationsRequest,
  IGetCeLocationsSuccess,
  IPutCeLocation,
  IPutCeLocationAddressRequest,
  IPutCeLocationAddressSuccess,
  IPutCeLocationFlex,
  IPutCeLocationFlexRequest,
  IPutCeLocationRequest,
  IPutCeLocationSuccess,
  IDeleteCePhoto,
  IPutCeLocationPhoto,
  PUT_CELOCATION_ADDRESS_REQUEST,
  PUT_CELOCATION_ADDRESS_SUCCESS,
  PUT_CELOCATION_FLEX_REQUEST,
  PUT_CELOCATION_REQUEST,
  PUT_CELOCATION_SUCCESS,
  DELETE_CELOCATION_PHOTO,
  PUT_CELOCATION_PHOTO,
  IDeleteCeLocationRequest,
  DELETE_CELOCATION_REQUEST
} from "./types";

export const getCeLocations = (): IGetCeLocationsRequest => ({
  type: GET_CELOCATIONS_REQUEST
});
export const getCeLocationsSuccess = (
  ceLocations: ICELocation[],
  content: any
): IGetCeLocationsSuccess => ({
  type: GET_CELOCATIONS_SUCCESS,
  payload: {
    ceLocations,
    content
  }
});

export const getCeLocationsError = (): IGetCeLocationsError => ({
  type: GET_CELOCATIONS_ERROR
});

export const putCeLocation = (
  ceInstallationId: string,
  ceLocationInfo: IPutCeLocation
): IPutCeLocationRequest => ({
  type: PUT_CELOCATION_REQUEST,
  payload: {
    ceInstallationId,
    ceLocationInfo
  }
});

export const putCeLocationFlex = (
  ceInstallationId: string,
  ceLocationInfo: IPutCeLocationFlex
): IPutCeLocationFlexRequest => ({
  type: PUT_CELOCATION_FLEX_REQUEST,
  payload: {
    ceInstallationId,
    ceLocationInfo
  }
});

export const putCeLocationSuccess = (
  ceLocation: ICELocation,
  content: any
): IPutCeLocationSuccess => ({
  type: PUT_CELOCATION_SUCCESS,
  payload: {
    ceLocation,
    content
  }
});

export const putCeLocationAddress = (
  ceLocationId: string,
  ceLocationAddress: ICELocationAddressEdit
): IPutCeLocationAddressRequest => ({
  type: PUT_CELOCATION_ADDRESS_REQUEST,
  payload: {
    ceLocationId,
    ceLocationAddress
  }
});

export const putCeLocationAddressSuccess = (
  ceLocationId: string
): IPutCeLocationAddressSuccess => ({
  type: PUT_CELOCATION_ADDRESS_SUCCESS,
  payload: {
    ceLocationId
  }
});

export const putCeLocationPhoto = (
  ceInstallationId: string,
  index: number,
  file: File
): IPutCeLocationPhoto => ({
  type: PUT_CELOCATION_PHOTO,
  payload: { ceInstallationId, index, file }
});

export const deleteCePhoto = (ceInstallationId: string, filename: string): IDeleteCePhoto => ({
  type: DELETE_CELOCATION_PHOTO,
  payload: { ceInstallationId, filename }
});

export const deleteCeLocationRequest = (
  ceInstallationId: string,
  certificationNumber: string
): IDeleteCeLocationRequest => ({
  type: DELETE_CELOCATION_REQUEST,
  payload: { ceInstallationId, certificationNumber }
});

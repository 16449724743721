import React from "react";
import { BodyHighlight } from "@ddm-design-system/typography";
import { EditSection } from "./CeLocationDetailEditItem.styles";

interface ICeLocationDetailEditItemProps {
  title: string;
}

const CeLocationDetailEditItem: React.FC<ICeLocationDetailEditItemProps> = ({
  title,
  children
}) => {
  return (
    <EditSection>
      <BodyHighlight>{title}</BodyHighlight>
      {children}
    </EditSection>
  );
};

export default CeLocationDetailEditItem;

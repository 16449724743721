import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import { getCurrentContent } from "../../../store/content/selectors";
import { IContent } from "../../../store/content/types";
import Button from "../button/Button";

interface IProps {
  authenticatorCode?: string;
  showHelp?: boolean;
  content: IContent;
  gotItCallback?: () => void;
}
export const AuthenticatorCodeInfo: React.FC<IProps> = ({
  authenticatorCode,
  showHelp,
  content,
  gotItCallback
}) => {
  return (
    content && (
      <div className="authenticator-code-modal">
        <span className="authenticator-code-modal-title">{content.common_authenticator_code}</span>
        <span className="authenticator-code-modal-subtitle">
          {content.common_authenticator_subtitle_1}
          <br />
          {content.common_authenticator_subtitle_2}
        </span>
        <div className="authenticator-code-modal-content">
          {authenticatorCode && (
            <span className="authenticator-code-modal-content-value">{authenticatorCode}</span>
          )}
          <span className="authenticator-code-modal-content-title">
            {content.common_authenticator_code}
          </span>
        </div>
        {showHelp && (
          <div className="authenticator-code-modal-footer">
            <span className="authenticator-code-modal-subtitle">
              {content.common_authenticator_help}
            </span>
            <Button secondary onClick={gotItCallback}>
              {content.common_authenticator_confirm}
            </Button>
          </div>
        )}
      </div>
    )
  );
};
const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);

  return {
    content: content ? content.common : null
  };
};

export default connect<any, any, any, any>(mapStateToProps, null)(AuthenticatorCodeInfo);

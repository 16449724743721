import React, { useEffect, useState } from "react";
import { BodyHighlight, MainTitle } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { FieldValue } from "../../common/fieldValue/fieldValue.styles";
import { getExtra10Outlet } from "../../../store/extra10outlets/selectors";
import { getExtra10OutletInfo } from "../../../store/extra10outlets/actions";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { EditButton } from "../../common/button/EditButton.styles";
import CreateOutletForm from "../../outlets/CreateOutletForm";
import EditExtra10OutletForm from "../EditExtra10OutletForm";

interface IProps {
  outletId: string;
}

const Info: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { common, outletDetails: content } = useContent();
  const [isEdit, setIsEdit] = useState(false);
  const outlet = useSelector(getExtra10Outlet(outletId));
  const language = useSelector(getCurrentLanguage);

  useEffect(() => {
    dispatch(getExtra10OutletInfo(outletId));
  }, [dispatch, outletId]);

  return (
    <div className="py-xxl">
      <div className="flex items-center justify-between">
        <MainTitle>Information</MainTitle>
        <div>
          <EditButton icon="Edit" onClick={() => setIsEdit(true)}>
            {content.outlet_details_edit_info}
          </EditButton>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_name}</BodyHighlight>
                <FieldValue>{outlet?.name}</FieldValue>
              </div>
              <div className="field items-start">
                <BodyHighlight>{common.common_contact}</BodyHighlight>
                <FieldValue disabled={!outlet?.contacts?.length}>
                  {outlet?.contacts?.length
                    ? outlet?.contacts.join(", ")
                    : common.common_no_information}
                </FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{content.outlet_details_id}</BodyHighlight>
                <FieldValue>{outlet?.id}</FieldValue>
              </div>
              <div className="field">
                <BodyHighlight>{content.outlet_details_installation_id}</BodyHighlight>
                <FieldValue>{outlet?.installationId || "--"}</FieldValue>
              </div>
            </div>
          </div>
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_date_created}</BodyHighlight>
                <FieldValue>
                  {new Date(outlet?.createDateTime as string).toLocaleDateString(language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                  })}
                </FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_country}</BodyHighlight>
                <FieldValue>{outlet?.locationData?.country?.name}</FieldValue>
              </div>
            </div>
          </div>
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_distributor_name}</BodyHighlight>
                <FieldValue>{outlet?.distributorName}</FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_client_code}</BodyHighlight>
                <FieldValue>{outlet?.clientNumberDistributor}</FieldValue>
              </div>
            </div>
          </div>
        </div>
      </div>

      {outlet && (
        <EditExtra10OutletForm outlet={outlet} visible={isEdit} onClose={() => setIsEdit(false)} />
      )}
    </div>
  );
};

export default Info;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../common/header/Header";
import useContent from "../../hooks/useContent";
import {
  getDistributors,
  getHasCreateDistributorError,
  getIsCreatingDistributor
} from "../../store/distributors/selectors";
import { getDistributors as getDistributorsAction } from "../../store/distributors/actions";
import { IMenuAction } from "../common/menu/ActionsMenu";
import AssociateDistributorForm from "./AssociateDistributorForm";
import CreateDistributorForm from "./CreateDistributorForm";
import DistributorList from "./DistributorList";

const Distributors: React.FC = () => {
  const dispatch = useDispatch();
  const { distributors: content, common } = useContent();
  const distributors = useSelector(getDistributors);
  const isCreatingDistributor = useSelector(getIsCreatingDistributor);
  const hasCreateDistributorError = useSelector(getHasCreateDistributorError);
  const [showCreateDistributorForm, setShowCreateDistributorForm] = useState(false);
  const [showAssociateDistributorForm, setShowAssociateDistributorForm] = useState(false);

  // Fetch distributors if necessary
  useEffect(() => {
    if (distributors.length === 0) {
      dispatch(getDistributorsAction());
    }
  }, []);

  // Close sidebar if distributor was successfully created/ edited
  useEffect(() => {
    if (!isCreatingDistributor && !hasCreateDistributorError) {
      if (showCreateDistributorForm) {
        setShowCreateDistributorForm(false);
      }

      if (showAssociateDistributorForm) {
        setShowAssociateDistributorForm(false);
      }
    }
  }, [isCreatingDistributor]);

  const headerActions: IMenuAction[] = [
    {
      label: content.distributors_create_new,
      onClick: () => {
        setShowCreateDistributorForm(true);
      }
    },
    {
      label: content.distributors_associate,
      onClick: () => {
        setShowAssociateDistributorForm(true);
      }
    }
  ];

  return (
    <div className="pb-xl">
      <Header title={common.common_distributors} actions={headerActions} />

      <DistributorList distributors={distributors} searchSelector="name" />

      <CreateDistributorForm
        visible={showCreateDistributorForm}
        onClose={() => {
          setShowCreateDistributorForm(false);
        }}
      />
      <AssociateDistributorForm
        visible={showAssociateDistributorForm}
        onClose={() => {
          setShowAssociateDistributorForm(false);
        }}
      />
    </div>
  );
};

export default Distributors;

import { ICELocation } from "../../store/ceLocations/types";

// Unfortunately ICELocation keys do not correspond to their contentful counterparts
const keyMap: {
  [key in keyof ICELocation]?: string;
} = {
  beerBoostPumpBrand: "beerBoostPumpBrands",
  cleaningUnitBrands: "cleaningUnitBrands",
  compressorBrand: "compressorBrands",
  coolingUnitBrands: "beerCoolerBrands",
  couplingBrand: "couplingBrands",
  fobDetectorBrand: "fobDetectorBrands",
  pythonBrand: "pythonBrands"
};

export const getTranslatedBrands = (
  ceLocation: ICELocation,
  brandKey: keyof ICELocation,
  valueList: any
) =>
  getTranslatedBrandsAsObject(ceLocation, brandKey, valueList)
    .map((value: any) => value.name)
    .join(", ");

export const getTranslatedBrandsAsObject = (
  ceLocation: ICELocation,
  brandKey: keyof ICELocation,
  valueList: any
) => {
  const brands = Array.isArray(ceLocation[brandKey])
    ? ceLocation[brandKey]
    : brandKey === "couplingBrand"
    ? (ceLocation[brandKey] || "").split(",")
    : [ceLocation[brandKey]];

  const content =
    valueList?.[`${keyMap[brandKey]}_${ceLocation.newInstallation ? "new" : "current"}`] || [];

  const intersection = content.filter((value: any) => (brands as string[]).includes(value.key));

  return intersection;
};

export const getAllBrands = (
  ceLocation: ICELocation,
  brandKey: keyof ICELocation,
  valueList: any
) => valueList?.[`${keyMap[brandKey]}_${ceLocation.newInstallation ? "new" : "current"}`] || [];

import { Reducer } from "redux";
import produce from "immer";
import { RehydrateAction } from "redux-persist/es/types";
import { mapContentfulEntries } from ".";
import {
  ContentActionTypes,
  GET_CONTENTFUL,
  GET_CONTENTFUL_ERROR,
  GET_CONTENTFUL_SUCCESS,
  IContentState,
  SELECT_LANGUAGE,
  SET_CURRENT_LANGUAGE,
  SET_SYNC_TOKEN
} from "./types";

const initialState: IContentState = {
  contentfulLoaded: false,
  loadingContentful: false,
  contentfulError: false,
  currentLanguage: process.env.REACT_APP_DEFAULT_LANG,
  entries: {}
};

const reducer: Reducer<IContentState, ContentActionTypes | RehydrateAction> = (
  state = initialState,
  action: ContentActionTypes | RehydrateAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_CONTENTFUL:
        draft.loadingContentful = true;
        draft.contentfulError = false;
        break;
      case GET_CONTENTFUL_SUCCESS:
        draft.loadingContentful = false;
        draft.contentfulError = false;

        if (Object.keys(action.payload.entries).length > 0) {
          try {
            draft.entries = mapContentfulEntries(action.payload.entries, action.payload.assets);
          } catch (e) {
            // console.log("error contentful mapping", e);
            draft.contentfulError = true;
          }
        }

        break;
      case SET_SYNC_TOKEN:
        if (action.payload.contentfulToken) {
          draft.contentfulToken = action.payload.contentfulToken;
        }

        if (action.payload.dbTimestamp) {
          draft.dbTimestamp = action.payload.dbTimestamp;
        }

        draft.loadingContentful = false;
        draft.contentfulLoaded = true;
        break;
      case SET_CURRENT_LANGUAGE:
        draft.currentLanguage = action.payload.code;
        break;
      case GET_CONTENTFUL_ERROR:
        draft.loadingContentful = false;
        draft.contentfulError = true;
        break;
      case SELECT_LANGUAGE:
        Object.keys(state.entries).forEach(key => {
          draft.entries[key].languageInfo.forEach((lang: any) => {
            // eslint-disable-next-line no-param-reassign
            lang.selected = lang.code === action.payload ? !lang.selected : lang.selected;
          });
        });
        break;
      default:
        break;
    }
  });

export default reducer;

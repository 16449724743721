import styled, { css } from "styled-components";
import { BaseMenuItemStyles, BaseMenuStyles, Menu, MenuItem } from "@ddm-design-system/menu";

export const StyledMenu = styled(Menu)(
  ({ theme }) => css`
    ${BaseMenuStyles.List} {
      border-radius: ${theme.spaceUnit.xxs};
      margin-top: ${theme.spaceUnit.xxs};
    }
  `
);

export const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    padding: ${theme.spaceUnit.sm} ${theme.spaceUnit.md};

    ${BaseMenuItemStyles.StyledBody} {
      ${theme.typography.description};
    }
  `
);

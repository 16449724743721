import { createSelector } from "reselect";
import { camelCase } from "lodash";
import { IAppState } from "..";
import {
  IComponentInfo,
  IControlUnitInfo,
  ICoolingUnitInfo,
  ILine,
  IComponentHealthStatus,
  IHealthStatus
} from "./types";
import { BeverageTranslation, IBeverageTranslation } from "../beverages/types";
import { EHealthStatusLevel } from "../healthStatus/types";
import { getBeverages, getBeveragesX10 } from "../beverages/selectors";
import { getCurrentLanguage, getCurrentContent } from "../content/selectors";
import { getLevelChipSelector } from "../healthStatus/helpers";

export const getComponent = (state: IAppState) => state.component.currentComponent;

export const getIsLoadingComponent = (state: IAppState) => state.component.requestingComponent;

export const getComponentLines = (state: IAppState) => state.component.componentLines;

export const getIsLoadingLines = (state: IAppState) => state.component.requestingComponentLines;

export const getComponentHealthStatus = (state: IAppState) => state.component.componentHealthStatus;

export const getIsLoadingHealthStatus = (state: IAppState) =>
  state.component.requestingComponentHealthStatus;

export const getCurrentComponentLines = () =>
  createSelector(getComponentLines, (lines?: ILine[]) => {
    return lines;
  });

export const getCurrentComponentHealthStatus = () =>
  createSelector(
    getCurrentContent,
    getComponentHealthStatus,
    (currentContent, healthStatuses?: IComponentHealthStatus) => {
      const { healthMonitor: content = {} } = currentContent || {};

      return healthStatuses
        ? {
            ...healthStatuses,
            healthStatus: healthStatuses?.healthStatus?.map((healthStatus: IHealthStatus) => {
              const level = healthStatus.level?.toLowerCase() as EHealthStatusLevel;
              const levelChipSelector = getLevelChipSelector(level);

              return {
                ...healthStatus,
                chipSelectors: [levelChipSelector],
                level,
                levelContent: content[`healthMonitor_level_${level}`] || level || "--",
                infoContent:
                  content[`healthMonitor_${healthStatus.type?.toLowerCase()}_info`] ||
                  healthStatus.type?.toLowerCase() ||
                  "--",
                typeContent:
                  content[`healthMonitor_${healthStatus.type?.toLowerCase()}`] ||
                  healthStatus.type?.toLowerCase() ||
                  "--"
              };
            })
          }
        : null;
    }
  );

export const getCurrentComponent = () =>
  createSelector(
    getCurrentContent,
    getBeverages,
    getBeveragesX10,
    getCurrentLanguage,
    getComponent,
    (
      currentContent,
      beverages: BeverageTranslation[],
      beveragesX10: BeverageTranslation[],
      language: string,
      currentComponent?: IComponentInfo | IControlUnitInfo | ICoolingUnitInfo
    ) => {
      const bev = [...beverages, ...beveragesX10].find(b => b.id === currentComponent?.beverageId);

      const { common: content = {} } = currentContent || {};

      return currentComponent
        ? {
            ...currentComponent,
            id: currentComponent?.id,
            name: currentComponent?.name,
            position: currentComponent?.position,
            boardSerialNumber: currentComponent?.boardSerialNumber || "--",
            unit: currentComponent?.primaryUnitId
              ? content.common_secondary_unit
              : content.common_primary_unit,
            tapsPositions: (currentComponent?.tapsPositions || []).sort().join(", ") || "--",
            brand: {
              photo: bev?.logoUrl() || "",
              title: bev?.name
            },
            volume: currentComponent?.volume
              ? ((100 * (currentComponent?.volumeLeft || 0)) / currentComponent?.volume).toFixed(0)
              : /* X10 */
                ((100 * (currentComponent?.volumeLeft || 0)) / 10000).toFixed(0),
            volumeLeft: ((currentComponent?.volumeLeft || 0) / 1000).toFixed(1),
            createDateTime: new Date(currentComponent?.createDateTime).toLocaleDateString(
              language,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric"
              }
            ),
            typeContent: `${content[`common_${camelCase(currentComponent?.type)}`]} ${
              currentComponent?.position || ` - ${currentComponent?.name}`
            }`,
            alive:
              typeof currentComponent?.alive === "boolean"
                ? currentComponent?.alive
                  ? content.common_online
                  : content.common_offline
                : "--"
          }
        : null;
    }
  );

import styled, { css } from "styled-components";
import { LinkButton, TertiaryButton } from "@ddm-design-system/button";
import { BaseCheckboxStyles, Checkbox } from "@ddm-design-system/checkbox";
import { Divider } from "@ddm-design-system/divider";
import SquaredButton from "../button/SquaredButton";

export const FilterContainer = styled.div``;

export const FilterButton = styled(SquaredButton)<{ active?: boolean }>(
  ({ active, theme }) => css`
    ${active &&
    css`
      border-color: ${theme.colors.black};
      color: ${theme.colors.black};
      fill: ${theme.colors.black};
    `}
  `
);

export const FilterHeaderContainer = styled.div`
  min-height: 75px;
`;

export const FilterHeaderTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const FilterClearButton = styled(TertiaryButton)(
  ({ theme }) => css`
    color: ${theme.colors.grey.grey50};
    fill: ${theme.colors.grey.grey50};
    padding-left: 0;
  `
);

export const FilterGroup = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spaceUnit.xxl};
  `
);

export const FilterDivider = styled(Divider)(
  ({ theme }) => css`
    background-color: ${theme.colors.grey.greenish};
    margin-top: ${theme.spaceUnit.md};
  `
);

export const FilterCheckbox = styled(Checkbox)(
  ({ theme }) => css`
    margin-top: ${theme.spaceUnit.md};
    text-transform: capitalize;

    ${BaseCheckboxStyles.CheckboxText} {
      ${theme.typography.body};
    }
  `
);

export const FilterMoreButton = styled(LinkButton).attrs<{ isOpen: boolean }>(({ isOpen }) => ({
  icon: isOpen ? "ChevronUp" : "ChevronDown"
}))<{ isOpen: boolean }>(
  ({ theme }) => css`
    align-self: flex-start;
    margin-top: ${theme.spaceUnit.xs};
    padding-left: 0;
  `
);

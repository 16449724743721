import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";

export const BreadcrumbsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Breadcrumb = styled(Description)`
  &:hover {
    text-decoration: underline;
  }
`;

export const LastBreadcrumb = styled(DescriptionMedium)`
  cursor: default;
`;

export const BreadcrumbsSeparator = styled(Icon).attrs({ name: "ChevronRight" })(
  ({ theme }) => css`
    fill: ${theme.colors.grey.grey100};
    margin: 0 ${theme.spaceUnit.xs};
    width: ${theme.spaceUnit.xs};
  `
);

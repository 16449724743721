import { fork, put, select, take } from "redux-saga/effects";
import { REHYDRATE } from "redux-persist/lib/constants";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { replaceTypeLink } from "../store/content";
import {
  getContentful as getContentfulAction,
  getContentfulError,
  getContentfulSuccess,
  setSyncToken
} from "../store/content/actions";
import { getContentfulSyncInfo } from "../store/content/selectors";
import { IContentfulSyncInfo } from "../store/content/types";

function* getContentful(jCoreService: IJCoreService) {
  yield take((action: any) => action.type === REHYDRATE && action.key === "language");
  yield put(getContentfulAction());
  const { contentfulToken, dbTimestamp }: IContentfulSyncInfo = yield select(getContentfulSyncInfo);

  try {
    let results = yield jCoreService.getContentfulEntries(contentfulToken, dbTimestamp);

    if (!results) {
      yield put(getContentfulError());

      return;
    }

    results = results.data;

    if (results.entries?.length) {
      let { entries } = results;

      /**
       * All tech app entries are being fetched and then filtered in the current implementation.
       * Ideally, only the entries in the necessary content types should be fetched with techAppClient.getEntries or techAppClient.getContentTypes,
       * but the parser (mapContentfulEntries) cannot be used as the returned type is not the same as that of techAppClient.sync.
       * It is also not trivial to implement a new parser for the new results.
       */
      try {
        const techAppEntriesTemp = yield jCoreService.getContentfulEntries(
          "",
          undefined,
          "tech_app"
        );
        const techAppEntries = techAppEntriesTemp?.data?.entries || [];
        const filteredTechAppEntries = techAppEntries
          .map((e: any) => replaceTypeLink(e, techAppEntries, []))
          .filter(
            (entry: any) =>
              entry.sys.contentType === "form" || entry.sys.contentType === "valueList"
          );

        filteredTechAppEntries
          .filter((f: any) => f.sys.contentType === "form")
          .forEach((form: any) => {
            form.fields.fields?.["en-GB"].forEach((field: any) => {
              field.fields.translations = Array.from(
                new Set(
                  Object.keys(field.fields.name)
                    .filter(k => field.fields.name[k] !== "")
                    .map(k => field.fields.name[k])
                )
              );
            });
          });

        entries = [...entries, ...filteredTechAppEntries];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("error tech", e);
      }

      yield put(
        getContentfulSuccess({
          entries,
          assets: results.assets
        })
      );
    }

    yield put(setSyncToken(results.contentfulToken, results.dbTimestamp));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("error", e);
    yield put(getContentfulError());
  }
}

export function* contentWatcher(context: IAppContext) {
  yield fork(getContentful, context.jCoreService);
}

export default contentWatcher;

import { call, put, select, takeLatest } from "redux-saga/effects";
import { ddmTheme } from "@ddm-design-system/tokens";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { getCurrentContent } from "../store/content/selectors";
import {
  deleteUserSuccess,
  getUser as getUserAction,
  getUsers as getUsersAction,
  getUsersSuccess,
  getUserSuccess
} from "../store/users/actions";
import { getSelectedUser } from "../store/users/selectors";
import {
  CREATE_USER,
  DELETE_USER,
  EDIT_USER,
  GET_USER_REQUEST,
  GET_USERS_REQUEST,
  ICreateUser,
  IDeleteUser,
  IEditUser,
  IGetUserRequest
} from "../store/users/types";

export function* getUsers(jCoreService: IJCoreService) {
  try {
    const result = yield call([jCoreService, jCoreService.fetchAzureUsers]);

    yield put(getUsersSuccess(result));
  } catch {
    // yield put(());
  }
}

export function* createUser(
  { jCoreService, notificationService }: IAppContext,
  action: ICreateUser
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    yield call([jCoreService, jCoreService.createAzureUser], action.payload);

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: (content.user_created || "").replace("%USER%", action.payload.username)
    });

    yield put(getUsersAction());
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.user_created_error
    });
  }
}

export function* getUser(jCoreService: IJCoreService, action: IGetUserRequest) {
  try {
    const result = yield call([jCoreService, jCoreService.fetchAzureUser], action.payload);

    yield put(getUserSuccess(result));
  } catch {
    //
  }
}

export function* editUser({ jCoreService, notificationService }: IAppContext, action: IEditUser) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const selectedUser = yield select(getSelectedUser);

    yield call([jCoreService, jCoreService.editAzureUser], action.payload);

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: (content.user_edited || "").replace("%USER%", selectedUser.username)
    });

    yield put(getUserAction(action.payload.userId));
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.user_edited_error
    });
  }
}

export function* deleteUser(
  { jCoreService, notificationService }: IAppContext,
  action: IDeleteUser
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    yield call([jCoreService, jCoreService.deleteAzureUser], action.payload);

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.user_deleted
    });

    yield put(deleteUserSuccess());
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.user_deleted_error
    });
  }
}

export function* usersWatcher(context: IAppContext) {
  yield takeLatest(GET_USERS_REQUEST, getUsers, context.jCoreService);
  yield takeLatest(CREATE_USER, createUser, context);
  yield takeLatest(GET_USER_REQUEST, getUser, context.jCoreService);
  yield takeLatest(EDIT_USER, editUser, context);
  yield takeLatest(DELETE_USER, deleteUser, context);
}

export default usersWatcher;

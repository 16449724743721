import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { BaseDropdownStyles, Dropdown, MultipleDropdown } from "@ddm-design-system/dropdown";
import { ErrorTextInput, TextInput } from "@ddm-design-system/textinput";
import { Subtitle } from "@ddm-design-system/typography";
import { ICreateDistributorObject, IDistributor } from "../../store/distributors/types";
import { createDistributor, editDistributor } from "../../store/distributors/actions";
import { getPurchasingGroups as getPurchasingGroupsAction } from "../../store/purchasingGroups/actions";
import { getOutlets } from "../../store/outlets/selectors";
import { getIsCreatingDistributor } from "../../store/distributors/selectors";
import { getPurchasingGroups } from "../../store/purchasingGroups/selectors";
import useContent from "../../hooks/useContent";
import { validateEmail } from "../../helpers";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import { CloseIcon } from "../common/icon/CloseIcon.styles";

export const StyledDropdown = styled(Dropdown)(
  () => css`
    background-color: white;

    ${BaseDropdownStyles.StyledPopover} {
      width: 272px;
    }
  `
);

export const StyledMultipleDropdown = styled(MultipleDropdown)(
  () => css`
    background-color: white;
    // proper styled components aren't exported in ddm
    & > div:last-child {
      width: 272px;
    }
  `
);

interface IProps {
  distributor?: IDistributor;
  visible: boolean;
  onClose: () => void;
}

const CreateDistributorForm: React.FC<IProps> = ({ distributor, visible, onClose }) => {
  const dispatch = useDispatch();
  const { common, distributors: content } = useContent();
  const isCreatingDistributor = useSelector(getIsCreatingDistributor);
  const purchasingGroups = useSelector(getPurchasingGroups);
  const outlets = useSelector(getOutlets);
  const [distributorName, setDistributorName] = useState(distributor?.name ?? "");
  const [distributorEmail, setDistributorEmail] = useState(distributor?.email ?? "");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [distributorPG, setDistributorPG] = useState<{ id: string; text: string } | undefined>(
    distributor &&
      distributor.purchasingGroup && {
        id: distributor.purchasingGroup.id,
        text: distributor.purchasingGroup.name
      }
  );
  const [distributorOutlets, setDistributorOutlets] = useState<
    { id: string; text: string; disabled?: boolean }[]
  >(
    distributor && distributor.outlets.length
      ? distributor.outlets.map(outlet => ({
          id: outlet.id,
          text: outlet.name,
          disabled: false
        }))
      : []
  );
  const [hasFormChanged, setHasFormChanged] = useState(false);

  useEffect(() => {
    if (!purchasingGroups.length) {
      dispatch(getPurchasingGroupsAction());
    }
  }, []);

  const distributorPgDropdownItems = useMemo(
    () => [
      ...purchasingGroups.map(pg => ({
        id: pg.id,
        text: pg.name
      })),
      {
        id: "",
        text: content.distributors_no_purchasing_group
      }
    ],
    [purchasingGroups]
  );

  const distributorOutletsDropdownItems = useMemo(
    () => [
      ...outlets.map(outlet => ({
        id: outlet.id,
        text: outlet.name
      }))
    ],
    [outlets]
  );

  const handleCreateDistributor = () => {
    const distributorObj: ICreateDistributorObject = {
      email: distributorEmail,
      name: distributorName
    };

    if (distributorPG?.id !== "") {
      distributorObj.purchasingGroupId = distributorPG?.id;
    }

    if (distributorOutlets.length) {
      distributorObj.outlets = distributorOutlets
        .filter(outlet => !outlet.disabled)
        .map(outlet => outlet.id);
    }

    if (distributor) {
      dispatch(editDistributor({ data: distributorObj, distributorId: distributor.id }));
    } else {
      dispatch(createDistributor(distributorObj));
    }
  };

  const onCloseAux = () => {
    setDistributorName(distributor?.name ?? "");
    setDistributorEmail(distributor?.email ?? "");
    setIsEmailValid(true);
    setDistributorPG(
      distributor && distributor.purchasingGroup
        ? {
            id: distributor.purchasingGroup.id,
            text: distributor.purchasingGroup.name
          }
        : undefined
    );
    setDistributorOutlets(
      distributor && distributor.outlets.length
        ? distributor.outlets.map(outlet => ({
            id: outlet.id,
            text: outlet.name,
            disabled: false
          }))
        : []
    );
    setHasFormChanged(false);
    onClose();
  };

  return (
    <SidebarModal className="!w-[450px]" visible={visible} onClose={onCloseAux}>
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col">
          <div className="flex justify-between mb-xxxl">
            <Subtitle>
              {distributor ? content.distributors_edit : content.distributors_create_new}
            </Subtitle>
            <CloseIcon className="mt-sm" onClick={onCloseAux} />
          </div>
          <form>
            <TextInput
              className="mb-lg"
              disabled={isCreatingDistributor}
              label={common.common_distributor}
              maxLength={100}
              value={distributorName}
              onChange={e => {
                setDistributorName(e.target.value);
                setHasFormChanged(true);
              }}
            />
            <ErrorTextInput
              className="mb-xxl"
              disabled={isCreatingDistributor}
              error={isEmailValid ? undefined : common.common_invalid_email}
              label={common.common_form_email_placeholder}
              value={distributorEmail}
              type="email"
              onBlur={e => setIsEmailValid(!!validateEmail(e.target.value))}
              onChange={e => {
                setDistributorEmail(e.target.value);
                setHasFormChanged(true);
              }}
            />
            <StyledDropdown
              disabled={!purchasingGroups.length}
              items={distributorPgDropdownItems}
              label={common.common_purchasing_group}
              placeholder={content.distributors_select_group}
              selectedItem={distributorPG}
              onItemSelected={item => {
                setDistributorPG(item);
                setHasFormChanged(true);
              }}
            />
            <div className="flex justify-end mt-lg">
              {visible && (
                  // @ts-ignore
                <StyledMultipleDropdown
                  disabled={!outlets}
                  items={distributorOutletsDropdownItems}
                  label={common.common_outlets}
                  placeholder={content.distributors_select_outlets}
                  selectedItems={distributorOutlets}
                  onItemsSelected={items => {
                    setHasFormChanged(true);
                    setDistributorOutlets(items);
                  }}
                />
              )}
            </div>
          </form>
        </div>
        <div className="flex justify-end">
          <SecondaryButton className="mr-lg" onClick={onCloseAux}>
            {common.common_cancel}
          </SecondaryButton>
          <PrimaryButton
            disabled={
              isCreatingDistributor ||
              !distributorName ||
              !distributorEmail ||
              !isEmailValid ||
              !hasFormChanged
            }
            onClick={handleCreateDistributor}
          >
            {isCreatingDistributor
              ? common.common_loading
              : distributor
              ? common.common_edit
              : common.common_create}
          </PrimaryButton>
        </div>
      </div>
    </SidebarModal>
  );
};

export default CreateDistributorForm;

import { Icon } from "@ddm-design-system/icon";
import { ddmTheme } from "@ddm-design-system/tokens";
import { BodyHighlight } from "@ddm-design-system/typography";
import React, { useCallback } from "react";
import useContent from "../../../../../hooks/useContent";
import { IComponentHealthStatus } from "../../../../../store/components/types";
import { FieldValue } from "../../../../common/fieldValue/fieldValue.styles";

interface IProps {
  healthStatus: IComponentHealthStatus;
}

const Status: React.FC<IProps> = ({ healthStatus }) => {
  const { common, outletDetails: content } = useContent();

  const renderComponentStatus = useCallback(() => {
    return (
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field items-start">
                <BodyHighlight className="flex items-center gap-xs">
                  {common.common_state}
                </BodyHighlight>
                <FieldValue>
                  {content[`outlet_component_cu_${healthStatus?.state?.toLocaleLowerCase()}`] ||
                    healthStatus?.state ||
                    "--"}
                </FieldValue>
              </div>
              <div className="field">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon
                    name="Wifi"
                    fill={
                      healthStatus?.alive
                        ? ddmTheme.colors.success.success100
                        : ddmTheme.colors.alert.alert100
                    }
                  />
                  {content.outlet_component_wifi_connection}
                </BodyHighlight>
                <FieldValue>
                  {healthStatus?.alive ? common.common_good : common.common_offline}
                </FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon name="CalendarCircle" size={16} />
                  {content.outlet_component_days_cycle}
                </BodyHighlight>
                <FieldValue>{healthStatus?.cleaningCyclesFromLast90days}</FieldValue>
              </div>
              <div className="field">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon
                    name="Cleaning"
                    fill={
                      healthStatus?.cleaningDue && healthStatus?.cleaningDue > 0
                        ? ddmTheme.colors.success.success100
                        : ddmTheme.colors.alert.alert100
                    }
                  />
                  Days until clean
                </BodyHighlight>
                <FieldValue>
                  {healthStatus?.cleaningDue != null
                    ? Math.floor(healthStatus?.cleaningDue / 60 / 60 / 24)
                    : "--"}{" "}
                  {common.common_days}
                </FieldValue>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [content, common, healthStatus]);

  return renderComponentStatus();
};

export default Status;

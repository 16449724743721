import { IconNames } from "@ddm-design-system/icon";
import { EChipType, IChipSelector } from "../../components/common/data_table/DataTable";
import { ComponentLevelStatus, IComponentStatus, EExtra10OutletStatusBadges } from "./types";

const getStatusChipType = (status: EExtra10OutletStatusBadges) => {
  switch (status) {
    case EExtra10OutletStatusBadges.OK:
      return EChipType.SUCCESS;
    case EExtra10OutletStatusBadges.WARNING:
      return EChipType.WARNING;
    case EExtra10OutletStatusBadges.ERROR:
    default:
      return EChipType.DEFAULT;
  }
};

export const getStatusChipSelector = (status: EExtra10OutletStatusBadges): IChipSelector => ({
  name: "statusContent",
  sort: Object.values(EExtra10OutletStatusBadges).indexOf(status),
  type: getStatusChipType(status)
});

export const iconStatusMap: { [key: string]: IconNames } = {
  OFFLINE: "Wifi",
  SENSOR_NOT_CONNECTED: "Sensor",
  SENSOR_FAULTY: "Sensor",
  OPEN: "Unlocked",
  LEAK: "Leak",
  EMPTY: "Keg",
  EXPIRED_KEG: "CalendarCircle",
  STALE_KEG: "Sleep",
  OK: "Ok"
};

export const getComponentStatus = (
  componentStatus: IComponentStatus[] | undefined
): Array<{ level: ComponentLevelStatus; icon: IconNames; title?: string }> => {
  const status: Array<{ level: ComponentLevelStatus; icon: IconNames; title?: string }> = [];

  componentStatus?.forEach(s => {
    status.push({
      level: s.level,
      icon: iconStatusMap[s.message] || "Error",
      title: s.message?.toString()
    });
  });

  return status;
};

import produce from "immer";
import { Reducer } from "redux";
import {
  CeLocationsActionTypes,
  GET_CELOCATIONS_ERROR,
  GET_CELOCATIONS_REQUEST,
  GET_CELOCATIONS_SUCCESS,
  ICELocationState,
  PUT_CELOCATION_SUCCESS
} from "./types";
import { mapCeLocation } from "./utils";

export const initialState: ICELocationState = {
  ceLocations: [],
  ceLocationsDeleted: [],
  requestingCeLocations: false,
  requestCeLocationsError: false
};

const reducer: Reducer<ICELocationState, CeLocationsActionTypes> = (
  state = initialState,
  action: CeLocationsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_CELOCATIONS_REQUEST:
        draft.requestingCeLocations = true;
        break;
      case GET_CELOCATIONS_ERROR:
        draft.requestingCeLocations = false;
        draft.requestCeLocationsError = true;
        break;
      case GET_CELOCATIONS_SUCCESS:
        draft.requestingCeLocations = false;
        draft.ceLocations = [];
        draft.ceLocationsDeleted = [];
        action.payload.ceLocations
          .sort((a, b) => +new Date(b.createDateTime) - +new Date(a.createDateTime))
          .reduce((acc, item) => {
            const newItem = mapCeLocation(item, action.payload.content);

            if (newItem.deleted) {
              acc.ceLocationsDeleted.push(newItem);
            } else {
              acc.ceLocations.push(newItem);
            }

            return acc;
          }, draft);
        break;
      case PUT_CELOCATION_SUCCESS:
        const index = draft.ceLocations.findIndex(ce => ce.id === action.payload.ceLocation.id);

        draft.ceLocations[index] = mapCeLocation(action.payload.ceLocation, action.payload.content);
        break;
      default:
        break;
    }
  });

export default reducer;

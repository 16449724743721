import produce from "immer";
import { Reducer } from "redux";
import { AuthActionTypes, GET_ME_SUCCESS, IAuthState } from "./types";

const initialState: IAuthState = {
  me: undefined
};

const reducer: Reducer<IAuthState, AuthActionTypes> = (
  state = initialState,
  action: AuthActionTypes
) =>
  produce(state, (draft: { me: any }) => {
    switch (action.type) {
      case GET_ME_SUCCESS:
        draft.me = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import { Link } from "../common/link/link.styles";
import DataTable from "../common/data_table/DataTable";
import { IDistributor } from "../../store/distributors/types";
import { IFilter } from "../common/filter/Filter";
import { getIsRequestingDistributors } from "../../store/distributors/selectors";

interface IProps {
  filters?: IFilter[];
  distributors: IDistributor[];
  searchSelector?: string;
  hideGroup?: boolean;
  title?: string;
}

const DistributorList: React.FC<IProps> = ({
  distributors,
  searchSelector,
  hideGroup,
  title = ""
}) => {
  const { distributors: content, common } = useContent();
  const isLoading = useSelector(getIsRequestingDistributors);

  const formatDistributor = useCallback(
    ({ id, name }: IDistributor) => (
      <Link to={Routes.distributorDetails.replace(":id", id)} title={name}>
        {name}
      </Link>
    ),
    []
  );

  const formatPurchasingGroup = useCallback(
    ({ purchasingGroup }: IDistributor) =>
      purchasingGroup ? (
        <Link
          to={Routes.purchasingGroupDetails.replace(":id", purchasingGroup.id)}
          title={purchasingGroup.name}
        >
          {purchasingGroup.name}
        </Link>
      ) : (
        <span>-</span>
      ),
    []
  );

  const columns = useMemo(
    () => [
      {
        format: formatDistributor,
        grow: 6,
        name: common.common_name,
        selector: (row: IDistributor) => row.name,
        sortable: true,
        sortFunction: (distributorA: IDistributor, distributorB: IDistributor) =>
          distributorA.name.toLocaleLowerCase() > distributorB.name.toLocaleLowerCase() ? 1 : -1
      },
      ...(hideGroup
        ? []
        : [
            {
              format: formatPurchasingGroup,
              name: common.common_purchasing_group,
              selector: (row: IDistributor) => row.purchasingGroup?.name ?? "-",
              sortable: true,
              sortFunction: (distributorA: IDistributor, distributorB: IDistributor) =>
                !distributorA.purchasingGroup && !distributorB.purchasingGroup
                  ? distributorA.name.toLocaleLowerCase() > distributorB.name.toLocaleLowerCase()
                    ? 1
                    : -1
                  : (distributorA.purchasingGroup?.name.toLocaleLowerCase() ?? "-") >
                    (distributorB.purchasingGroup?.name.toLocaleLowerCase() ?? "-")
                  ? 1
                  : -1
            }
          ]),
      {
        name: common.common_outlets,
        selector: (row: IDistributor) => row.outlets?.length ?? row.outletCount ?? 0,
        sortable: true
      }
    ],
    [
      formatPurchasingGroup,
      common.common_name,
      common.common_outlets,
      common.common_purchasing_group
    ]
  );

  return (
    <DataTable
      columns={columns}
      data={distributors}
      defaultSortAsc={false}
      defaultSortField="name"
      isLoading={isLoading}
      searchPlaceholder={content.distributors_search}
      searchSelector={searchSelector}
      title={title}
      pagination
      paginationPerPage={100}
    />
  );
};

export default DistributorList;

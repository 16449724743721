import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import * as ReactExport from "react-export-excel";
import useContent from "../../hooks/useContent";
import { AnalyticsContext } from "../../services/analytics";
import { IAppState } from "../../store";
import { ICELocation } from "../../store/ceLocations/types";
import { IContent } from "../../store/content/types";
import Header from "../common/header/Header";
import "./celocations.scss";
import { IBadge } from "../../store/outlets/types";
import CeLocationList from "./CeLocationList";
import { IFilter } from "../common/filter/Filter";

const { ExcelFile, ExcelSheet } = ReactExport.modules;

export interface IProps {
  ceLocations: ICELocation[];
  requestingLocations: boolean;
  content: IContent;
  getCeLocations: () => void;
}

export interface IBadgeCheckbox extends IBadge {
  selected?: boolean;
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};

export const CeLocations: React.FC<IProps> = () => {
  const analytics = useContext(AnalyticsContext);
  const me = useSelector((state: IAppState) => state.authReducer.me);
  const { ceLocations, ceLocationsDeleted } = useSelector((state: IAppState) => ({
    ceLocations: state.ceLocations.ceLocations,
    ceLocationsDeleted: state.ceLocations.ceLocationsDeleted
  }));
  const {
    ceLocations: content,
    common: contentCommon,
    controlTowerCountries: contentCountry
  } = useContent();

  const exportColumns = useMemo(
    () => [
      "id",
      "technician",
      "newInstallation",
      "createDateTime",
      "date",
      "dismantled",
      "name",
      "address",
      "city",
      "zip",
      "international",
      "type",
      "customType",
      "certificationNumber",
      "additionalInformation",
      "taps",
      "pressureChambers",
      "coolingUnits",
      "cleaningUnits",
      "oldestKegModuleYear",
      "coolingUnitBrands",
      "cleaningUnitBrands",
      "compressorBrand",
      "beerBoostPumpBrand",
      "couplingBrand",
      "fobDetectorBrand",
      "pythonBrand",
      "flex20Year",
      "flex20Serial"
    ],
    []
  );

  const getCustomFields = useCallback(
    (locations: ICELocation[]) =>
      locations?.reduce((agg: any[], v) => {
        const fields =
          Object.keys((v.customFields !== "\"{}\"" && JSON.parse(v.customFields)) || {}) || [];

        fields.forEach(f => {
          if (!agg.includes(f)) {
            agg.push(f);
          }
        });

        return agg;
      }, []),
    []
  );

  const getDataSet = useCallback(
    (locations: ICELocation[], customFields: any[], isCSV = false) => {
      const locationsData = locations.map((c: ICELocation) => [
        c.id,
        c.technician?.name,
        c.newInstallation?.toString(),
        c.createDateTime,
        new Date(c.createDateTime).toLocaleDateString(undefined, dateOptions),
        c.deleted.toString(),
        c.ceLocation?.name ? `"${c.ceLocation.name}"` : "--",
        c.ceLocation?.address ? `"${c.ceLocation.address}"` : "--",
        c.ceLocation?.city ? `"${c.ceLocation.city}"` : "--",
        c.ceLocation?.zip ? `"${c.ceLocation.zip}"` : "--",
        `${c.ceLocation?.international ?? false}`,
        c.flex20 ? "flex_20" : c.type,
        c.customType ? `"${c.customType}"` : "--",
        c.certificationNumber,
        c.description ? `"${c.description}"` : "--",
        c.taps?.toString() ?? "--",
        c.pressureChambers?.toString() ?? "--",
        c.coolingUnits?.toString() ?? "--",
        c.cleaningUnits?.toString() ?? "--",
        c.oldestKegModuleYear?.toString() ?? "--",
        `"${c.coolingUnitBrands?.join(", ")}"`,
        `"${c.cleaningUnitBrands?.join(", ")}"`,
        c.compressorBrand || "--",
        c.beerBoostPumpBrand || "--",
        c.couplingBrand || "--",
        c.fobDetectorBrand || "--",
        c.pythonBrand || "--",
        c.flex20Year?.toString() ?? "--",
        c.flex20Serial?.toString() || "--",
        ...customFields.map(
          f => ((c.customFields !== "{}" && JSON.parse(c.customFields)) || {})[f] || "--"
        )
      ]);

      const dataSet = (
        isCSV ? [[...exportColumns, ...customFields]].concat(locationsData) : locationsData
      ).map(r =>
        r
          .map(r2 => {
            return r2 &&
              (r2.startsWith("=") ||
                r2.startsWith("+") ||
                r2.startsWith("-") ||
                r2.startsWith("@")) &&
              !r2.startsWith("--")
              ? `'${r2}`
              : r2;
          })
          .map(r2 =>
            r2 &&
            (r2.startsWith("\"=") ||
              r2.startsWith("\"+") ||
              r2.startsWith("\"-") ||
              r2.startsWith("\"@")) &&
            !r2.startsWith("--")
              ? `"'${r2.slice(1)}`
              : r2
          )
      );

      return dataSet;
    },
    [exportColumns]
  );

  const CSVDataSet = useCallback(
    (locs = undefined) => {
      const separator = ",";
      const locations = locs || [...ceLocations, ...ceLocationsDeleted];
      const customFields = getCustomFields(locations);
      const rows = getDataSet(locations, customFields, true);

      return rows.map(e => e.join(separator)).join("\n");
    },
    [ceLocations, ceLocationsDeleted, getCustomFields, getDataSet]
  );

  const downloadCSV = useCallback(
    (locations = undefined) => {
      analytics.logEvent("DOWNLOAD_CSV", me?.country?.name);
      const csvContent = `data:text/csv;charset=utf-8,${CSVDataSet(locations)}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "ce_locations.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
      document.body.removeChild(link);
    },
    [analytics, me, CSVDataSet]
  );

  const getExcelDataSet = useCallback(
    (locations: ICELocation[]) => {
      const customFields = getCustomFields(locations);
      const dataSet = getDataSet(locations, customFields, false);

      return [
        {
          columns: [...exportColumns, ...customFields],
          data: dataSet.length > 0 ? dataSet : [Array(exportColumns.length).fill("")]
        }
      ];
    },
    [exportColumns, getCustomFields, getDataSet]
  );

  const excelDataSet = useCallback(locations => getExcelDataSet(locations), [getExcelDataSet]);

  const excelDataSetDeleted = useMemo(
    () => getExcelDataSet(ceLocationsDeleted),
    [ceLocationsDeleted, getExcelDataSet]
  );

  const filters = useMemo<IFilter[]>(
    () => [
      {
        id: "country",
        options: [
          {
            id: "international",
            label: content.celocations_international
          },
          ...Array.from(
            new Set(
              ceLocations
                .filter(location => !location.ceLocation.international)
                .map(location => location.ceLocation.country.code)
            )
          ).map(code => ({
            id: code,
            label: contentCountry[`countries_${code.toLocaleLowerCase()}`] || code
          }))
        ],
        title: contentCommon.common_country
      }
    ],
    [contentCommon, ceLocations]
  );

  return (
    <div className="pb-xl">
      <Header title={content.celocations_title} />
      <CeLocationList
        locations={ceLocations}
        filters={filters}
        searchSelector={["title", "certificationNumber"]}
        downloadCSV={downloadCSV}
        downloadExcel={(locations: ICELocation[]) => (
          <ExcelFile key="0" filename="ce_locations" element={content.celocations_download_excel}>
            <ExcelSheet dataSet={excelDataSet(locations)} name={content.celocations_title} />
            <ExcelSheet dataSet={excelDataSetDeleted} name={content.celocations_dismantled} />
          </ExcelFile>
        )}
      />
    </div>
  );
};

export default CeLocations;

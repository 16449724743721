import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Subtitle } from "@ddm-design-system/typography";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { getDistributors } from "../../store/distributors/selectors";
import { IDistributor } from "../../store/distributors/types";
import { associatePurchasingGroupDistributors } from "../../store/purchasingGroups/actions";
import {
  getIsCreatingPurchasingGroup,
  getPurchasingGroups
} from "../../store/purchasingGroups/selectors";
import { IPurchasingGroup } from "../../store/purchasingGroups/types";
import { CloseIcon } from "../common/icon/CloseIcon.styles";
import Select from "../common/select/Select";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import "./associatePurchasingGroupForm.scss";

interface IProps {
  purchasingGroup?: IPurchasingGroup;
  visible: boolean;
  onClose: () => void;
}

const AssociatePurchasingGroupForm: React.FC<IProps> = ({ purchasingGroup, visible, onClose }) => {
  const dispatch = useDispatch();
  const { common, purchasingGroups: content } = useContent();
  const purchasingGroups = useSelector(getPurchasingGroups);
  const distributors = useSelector(getDistributors);
  const isCreatingPG = useSelector(getIsCreatingPurchasingGroup);
  const [selectedPG, setSelectedPG] = useState<IPurchasingGroup & { text: string }>();
  const [selectedDistributor, setSelectedDistributor] = useState<IDistributor & { text: string }>();

  // set initial selected PG if applicable
  useEffect(() => {
    if (purchasingGroup && !selectedPG) {
      setSelectedPG({ ...purchasingGroup, text: purchasingGroup.name });
    }
  }, [purchasingGroup]);

  // Clean up form if sidebar is closed
  useEffect(() => {
    if (!visible) {
      setSelectedPG(
        purchasingGroup ? { ...purchasingGroup, text: purchasingGroup.name } : undefined
      );
      setSelectedDistributor(undefined);
    }
  }, [visible]);

  const purchasingGroupsOptions = useMemo(
    () => purchasingGroups.map(pg => ({ ...pg, text: pg.name })),
    [purchasingGroups]
  );

  const distributorsOptions = useMemo(
    () =>
      distributors.map(distributor => ({
        ...distributor,
        text: distributor.name,
        disabled: !!distributor.purchasingGroup
      })),
    [distributors]
  );

  const handleAssociatePurchasingGroup = () => {
    dispatch(
      associatePurchasingGroupDistributors({
        pgId: selectedPG?.id as string,
        distributorIds: selectedDistributor?.id as string
      })
    );
  };

  return (
    <SidebarModal className="associate-pg-distributors" visible={visible} onClose={onClose}>
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col">
          <div className="flex justify-between mb-xxxl">
            <Subtitle>{content.purchasing_groups_associate_long}</Subtitle>
            <CloseIcon className="mt-sm" onClick={onClose} />
          </div>
          <form className="flex flex-col">
            <Select
              search
              className="grow mb-lg"
              label={common.common_purchasing_group}
              placeholder={common.common_search_purchasing_group_placeholder}
              selectedOption={selectedPG}
              options={purchasingGroupsOptions}
              onOptionSelected={option => setSelectedPG(option)}
              disabled={!!purchasingGroup}
            />
            <Select
              search
              className="grow"
              label={common.common_distributor}
              placeholder={common.common_search_distributor_placeholder}
              selectedOption={selectedDistributor}
              options={distributorsOptions}
              onOptionSelected={option => setSelectedDistributor(option)}
            />
          </form>
        </div>
        <div className="flex justify-end">
          <SecondaryButton className="mr-lg" onClick={onClose}>
            {common.common_cancel}
          </SecondaryButton>
          <PrimaryButton
            disabled={isCreatingPG || !selectedPG || !selectedDistributor}
            onClick={handleAssociatePurchasingGroup}
          >
            {isCreatingPG ? common.common_loading : common.common_associate_button_label}
          </PrimaryButton>
        </div>
      </div>
    </SidebarModal>
  );
};

export default AssociatePurchasingGroupForm;

import produce from "immer";
import { Reducer } from "redux";
import {
  CREATE_PURCHASING_GROUP,
  CREATE_PURCHASING_GROUP_ERROR,
  CREATE_PURCHASING_GROUP_SUCCESS,
  EDIT_PURCHASING_GROUP,
  EDIT_PURCHASING_GROUP_SUCCESS,
  EDIT_PURCHASING_GROUP_ERROR,
  GET_PURCHASING_GROUPS_ERROR,
  GET_PURCHASING_GROUPS_REQUEST,
  GET_PURCHASING_GROUPS_SUCCESS,
  IPurchasingGroupsState,
  PurchasingGroupsActionTypes,
  ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS,
  ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_SUCCESS,
  ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_ERROR
} from "./types";

const initalState: IPurchasingGroupsState = {
  purchasingGroups: [],
  requestingPurchasingGroups: false,
  requestPurchasingGroupsError: false,
  creatingPurchasingGroup: false,
  createPurchasingGroupError: false
};

const reducer: Reducer<IPurchasingGroupsState, PurchasingGroupsActionTypes> = (
  state = initalState,
  action: PurchasingGroupsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PURCHASING_GROUPS_REQUEST:
        draft.requestingPurchasingGroups = true;
        draft.requestPurchasingGroupsError = false;
        break;

      case GET_PURCHASING_GROUPS_SUCCESS:
        draft.requestingPurchasingGroups = false;
        draft.purchasingGroups = action.payload.sort((a, b) =>
          a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
        );
        break;

      case GET_PURCHASING_GROUPS_ERROR:
        draft.requestPurchasingGroupsError = true;
        draft.requestingPurchasingGroups = false;
        break;

      case CREATE_PURCHASING_GROUP:
      case EDIT_PURCHASING_GROUP:
      case ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS:
        draft.creatingPurchasingGroup = true;
        draft.createPurchasingGroupError = false;
        break;

      case CREATE_PURCHASING_GROUP_SUCCESS:
      case EDIT_PURCHASING_GROUP_SUCCESS:
      case ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_SUCCESS:
        draft.creatingPurchasingGroup = false;
        break;

      case CREATE_PURCHASING_GROUP_ERROR:
      case EDIT_PURCHASING_GROUP_ERROR:
      case ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_ERROR:
        draft.creatingPurchasingGroup = false;
        draft.createPurchasingGroupError = true;
        break;

      default:
        break;
    }
  });

export default reducer;

import React, { useCallback } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { TabGroup } from "@ddm-design-system/tab";
import { StyledTab, TabDivider, TabsContainer } from "./tabs.styles";

export interface ITab {
  content: JSX.Element;
  disabled?: boolean;
  label: string | JSX.Element;
  route: string;
}

interface IProps {
  disabled?: boolean;
  initialTab?: number;
  onTabClick?: (index: number) => void;
  tabs: ITab[];
}

const Tabs: React.FC<IProps> = ({ disabled, initialTab = 0, onTabClick, tabs, ...props }) => {
  const history = useHistory();

  const handleTabClick = useCallback(
    (tabIndex: number | string) => {
      history.replace(tabs[Number(tabIndex)].route);

      onTabClick?.(Number(tabIndex));
    },
    [history, onTabClick, tabs]
  );

  const isTabDisabled = (tab: number) => !!(disabled || !tabs[tab] || tabs[tab].disabled);

  const selectedTab = tabs.findIndex((tab: ITab) =>
    history.location?.pathname?.startsWith(tab.route)
  );

  const isSelectedTabDisabled = isTabDisabled(selectedTab);

  let redirectTab: number;

  if (selectedTab === -1) {
    redirectTab = isTabDisabled(initialTab) ? 0 : initialTab;
  } else {
    redirectTab = isSelectedTabDisabled ? 0 : selectedTab;
  }

  const renderedTabs = tabs.map(({ disabled: disabledTab, label }: ITab, index: number) => (
    <StyledTab disabled={disabledTab} key={index} label={label} />
  ));

  return (
    <TabsContainer {...props}>
      <TabGroup
        disabled={disabled}
        initialTab={isSelectedTabDisabled ? -1 : selectedTab}
        onTabClick={handleTabClick}
        selectedTab={isSelectedTabDisabled ? undefined : selectedTab}
      >
        {renderedTabs}
      </TabGroup>
      <TabDivider />
      <Switch>
        <Route
          exact
          path={tabs[redirectTab].route}
          render={() => !isTabDisabled(redirectTab) && tabs[redirectTab].content}
        />
        <Redirect to={tabs[redirectTab].route} />
      </Switch>
    </TabsContainer>
  );
};

export default Tabs;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Subtitle } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import useContent from "../../hooks/useContent";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import { resetOutletFormState } from "../../store/outlets/actions";
import { CloseIcon } from "../common/icon/CloseIcon.styles";
import { QuestionType } from "../../hooks/useGetQuestionnaire";
import { CreateOutletFooter } from "../outlets/createOutletForm.styles";
import CreateOutletFormContent from "../outlets/CreateOutletFormContent";
import { IComponentInfo } from "../../store/components/types";
import { getBeveragesX10 } from "../../store/beverages/selectors";
import { ICountry } from "../../store/beverages/types";
import { setComponentBeverage } from "../../store/extra10outlets/actions";
import { getErrorChangingBeverage } from "../../store/extra10outlets/selectors";

interface IProps {
  visible: boolean;
  onClose: () => void;
  x10: IComponentInfo;
  country?: ICountry;
}

const EditExtra10Form: React.FC<IProps> = ({ x10, country, visible, onClose }) => {
  const { outlets: content, outletDetails: contentDetails, common } = useContent();
  const dispatch = useDispatch();
  const [values, setValues] = useState<any>({});
  const beveragesX10 = useSelector(getBeveragesX10);
  const [isSaving, setIsSaving] = useState(false);
  const errorChangingBeverage = useSelector(getErrorChangingBeverage);

  const availableBeverages = useMemo(() => {
    if (!country) {
      return beveragesX10;
    }
    return beveragesX10.filter(b => b.countries.map(c => c.id).includes(country.id));
  }, [country, beveragesX10]);

  useEffect(() => {
    if (errorChangingBeverage) {
      setIsSaving(false);
    }
  }, [errorChangingBeverage]);

  useEffect(() => {
    if (!x10) {
      return;
    }

    const initValues = {
      beverageId: { id: x10.beverageId, text: x10?.brand?.title }
    };
    setValues(initValues);
  }, [x10]);

  const editComponentAux = useCallback(() => {
    const bevId = values.beverageId?.id;
    if (x10 && bevId) {
      dispatch(
        setComponentBeverage({ thingId: x10.thingId, beverageId: bevId, componentId: x10.id })
      );
      setIsSaving(true);
    }
  }, [x10, values, dispatch]);

  const onCloseAux = (force = false) => {
    const initValues = {
      beverageId: { id: x10.beverageId, text: x10?.brand?.title }
    };
    setValues(initValues);

    dispatch(resetOutletFormState());
    setIsSaving(false);
    onClose();
  };

  const validForm = useMemo(() => {
    return true;
  }, [values, x10]);

  const canAdvance = useMemo(() => {
    if (isSaving) {
      return false;
    }
    return validForm;
  }, [values, validForm, isSaving]);

  return (
    <SidebarModal visible={visible} onClose={onCloseAux}>
      <div className="flex flex-col h-full">
        <div className="h-[120px]">
          <div className="flex justify-between items-center mb-xs">
            <Subtitle>{contentDetails.outlet_details_edit_info}</Subtitle>
            <CloseIcon onClick={() => onCloseAux(true)} />
          </div>
        </div>
        <CreateOutletFormContent
          questions={[
            {
              question: common.common_beverage,
              placeholder: common.common_beverage,
              field: "beverageId",
              type: QuestionType.DROPDOWN,
              options: availableBeverages.map(b => ({ id: b.id, text: b.name }))
            }
          ]}
          values={values}
          onSet={values => {
            setValues(values);
          }}
        />

        {errorChangingBeverage && (
          <span className="text-alert-alert100">
            Cannot change beverage: No mount on this Extra 10
          </span>
        )}
        <CreateOutletFooter error={errorChangingBeverage}>
          <SecondaryButton onClick={() => onCloseAux()}>{common.common_cancel}</SecondaryButton>
          <PrimaryButton disabled={!canAdvance} onClick={editComponentAux}>
            {common.common_save}
          </PrimaryButton>
        </CreateOutletFooter>
      </div>
    </SidebarModal>
  );
};

export default EditExtra10Form;

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { DescriptionMedium, MainTitle } from "@ddm-design-system/typography";
import { useIsMobile, useScroll } from "@ddm-design-system/hooks";
import classnames from "classnames";
import { Anchor, Content } from "./anchorContainer.styles";
import { LoadingIcon } from "../data_table/dataTable.styles";

export interface IAnchorItem {
  href: string;
  title: string;
  renderContent: () => JSX.Element;
}

interface IProps {
  list: IAnchorItem[];
  emptyString?: string;
  loading?: boolean;
}

const MIN_DISTANCE = 70;

const AnchorContainer: React.FC<IProps> = ({ list, emptyString, loading }) => {
  const isMobile = useIsMobile();
  const [_list, setList] = useState(list);
  const [scrollHeight, setScrollHeight] = useState(1000);
  const scrollingElement = useRef<HTMLDivElement>(null);
  const parentElement = useRef<HTMLDivElement>(null);
  const [, updateState] = React.useState();
  const scrollContent = useScroll(scrollingElement.current ?? undefined);

  useLayoutEffect(() => {
    setList(list);

    // forcing update with the right scrollingElement
    if (scrollingElement.current) {
      updateState(null);
      setScrollHeight(window.innerHeight - scrollingElement.current.offsetTop);
    }
  }, [list]);

  // calculate each element scroll position
  const listScroll = useMemo(() => {
    return _list
      .map(l => {
        const elem = document.getElementById(l.href);

        return elem ? elem?.offsetTop - (parentElement.current?.offsetTop || 0) : 0;
      })
      .reverse();
  }, [_list]);

  const currentItem = useMemo(() => {
    if (list.length === 0) {
      return undefined;
    }

    if (scrollContent?.scroll === 0) {
      return list[0]?.href;
    }

    if (scrollContent?.hasScrolledToEnd) {
      return list[list.length - 1]?.href;
    }

    const index =
      list.length - 1 - listScroll.findIndex(v => v - MIN_DISTANCE <= (scrollContent?.scroll || 0));

    return index >= 0 ? list[index]?.href : list[list.length - 1]?.href;
  }, [list, listScroll, scrollContent]);

  useEffect(() => {
    if (listScroll) {
      const hash = window.location.hash?.slice(1);

      if (hash) {
        const elem = document.getElementById(hash);

        if (elem) {
          elem.scrollIntoView();
        }
      }
    }
  }, [listScroll]);

  const AnchorContent = useMemo(() => {
    return (
      <Content ref={scrollingElement as any}>
        {loading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingIcon />
          </div>
        ) : (
          <>
            {list.map(l => (
              <div id={l.href} key={l.href}>
                <MainTitle className="pt-xxl pb-lg capitalize">{l.title}</MainTitle>
                {l.renderContent()}
              </div>
            ))}

            {!list.length && (
              <div className="text-grey-grey50 mt-lg ml-md w-full text-center">{emptyString}</div>
            )}
          </>
        )}
      </Content>
    );
  }, [emptyString, list, loading]);

  return (
    <div
      className="h-full flex"
      ref={parentElement as any}
      style={{ maxHeight: isMobile ? "unset" : scrollHeight }}
    >
      {!isMobile && (
        <div
          className={classnames("flex flex-col pt-xxl overflow-auto", { "pr-xxl": list.length })}
        >
          {list.map(l => (
            <Anchor key={l.href} href={`#${l.href}`} selected={currentItem === l.href}>
              <DescriptionMedium className="capitalize">{l.title}</DescriptionMedium>
            </Anchor>
          ))}
        </div>
      )}
      {AnchorContent}
    </div>
  );
};

export default AnchorContainer;

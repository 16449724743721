import { IRecentlyCreatedTechnician, ITechnicianOutletAssociation } from "../../services/jcore";
import { ICountry } from "../beverages/types";
import { ILocation } from "../outlets/types";

export const GET_TECHNICIANS_REQUEST = "technicians/GET_TECHNICIANS_REQUEST";
export const NEW_TECHNICIAN_REQUEST = "technicians/NEW_TECHNICIAN_REQUEST";
export const GET_TECHNICIANS_SUCCESS = "technicians/GET_TECHNICIANS_SUCCESS";
export const GET_TECHNICIANS_ERROR = "technicians/GET_TECHNICIANS_ERROR";
export const GET_TECHNICIAN_REQUEST = "technicians/GET_TECHNICIAN_REQUEST";
export const GET_TECHNICIAN_SUCCESS = "technicians/GET_TECHNICIAN_SUCCESS";
export const GET_TECHNICIAN_ERROR = "technicians/GET_TECHNICIAN_ERROR";
export const NEW_TECHNICIAN_ERROR = "technicians/NEW_TECHNICIAN_ERROR";
export const NEW_TECHNICIAN_SUCCESS = "technicians/NEW_TECHNICIAN_SUCCESS";
export const RESET_TECHNICIAN_ID = "technicians/RESET_TECHNICIAN_ID";
export const GET_TECHNICIAN_DEVICE_CODE_REQUEST = "technciians/GET_TECHNICIAN_DEVICE_CODE_REQUEST";
export const GET_TECHNICIAN_DEVICE_CODE_SUCCESS = "technciians/GET_TECHNICIAN_DEVICE_CODE_SUCCESS";
export const GET_TECHNICIAN_DEVICE_CODE_ERROR = "technciians/GET_TECHNICIAN_DEVICE_CODE_ERROR";

export const DELETE_TECHNICIAN_REQUEST = "technicians/DELETE_TECHNICIAN_REQUEST";
export const DELETE_TECHNICIAN_SUCCESS = "technicians/DELETE_TECHNICIAN_SUCCESS";
export const DELETE_TECHNICIAN_ERROR = "technicians/DELETE_TECHNICIAN_ERROR";

export const EDIT_TECHNICIAN_REQUEST = "technicians/EDIT_TECHNICIAN_REQUEST";
export const EDIT_TECHNICIAN_SUCCESS = "technicians/EDIT_TECHNICIAN_SUCCESS";
export const EDIT_TECHNICIAN_ERROR = "technicians/EDIT_TECHNICIAN_ERROR";
export const UNREGISTER_DEVICE = "technician/UNREGISTER_DEVICE";
export const RESET_TECHNICIANS = "technician/RESET_TECHNICIANS";

export const SET_TECHNICIAN_INTERNATIONAL_REQUEST =
  "technicians/SET_TECHNICIAN_INTERNATIONAL_REQUEST";
export const SET_TECHNICIAN_INTERNATIONAL_SUCCESS =
  "technicians/SET_TECHNICIAN_INTERNATIONAL_SUCCESS";
export const SET_TECHNICIAN_INTERNATIONAL_ERROR = "technicians/SET_TECHNICIAN_INTERNATIONAL_ERROR";

export interface ITechnicianState {
  technicians: ITechnician[];
  requestingTechnicians: boolean;
  requestTechniciansError: boolean;
  currentTechnician?: ITechnician;
  requestingCurrentTechnician: boolean;
  newTechnicianId: string;
  requestingTechnicianDeviceCode: boolean;
  technicianDeviceCode?: string;
  technicianDeviceCodeError?: boolean;
  technicianWasDeleted?: boolean;
  requestingEditTechnician?: boolean;
  requestingCreateTechnician?: boolean;
  requestingCreateTechnicianError?: boolean;
  requestingSetTechnicianInternational?: boolean;
  requestSetTechnicianInternationalError?: boolean;
}

export interface IRegisteredDevice {
  id: string;
  fingerprint: string;
  info: string;
  registerCode: string;
}

export interface ITechnician {
  id: string;
  name: string;
  hubCode: string;
  country?: ICountry;
  international?: boolean;
  phoneNumber: string;
  email: string;
  lastLogin?: Date;
  lastInstallation: Date;
  outlets: number;
  schedule?: ITechnicianSchedule[];
  registerCode?: string;
  associatedLocations?: ILocation[];
  technicianRegisteredDevices?: IRegisteredDevice[];
  technicianLocationAssociationResponse?: ITechnicianOutletAssociation[];
}

export interface ITechnicianSchedule {
  outlet: string;
  location: string;
  added: Date;
  status: string;
}

export interface ITechnicianParams {
  name: string;
  country?: string;
  phoneNumber: string;
  email: string;
}

export interface IGetTechniciansRequest {
  type: typeof GET_TECHNICIANS_REQUEST;
}

export interface IGetTechniciansSuccess {
  type: typeof GET_TECHNICIANS_SUCCESS;
  payload: ITechnician[];
}

export interface IGetTechniciansError {
  type: typeof GET_TECHNICIANS_ERROR;
}

export interface INewTechnicianRequest {
  type: typeof NEW_TECHNICIAN_REQUEST;
  payload: ITechnicianParams;
}

export interface INewTechnicianError {
  type: typeof NEW_TECHNICIAN_ERROR;
}

export interface INewTechnicianSuccess {
  type: typeof NEW_TECHNICIAN_SUCCESS;
  payload: IRecentlyCreatedTechnician;
}

export interface IResetTechnicianId {
  type: typeof RESET_TECHNICIAN_ID;
}

export interface IGetTechnicianRequest {
  type: typeof GET_TECHNICIAN_REQUEST;
  payload: string;
}

export interface IGetTechnicianSuccess {
  type: typeof GET_TECHNICIAN_SUCCESS;
  payload: ITechnician;
}

export interface IGetTechnicianError {
  type: typeof GET_TECHNICIAN_ERROR;
}

export interface IGetTechnicianDeviceCodeRequest {
  type: typeof GET_TECHNICIAN_DEVICE_CODE_REQUEST;
  payload: string;
}

export interface IGetTechnicianDeviceCodeSuccess {
  type: typeof GET_TECHNICIAN_DEVICE_CODE_SUCCESS;
  payload: IRecentlyCreatedTechnician;
}

export interface IGetTechnicianDeviceCodeError {
  type: typeof GET_TECHNICIAN_DEVICE_CODE_ERROR;
}

export interface IDeleteTechnicianRequest {
  type: typeof DELETE_TECHNICIAN_REQUEST;
  payload: string;
}

export interface IDeleteTechnicianSuccess {
  type: typeof DELETE_TECHNICIAN_SUCCESS;
  payload: string;
}

export interface IDeleteTechnicianError {
  type: typeof DELETE_TECHNICIAN_ERROR;
  payload: string;
}

export interface IEditTechnicianRequest {
  type: typeof EDIT_TECHNICIAN_REQUEST;
  payload: {
    params: ITechnicianParams;
    id: string;
  };
}

export interface IEditTechnicianSuccess {
  type: typeof EDIT_TECHNICIAN_SUCCESS;
  payload: {
    technicianId: string;
    params: ITechnicianParams;
  };
}

export interface IEditTechnicianError {
  type: typeof EDIT_TECHNICIAN_ERROR;
  payload: string;
}

export interface IUnregisterDevice {
  type: typeof UNREGISTER_DEVICE;
  payload: string;
}

export interface IResetTechnicians {
  type: typeof RESET_TECHNICIANS;
}

export interface ISetTechnicianInternationalRequest {
  type: typeof SET_TECHNICIAN_INTERNATIONAL_REQUEST;
  payload: {
    id: string;
    international: boolean;
  };
}

export interface ISetTechnicianInternationalSuccess {
  type: typeof SET_TECHNICIAN_INTERNATIONAL_SUCCESS;
  payload: {
    id: string;
    international: boolean;
  };
}

export interface ISetTechnicianInternationalError {
  type: typeof SET_TECHNICIAN_INTERNATIONAL_ERROR;
  payload: {
    id: string;
    international: boolean;
  };
}

export type TechnicianActionTypes =
  | IGetTechniciansError
  | IGetTechniciansRequest
  | IGetTechniciansSuccess
  | INewTechnicianRequest
  | INewTechnicianError
  | INewTechnicianSuccess
  | IResetTechnicianId
  | IGetTechnicianRequest
  | IGetTechnicianSuccess
  | IGetTechnicianError
  | IGetTechnicianDeviceCodeRequest
  | IGetTechnicianDeviceCodeSuccess
  | IGetTechnicianDeviceCodeError
  | IDeleteTechnicianError
  | IDeleteTechnicianRequest
  | IDeleteTechnicianSuccess
  | IEditTechnicianRequest
  | IEditTechnicianSuccess
  | IEditTechnicianError
  | IUnregisterDevice
  | IResetTechnicians
  | ISetTechnicianInternationalRequest
  | ISetTechnicianInternationalSuccess
  | ISetTechnicianInternationalError;

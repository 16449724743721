import {
  CLOSE_SIDEBAR,
  ICloseSidebar,
  IOpenSidebar,
  IToggleSidebar,
  OPEN_SIDEBAR,
  TOGGLE_SIDEBAR
} from "./types";

export const toggleSidebar = (): IToggleSidebar => ({
  type: TOGGLE_SIDEBAR
});

export const openSidebar = (): IOpenSidebar => ({
  type: OPEN_SIDEBAR
});

export const closeSidebar = (): ICloseSidebar => ({
  type: CLOSE_SIDEBAR
});

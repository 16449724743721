import {
  GET_COMPONENT_INFO,
  GET_COMPONENT_LINES,
  GET_COMPONENT_HEALTH_STATUS,
  GET_COMPONENT_INFO_SUCCESS,
  GET_COMPONENT_LINES_SUCCESS,
  GET_COMPONENT_HEALTH_STATUS_SUCCESS,
  GET_COMPONENT_INFO_ERROR,
  GET_COMPONENT_HEALTH_STATUS_ERROR,
  GET_COMPONENT_LINES_ERROR,
  IComponentInfo,
  ILine,
  IComponentHealthStatus,
  IGetComponentInfoAction,
  IGetComponentLinesAction,
  IGetComponentHealthStatusAction,
  IGetComponentInfoSuccessAction,
  IGetComponentLinesSuccessAction,
  IGetComponentHealthStatusSuccessAction,
  IGetComponentInfoErrorAction,
  IGetComponentLinesErrorAction,
  IGetComponentHealthStatusErrorAction
} from "./types";

export const getComponentInfo = (componentId: string): IGetComponentInfoAction => ({
  type: GET_COMPONENT_INFO,
  payload: componentId
});

export const getComponentInfoSuccess = (
  componentInfo: IComponentInfo
): IGetComponentInfoSuccessAction => ({
  type: GET_COMPONENT_INFO_SUCCESS,
  payload: componentInfo
});

export const getComponentInfoError = (componentId: string): IGetComponentInfoErrorAction => ({
  type: GET_COMPONENT_INFO_ERROR,
  payload: componentId
});

export const getComponentLines = (componentId: string): IGetComponentLinesAction => ({
  type: GET_COMPONENT_LINES,
  payload: componentId
});

export const getComponentLinesSuccess = (lines: {
  id: string;
  lines: ILine[];
}): IGetComponentLinesSuccessAction => ({
  type: GET_COMPONENT_LINES_SUCCESS,
  payload: lines
});

export const getComponentLinesError = (componentId: string): IGetComponentLinesErrorAction => ({
  type: GET_COMPONENT_LINES_ERROR,
  payload: componentId
});

export const getComponentHealthStatus = (componentId: string): IGetComponentHealthStatusAction => ({
  type: GET_COMPONENT_HEALTH_STATUS,
  payload: componentId
});

export const getComponentHealthStatusSuccess = (
  componentHealthStatus: IComponentHealthStatus
): IGetComponentHealthStatusSuccessAction => ({
  type: GET_COMPONENT_HEALTH_STATUS_SUCCESS,
  payload: componentHealthStatus
});

export const getComponentHealthStatusError = (
  componentId: string
): IGetComponentHealthStatusErrorAction => ({
  type: GET_COMPONENT_HEALTH_STATUS_ERROR,
  payload: componentId
});

import React, { useCallback, useMemo, useState } from "react";
import { DataTable as DDMDataTable, DataTableProps } from "@ddm-design-system/data-table";
import { useIsMobile } from "@ddm-design-system/hooks";
import { Subtitle } from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import Filter, { IFilter, IFilterCheckbox } from "../filter/Filter";
import ActionsMenu, { IMenuAction } from "../menu/ActionsMenu";
import Search from "../search/Search";
import {
  DataTableContainer,
  DataTableFilterBar,
  DataTableHeader,
  DataTableHeaderContainer,
  LoadingIcon
} from "./dataTable.styles";
import { EOutletStatusLevel } from "../../../store/outlets/types";
import { EExtra10OutletStatusLevel } from "../../../store/extra10outlets/types";

export enum EChipType {
  DEFAULT = "default",
  NEUTRAL = "default",
  WARNING = "warning",
  DANGER = "danger",
  SUCCESS = "success"
}

export interface IChipSelector {
  name: string;
  sort?: number;
  type: EChipType | EOutletStatusLevel | EExtra10OutletStatusLevel;
}

// This is a temporary style while we have a grey background on the app.
const customStyles = {
  progress: {
    style: {
      backgroundColor: "var(--color-background)"
    }
  }
};

interface IProps<T> {
  actions?: IMenuAction[];
  columns: DataTableProps<T>["columns"];
  data: DataTableProps<T>["data"];
  defaultSortAsc?: boolean;
  defaultSortField?: string;
  filters?: IFilter[];
  isLoading?: boolean;
  searchPlaceholder?: string;
  searchSelector?: string | string[];
  title: string;
  enableActions?: boolean;
  pagination?: boolean;
  paginationPerPage?: number;
  showOnlyFirstAction?: boolean;
  expandableRows?: boolean;
  expandableRowsComponent?: any;
  expandableRowExpanded?: any;
}

const DataTable = <T,>({
  actions = [],
  columns,
  data,
  defaultSortAsc,
  defaultSortField,
  filters,
  isLoading,
  searchPlaceholder,
  searchSelector = "",
  title,
  enableActions = true,
  pagination = false,
  paginationPerPage,
  showOnlyFirstAction = false,
  expandableRows = false,
  expandableRowsComponent,
  expandableRowExpanded,
  ...props
}: IProps<T>) => {
  const { common: content } = useContent();
  const isMobile = useIsMobile();
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<IFilter[]>(filters || []);

  const handleSearchChange = useCallback((value: string) => setSearch(value.toLowerCase()), []);

  const handleFilterChange = useCallback((newFilter: IFilter[]) => setFilter(newFilter), []);

  const filteredData = useMemo(() => {
    return data.filter(row => {
      const searchPredicate =
        !searchSelector ||
        (!Array.isArray(searchSelector)
          ? (row as any)[searchSelector]?.toLowerCase().includes(search)
          : searchSelector.some(s => (row as any)[s]?.toLowerCase().includes(search)));

      const filterPredicate = filter.every(({ id, options }: IFilter) => {
        const optionsSelected = options.reduce(
          (result: string[], { id: optionId, selected }: IFilterCheckbox) =>
            selected ? [...result, optionId] : result,
          []
        );

        return optionsSelected.length === 0 || optionsSelected.includes((row as any)[id]);
      });
      return searchPredicate && filterPredicate;
    });
  }, [data, filter, search, searchSelector]);

  const csvContent = useCallback(() => {
    const csvColumns = columns.map(column => column.name);
    const csvRows = filteredData.map(row =>
      columns.map(({ selector }) => {
        const value = selector?.(row) || 0;
        const isTimestamp = parseInt(value.toString()) > 10000000000;

        return `"${
          typeof selector === "string"
            ? row[selector]
            : isTimestamp
            ? new Date(parseInt(value.toString())).toISOString()
            : selector?.(row)
        }"`;
      })
    );
    const csvData = [csvColumns, ...csvRows].map(row => row.join(",")).join("\n");

    return encodeURI(`data:text/csv;charset=utf-8,${csvData}`);
  }, [columns, filteredData]);

  const downloadCSV = useCallback(() => {
    const link = document.createElement("a");

    link.setAttribute("href", csvContent());
    link.setAttribute("download", `${title.toLowerCase()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [csvContent, title]);

  const actionsDownload: IMenuAction[] = useMemo(() => {
    if (actions.length === 0 || !actions.map(a => a.label).includes(content.common_download_csv)) {
      return [
        ...actions,
        {
          icon: "Download",
          label: content.common_download_csv,
          onClick: downloadCSV
        }
      ];
    }
    return actions.map(a =>
      a.label === content.common_download_csv
        ? {
            icon: "Download",
            label: content.common_download_csv,
            onClick: () => a.onClick(filteredData)
          }
        : {
            ...a,
            label: typeof a.label === "string" ? a.label : () => a.label(filteredData)
          }
    );
  }, [actions, content.common_download_csv, downloadCSV, filteredData]);

  const searchBar = searchSelector && (
    <Search onChange={handleSearchChange} placeholder={searchPlaceholder} />
  );

  const dataTableHeader = (
    <DataTableHeader>
      <DataTableHeaderContainer>
        <Subtitle>{title}</Subtitle>
        <DataTableFilterBar>
          {!isMobile && searchBar}
          {filters && <Filter filters={filters} onChange={handleFilterChange} />}
          {enableActions && (
            <ActionsMenu actions={actionsDownload} showOnlyFirstAction={showOnlyFirstAction} />
          )}
        </DataTableFilterBar>
      </DataTableHeaderContainer>
      {isMobile && <div>{searchBar}</div>}
    </DataTableHeader>
  );

  return (
    <DataTableContainer {...props}>
      {dataTableHeader}
      <DDMDataTable
        columns={columns}
        customStyles={customStyles}
        data={filteredData}
        defaultSortAsc={defaultSortAsc}
        defaultSortFieldId={defaultSortField}
        noHeader
        progressComponent={<LoadingIcon />}
        progressPending={isLoading}
        pagination={pagination}
        paginationPerPage={paginationPerPage}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        expandableRowExpanded={expandableRowExpanded}
      />
    </DataTableContainer>
  );
};

export default DataTable;

import styled, { css } from "styled-components";
import { PrimaryButton } from "@ddm-design-system/button";
import { EBreakpoints } from "@ddm-design-system/tokens";

export const Cookies = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 10px;
    bottom: ${theme.spaceUnit.md};
    box-shadow: 0px 2px 8px ${theme.colors.background.overlay};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    left: ${theme.spaceUnit.md};
    padding: ${theme.spaceUnit.md};
    position: fixed;
    text-align: left;
    width: 280px;
    z-index: 3;

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE2]}px) {
      width: calc(100% - 2 * ${theme.spaceUnit.md});
    }
  `
);

export const TextLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

export const AcceptButton = styled(PrimaryButton)(
  ({ theme }) => css`
    margin-top: ${theme.spaceUnit.sm};
  `
);

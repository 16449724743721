import { SyncCollection } from "contentful";
import {
  GET_CONTENTFUL,
  GET_CONTENTFUL_ERROR,
  GET_CONTENTFUL_SUCCESS,
  IGetContentfulAction,
  IGetContentfulErrorAction,
  IGetContentfulSuccessAction,
  ILanguageInfo,
  ISelectLanguage,
  ISetCurrentLanguageAction,
  ISetSyncToken,
  SELECT_LANGUAGE,
  SET_CURRENT_LANGUAGE,
  SET_SYNC_TOKEN
} from "./types";
import { IJCoreEntry } from "./index";

export const getContentful = (): IGetContentfulAction => ({
  type: GET_CONTENTFUL
});

export const setCurrentLanguage = (lang: ILanguageInfo): ISetCurrentLanguageAction => ({
  type: SET_CURRENT_LANGUAGE,
  payload: lang
});

export const getContentfulSuccess = (contentful: {
  entries: IJCoreEntry<any>[];
  assets: IJCoreEntry<any>[];
}): IGetContentfulSuccessAction => ({
  type: GET_CONTENTFUL_SUCCESS,
  payload: contentful
});

export const getContentfulError = (): IGetContentfulErrorAction => ({
  type: GET_CONTENTFUL_ERROR
});

export const setSyncToken = (
  contentfulToken: SyncCollection["nextSyncToken"],
  dbTimestamp: number
): ISetSyncToken => ({
  type: SET_SYNC_TOKEN,
  payload: {
    contentfulToken,
    dbTimestamp
  }
});

export const selectLanguage = (payload: string): ISelectLanguage => ({
  type: SELECT_LANGUAGE,
  payload
});

import {
  GET_X10_OUTLET_INFO,
  GET_X10_OUTLET_INFO_SUCCESS,
  GET_X10_OUTLET_INSTALLATION,
  GET_X10_OUTLET_INSTALLATION_SUCCESS,
  GET_X10_OUTLETS_ERROR,
  GET_X10_OUTLETS_REQUEST,
  GET_X10_OUTLETS_SUCCESS,
  IExtra10Installation,
  IExtra10Location,
  IGetExtra10OutletInfoAction,
  IGetExtra10OutletInfoSuccessAction,
  IGetExtra10OutletInstallationAction,
  IGetExtra10OutletInstallationSuccessAction,
  IGetExtra10OutletsErrorAction,
  IGetExtra10OutletsRequestAction,
  IGetExtra10OutletsSuccessAction,
  ISetComponentBeverageAction,
  ISetComponentBeverageError,
  SET_COMPONENT_BEVERAGE,
  SET_COMPONENT_BEVERAGE_ERROR
} from "./types";

export const getExtra10Outlets = (): IGetExtra10OutletsRequestAction => ({
  type: GET_X10_OUTLETS_REQUEST
});

export const getExtra10OutletsSuccess = (
  payload: IExtra10Location[]
): IGetExtra10OutletsSuccessAction => ({
  type: GET_X10_OUTLETS_SUCCESS,
  payload
});

export const getExtra10OutletsError = (): IGetExtra10OutletsErrorAction => ({
  type: GET_X10_OUTLETS_ERROR
});

export const getExtra10OutletInfo = (outletId: string): IGetExtra10OutletInfoAction => ({
  type: GET_X10_OUTLET_INFO,
  payload: outletId
});

export const getExtra10OutletInfoSuccess = (
  outlet: IExtra10Location
): IGetExtra10OutletInfoSuccessAction => ({
  type: GET_X10_OUTLET_INFO_SUCCESS,
  payload: outlet
});

export const getExtra10OutletInstallation = (
  outletId: string
): IGetExtra10OutletInstallationAction => ({
  type: GET_X10_OUTLET_INSTALLATION,
  payload: outletId
});

export const getExtra10OutletInstallationSuccess = (payload: {
  id: string;
  installation: IExtra10Installation;
}): IGetExtra10OutletInstallationSuccessAction => ({
  type: GET_X10_OUTLET_INSTALLATION_SUCCESS,
  payload
});

export const setComponentBeverage = (payload: {
  thingId: string;
  beverageId: string;
  componentId: string;
}): ISetComponentBeverageAction => ({
  type: SET_COMPONENT_BEVERAGE,
  payload
});

export const setComponentBeverageError = (): ISetComponentBeverageError => ({
  type: SET_COMPONENT_BEVERAGE_ERROR
});

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import useContent from "../../hooks/useContent";
import { editBeverage, mergeBeverage } from "../../store/beverages/actions";
import { IBeverageTranslation } from "../../store/beverages/types";
import Button from "../common/button/Button";
import Select from "../common/select/Select";
import "./beverages.scss";
import { getBeverages } from "../../store/beverages/selectors";

interface IProps {
  beverage: IBeverageTranslation;
  onMerge: (id: string) => void;
}

export const BeverageMerge: React.FC<IProps> = ({ beverage, onMerge }) => {
  const dispatch = useDispatch();
  const beverages = useSelector(getBeverages);
  const [selectedBeverage, selectBeverage] = useState<any>();
  const [beverageOptions, setBeverageOptions] = useState<any>();

  const { controlTowerBeveragesPage: content, common } = useContent();

  useEffect(() => {
    setBeverageOptions(
      beverages
        .filter(b => b.active === true)
        .map(b => ({
          text: b.name,
          ...b
        }))
    );
  }, [beverages]);

  return (
    <div className="beverage-merge-content">
      <div className="beverage-merge-description">
        <div className="row">
          <Icon name="Warning" />
          <h2>{content.control_tower_newly_created_beer}</h2>
        </div>
        <div className="description-body">{content.control_tower_merge_description}</div>
      </div>
      <div className="beverage-merge-body">
        <div className="beverage-merge-card">
          <h2>{content.control_tower_fix_beverage}</h2>
          <span className="beverage-merge-card-description">
            {content.control_tower_fix_beverage_description}
          </span>
          <div className="beverage-merge-options">
            {beverageOptions && (
              <Select
                search
                className="grow mr-md"
                style={{ flex: 2 }}
                label={content.control_tower_search_replace_beverage}
                placeholder={content.control_tower_search_replace_beverage}
                selectedOption={selectedBeverage}
                options={beverageOptions}
                onOptionSelected={option => selectBeverage(option)}
              />
            )}
            <div className="buttons-container">
              <Button
                primary
                onClick={() => {
                  if (selectedBeverage) {
                    dispatch(
                      mergeBeverage({
                        beverageToMergeId: beverage.id,
                        officialBeverageId: selectedBeverage?.id
                      })
                    );
                    onMerge(selectedBeverage.id);
                  }
                }}
              >
                {common.common_replace}
              </Button>
              <div className="vertical-line" />
              <div className="horizontal-line" />
              <Button secondary onClick={() => dispatch(editBeverage(beverage))}>
                {content.control_tower_its_new}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BeverageMerge;

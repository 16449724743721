import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { IFilter, IFilterCheckbox } from "../common/filter/Filter";
import { IMenuAction } from "../common/menu/ActionsMenu";
import { EOutletStatusBadges } from "../../store/outlets/types";
import { getCountries } from "../../store/outlets/actions";
import { getOutletStatuses } from "../../store/outlets/selectors";
import CreateOutletForm from "./CreateOutletForm";
import Header from "../common/header/Header";
import OutletList from "./OutletList";

const Outlets: React.FC = () => {
  const dispatch = useDispatch();
  const { outlets: content } = useContent();
  const [openCreateOutlet, setOpenCreateOutlet] = useState(false);
  const outlets = useSelector(getOutletStatuses);

  useEffect(() => {
    // used to create new outlet form
    dispatch(getCountries());
  }, [dispatch]);

  const headerActions: IMenuAction[] = [
    {
      label: content.outlets_create_new_outlet,
      onClick: () => {
        setOpenCreateOutlet(true);
      }
    }
  ];

  const getFilterOptions = useCallback(
    (options: string[]) =>
      options.map(
        (option: string): IFilterCheckbox => ({
          id: option,
          label: content[`outlets_status_${option?.toLocaleLowerCase()}`]
        })
      ),
    [content]
  );

  const filters = useMemo<IFilter[]>(
    () => [
      {
        id: "status",
        options: getFilterOptions(Object.values(EOutletStatusBadges)),
        title: content.outlets_status
      }
    ],
    [content.outlets_status, getFilterOptions]
  );

  return (
    <div className="pb-xl">
      <Header title={content.outlets_title} actions={headerActions} />
      <OutletList filters={filters} outlets={outlets} searchSelector="name" showGroupingInfo />
      <CreateOutletForm visible={openCreateOutlet} onClose={() => setOpenCreateOutlet(false)} />
    </div>
  );
};

export default Outlets;

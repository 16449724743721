import produce from "immer";
import { Reducer } from "redux";
import {
  ASSOCIATE_DISTRIBUTOR_OUTLETS,
  ASSOCIATE_DISTRIBUTOR_OUTLETS_ERROR,
  ASSOCIATE_DISTRIBUTOR_OUTLETS_SUCCESS,
  CREATE_DISTRIBUTOR,
  CREATE_DISTRIBUTOR_ERROR,
  CREATE_DISTRIBUTOR_SUCCESS,
  DistributorsActionTypes,
  EDIT_DISTRIBUTOR,
  EDIT_DISTRIBUTOR_ERROR,
  EDIT_DISTRIBUTOR_SUCCESS,
  GET_DISTRIBUTORS_ERROR,
  GET_DISTRIBUTORS_REQUEST,
  GET_DISTRIBUTORS_SUCCESS,
  IDistributorsState
} from "./types";

const initalState: IDistributorsState = {
  distributors: [],
  requestingDistributors: false,
  requestDistributorsError: false,
  creatingDistributor: false,
  createDistributorError: false
};

const reducer: Reducer<IDistributorsState, DistributorsActionTypes> = (
  state = initalState,
  action: DistributorsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_DISTRIBUTORS_REQUEST:
        draft.requestingDistributors = true;
        draft.requestDistributorsError = false;
        break;

      case GET_DISTRIBUTORS_SUCCESS:
        draft.requestingDistributors = false;
        draft.distributors = action.payload.sort((a, b) =>
          a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
        );
        break;

      case GET_DISTRIBUTORS_ERROR:
        draft.requestingDistributors = false;
        draft.requestDistributorsError = true;
        break;

      case CREATE_DISTRIBUTOR:
      case EDIT_DISTRIBUTOR:
      case ASSOCIATE_DISTRIBUTOR_OUTLETS:
        draft.creatingDistributor = true;
        draft.createDistributorError = false;
        break;

      case CREATE_DISTRIBUTOR_SUCCESS:
      case EDIT_DISTRIBUTOR_SUCCESS:
      case ASSOCIATE_DISTRIBUTOR_OUTLETS_SUCCESS:
        draft.creatingDistributor = false;
        break;

      case CREATE_DISTRIBUTOR_ERROR:
      case EDIT_DISTRIBUTOR_ERROR:
      case ASSOCIATE_DISTRIBUTOR_OUTLETS_ERROR:
        draft.creatingDistributor = false;
        draft.createDistributorError = true;
        break;

      default:
        break;
    }
  });

export default reducer;

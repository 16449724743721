import { Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { IAppState } from "../../store";
import { setTechnicianInternationalRequest } from "../../store/technicians/actions";
import { ITechnician } from "../../store/technicians/types";

interface IProps {
  technician: ITechnician;
}

const SetInternational: React.FC<IProps> = ({
  technician: { international: initialInternational, country, id }
}) => {
  const dispatch = useDispatch();
  const { technicianDetail: content } = useContent();
  const loading = useSelector<IAppState, boolean>(
    state => state.technician.requestingSetTechnicianInternational ?? false
  );

  const setTechnicianInternational = (value: boolean) => {
    setInternational(value);
    dispatch(setTechnicianInternationalRequest(id, value));
  };

  const [international, setInternational] = useState(initialInternational);

  useEffect(() => {
    setInternational(initialInternational);
  }, [initialInternational]);

  return (
    <div className="technician-outlets">
      <b>{content.technician_detail_ce_locations}</b>
      <div className="technician-outlets-list">
        {country && (
          <>
            <div className="item technician-ce-item">
              <h2 className="truncate">
                {content.technician_detail_all_country.replace("%COUNTRY%", country.name)}
              </h2>
            </div>
            <div className="item technician-ce-item">
              <Checkbox
                id="isInternational"
                onChange={e => setTechnicianInternational(e.target.checked)}
                disableRipple
                checked={international}
                disabled={loading}
              />
              <label htmlFor="isInternational" style={{ cursor: "pointer" }}>
                <h2 className="truncate">{content.technician_detail_all_international}</h2>
              </label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SetInternational;

import { IChipSelector } from "../../components/common/data_table/DataTable";

export const GET_HEALTH_STATUS_ERROR = "healthstatus/GET_HEALTH_STATUS_ERROR";
export const GET_HEALTH_STATUS_REQUEST = "healthstatus/GET_HEALTH_STATUS_REQUEST";
export const GET_HEALTH_STATUS_SUCCESS = "healthstatus/GET_HEALTH_STATUS_SUCCESS";

export const GET_X10_HEALTH_STATUS_ERROR = "healthstatus/GET_X10_HEALTH_STATUS_ERROR";
export const GET_X10_HEALTH_STATUS_REQUEST = "healthstatus/GET_X10_HEALTH_STATUS_REQUEST";
export const GET_X10_HEALTH_STATUS_SUCCESS = "healthstatus/GET_X10_HEALTH_STATUS_SUCCESS";

export enum EHealthStatusLevel {
  VERY_HIGH = "very_high",
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low"
}

export enum EHealthStatusTarget {
  COMPRESSOR_LINE = "compressorLine",
  CONTROL_UNIT = "controlUnit",
  COOLING_UNIT = "coolingUnit",
  PRESSURE_CHAMBER = "pressureChamber"
}

export enum EHealthStatusType {
  COMPRESSOR_LINE_LEAK = "compressor_line_leak",
  CONTROL_UNIT_AIR_LEAK = "control_unit_air_leak",
  CONTROL_UNIT_BATTERY_DEPLETED = "control_unit_battery_depleted",
  CONTROL_UNIT_CLEANING_LEAK = "control_unit_cleaning_leak",
  CONTROL_UNIT_COMPRESSOR_HIGH_PRESSURE = "control_unit_compressor_high_pressure",
  CONTROL_UNIT_COMPRESSOR_LOW_PRESSURE = "control_unit_compressor_low_pressure",
  CONTROL_UNIT_DDM_NOT_DETECTED = "control_unit_ddm_not_detected",
  CONTROL_UNIT_FLOW_BLOCK = "control_unit_flow_block",
  CONTROL_UNIT_INVALID_INLET_PRESSURE = "control_unit_invalid_inlet_pressure",
  CONTROL_UNIT_KEG_COUPLER_LEAK = "control_unit_keg_coupler_leak",
  CONTROL_UNIT_MW_VALVE_FAULT = "control_unit_mw_valve_fault",
  CONTROL_UNIT_POWER_FAULT_DDM = "control_unit_power_fault_ddm",
  CONTROL_UNIT_POWER_FAULT_NCU = "control_unit_power_fault_ncu",
  CONTROL_UNIT_PRESSURE_INCREASE = "control_unit_pressure_increase",
  CONTROL_UNIT_SOLENOID_FAULT = "control_unit_solenoid_fault",
  CONTROL_UNIT_STEPPER_FAULT = "control_unit_stepper_fault",
  CONTROL_UNIT_STEPPER_STALL = "control_unit_stepper_stall",
  CONTROL_UNIT_STRESSED_PUMP = "control_unit_stressed_pump",
  CONTROL_UNIT_WATER_INSUFFICIENT = "control_unit_water_insufficient",
  CONTROL_UNIT_WATER_LEAK = "control_unit_water_leak",
  CONTROL_UNIT_WATER_REGULATOR_FAULT = "control_unit_water_regulator_fault",
  COOLING_UNIT_TEMPERATURE_ABOVE_LIMIT_FAN = "cooling_unit_temperature_above_limit_fan",
  COOLING_UNIT_TEMPERATURE_ABOVE_LIMIT_ROOM = "cooling_unit_temperature_above_limit_room",
  COOLING_UNIT_TEMPERATURE_ABOVE_LIMIT_WATER_TANK = "cooling_unit_temperature_above_limit_water_tank",
  COOLING_UNIT_TEMPERATURE_ABOVE_LIMIT_WATER_TUBE = "cooling_unit_temperature_above_limit_water_tube",
  COOLING_UNIT_TEMPERATURE_ANOMALY = "cooling_unit_temperature_anomaly",
  COOLING_UNIT_TEMPERATURE_BELOW_LIMIT = "cooling_unit_temperature_below_limit",
  COOLING_UNIT_TEMPERATURE_DRIFT = "cooling_unit_temperature_drift",
  PRESSURE_CHAMBER_LEAK = "pressure_chamber_leak"
}

export interface IHealthStatusResponse {
  createDateTime: string;
  customFields: string;
  level: string;
  locationId: string;
  position?: number;
  target: EHealthStatusTarget;
  type: string;
}

export interface ICustomFields {
  connectedComponentId?: string;
  position?: string;
  componentsPositions?: string[];
  componentsIds?: string[];
}

export interface IHealthStatus {
  chipSelectors: IChipSelector[];
  createDateTime: string;
  customFields: ICustomFields;
  id: number;
  info: string;
  level: EHealthStatusLevel;
  levelContent: string;
  locationId: string;
  locationName: string;
  position?: number;
  target: EHealthStatusTarget;
  targetContent: string;
  type: EHealthStatusType;
  typeContent: string;
}

export interface IHealthStatusState {
  healthStatuses: IHealthStatus[];
  x10HealthStatuses: IHealthStatus[];
  loading: boolean;
  x10Loading: boolean;
}

export interface IGetHealthStatusErrorAction {
  type: typeof GET_HEALTH_STATUS_ERROR;
}

export interface IGetHealthStatusRequestAction {
  type: typeof GET_HEALTH_STATUS_REQUEST;
}

export interface IGetHealthStatusSuccessAction {
  type: typeof GET_HEALTH_STATUS_SUCCESS;
  payload: IHealthStatus[];
}

export interface IGetX10HealthStatusErrorAction {
  type: typeof GET_X10_HEALTH_STATUS_ERROR;
}

export interface IGetX10HealthStatusRequestAction {
  type: typeof GET_X10_HEALTH_STATUS_REQUEST;
}

export interface IGetX10HealthStatusSuccessAction {
  type: typeof GET_X10_HEALTH_STATUS_SUCCESS;
  payload: IHealthStatus[];
}

export type HealthStatusActionTypes =
  | IGetHealthStatusErrorAction
  | IGetHealthStatusRequestAction
  | IGetHealthStatusSuccessAction
  | IGetX10HealthStatusErrorAction
  | IGetX10HealthStatusRequestAction
  | IGetX10HealthStatusSuccessAction;

import styled, { css } from "styled-components";
import { LinkButton, PrimaryButton } from "@ddm-design-system/button";
import { Chip } from "@ddm-design-system/chip";
import { Icon } from "@ddm-design-system/icon";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { Body, ButtonText, Label, SectionTitle } from "@ddm-design-system/typography";

export const LanguageAndPrivacyContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PrivacyLink = styled(ButtonText)(
  ({ theme }) => css`
    color: ${theme.colors.grey.grey100};
  `
);

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationLink = styled(Body)`
  cursor: pointer;
  text-decoration: underline;
`;

export const ContentfulError = styled(SectionTitle)`
  text-align: center;
`;

export const DDMLogo = styled(Icon).attrs({ name: "DmFullLogo" })(
  ({ theme }) => css`
    fill: ${theme.colors.black};
    margin-top: ${theme.spaceUnit.xxl};

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE2]}px) {
      margin-top: 0;
    }
  `
);

export const LoginBackground = styled.div<{ noImage?: boolean }>(
  ({ noImage, theme }) => css`
    animation: ${theme.animations.fadeIn} 3s 1s forwards;
    display: flex;
    flex: 1;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    position: relative;
    z-index: 2;

    ${noImage &&
    css`
      background-color: ${theme.colors.keg};
    `}
    & > * {
      position: absolute;
    }

    & > img,
    & > video {
      height: 100vh;
      object-fit: cover;
      width: 100%;
    }

    .image-anim-enter {
      opacity: 0;
    }

    .image-anim-enter.image-anim-enter-active {
      opacity: 1;
      transition: opacity 2s;
    }

    .image-anim-exit {
      opacity: 1;
    }

    .image-anim-exit.image-anim-exit-active {
      opacity: 0;
      transition: opacity 2s;
    }
  `
);

export const BackgroundImage = styled.img(
  ({ theme }) => css`
    animation: ${theme.animations.floating} 75s infinite linear;
  `
);

export const LoginForm = styled.form(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spaceUnit.xxl};
    width: 100%;
  `
);

export const LoginFormButton = styled(PrimaryButton)(
  ({ theme }) => css`
    align-self: flex-end;
    margin-top: ${theme.spaceUnit.xxl};
  `
);

export const UsernameBadge = styled(Chip)`
  align-self: flex-start;
`;

export const LoginFormButtons = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.spaceUnit.xs};
    justify-content: flex-end;
    margin-top: ${theme.spaceUnit.xxl};
  `
);

export const RedirectText = styled(Body).attrs({ as: "div" })(
  ({ theme }) => css`
    margin-top: ${theme.spaceUnit.xxl};
    text-align: start;
  `
);

export const LoginPage = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const LoginPanel = styled.div(
  ({ theme }) => css`
    animation: ${theme.animations.slideFromRight} 0.3s 0.5s ease-out forwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    transform: scale(1, 0);
    transform-origin: right;
    width: 480px;

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE2]}px) {
      width: 100%;
    }
  `
);

export const LoginPanelContent = styled.div(
  ({ theme }) => css`
    animation: ${theme.animations.fadeIn} 1s 0.5s ease-in forwards;
    display: flex;
    flex: 1;
    flex-direction: column;
    opacity: 0;
    padding: ${theme.spaceUnit.xxl} ${theme.spaceUnit.xxl} 0;

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE2]}px) {
      padding: ${theme.spaceUnit.lg} ${theme.spaceUnit.lg} 0;
    }
  `
);

export const LoginPanelTop = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE2]}px) {
      align-items: center;
    }
  `
);

export const LoginPanelMiddle = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 371px;
    margin: ${theme.spaceUnit.lg} 0 ${theme.spaceUnit.md};

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE2]}px) {
      align-items: center;
    }

    .background-enter {
      opacity: 0;
      transform: translate(calc(100% + (calc(${theme.spaceUnit.xxl} * 2))));
    }

    .background-enter.background-enter-active {
      opacity: 1;
      transform: translate(0);
      transition: transform 400ms ease-in-out, opacity 200ms 200ms;
    }

    .background-exit {
      opacity: 1;
      transform: translate(0);
    }

    .background-exit.background-exit-active {
      opacity: 0;
      transform: translate(calc(-100% - (calc(${theme.spaceUnit.xxl} * 2))));
      transition: transform 400ms ease-in-out, opacity 200ms;
    }

    .background-inverse-enter {
      opacity: 0;
      transform: translate(calc(-100% - (calc(${theme.spaceUnit.xxl} * 2))));
    }

    .background-inverse-enter.background-inverse-enter-active {
      opacity: 1;
      transform: translate(0);
      transition: transform 400ms ease-in-out, opacity 200ms 200ms;
    }

    .background-inverse-exit {
      opacity: 1;
      transform: translate(0);
    }

    .background-inverse-exit.background-inverse-exit-active {
      opacity: 0;
      transform: translate(calc(100% + (calc(${theme.spaceUnit.xxl} * 2))));
      transition: transform 400ms ease-in-out, opacity 200ms;
    }
  `
);

export const LoginPanelMiddleSubtitle = styled(Body)(
  ({ theme }) => css`
    margin-top: ${theme.spaceUnit.md};
  `
);

export const LoginPanelBottom = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: ${theme.spaceUnit.lg};
    min-height: 120px;
  `
);

export const LoginPanelBottomAnimation = styled.div(
  ({ theme }) => css`
    animation: ${theme.animations.fadeInFromBelow} 1s 2s forwards;
    display: flex;
    flex-direction: column;
    opacity: 0;
  `
);

export const AzureButton = styled(LinkButton)(
  ({ theme }) => css`
    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE2]}px) {
      margin-bottom: ${theme.spaceUnit.lg};
    }
  `
);

export const RecaptchaText = styled(Label).attrs({ as: "div" })(
  ({ theme }) => css`
    animation: ${theme.animations.fadeInFromBelow} 1s 2s forwards;
    opacity: 0;
    text-align: center;
  `
);

import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconNames } from "@ddm-design-system/icon";
import { Description, Label } from "@ddm-design-system/typography";
import Routes from "../../../routes";
import useContent from "../../../hooks/useContent";
import { Container, PressureChamberIcon } from "./installation.styles";
import { Link } from "../../common/link/link.styles";
import AnchorContainer, { IAnchorItem } from "../../common/anchor_container/AnchorContainer";
import {
  getExtra10Outlet,
  getOutletExtra10Statuses
} from "../../../store/extra10outlets/selectors";
import DataTable from "../../common/data_table/DataTable";
import { ComponentLevelStatus, IExtra10Status } from "../../../store/extra10outlets/types";
import { getExtra10OutletInstallation } from "../../../store/extra10outlets/actions";
import { hexToDec } from "../../../helpers";

interface IProps {
  outletId: string;
}

const Installation: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { common, outletDetails: content } = useContent();
  const x10s = useSelector(getOutletExtra10Statuses(outletId));

  const formatName = useCallback(
    ({ name, id }: IExtra10Status) => (
      <Link
        to={Routes.extra10OutletComponentDetails.replace(":id", outletId).replace(":cid", id)}
        title={`${name}`}
      >
        {`${name}`}
      </Link>
    ),
    [outletId]
  );

  const formatSN = useCallback(({ boardSerialNumber }) => {
    return (
      <div className="flex flex-col">
        <Description>{boardSerialNumber}</Description>
      </div>
    );
  }, []);

  const formatVolume = useCallback(({ volume }) => <span>{volume}L</span>, []);

  const formatStatus = useCallback(
    ({ status }) => (
      <>
        {(status || []).map(
          (s: { level: ComponentLevelStatus; icon: IconNames; title: string }) => {
            return s.icon ? (
              <div title={s.title}>
                <PressureChamberIcon key={s.icon} size={16} name={s.icon} level={s.level} />
              </div>
            ) : (
              <></>
            );
          }
        )}
      </>
    ),
    []
  );

  const renderExtra10Table = useCallback(() => {
    const columns = [
      {
        format: formatName,
        name: common.common_name,
        selector: (row: IExtra10Status) => row.name,
        sortable: true
      },
      {
        format: formatSN,
        name: common.common_serial_number,
        selector: (row: IExtra10Status) => row.boardSerialNumber
      },
      {
        name: common.common_brand,
        selector: (row: IExtra10Status) => row.brand as any,
        sortable: false,
        isImageSelector: true
      },
      {
        format: formatVolume,
        name: common.common_volume,
        selector: (row: IExtra10Status) => row.volume,
        sortable: true
      },
      {
        format: formatStatus,
        name: common.common_status,
        selector: (row: IExtra10Status) => row.status as any
      }
    ];

    return <DataTable columns={columns} data={x10s} title="" enableActions={false} />;
  }, [formatName, formatStatus, formatSN, formatVolume, x10s, common]);

  useEffect(() => {
    dispatch(getExtra10OutletInstallation(outletId));
  }, [dispatch, outletId]);

  return <Container>{renderExtra10Table()}</Container>;
};

export default Installation;

import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IProps extends RouteComponentProps {
  className?: string;
}
interface IScrollPositions {
  [key: string]: number;
}

const ScrollToTop: React.FC<IProps> = ({ children, className, history }) => {
  const scrollingElement = document.getElementById("root");
  const [scrollPositions, setScrollPositions] = useState<IScrollPositions>({});
  const [lastPath, setLastPath] = useState<string>(history.location.pathname);

  useEffect(() => {
    if (scrollingElement) {
      const unlisten = history.listen(locationHist => {
        const positions = { ...scrollPositions };

        positions[lastPath] = scrollingElement.scrollTop;

        const path = locationHist.pathname;
        const scroll = positions[path] || 0;

        setScrollPositions(positions);
        setLastPath(path);
        scrollingElement.scrollTo(0, scroll || 0);
      });

      return () => {
        unlisten();
      };
    }
  }, [history, scrollingElement, scrollPositions, lastPath]);

  return <div className={className}>{children}</div>;
};

export default withRouter(ScrollToTop);

import { call, put, select, takeLatest } from "redux-saga/effects";
import { ddmTheme } from "@ddm-design-system/tokens";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { getCurrentContent } from "../store/content/selectors";
import {
  ASSOCIATE_DISTRIBUTOR_OUTLETS,
  CREATE_DISTRIBUTOR,
  CreateDistributorErrorCodes,
  EDIT_DISTRIBUTOR,
  GET_DISTRIBUTORS_REQUEST,
  IAssociateDistributorOutletsAction,
  ICreateDistributorAction,
  IDistributor,
  IEditDistributorAction
} from "../store/distributors/types";
import {
  associateDistributorOutletsError,
  associateDistributorOutletsSuccess,
  createDistributorError,
  createDistributorSuccess,
  editDistributorError,
  editDistributorSuccess,
  getDistributorsError,
  getDistributorsSuccess
} from "../store/distributors/actions";
import { refreshGroupingData } from "../store/purchasingGroups/actions";

export function* getDistributors(jCoreService: IJCoreService) {
  try {
    const result: IDistributor[] = yield call([jCoreService, jCoreService.fetchDistributors]);

    yield put(getDistributorsSuccess(result));
  } catch {
    yield put(getDistributorsError());
  }
}

export function* createDistributor(
  { jCoreService, notificationService }: IAppContext,
  action: ICreateDistributorAction
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const newDistributor: IDistributor = yield call(
      [jCoreService, jCoreService.createDistributor],
      action.payload
    );

    yield put(createDistributorSuccess(newDistributor));
    yield put(refreshGroupingData());
  } catch (e) {
    const { message } = (e as { response: { data: { message: string } } }).response.data;
    const errorMessage =
      message === CreateDistributorErrorCodes.DUPLICATE_DISTRIBUTOR_NAME
        ? content.distributor_create_duplicate_name_error
        : message === CreateDistributorErrorCodes.DUPLICATE_USERNAME
        ? content.distributor_create_duplicate_email_error
        : content.distributor_create_error;

    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: errorMessage
    });
    yield put(createDistributorError());
  }
}

export function* editDistributor(
  { jCoreService, notificationService }: IAppContext,
  action: IEditDistributorAction
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const editedDistributor: IDistributor = yield call(
      [jCoreService, jCoreService.editDistributor],
      action.payload.data,
      action.payload.distributorId
    );

    yield put(editDistributorSuccess(editedDistributor));
    yield put(refreshGroupingData());
  } catch (e) {
    const { message } = (e as { response: { data: { message: string } } }).response.data;
    const errorMessage =
      message === CreateDistributorErrorCodes.DUPLICATE_DISTRIBUTOR_NAME
        ? content.distributor_create_duplicate_name_error
        : message === CreateDistributorErrorCodes.DUPLICATE_USERNAME
        ? content.distributor_create_duplicate_email_error
        : content.distributor_edit_error;

    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: errorMessage
    });
    yield put(editDistributorError());
  }
}

export function* associateDistributor(
  { jCoreService, notificationService }: IAppContext,
  action: IAssociateDistributorOutletsAction
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const editedDistributor: IDistributor = yield call(
      [jCoreService, jCoreService.associateDistributorOutlets],
      action.payload.distributorId,
      typeof action.payload.outletIds === "string"
        ? [action.payload.outletIds]
        : action.payload.outletIds
    );

    yield put(associateDistributorOutletsSuccess(editedDistributor));
    yield put(refreshGroupingData());
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.distributor_edit_error
    });
    yield put(associateDistributorOutletsError());
  }
}

export function* distributorsWatcher(context: IAppContext) {
  yield takeLatest(GET_DISTRIBUTORS_REQUEST, getDistributors, context.jCoreService);
  yield takeLatest(CREATE_DISTRIBUTOR, createDistributor, context);
  yield takeLatest(EDIT_DISTRIBUTOR, editDistributor, context);
  yield takeLatest(ASSOCIATE_DISTRIBUTOR_OUTLETS, associateDistributor, context);
}

export default distributorsWatcher;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes";
import Header from "../common/header/Header";
import useContent from "../../hooks/useContent";
import {
  getHasCreatePurchasingGroupError,
  getIsCreatingPurchasingGroup,
  getIsRequestingPurchasingGroups,
  getPurchasingGroups
} from "../../store/purchasingGroups/selectors";
import { getPurchasingGroups as getPurchasingGroupsAction } from "../../store/purchasingGroups/actions";
import { Link } from "../common/link/link.styles";
import DataTable from "../common/data_table/DataTable";
import { IPurchasingGroup } from "../../store/purchasingGroups/types";
import { IMenuAction } from "../common/menu/ActionsMenu";
import CreatePurchasingGroupForm from "./CreatePurchasingGroupForm";
import AssociatePurchasingGroupForm from "./AssociatePurchasingGroupForm";

const PurchasingGroups: React.FC = () => {
  const dispatch = useDispatch();
  const { purchasingGroups: content, common } = useContent();
  const isLoading = useSelector(getIsRequestingPurchasingGroups);
  const purchasingGroups = useSelector(getPurchasingGroups);
  const isCreatingPurchasingGroup = useSelector(getIsCreatingPurchasingGroup);
  const hasCreatePurchasingGroupError = useSelector(getHasCreatePurchasingGroupError);
  const [showCreatePgForm, setShowCreatePgForm] = useState(false);
  const [showAssociatePgForm, setShowAssociatePgForm] = useState(false);

  useEffect(() => {
    if (purchasingGroups.length === 0) {
      dispatch(getPurchasingGroupsAction());
    }
  }, []);

  // Close sidebar if purchasing group was successfully created/ edited
  useEffect(() => {
    if (!isCreatingPurchasingGroup && !hasCreatePurchasingGroupError) {
      if (showCreatePgForm) {
        setShowCreatePgForm(false);
      }

      if (showAssociatePgForm) {
        setShowAssociatePgForm(false);
      }
    }
  }, [isCreatingPurchasingGroup]);

  const headerActions: IMenuAction[] = [
    {
      label: content.purchasing_groups_create_new,
      onClick: () => {
        setShowCreatePgForm(true);
      }
    },
    {
      label: content.purchasing_groups_associate,
      onClick: () => {
        setShowAssociatePgForm(true);
      }
    }
  ];

  const formatPurchasingGroup = useCallback(
    ({ id, name }: IPurchasingGroup) => (
      <Link to={Routes.purchasingGroupDetails.replace(":id", id)} title={name || id}>
        {name || id}
      </Link>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        format: formatPurchasingGroup,
        grow: 6,
        name: common.common_purchasing_group,
        selector: (row: IPurchasingGroup) => row.name,
        sortable: true,
        sortFunction: (pgA: IPurchasingGroup, pgB: IPurchasingGroup) =>
          pgA.name.toLocaleLowerCase() > pgB.name.toLocaleLowerCase() ? 1 : -1
      },
      {
        name: common.common_distributors,
        selector: (row: IPurchasingGroup) => row.distributors?.length ?? 0,
        sortable: true
      },
      {
        name: common.common_outlets,
        selector: (row: IPurchasingGroup) => row.outletCount ?? 0,
        sortable: true
      }
    ],
    [
      formatPurchasingGroup,
      common.common_purchasing_group,
      common.common_distributors,
      common.common_outlets
    ]
  );

  return (
    <div className="pb-xl">
      <Header title={content.purchasing_groups_title} actions={headerActions} />

      <DataTable
        columns={columns}
        data={purchasingGroups}
        defaultSortAsc={false}
        defaultSortField="PurchasingGroup"
        isLoading={isLoading}
        searchPlaceholder={content.purchasing_groups_search}
        searchSelector="name"
        title=""
        pagination
        paginationPerPage={100}
      />
      <CreatePurchasingGroupForm
        onClose={() => {
          setShowCreatePgForm(false);
        }}
        visible={showCreatePgForm}
      />
      <AssociatePurchasingGroupForm
        visible={showAssociatePgForm}
        onClose={() => {
          setShowAssociatePgForm(false);
        }}
      />
    </div>
  );
};

export default PurchasingGroups;

export function validateEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
    email
  );
}

export function lightOrDark(color: any) {
  // Variables for red, green, blue values
  let r;
  let g;
  let b;
  let newColor = color;

  if (!color) {
    return;
  }

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    newColor = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = newColor[1];
    g = newColor[2];
    b = newColor[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    newColor = +`0x${color.slice(1).replace(color.length < 5 && /./g, "$&$&")}`;

    // eslint-disable-next-line no-bitwise
    r = newColor >> 16;
    // eslint-disable-next-line no-bitwise
    g = (newColor >> 8) & 255;
    // eslint-disable-next-line no-bitwise
    b = newColor & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  }

  return "dark";
}

export function generateInitials(brand: string, subbrand: string) {
  let initials = brand ? brand[0] : "";

  const words = subbrand?.split(" ");
  const firstWord = words?.[0];
  const secondWord = words?.[1];

  if (firstWord) {
    initials += Number.isInteger(parseInt(firstWord[0]))
      ? firstWord[firstWord.length - 1]
      : firstWord[0];

    if (secondWord) {
      initials += Number.isInteger(parseInt(secondWord[0]))
        ? secondWord[secondWord.length - 1]
        : secondWord[0];
    }
  } else {
    initials += brand?.[1] || "";
    initials += brand?.[2] || "";
  }

  return initials;
}

export const getPhotoURL = (key: string) => {
  const imageRequest = JSON.stringify({
    bucket: process.env.REACT_APP_DEFAULT_PHOTO_BUCKET,
    key,
    edits: {
      resize: {
        fit: "inside",
        height: 800,
        width: 800
      }
    }
  });

  return `${process.env.REACT_APP_DEFAULT_CLOUDFRONT_URL}/${btoa(imageRequest)}`;
};

export const sleep = (milliseconds = 0) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, milliseconds)
  );
};

export function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);

  for (let i = 0; i < s.length; ++i) {
    // eslint-disable-next-line no-bitwise
    view[i] = s.charCodeAt(i) & 0xff;
  }

  return buf;
}

export function hexToDec(s = "") {
  const digits = [0];
  let carry;

  if (!s) {
    return "0";
  }

  for (let i = 0; i < s.length; i += 1) {
    carry = parseInt(s.charAt(i), 16);

    for (let j = 0; j < digits.length; j += 1) {
      digits[j] = digits[j] * 16 + carry;
      // eslint-disable-next-line no-bitwise
      carry = (digits[j] / 10) | 0;
      digits[j] %= 10;
    }

    while (carry > 0) {
      digits.push(carry % 10);
      // eslint-disable-next-line no-bitwise
      carry = (carry / 10) | 0;
    }
  }

  return digits.reverse().join("");
}

export function outdatedFirmwareVersion(current: string, latest: string) {
  const tokens1 = (current || "").split(".");
  const tokens2 = (latest || "").split(".");

  if (parseInt(tokens1[0]) < parseInt(tokens2[0])) {
    return true;
  }
  if (parseInt(tokens1[0]) > parseInt(tokens2[0])) {
    return false;
  }

  if (parseInt(tokens1[1]) < parseInt(tokens2[1])) {
    return true;
  }
  if (parseInt(tokens1[1]) > parseInt(tokens2[1])) {
    return false;
  }

  if (parseInt(tokens1[2]) < parseInt(tokens2[2])) {
    return true;
  }
  return false;
}

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Routes from "../../routes";
import Header from "../common/header/Header";
import useContent from "../../hooks/useContent";
import Tabs, { ITab } from "../common/tabs/Tabs";
import { getIsLoadingOutlets, getExtra10Outlet } from "../../store/extra10outlets/selectors";
import Installation from "./installation/Installation";
import Info from "./info/Info";
import { LoadingIcon } from "../common/data_table/dataTable.styles";
import OutletHealthMonitor from "./health-monitor/OutletHealthMonitor";

interface IProps {
  outletId: string;
}

const Extra10OutletDetails: React.FC<IProps> = ({ outletId }) => {
  const { common, outletDetails: content } = useContent();
  const outlet = useSelector(getExtra10Outlet(outletId));
  const loading = useSelector(getIsLoadingOutlets);

  const tabs = useMemo<ITab[]>(
    () => [
      {
        content: <Info outletId={outletId} />,
        label: common.common_info,
        route: Routes.extra10OutletDetailsInfo.replace(":id", outletId)
      },
      {
        content: <Installation outletId={outletId} />,
        label: common.common_installation,
        route: Routes.extra10OutletDetailsInstallation.replace(":id", outletId)
      },
      {
        content: <OutletHealthMonitor outletId={outletId} />,
        label: common.common_health_monitor,
        route: Routes.extra10OutletDetailsHealthMonitor.replace(":id", outletId)
      }
    ],
    [outletId, common]
  );

  return (
    <div>
      <Header
        title={outlet?.name}
        breadcrumbs={[
          { link: Routes.extra10Outlets, name: content.extra10outlet_details_back_text }
        ]}
      />
      <div style={{ overflow: "auto", flex: 1 }}>
        {loading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingIcon />
          </div>
        ) : outlet ? (
          <Tabs tabs={tabs} />
        ) : (
          <div>{content.outlet_details_not_found.replace("%ID%", outletId)}</div>
        )}
      </div>
    </div>
  );
};

export default Extra10OutletDetails;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BodyHighlight, MainTitle } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import {
  getHasCreatePurchasingGroupError,
  getIsCreatingPurchasingGroup
} from "../../store/purchasingGroups/selectors";
import { IPurchasingGroup } from "../../store/purchasingGroups/types";
import CreatePurchasingGroupForm from "./CreatePurchasingGroupForm";
import { EditButton } from "../common/button/EditButton.styles";
import { FieldValue } from "../common/fieldValue/fieldValue.styles";

interface IProps {
  purchasingGroup: IPurchasingGroup | undefined;
}

const PurchasingGroupInfo: React.FC<IProps> = ({ purchasingGroup }) => {
  const { common, purchasingGroups: content } = useContent();
  const isCreatingPurchasingGroup = useSelector(getIsCreatingPurchasingGroup);
  const hasCreatePurchasingGroupError = useSelector(getHasCreatePurchasingGroupError);
  const [showEditPgForm, setShowEditPgForm] = useState(false);

  // Close sidebar if purchasing group was successfully edited
  useEffect(() => {
    if (!isCreatingPurchasingGroup && !hasCreatePurchasingGroupError && showEditPgForm) {
      setShowEditPgForm(false);
    }
  }, [isCreatingPurchasingGroup]);

  return (
    <div className="pt-xxl">
      <div className="flex items-center justify-between">
        <MainTitle>{common.common_information}</MainTitle>
        <div>
          <EditButton
            icon="Edit"
            onClick={() => {
              setShowEditPgForm(true);
            }}
          >
            {common.common_edit_info}
          </EditButton>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_name}</BodyHighlight>
                <FieldValue>{purchasingGroup?.name}</FieldValue>
              </div>
              <div className="field items-start">
                <BodyHighlight>{common.common_contact}</BodyHighlight>
                <FieldValue>{purchasingGroup?.email}</FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{content.purchasing_groups_details_id}</BodyHighlight>
                <FieldValue>{purchasingGroup?.id}</FieldValue>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreatePurchasingGroupForm
        onClose={() => {
          setShowEditPgForm(false);
        }}
        purchasingGroup={purchasingGroup}
        visible={showEditPgForm}
      />
    </div>
  );
};

export default PurchasingGroupInfo;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { INavbarItem, Navbar } from "@ddm-design-system/navbar";
import { useIsMobile } from "@ddm-design-system/hooks";
import { ESidebarModes } from "@ddm-design-system/sidebar";
import { createPortal } from "react-dom";
import useContent from "../../../hooks/useContent";
import Routes from "../../../routes";
import { getHasOutlets, getIsRequestingOutlets } from "../../../store/outlets/selectors";
import { closeSidebar } from "../../../store/sidebar/actions";
import { getIsSidebarOpen } from "../../../store/sidebar/selectors";
import Profile from "../profile/Profile";
import {
  LanguagePickerContainer,
  NavbarContainer,
  NavigationMenuContainer,
  SidebarMobileContainer
} from "./navigationMenu.styles";
import { LanguagePicker } from "../language_picker/LanguagePicker";
import { getUser } from "../../../store/auth/selectors";

const NavigationMenu: React.FC = () => {
  const [lastRoute, setLastRoute] = useState<string>();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const hasOutlets = useSelector(getHasOutlets);
  const isLoadingOutlets = useSelector(getIsRequestingOutlets);
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const me = useSelector(getUser);

  const handleSidebarClose = () => dispatch(closeSidebar());

  const modalRoot = document.getElementById("modal-root");
  const navigationMenuElement = useMemo(() => document.createElement("div"), []);

  const { sidebar: sidebarTranslations } = useContent();

  const {
    sidebar_overview: sidebarOverview,
    sidebar_technicians: sidebarTechnicians,
    sidebar_purchasing_groups: sidebarPurchasingGroups,
    sidebar_distributors: sidebarDistributors,
    sidebar_outlets: sidebarOutlets,
    sidebar_x10s: sidebarX10s,
    sidebar_beverages: sidebarBeverages,
    sidebar_internal_users: sidebarInternalUsers,
    sidebar_ce_locations: sidebarCELocations,
    sidebar_health_monitor: sidebarHealthMonitor
  } = sidebarTranslations;

  const navbarItems: INavbarItem[] = [
    {
      icon: "Home",
      selected: pathname.startsWith(Routes.overview),
      disabled: isLoadingOutlets,
      title: sidebarOverview,
      linkOptions: {
        to: Routes.overview,
        title: sidebarOverview
      }
    },
    {
      icon: "Technician",
      selected: pathname.startsWith(Routes.technicians),
      disabled: isLoadingOutlets || me?.ceOnly,
      title: sidebarTechnicians,
      linkOptions: {
        to: Routes.technicians,
        title: sidebarTechnicians
      }
    },
    {
      icon: "PurchasingGroup",
      selected: pathname.startsWith(Routes.purchasingGroups),
      disabled: isLoadingOutlets || me?.ceOnly,
      title: sidebarPurchasingGroups,
      linkOptions: {
        to: Routes.purchasingGroups,
        title: sidebarPurchasingGroups
      }
    },
    {
      icon: "Distributor",
      selected: pathname.startsWith(Routes.distributors),
      disabled: isLoadingOutlets || me?.ceOnly,
      title: sidebarDistributors,
      linkOptions: {
        to: Routes.distributors,
        title: sidebarDistributors
      }
    },
    {
      icon: "Outlets",
      selected: pathname.startsWith(Routes.outlets),
      disabled: isLoadingOutlets || me?.ceOnly,
      title: sidebarOutlets,
      linkOptions: {
        to: Routes.outlets,
        title: sidebarOutlets
      }
    },
    {
      icon: "X10",
      selected: pathname.startsWith(Routes.extra10Outlets),
      disabled: isLoadingOutlets || me?.ceOnly,
      title: sidebarX10s,
      linkOptions: {
        to: Routes.extra10Outlets,
        title: sidebarX10s
      }
    },
    {
      icon: "Beverage",
      selected: pathname.startsWith(Routes.beverages),
      disabled: isLoadingOutlets || me?.ceOnly,
      title: sidebarBeverages,
      linkOptions: {
        to: Routes.beverages,
        title: sidebarBeverages
      }
    },
    {
      icon: "Users",
      selected: pathname.startsWith(Routes.users),
      disabled: isLoadingOutlets || me?.ceOnly,
      title: sidebarInternalUsers,
      linkOptions: {
        to: Routes.users,
        title: sidebarInternalUsers
      }
    },
    {
      icon: "CE",
      selected: pathname.startsWith(Routes.ceLocations),
      disabled: isLoadingOutlets,
      title: sidebarCELocations,
      linkOptions: {
        to: Routes.ceLocations,
        title: sidebarCELocations
      }
    },
    {
      icon: "Activity",
      selected: pathname.startsWith(Routes.healthMonitor),
      disabled: !hasOutlets || me?.ceOnly,
      title: sidebarHealthMonitor,
      linkOptions: {
        to: Routes.healthMonitor,
        title: sidebarHealthMonitor
      }
    }
  ];

  useEffect(() => {
    if (pathname !== lastRoute) {
      setLastRoute(pathname);
      handleSidebarClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, lastRoute]);

  useEffect(() => {
    modalRoot?.appendChild(navigationMenuElement);

    return () => {
      modalRoot?.removeChild(navigationMenuElement);
    };
    // eslint-disable-next-line
  }, [navigationMenuElement]);

  const renderNavigationMenuMobile = () =>
    createPortal(navigationMenuMobile, navigationMenuElement);

  const renderLanguagePickerAndProfile = () => (
    <>
      <LanguagePickerContainer>
        <LanguagePicker />
      </LanguagePickerContainer>
      <Profile />
    </>
  );

  const renderNavigationMenuDesktopTablet = () => (
    <NavbarContainer>
      <Navbar items={navbarItems} LinkComponent={NavLink} />
      {renderLanguagePickerAndProfile()}
    </NavbarContainer>
  );

  const navigationMenuMobile = (
    <SidebarMobileContainer
      open={isSidebarOpen}
      mode={ESidebarModes.OVER}
      onClose={handleSidebarClose}
    >
      <NavbarContainer>
        <Navbar items={navbarItems} LinkComponent={NavLink} forceVersion="desktop" />
        {renderLanguagePickerAndProfile()}
      </NavbarContainer>
    </SidebarMobileContainer>
  );

  return (
    <NavigationMenuContainer isMobile={isMobile}>
      {isMobile ? renderNavigationMenuMobile() : renderNavigationMenuDesktopTablet()}
    </NavigationMenuContainer>
  );
};

export default NavigationMenu;

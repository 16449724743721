import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "@ddm-design-system/icon";
import { ComponentLevelStatus } from "../../../store/extra10outlets/types";

export const Container = styled.div(() => css``);

export const UnitLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.grey.grey100};
    white-space: normal !important;

    :hover {
      text-decoration: underline;
    }
  `
);

export const PressureChamberIcon = styled(Icon).attrs<{
  level: ComponentLevelStatus;
}>(({ theme, level }) => ({
  color:
    level === "ERROR"
      ? theme.colors.alert.alert100
      : level === "WARNING"
      ? theme.colors.warning.warning100
      : level === "SUCCESS"
      ? theme.colors.success.success100
      : theme.colors.grey.grey100
}))<{ level: ComponentLevelStatus }>(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xs};
  `
);

import produce from "immer";
import { Reducer } from "redux";
import {
  GET_HEALTH_STATUS_ERROR,
  GET_HEALTH_STATUS_REQUEST,
  GET_HEALTH_STATUS_SUCCESS,
  GET_X10_HEALTH_STATUS_ERROR,
  GET_X10_HEALTH_STATUS_REQUEST,
  GET_X10_HEALTH_STATUS_SUCCESS,
  HealthStatusActionTypes,
  IHealthStatusState
} from "./types";

const initalState: IHealthStatusState = {
  healthStatuses: [],
  x10HealthStatuses: [],
  loading: false,
  x10Loading: false
};

const reducer: Reducer<IHealthStatusState, HealthStatusActionTypes> = (
  state = initalState,
  action: HealthStatusActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_HEALTH_STATUS_ERROR:
        draft.loading = false;
        break;
      case GET_HEALTH_STATUS_REQUEST:
        draft.loading = true;
        break;
      case GET_HEALTH_STATUS_SUCCESS:
        draft.healthStatuses = action.payload;
        draft.loading = false;
        break;
      case GET_X10_HEALTH_STATUS_ERROR:
        draft.x10Loading = false;
        break;
      case GET_X10_HEALTH_STATUS_REQUEST:
        draft.x10Loading = true;
        break;
      case GET_X10_HEALTH_STATUS_SUCCESS:
        draft.x10HealthStatuses = action.payload;
        draft.x10Loading = false;
        break;
      default:
        break;
    }
  });

export default reducer;

import { EChipType, IChipSelector } from "../../components/common/data_table/DataTable";
import {
  EHealthStatusLevel,
  EHealthStatusType,
  ICustomFields,
  IHealthStatus,
  IHealthStatusResponse
} from "./types";

const getLevelChipType = (level: EHealthStatusLevel) => {
  switch (level) {
    case EHealthStatusLevel.LOW:
      return EChipType.DEFAULT;
    case EHealthStatusLevel.MEDIUM:
      return EChipType.WARNING;
    case EHealthStatusLevel.HIGH:
      return EChipType.DANGER;
    case EHealthStatusLevel.VERY_HIGH:
      return EChipType.DANGER;
    default:
      return EChipType.DEFAULT;
  }
};

export const getLevelChipSelector = (level: EHealthStatusLevel): IChipSelector => ({
  name: "levelContent",
  sort: Object.values(EHealthStatusLevel).indexOf(level),
  type: getLevelChipType(level)
});

export const mapHealthStatusResponse = (healthStatuses: IHealthStatusResponse[]): IHealthStatus[] =>
  healthStatuses.map((healthStatus: IHealthStatusResponse, id: number) => {
    const customFields = JSON.parse(healthStatus.customFields) as ICustomFields;
    const level = healthStatus.level.toLowerCase() as EHealthStatusLevel;
    const levelChipSelector = getLevelChipSelector(level);
    const type = healthStatus.type.toLowerCase() as EHealthStatusType;

    return {
      ...healthStatus,
      chipSelectors: [levelChipSelector],
      customFields,
      id,
      level,
      type,
      info: "",
      levelContent: "",
      locationName: "",
      targetContent: "",
      typeContent: ""
    };
  });

import { IAppState } from "..";

export const getDistributors = (state: IAppState) => state.distributors.distributors;

export const getIsRequestingDistributors = (state: IAppState) =>
  state.distributors.requestingDistributors;

export const getHasDistributorsError = (state: IAppState) =>
  state.distributors.requestDistributorsError;

export const getIsCreatingDistributor = (state: IAppState) =>
  state.distributors.creatingDistributor;

export const getHasCreateDistributorError = (state: IAppState) =>
  state.distributors.createDistributorError;

export const getDistributor = (id?: string) => (state: IAppState) =>
  getDistributors(state).find(distributor => distributor.id === id);

import classnames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { lightOrDark } from "../../../helpers";
import Icon from "../icon/Icon";
import "./logoUpload.scss";

export interface IProps {
  name?: string;
  label?: string;
  mainColor?: string;
  initials?: string;
  value: any;
  defaultValue: any;
  onChange: (file: File | null) => void;
}

export const LogoUpload: React.FC<IProps> = ({
  name = "",
  label,
  mainColor,
  initials,
  value,
  defaultValue,
  onChange
}) => {
  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null);
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        setImage(reader.result);
      }
    };

    if (value && value instanceof File) {
      reader.readAsDataURL(value);
    } else if (!value && defaultValue && defaultValue instanceof File) {
      reader.readAsDataURL(defaultValue);
    } else {
      setImage(null);
    }
  }, [value, defaultValue]);

  const downloadLogo = useCallback(() => {
    if (image) {
      const a = document.createElement("a"); // create html element anchor

      a.href = image; // xhr.response is a blob
      a.download = `${name}_${label}.png`; // Set the file name.
      a.style.display = "none"; // set anchor as hidden
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      const xhr = new XMLHttpRequest();

      xhr.responseType = "blob";
      xhr.onload = () => {
        const a = document.createElement("a"); // create html element anchor

        a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
        a.download = `${name}_${label}.png`; // Set the file name.
        a.style.display = "none"; // set anchor as hidden
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      xhr.open("GET", value);
      xhr.send();
    }
  }, [image, label, name, value]);

  const deleteLogo = useCallback(() => {
    setImage(null);
    onChange(null);
  }, [onChange]);

  const isDarkColor = useMemo(() => lightOrDark(mainColor) === "dark", [mainColor]);

  return (
    <div className="logo-upload">
      {label && <span className="label">{label}</span>}
      <div
        className="logo-background"
        style={{
          backgroundImage: `url(${image || value || defaultValue})`,
          backgroundColor:
            !value && !defaultValue && mainColor
              ? mainColor
              : !image && !value
              ? "var(--color-grey-grey50)"
              : ""
        }}
      >
        <div
          className={classnames("logo-overlay", {
            "logo-default": !value && defaultValue
          })}
          onClick={() => fileInput && fileInput.click()}
        >
          <Icon name="camera" size={40} />
          {!value && defaultValue && (
            <span style={{ maxWidth: "80%" }}>Using the English logo</span>
          )}
          {!value && !defaultValue && (
            <div
              className="initials"
              style={{ color: isDarkColor ? "white" : "var(--color-text)" }}
            >
              {initials}
            </div>
          )}
        </div>
      </div>
      {value && (
        <div style={{ marginBottom: "var(--space-unit-md)" }}>
          <span className="logo-button logo-download" onClick={downloadLogo}>
            download
          </span>
          <span className="logo-button logo-delete" onClick={deleteLogo}>
            delete
          </span>
        </div>
      )}

      <input
        ref={ref => setFileInput(ref)}
        type="file"
        style={{ display: "none" }}
        name="logo"
        onChange={e => {
          if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const filesize = (file.size / 1024 / 1024).toFixed(4);

            const filerdr = new FileReader();

            // eslint-disable-next-line
            filerdr.onload = function (fileE) {
              const elem = document.createElement("img");

              elem.onerror = () => {
                // eslint-disable-next-line no-alert
                alert("This is not a valid png/jpg image.");
              };
              elem.onload = () => {
                if (parseInt(filesize, 10) <= 10) {
                  onChange(file);
                } else {
                  // eslint-disable-next-line no-alert
                  alert("File size is too big to be uploaded");
                }
              };
              elem.src = fileE.target?.result as string;
              elem.remove();
            };
            filerdr.readAsDataURL(file);
          }
        }}
        accept="image/png, image/jpeg"
      />
    </div>
  );
};

export default LogoUpload;

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  withStyles
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import classnames from "classnames";
import React, { useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { IAppState } from "../../store";
import { BeverageTranslation, IBeverageTranslation, ICountry } from "../../store/beverages/types";
import { selectLanguage } from "../../store/content/actions";
import { ILanguageInfo } from "../../store/content/types";
import Button from "../common/button/Button";
import LogoUpload from "../common/logoUpload/LogoUpload";
import { DMRadio } from "../common/radio/DmRadio";
import TextField from "../common/textfield/Textfield";
import ToggleButtonGroup from "../common/toggleButtonGroup/ToggleButtonGroup";
import "./beverage-form.scss";
import { generateInitials } from "../../helpers";
import VideoUpload from "../common/videoUpload/VideoUpload";

interface IProps {
  multipleLanguages?: boolean;
  onSubmit: (newBeverage: IBeverageTranslation) => void;
  beverage: IBeverageTranslation;
  myCountry: ICountry;
  onChanges?: (s: boolean) => void;
  isCreate?: boolean;
}

const CustomCheckbox = withStyles({
  root: {
    color: "var(--color-primary)",
    "&$checked": {
      color: "var(--color-primary)"
    }
  },
  checked: {}
})((props: any) => <Checkbox {...props} />);
const CustomFormControlLabel = withStyles({
  label: {
    fontFamily: "var(--font-primary)"
  }
})((props: any) => <FormControlLabel {...props} />);

export const BeverageForm: React.FC<IProps> = ({
  onSubmit,
  multipleLanguages = true,
  myCountry,
  onChanges,
  isCreate,
  ...props
}) => {
  const dispatch = useDispatch();

  const { languageInfo: languages } = useContent();
  const languagesSelected = languages ? languages.filter(l => l.selected) : [];

  const [beverage, setBeverage] = useState({
    ...props.beverage,
    initials: props.beverage.initials
      ? props.beverage.initials
      : generateInitials(
          props.beverage.locales[process.env.REACT_APP_DEFAULT_LANG].brand,
          props.beverage.locales[process.env.REACT_APP_DEFAULT_LANG].subbrand
        )
  });
  const {
    controlTowerBeveragesPage: content,
    controlTowerCountries: countries,
    common
  } = useContent();

  const setLanguageAux = (text: string) => {
    dispatch(selectLanguage(text));
  };
  const me = useSelector((state: IAppState) => state.authReducer.me);

  const onBeverageChange = useCallback(
    (key: string, value: any, locale = null, isNumber = false) => {
      if (onChanges) {
        onChanges(true);
      }

      if (!locale) {
        setBeverage({
          ...beverage,
          [key]: !isNumber ? value : value ? parseFloat(value) : null
        });
      } else if (key === "logoFile" && value === "delete") {
        setBeverage({
          ...beverage,
          locales: {
            ...beverage.locales,
            [locale || ""]: {
              ...((beverage.locales as any)[locale || ""] || {}),
              logoFile: value,
              logoUrl: null
            }
          }
        });
      } else if (key === "videoFile" && value === "delete") {
        setBeverage({
          ...beverage,
          locales: {
            ...beverage.locales,
            [locale || ""]: {
              ...((beverage.locales as any)[locale || ""] || {}),
              videoFile: value,
              videoUrl: null
            }
          }
        });
      } else {
        setBeverage({
          ...beverage,
          locales: {
            ...beverage.locales,
            [locale || ""]: {
              ...((beverage.locales as any)[locale || ""] || {}),
              [key]: !isNumber ? value : value ? parseFloat(value) : null
            }
          }
        });
      }
    },
    [beverage, onChanges]
  );

  let myCountryTranslated = myCountry;

  if (myCountry && countries) {
    myCountryTranslated = {
      ...myCountry,
      name: countries[`countries_${myCountry.code.toLocaleLowerCase()}`]
        ? countries[`countries_${myCountry.code.toLocaleLowerCase()}`]
        : myCountry.name
    };
  }

  let count = 0;

  return (
    content && (
      <div className="beverage-form">
        <div className="beverage-form-submit-container-parent">
          <div className="beverage-form-submit-container">
            <div className="beverage-form-submit-container-language">
              <h2>{content.control_tower_show_hide_title}</h2>
              <ReactMarkdown>{content.control_tower_show_hide_description}</ReactMarkdown>

              {multipleLanguages && (
                <div style={{ marginTop: "var(--space-unit-md)" }}>
                  <div className="beverage-form-checkbox-mobile">
                    <ToggleButtonGroup
                      buttons={languages.map(l => ({
                        id: l.code,
                        text: l.name,
                        selected: l.selected,
                        disabled: l.code === process.env.REACT_APP_DEFAULT_LANG
                      }))}
                      onButtonChange={(buttons, button) => setLanguageAux(button.id)}
                      multiple
                    />
                  </div>

                  {/* <CheckboxGroup title={""} checkboxes={languages.map(l => ({id:l.code, text:l.text, selected:l.selected}))} */}
                  {/*               onCheckboxesChange={(checkboxes, checkbox) => setLanguageAux(checkbox.id)} /> */}

                  <div className="beverage-form-checkbox-not-mobile">
                    <FormControl component="fieldset">
                      <FormGroup>
                        {languages.map((l: ILanguageInfo) => (
                          <CustomFormControlLabel
                            color="primary"
                            key={l.code}
                            control={
                              <CustomCheckbox
                                checked={l.selected}
                                onChange={() => setLanguageAux(l.code)}
                                value={l.selected}
                                color="primary"
                                disabled={l.code === process.env.REACT_APP_DEFAULT_LANG}
                              />
                            }
                            label={`${l.name} (${new BeverageTranslation(beverage).localePercentage(
                              l.code
                            )}%)`}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
            <div className="beverage-form-submit-button-container">
              <Button
                secondary
                onClick={() => onSubmit(beverage)}
                disabled={!new BeverageTranslation(beverage).readyToSubmit()}
              >
                {isCreate
                  ? content.control_tower_save_approve_beverage
                  : content.control_tower_save_beverage}
              </Button>
            </div>
          </div>
        </div>
        <div className="beverage-form-container">
          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_form_availability}
            subQuestion={
              content.control_tower_is_beverage_available &&
              content.control_tower_is_beverage_available.replace(
                "%COUNTRY%",
                myCountryTranslated.name
              )
            }
            type="radio"
            options={[
              { text: common.common_yes, value: true },
              { text: common.common_no, value: false }
            ]}
            value={beverage.countries.map(c => c.code).includes(myCountry.code)}
            onChange={value =>
              onBeverageChange(
                "countries",
                value === "true"
                  ? beverage.countries.concat([myCountry])
                  : beverage.countries.filter((c: ICountry) => c.code !== myCountry.code)
              )
            }
            extra={
              beverage.countries.map(c => c.code).includes(myCountry.code) &&
              me &&
              me.country.code === "DK" && (
                <BeverageInput
                  type="textfield"
                  options={[
                    {
                      text: content.control_tower_optional_buy_link,
                      value: beverage.carlsShopUrl,
                      key: "carlsShopUrl"
                    }
                  ]}
                  onChange={value => onBeverageChange(value.key, value.value)}
                  style={{ marginBottom: 0 }}
                />
              )
            }
          />

          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_beer_lens}
            description={content.control_tower_english_fallback}
            type="image"
            options={[{ text: "", value: beverage, key: "logoFile" }]}
            onChange={value => onBeverageChange(value.key, value.value, value.locale)}
            useLanguages={languagesSelected}
          >
            <div className="beverage-form-logo-inputs">
              <TextField
                label={content.control_tower_form_main_color}
                value={beverage.mainColor || ""}
                type="text"
                onChange={e => onBeverageChange("mainColor", e.target.value.slice(0, 7))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          backgroundColor: beverage.mainColor ? beverage.mainColor : "",
                          border: "1px solid var(--color-grey-grey50)",
                          borderRadius: "50%"
                        }}
                      />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
                className={classnames("beverage-textfield")}
              />
              <TextField
                label={content.control_tower_form_initials}
                value={beverage.initials || ""}
                type="text"
                onChange={e =>
                  onBeverageChange("initials", (e.target.value || "").slice(0, 4).toUpperCase())
                }
                InputLabelProps={{
                  shrink: true
                }}
                className={classnames("beverage-textfield")}
              />
            </div>
          </BeverageInput>

          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_digital_lens_video}
            description={content.control_tower_digital_lens_subtitle}
            type="video"
            options={[{ text: "", value: beverage, key: "videoFile" }]}
            onChange={value => onBeverageChange(value.key, value.value, value.locale)}
            // useLanguages={languagesSelected}
          />

          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_brand_and_subbrand}
            description={content.control_tower_english_fallback}
            type="textfield"
            required
            options={[
              { text: common.common_brand, value: beverage, key: "brand" },
              { text: common.common_subbrand, value: beverage, key: "subbrand" }
            ]}
            onChange={value => onBeverageChange(value.key, value.value, value.locale)}
            useLanguages={languagesSelected}
          />

          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_form_description}
            type="textfield"
            options={[
              {
                text: content.control_tower_form_description,
                value: beverage,
                key: "description"
              }
            ]}
            onChange={value => onBeverageChange(value.key, value.value, value.locale)}
            useLanguages={languagesSelected}
            multiline
          />

          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_form_beer_type}
            type="textfield"
            options={[{ text: common.common_type, value: beverage, key: "type" }]}
            onChange={value => onBeverageChange(value.key, value.value, value.locale)}
            useLanguages={languagesSelected}
          />

          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_form_abv}
            type="textfield-number"
            options={[
              {
                text: content.control_tower_form_abv,
                value: beverage.abv,
                key: "abv"
              }
            ]}
            onChange={value => onBeverageChange(value.key, value.value, value.locale, true)}
            // useLanguages={languagesSelected}
          />

          <BeverageInput
            numberQuestion={++count}
            question={content.control_tower_form_country_of_origin}
            type="textfield"
            options={[
              {
                text: content.control_tower_form_country_of_origin,
                value: beverage,
                key: "countryOfOrigin"
              }
            ]}
            onChange={value => onBeverageChange(value.key, value.value, value.locale)}
            useLanguages={languagesSelected}
          />
        </div>
      </div>
    )
  );
};

interface IInputProps {
  numberQuestion?: number;
  question?: string;
  subQuestion?: string;
  type: "radio" | "checkbox" | "textfield" | "textfield-number" | "textarea" | "image" | "video";
  options?: Array<{ text: string; value: any; key?: string }>;
  value?: any;
  onChange: (value: any) => void;
  required?: boolean;
  useLanguages?: ILanguageInfo[];
  multiline?: boolean;
  description?: string;
  extra?: any;
  style?: any;
}

export const BeverageInput: React.FC<IInputProps> = ({
  numberQuestion,
  question,
  type,
  value,
  onChange,
  required,
  multiline,
  description,
  extra,
  style,
  children,
  ...props
}) => {
  const getValue = (valueObject: any, key: string, locale = "") => {
    if (!locale) {
      return valueObject === undefined || valueObject === null ? "" : valueObject;
    }

    if (!valueObject.locales[locale || ""]) {
      return "";
    }

    if (!valueObject.locales[locale || ""][key]) {
      return "";
    }

    return valueObject.locales[locale || ""][key];
  };

  return (
    <div className="beverage-input" style={style}>
      {(numberQuestion || question) && (
        <h2 className="question">
          {numberQuestion && `${numberQuestion}. `}
          {question}
        </h2>
      )}
      <span className="description">{description || ""}</span>
      {children}
      <div
        style={{
          paddingLeft: "var(--space-unit-lg)",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {props.subQuestion && <span className="subquestion">{props.subQuestion}</span>}
        {type === "radio" && (
          <>
            <div className="beverage-form-checkbox-mobile">
              <ToggleButtonGroup
                buttons={
                  props.options
                    ? props.options.map(o => ({
                        id: o.text,
                        text: o.text,
                        selected: value === o.value,
                        value: o.value
                      }))
                    : []
                }
                onButtonChange={(_, button) => onChange(button.value ? "true" : "false")}
              />
            </div>
            <div className="beverage-form-checkbox-not-mobile">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="position"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                >
                  {props.options &&
                    props.options.map((option, i) => (
                      <FormControlLabel
                        key={`${option.key}${i}`}
                        value={option.value}
                        control={<DMRadio />}
                        label={option.text}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}

        {(type === "textfield" || type === "textfield-number") && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {(props.useLanguages || [{ name: "", code: "", country: "", selected: true }]).map(
              (l: ILanguageInfo) =>
                props.options &&
                props.options.map((option, i) => (
                  <TextField
                    key={option.text || i}
                    label={
                      option.text +
                      (l.name ? ` - ${l.name}` : "") +
                      (required && l.code === process.env.REACT_APP_DEFAULT_LANG ? " *" : "")
                    }
                    value={getValue(option.value, option.key || "", l.code)}
                    type={type === "textfield-number" ? "number" : "text"}
                    onChange={e =>
                      onChange({
                        key: option.key,
                        value: e.target.value,
                        locale: l.name && l.code
                      })
                    }
                    error={
                      required && l.code === process.env.REACT_APP_DEFAULT_LANG
                        ? !getValue(option.value, option.key || "", l.code)
                        : type === "textfield-number" &&
                          getValue(option.value, option.key || "", l.code)
                        ? Number.isNaN(
                            parseInt(getValue(option.value, option.key || "", l.code), 10)
                          )
                        : false
                    }
                    InputProps={
                      type === "textfield-number"
                        ? {
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }
                        : option.key === "mainColor"
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    width: 20,
                                    height: 20,
                                    backgroundColor: option.value,
                                    border: "1px solid grey"
                                  }}
                                />
                              </InputAdornment>
                            )
                          }
                        : {}
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classnames("beverage-textfield", {
                      multiline
                    })}
                    multiline={multiline}
                  />
                ))
            )}
          </div>
        )}
        {type === "image" && (
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {(props.useLanguages || [{ name: "", code: "", country: "", selected: true }]).map(
              (l: ILanguageInfo) =>
                props.options &&
                props.options.map(option => {
                  const beverage = new BeverageTranslation(option.value);

                  return (
                    <LogoUpload
                      key={option.text + l.name}
                      name={beverage.name}
                      label={
                        l.name +
                        (required && l.code === process.env.REACT_APP_DEFAULT_LANG ? " *" : "")
                      }
                      mainColor={beverage.mainColor}
                      initials={beverage.initials}
                      value={
                        beverage.logoFile(l.name && l.code) &&
                        beverage.logoFile(l.name && l.code) !== "delete"
                          ? beverage.logoFile(l.name && l.code)
                          : beverage.logoUrl(l.name && l.code)
                      }
                      defaultValue={
                        beverage.logoFile(process.env.REACT_APP_DEFAULT_LANG) &&
                        beverage.logoFile(process.env.REACT_APP_DEFAULT_LANG) !== "delete"
                          ? beverage.logoFile(process.env.REACT_APP_DEFAULT_LANG)
                          : beverage.logoUrl(process.env.REACT_APP_DEFAULT_LANG)
                      }
                      onChange={file => {
                        onChange({
                          key: option.key,
                          value: file === null ? "delete" : file,
                          locale: l.name && l.code
                        });
                      }}
                    />
                  );
                })
            )}
          </div>
        )}

        {type === "video" && (
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {(
              props.useLanguages || [
                { code: "en-GB", name: "English", country: "GB", selected: true }
              ]
            ).map(
              (l: ILanguageInfo) =>
                props.options &&
                props.options.map(option => {
                  const beverage = new BeverageTranslation(option.value);

                  return (
                    <VideoUpload
                      key={option.text + l.name}
                      name={beverage.name}
                      // label={
                      //   l.name +
                      //   (required && l.code === process.env.REACT_APP_DEFAULT_LANG ? " *" : "")
                      // }
                      value={
                        beverage.videoFile(l.name && l.code) &&
                        beverage.videoFile(l.name && l.code) !== "delete"
                          ? beverage.videoFile(l.name && l.code)
                          : beverage.videoUrl(l.name && l.code)
                      }
                      defaultValue={
                        beverage.videoFile(process.env.REACT_APP_DEFAULT_LANG) &&
                        beverage.videoFile(process.env.REACT_APP_DEFAULT_LANG) !== "delete"
                          ? beverage.videoFile(process.env.REACT_APP_DEFAULT_LANG)
                          : beverage.videoUrl(process.env.REACT_APP_DEFAULT_LANG)
                      }
                      onChange={file => {
                        onChange({
                          key: option.key,
                          value: file === null ? "delete" : file,
                          locale: l.name && l.code
                        });
                      }}
                    />
                  );
                })
            )}
          </div>
        )}
      </div>
      {extra}
    </div>
  );
};

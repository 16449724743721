import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { useDispatch } from "react-redux";
import CeLocationDetailEditItem from "./CeLocationDetailEditItem";
import { EditItem } from "./CeLocationDetailEditItem.styles";
import { ICELocation } from "../../../store/ceLocations/types";
import useContent from "../../../hooks/useContent";
import { IItem } from "../../common/simple_dropdown/SimpleDropdown";
import {
  StyledModal,
  StyledModalBody,
  StyledDropdown,
  StyledTextInput
} from "./CeLocationDetailEdit.styles";
import { putCeLocationFlex } from "../../../store/ceLocations/actions";
import { generateArrayOfYears } from "./utils";

interface ICeLocationFlexDetailEditProps {
  onClose: () => any;
  ceLocation: ICELocation;
}

const CeLocationFlexDetailEdit: React.FC<ICeLocationFlexDetailEditProps> = ({
  ceLocation,
  onClose
}) => {
  const { ceLocations: content, common } = useContent();
  const dispatch = useDispatch();

  const modalEl = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.getElementById("modal-root")?.appendChild(modalEl);
  }, [modalEl]);

  const [flex20Year, setFlex20Year] = useState<IItem | undefined>(
    ceLocation.flex20Year
      ? {
          id: `${ceLocation.flex20Year}`,
          text: `${ceLocation.flex20Year}`
        }
      : undefined
  );

  const [flex20SerialNumber, setFlex20SerialNumber] = useState<string | undefined>(
    ceLocation.flex20Serial || ""
  );

  const handleSave = useCallback(() => {
    dispatch(
      putCeLocationFlex(ceLocation.id, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        flex20Year: Number(flex20Year!.id),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        flex20Serial: flex20SerialNumber!
      })
    );
    onClose();
  }, [ceLocation.id, dispatch, flex20SerialNumber, flex20Year, onClose]);

  const children = (
    <StyledModal isOpen onRequestClose={onClose}>
      <ModalHeader headerTitle={content.celocation_edit_location} />
      <StyledModalBody>
        <CeLocationDetailEditItem title={`${content.celocation_flex_20_title}*`}>
          <EditItem title={`${content.celocation_flex_20_year}*`}>
            <StyledDropdown
              required
              placeholder={content.celocation_flex_20_year}
              selectedItem={flex20Year}
              onItemSelected={setFlex20Year}
              items={generateArrayOfYears()}
            />
          </EditItem>
          <EditItem title={`${content.celocation_flex_20_serial_number}*`}>
            <StyledTextInput
              required
              placeholder={content.celocation_flex_20_serial_number}
              value={`${flex20SerialNumber}`}
              onChange={e => setFlex20SerialNumber(e.currentTarget.value)}
            />
          </EditItem>
        </CeLocationDetailEditItem>
      </StyledModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onClose}>{common.common_cancel}</SecondaryButton>
        <PrimaryButton
          disabled={!(flex20Year && flex20Year.id) || !flex20SerialNumber}
          onClick={handleSave}
        >
          {common.common_save}
        </PrimaryButton>
      </ModalFooter>
    </StyledModal>
  );

  return createPortal(children, modalEl);
};

export default CeLocationFlexDetailEdit;

import { createSelector } from "reselect";
import { IAppState } from "..";
import { ITechnician } from "./types";

export const getTechnicians = (state: IAppState) => state.technician.technicians;

export const getCurrentTechnician = (state: IAppState) => state.technician.currentTechnician;

export const getTechnician = (id: string) =>
  createSelector(getTechnicians, (technicians: ITechnician[]) =>
    technicians.find(technician => technician.id === id)
  );

import { call, put, select, takeLatest } from "redux-saga/effects";
import { ddmTheme } from "@ddm-design-system/tokens";
import { IAppContext } from "../services";
import { getCurrentContent } from "../store/content/selectors";
import {
  getHealthStatusError,
  getHealthStatusSuccess,
  getX10HealthStatusSuccess
} from "../store/healthStatus/actions";
import { mapHealthStatusResponse } from "../store/healthStatus/helpers";
import {
  GET_HEALTH_STATUS_REQUEST,
  GET_X10_HEALTH_STATUS_REQUEST,
  IHealthStatusResponse
} from "../store/healthStatus/types";

function* getHealthStatus({ jCoreService, notificationService }: IAppContext) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const response: IHealthStatusResponse[] = yield call([
      jCoreService,
      jCoreService.getHealthStatus
    ]);

    yield put(getHealthStatusSuccess(mapHealthStatusResponse(response)));
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.healthStatus_error
    });

    yield put(getHealthStatusError());
  }
}

function* getX10HealthStatus({ jCoreService, notificationService }: IAppContext) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const response: IHealthStatusResponse[] = yield call([
      jCoreService,
      jCoreService.getX10HealthStatus
    ]);

    yield put(getX10HealthStatusSuccess(mapHealthStatusResponse(response)));
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.healthStatus_error
    });

    yield put(getHealthStatusError());
  }
}

function* healthStatusWatcher(context: IAppContext) {
  yield takeLatest(GET_HEALTH_STATUS_REQUEST, getHealthStatus, context);
  yield takeLatest(GET_X10_HEALTH_STATUS_REQUEST, getX10HealthStatus, context);
}

export default healthStatusWatcher;

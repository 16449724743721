import styled, { css } from "styled-components";
import { BaseButtonStyles, PrimaryButton } from "@ddm-design-system/button";

export const StyledPrimaryButton = styled(PrimaryButton)(
  ({ theme, disabled }) => css`
    padding: ${theme.spaceUnit.xxs} ${theme.spaceUnit.md};

    ${BaseButtonStyles.StyledButtonText} {
      ${theme.typography.description}
    }

    ${disabled &&
    css`
      cursor: initial;
      &:hover {
        background-color: ${theme.colors.white};
      }
    `}
  `
);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Body, Subtitle } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import useContent from "../../hooks/useContent";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import { getCreateOrEditOutletState } from "../../store/outlets/selectors";
import { editOutlet, resetOutletFormState } from "../../store/outlets/actions";
import { CloseIcon } from "../common/icon/CloseIcon.styles";
import { QuestionType } from "../../hooks/useGetQuestionnaire";
import { IExtra10Location } from "../../store/extra10outlets/types";
import { CreateOutletFooter } from "../outlets/createOutletForm.styles";
import CreateOutletFormContent from "../outlets/CreateOutletFormContent";

interface IProps {
  visible: boolean;
  onClose: () => void;
  outlet: IExtra10Location;
}

const EditExtra10OutletForm: React.FC<IProps> = ({ outlet, visible, onClose }) => {
  const { outlets: content, common } = useContent();
  const dispatch = useDispatch();
  const editOutletState = useSelector(getCreateOrEditOutletState);
  const [values, setValues] = useState<any>({});

  useEffect(() => {
    if (!outlet) {
      return;
    }

    const initValues = {
      distributorName: outlet.distributorName,
      clientNumberDistributor: outlet.clientNumberDistributor
    };
    setValues(initValues);
  }, [outlet]);

  const editOutletAux = useCallback(() => {
    const outletObj: any = {
      distributorName: values.distributorName?.trim(),
      clientNumberDistributor: values.clientNumberDistributor?.trim()
    };

    if (outlet) {
      dispatch(editOutlet(outlet.id, outletObj));
    }
  }, [outlet, values, dispatch]);

  const onCloseAux = (force = false) => {
    const initValues = {
      distributorName: outlet.distributorName,
      clientNumberDistributor: outlet.clientNumberDistributor
    };
    setValues(initValues);

    dispatch(resetOutletFormState());

    onClose();
  };

  const validForm = useMemo(() => {
    return true;
  }, [values, outlet]);

  const canAdvance = useMemo(() => {
    if (editOutletState.loading) {
      return false;
    }
    return validForm;
  }, [values, validForm, editOutletState]);

  return (
    <SidebarModal visible={visible} onClose={onCloseAux}>
      <div className="flex flex-col h-full">
        <div className="h-[120px]">
          <div className="flex justify-between items-center mb-xs">
            <Subtitle>{content.outlets_edit_outlet_info}</Subtitle>
            <CloseIcon onClick={() => onCloseAux(true)} />
          </div>
        </div>
        <CreateOutletFormContent
          questions={[
            {
              question: common.common_distributor_name,
              placeholder: common.common_distributor_name,
              field: "distributorName",
              type: QuestionType.INPUT
            },
            {
              question: common.common_client_code,
              placeholder: common.common_client_code,
              field: "clientNumberDistributor",
              type: QuestionType.INPUT
            }
          ]}
          values={values}
          onSet={values => {
            setValues(values);
          }}
        />

        {editOutletState.error && (
          <span className="text-alert-alert100">{common.common_error_during_request}</span>
        )}
        <CreateOutletFooter error={editOutletState.error}>
          <SecondaryButton onClick={() => onCloseAux()}>{common.common_cancel}</SecondaryButton>
          <PrimaryButton disabled={!canAdvance} onClick={editOutletAux}>
            {common.common_save}
          </PrimaryButton>
        </CreateOutletFooter>
      </div>
    </SidebarModal>
  );
};

export default EditExtra10OutletForm;

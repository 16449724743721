import React, { useCallback, useMemo, useState } from "react";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { StyledMenu, StyledMenuItem } from "./actionsMenu.styles";
import SquaredButton from "../button/SquaredButton";

export interface IMenuAction {
  icon?: IconNames;
  label: string | JSX.Element | any;
  onClick: (value?: any) => void;
}

interface IProps {
  actions: IMenuAction[];
  showOnlyFirstAction?: boolean;
}

const ActionsMenu: React.FC<IProps> = ({ actions, showOnlyFirstAction, ...props }) => {
  const [show, setShow] = useState(false);

  const renderHeader = useCallback(
    () => (
      <SquaredButton className="p-sm" renderLeftIcon={() => <Icon name="Ellipsis" size={19} />} />
    ),
    []
  );

  const renderMenuItem = (action: IMenuAction, index: number) => {
    return (
      <StyledMenuItem icon={action.icon} key={index} onClick={action.onClick}>
        {typeof action.label === "string" ? action.label : show ? action.label() : ""}
      </StyledMenuItem>
    );
  };

  const firstAction = useMemo(() => actions[0], [actions]);

  return showOnlyFirstAction && firstAction ? (
    <SquaredButton className="p-sm" onClick={firstAction.onClick}>
      {firstAction.label}
    </SquaredButton>
  ) : (
    <StyledMenu
      onShow={() => setShow(true)}
      onHide={() => setShow(false)}
      direction="bottom-right"
      renderHeader={renderHeader}
      {...props}
    >
      {actions.map(renderMenuItem)}
    </StyledMenu>
  );
};

export default ActionsMenu;

import React from "react";
import { Link } from "react-router-dom";
import { call, put, select, takeLatest } from "redux-saga/effects";
import FileSaver from "file-saver";
import { ddmTheme } from "@ddm-design-system/tokens";
import { Body } from "@ddm-design-system/typography";
import Routes from "../routes";
import { IAppContext } from "../services";
import { IFirmwareVersions, IJCoreService } from "../services/jcore";
import { getCurrentContent } from "../store/content/selectors";
import history from "../history";
import {
  createOutletError,
  createOutletSuccess,
  editOutletError,
  editOutletSuccess,
  getCountriesSuccess,
  getOutletInfoSuccess,
  getOutletInstallationSuccess,
  getOutletLinesSuccess,
  getOutletNotes as getOutletNotesAction,
  getOutletNotesSuccess,
  getOutletsError,
  getOutletsSuccess,
  getOutletTechnicians as getOutletTechniciansRequest,
  getOutletTechniciansSuccess,
  getOutletUnitsSuccess
} from "../store/outlets/actions";
import { getOutlet } from "../store/outlets/selectors";
import {
  ASSOCIATE_TECHNICIAN_TO_OUTLET,
  CREATE_OUTLET,
  EDIT_OUTLET,
  EXPORT_ALL_DATA,
  EXPORT_DATA,
  GET_COUNTRIES,
  GET_OUTLET_INFO,
  GET_OUTLET_INSTALLATION,
  GET_OUTLET_LINES,
  GET_OUTLET_NOTES,
  GET_OUTLET_TECHNICIANS,
  GET_OUTLET_UNITS,
  GET_OUTLETS_REQUEST,
  IAssociateTechnicianToOutlet,
  ICreateOutletAction,
  IEditOutletAction,
  IExportAllData,
  IExportData,
  IGetOutletInfoAction,
  IGetOutletInstallationAction,
  IGetOutletLinesAction,
  IGetOutletNotesAction,
  IGetOutletTechniciansAction,
  IGetOutletUnitsAction,
  ILocation,
  IRemoveAssociation,
  IResetBeerDrive,
  ISaveOutletDescription,
  IUnit,
  REMOVE_ASSOCIATION,
  RESET_BEER_DRIVE,
  SAVE_OUTLET_DESCRIPTION
} from "../store/outlets/types";
import { getTechnician } from "../store/technicians/actions";
import { getTechnician as getTechnicianSelector } from "../store/technicians/selectors";
import { ITechnician } from "../store/technicians/types";
import { s2ab } from "../helpers";
import { EComponentType } from "../store/components/types";

const renderNotification = (
  firstLinkTo: string,
  first: string,
  center: string,
  lastLinkTo: string,
  last: string
) => (
  <Body>
    <Link style={{ color: "inherit" }} to={firstLinkTo}>
      <b>{first}</b>
    </Link>
    &nbsp;{center}&nbsp;
    <Link style={{ color: "inherit" }} to={lastLinkTo}>
      <b>{last}</b>
    </Link>
  </Body>
);

export function* getOutlets(jCoreService: IJCoreService) {
  try {
    const result = yield call([jCoreService, jCoreService.fetchLocations]);

    yield put(getOutletsSuccess(result));
  } catch {
    yield put(getOutletsError());
  }
}

export function* getOutletInfo(jCoreService: IJCoreService, action: IGetOutletInfoAction) {
  try {
    const result = yield call([jCoreService, jCoreService.getOutletInfo], action.payload);

    yield put(
      getOutletInfoSuccess({
        ...result,
        questionnaire: result.questionnaire ? JSON.parse(result.questionnaire) : {},
        id: action.payload
      })
    );
  } catch {
    // do nothing
  }
}

export function* getOutletLines(jCoreService: IJCoreService, action: IGetOutletLinesAction) {
  try {
    const result = yield call([jCoreService, jCoreService.getOutletLines], action.payload);

    yield put(getOutletLinesSuccess({ lines: result?.groupings, id: action.payload }));
  } catch {
    // do nothing
  }
}

export function* getOutletInstallation(
  jCoreService: IJCoreService,
  action: IGetOutletInstallationAction
) {
  try {
    const result = yield call([jCoreService, jCoreService.getOutletInstallation], action.payload);

    yield put(getOutletInstallationSuccess({ installation: result, id: action.payload }));
  } catch {
    // do nothing
  }
}

export function* getOutletUnits(jCoreService: IJCoreService, action: IGetOutletUnitsAction) {
  let latestFirmwareVersion: IFirmwareVersions;

  try {
    latestFirmwareVersion = yield call([jCoreService, jCoreService.getLatestFirmwareVersion]);
  } catch {
    // do nothing
  }

  try {
    const result = yield call([jCoreService, jCoreService.getOutletUnits], action.payload);
    let countPrimary = 0;
    let countSecondary = 0;
    const preparedResult = result
      ?.sort((a: IUnit, b: IUnit) => {
        if ((a.primaryUnitId && b.primaryUnitId) || (!a.primaryUnitId && !b.primaryUnitId)) {
          return +new Date(a.createDateTime) - +new Date(b.createDateTime);
        }

        return !a.primaryUnitId ? -1 : 1;
      })
      .map((u: IUnit) => ({
        ...u,
        name: !u.primaryUnitId
          ? `Primary Unit ${++countPrimary}`
          : `Secondary Unit ${++countSecondary}`,
        type: u.type
          ?.split("")
          .map((a: string, i: number) => (i === 0 ? a.toLocaleLowerCase() : a))
          .join(""),
        latestFirmwareVersion:
          u.type === EComponentType.X10
            ? undefined
            : u.type === EComponentType.ControlUnit
            ? u.onomondo
              ? latestFirmwareVersion.firmwareOnomondoNcu
              : latestFirmwareVersion.firmwareNcu
            : u.onomondo
            ? latestFirmwareVersion.firmwareOnomondo
            : latestFirmwareVersion.firmware
      }));

    yield put(getOutletUnitsSuccess({ units: preparedResult, id: action.payload }));
  } catch {
    // do nothing
  }
}

export function* getOutletTechnicians(
  jCoreService: IJCoreService,
  action: IGetOutletTechniciansAction
) {
  try {
    const result = yield call([jCoreService, jCoreService.getOutletTechnicians], action.payload);

    yield put(getOutletTechniciansSuccess({ associatedTechnicians: result, id: action.payload }));
  } catch {
    // do nothing
  }
}

export function* getOutletNotes(jCoreService: IJCoreService, action: IGetOutletNotesAction) {
  try {
    const result = yield call([jCoreService, jCoreService.getOutletNotes], action.payload);

    yield put(getOutletNotesSuccess({ notes: result || {}, id: action.payload }));
  } catch {
    // do nothing
  }
}

export function* associateTechnicianToOutlet(
  { jCoreService, notificationService }: IAppContext,
  action: IAssociateTechnicianToOutlet
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const technician: ITechnician = yield select(getTechnicianSelector(action.payload.technicianId));
  const outlet: ILocation = yield select(getOutlet(action.payload.outletId));

  try {
    yield call(
      [jCoreService, jCoreService.associateTechnicianToOutlet],
      action.payload.technicianId,
      action.payload.outletId
    );

    if (action.payload.refreshTechnician) {
      yield put(getTechnician(action.payload.technicianId));
    } else if (action.payload.refreshOutlet) {
      yield put(getOutletTechniciansRequest(action.payload.outletId));
    }

    if (technician && outlet) {
      notificationService.addNotification({
        iconProps: { name: "ok" },
        text: renderNotification(
          Routes.technicianDetails.replace(":id", technician.id),
          technician.name,
          content.associated_technician_to_outlet,
          Routes.outletDetails.replace(":id", outlet.id),
          outlet.name
        )
      });
    }
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.associated_technician_to_outlet_error
    });
  }
}

export function* unassociateTechnicianToOutlet(
  { jCoreService, notificationService }: IAppContext,
  action: IRemoveAssociation
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const technician: ITechnician = yield select(getTechnicianSelector(action.payload.technicianId));
  const outlet: ILocation = yield select(getOutlet(action.payload.outletId));

  try {
    yield call(
      [jCoreService, jCoreService.unassociateTechnicianToOutlet],
      action.payload.technicianId,
      action.payload.outletId
    );

    if (action.payload.refreshTechnician) {
      yield put(getTechnician(action.payload.technicianId));
    } else if (action.payload.refreshOutlet) {
      yield put(getOutletTechniciansRequest(action.payload.outletId));
    }

    if (technician && outlet) {
      notificationService.addNotification({
        iconProps: { name: "ok" },
        text: renderNotification(
          Routes.technicianDetails.replace(":id", technician.id),
          technician.name,
          content.removed_technician_from_outlet,
          Routes.outletDetails.replace(":id", outlet.id),
          outlet.name
        )
      });
    }
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.removed_technician_from_outlet_error
    });
  }
}

export function* saveOutletDescription(
  jCoreService: IJCoreService,
  action: ISaveOutletDescription
) {
  try {
    yield call(
      [jCoreService, jCoreService.saveOutletDescription],
      action.payload.installationId,
      action.payload.description
    );
    yield put(getOutletNotesAction(action.payload.locationId));
  } catch {
    // eslint-disable-next-line no-console
    console.error("Error");
  }
}

function* resetBeerDrive(
  { jCoreService, notificationService }: IAppContext,
  action: IResetBeerDrive
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const { beerDriveId, outletId } = action.payload;

  try {
    yield call([jCoreService, jCoreService.resetBeerDrive], outletId, beerDriveId);
    // TODO request current beer drive again
    // yield put(getCurrentOutletRequest(outletId));

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: (content.beer_drive_reset || "").replace("%ID%", beerDriveId)
    });
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: (content.beer_drive_reset_error || "").replace("%ID%", beerDriveId)
    });
  }
}

function* exportData({ jCoreService, notificationService }: IAppContext, action: IExportData) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const { start, end, outletId, outletName } = action.payload;

  try {
    const file = yield call([jCoreService, jCoreService.exportData], outletId, start, end);

    const data = s2ab(file?.data || "");

    FileSaver.saveAs(
      new Blob([data], { type: "application/octet-stream" }),
      `data_${outletName}_${new Date(start).toLocaleDateString()}_to_${new Date(
        end
      ).toLocaleDateString()}.csv`
    );

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.export_data
    });
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.export_data_error
    });
  }
}

function* exportAllData(
  { jCoreService, notificationService }: IAppContext,
  action: IExportAllData
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const { start, end, x10 } = action.payload;

  try {
    const file = yield call([jCoreService, jCoreService.exportAllData], x10, start, end);

    const data = s2ab(file?.data || "");

    FileSaver.saveAs(
      new Blob([data], { type: "application/octet-stream" }),
      `data_outlets_${new Date(start).toLocaleDateString()}_to_${new Date(
        end
      ).toLocaleDateString()}.csv`
    );

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.export_data
    });
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.export_data_error
    });
  }
}

export function* getCountries(jCoreService: IJCoreService) {
  try {
    const result = yield call([jCoreService, jCoreService.getCountries]);

    yield put(getCountriesSuccess(result));
  } catch {
    // do nothing
  }
}

export function* createOutlet(jCoreService: IJCoreService, action: ICreateOutletAction) {
  try {
    const result = yield call([jCoreService, jCoreService.createOutlet], action.payload);

    yield call(getOutlets, jCoreService);
    yield put(createOutletSuccess());
    yield history.push(`outlets/${result.id}`);
  } catch {
    yield put(createOutletError());
  }
}

export function* editOutlet(jCoreService: IJCoreService, action: IEditOutletAction) {
  try {
    const result = yield call(
      [jCoreService, jCoreService.editOutlet],
      action.payload.outletId,
      action.payload.outletObj
    );

    yield call(getOutlets, jCoreService);
    yield put(editOutletSuccess());
    yield history.push(`outlets/${result.id}`);
  } catch {
    yield put(editOutletError());
  }
}

export function* outletsWatcher(context: IAppContext) {
  yield takeLatest(GET_OUTLETS_REQUEST, getOutlets, context.jCoreService);
  yield takeLatest(GET_OUTLET_INFO, getOutletInfo, context.jCoreService);
  yield takeLatest(GET_OUTLET_LINES, getOutletLines, context.jCoreService);
  yield takeLatest(GET_OUTLET_INSTALLATION, getOutletInstallation, context.jCoreService);
  yield takeLatest(GET_OUTLET_UNITS, getOutletUnits, context.jCoreService);
  yield takeLatest(GET_OUTLET_TECHNICIANS, getOutletTechnicians, context.jCoreService);
  yield takeLatest(GET_OUTLET_NOTES, getOutletNotes, context.jCoreService);
  yield takeLatest(ASSOCIATE_TECHNICIAN_TO_OUTLET, associateTechnicianToOutlet, context);
  yield takeLatest(REMOVE_ASSOCIATION, unassociateTechnicianToOutlet, context);
  yield takeLatest(RESET_BEER_DRIVE, resetBeerDrive, context);
  yield takeLatest(SAVE_OUTLET_DESCRIPTION, saveOutletDescription, context.jCoreService);
  yield takeLatest(EXPORT_DATA, exportData, context);
  yield takeLatest(EXPORT_ALL_DATA, exportAllData, context);
  yield takeLatest(GET_COUNTRIES, getCountries, context.jCoreService);
  yield takeLatest(CREATE_OUTLET, createOutlet, context.jCoreService);
  yield takeLatest(EDIT_OUTLET, editOutlet, context.jCoreService);
}

export default outletsWatcher;

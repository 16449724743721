import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import { getCurrentContent } from "../../../store/content/selectors";
import { IContent } from "../../../store/content/types";
import Button from "../button/Button";
import TextField from "../textfield/Textfield";

interface IProps {
  createTechnicianCallback: (name: string, email: string, phone: string) => void;
  cancelCallback: () => void;
  content: IContent;
  submitting: boolean;
  isEdit?: boolean;
  currentName?: string;
  currentEmail?: string;
  currentPhone?: string;
  error?: boolean;
}
export const CreateTechnicianForm: React.FC<IProps> = ({
  createTechnicianCallback,
  cancelCallback,
  currentName,
  currentEmail,
  currentPhone,
  submitting,
  isEdit,
  content,
  error
}) => {
  const [name, setName] = useState(currentName || "");
  const [email, setEmail] = useState(currentEmail || "");
  const [phone, setPhone] = useState(currentPhone || "");
  const [createTechnicianButtonText, setCreateTechnicianButtonText] = useState("");

  useEffect(() => {
    setCreateTechnicianButtonText(
      isEdit
        ? submitting
          ? content.common_saving
          : content.common_save
        : submitting
        ? content.common_creating
        : content.common_create_technician
    );
  }, [
    submitting,
    isEdit,
    content.common_saving,
    content.common_save,
    content.common_creating,
    content.common_create_technician
  ]);

  return (
    content && (
      <div className="modal-create-technician flex-col">
        <h2 className="mt-0 mb-xs">{content.common_technician_info}</h2>
        <p className="mt-0 mb-lg">
          {isEdit
            ? content.common_technician_edit_subtitle
            : content.common_technician_create_subtitle}
        </p>
        <div className="mb-lg">
          <TextField
            className="text-form"
            label={content.common_form_name_placeholder}
            required
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </div>
        <div className="mb-lg">
          <TextField
            className="text-form"
            label={content.common_form_email_placeholder}
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            error={error}
          />
        </div>
        <div className="mb-lg">
          <TextField
            className="text-form"
            label={content.common_form_cellphone_placeholder}
            type="tel"
            value={phone}
            onChange={event => setPhone(event.target.value)}
          />
        </div>
        <div className="form-option flex justify-end">
          <Button ghost inline className="form-option-cancel" onClick={() => cancelCallback()}>
            {content.common_cancel}
          </Button>
          <Button
            disabled={!name}
            secondary
            inline
            className="mt-xxl"
            onClick={() => {
              if (name) {
                createTechnicianCallback(name, email, phone);
              }
            }}
          >
            {createTechnicianButtonText}
          </Button>
        </div>
      </div>
    )
  );
};
const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);

  return {
    content: content ? content.common : null,
    error: state.technician.requestingCreateTechnicianError
  };
};

export default connect<any, any, any, any>(mapStateToProps, null)(CreateTechnicianForm);

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { getCurrentContent } from "../../store/content/selectors";
import { IContent } from "../../store/content/types";
import * as outletActions from "../../store/outlets/actions";
import * as actions from "../../store/technicians/actions";
import { ITechnician, ITechnicianParams, IRegisteredDevice } from "../../store/technicians/types";
import Button from "../common/button/Button";
import { DetailPage } from "../common/DetailPage/DetailPage";
import { DetailPageContent } from "../common/DetailPage/DetailPageContent";
import Header from "../common/header/Header";
import Icon from "../common/icon/Icon";
import CardItem from "../common/item/CardItem";
import ConfirmationModal from "../common/modal/ConfirmationModal";
import Modal from "../common/modal/Modal";
import AssociateTechnicianModal from "../common/technician/AssociateTechnicianModal";
import CreateTechnicianForm from "../common/technician/CreateTechnicianForm";
import SetInternational from "./SetInternational";
import "./technicians.scss";
import { ILocation } from "../../store/outlets/types";

export interface IProps {
  technicianId: string;
  technician?: ITechnician;
  requesting: boolean;
  requestingTechnicianDeviceCode: boolean;
  technicianWasDeleted?: boolean;
  requestingEditTechnician?: boolean;
  getTechnician: (technicianId: string) => void;
  getTechnicianCode: (technicianId: string) => void;
  deleteTechnician: (technicianId: string) => void;
  editTechnician: (params: ITechnicianParams, id: string) => void;
  unregisterDevice: (deviceId: string) => void;
  removeAssociation: (
    technicianId: string,
    outletId: string,
    refreshTechnician?: boolean,
    refreshOutlet?: boolean
  ) => void;
  associate: (
    technicianId: string,
    outletId: string,
    refreshTechnician?: boolean,
    refreshOutlet?: boolean
  ) => void;
  content: IContent;
}

export interface IState {
  showModal: boolean;
  showEditModal: boolean;
  showConfirmDeleteModal: boolean;
  outletToRemove?: ILocation;
  showConfirmRemoveDevice: boolean;
  deviceToRemove?: IRegisteredDevice;
}

export class TechnicianDetail extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      showConfirmDeleteModal: false,
      showEditModal: false,
      showConfirmRemoveDevice: false
    };
  }

  componentDidMount() {
    this.setState({
      showModal: false,
      showConfirmDeleteModal: false,
      showEditModal: false,
      showConfirmRemoveDevice: false
    });
    this.props.getTechnician(this.props.technicianId);
    this.props.getTechnicianCode(this.props.technicianId);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.requestingEditTechnician !== this.props.requestingEditTechnician) {
      this.props.getTechnician(this.props.technicianId);
      this.props.getTechnicianCode(this.props.technicianId);
    }
  }

  removeDevice() {
    const { deviceToRemove: { id = "" } = {} } = this.state;

    this.setState({ showConfirmRemoveDevice: false, deviceToRemove: undefined });

    this.props.unregisterDevice(id);
  }

  deleteTechnician() {
    this.setState({ showConfirmDeleteModal: false });

    if (this.props.technician) {
      this.props.deleteTechnician(this.props.technician.id);
    }
  }

  editTechnician(params: ITechnicianParams) {
    this.setState({ showEditModal: false });

    if (this.props.technician) {
      this.props.editTechnician(params, this.props.technician.id);
    }
  }

  render() {
    const {
      technician,
      technicianId,
      requesting,
      technicianWasDeleted,
      associate,
      removeAssociation,
      content,
      requestingEditTechnician
    } = this.props;
    const associateTechnicianToOutletFunction = (
      targetTechnician: ITechnician,
      outlet: ILocation
    ) => {
      associate(targetTechnician.id, outlet.id, true);
    };
    const removeAssociationFromOutletFunction = (outlet: ILocation) => {
      removeAssociation(technicianId, outlet.id, true);
    };

    const renderAttributes = () =>
      content &&
      technician &&
      technicianId === technician.id && (
        <div className="technician-detail-header-container">
          <div className="attribute-container">
            <div className="technician-attribute">
              <span className="technician-attribute-name">{content.technician_detail_number}</span>
              <span className="technician-attribute-value">{technician.phoneNumber || "--"}</span>
            </div>
            <div className="technician-attribute">
              <span className="technician-attribute-name">{content.technician_detail_email}</span>
              <span className="technician-attribute-value">{technician.email || "--"}</span>
            </div>
            <div className="technician-attribute">
              <span className="technician-attribute-name">
                {content.technician_detail_authenticator_code}
              </span>
              <span className="technician-attribute-value">{technician.registerCode || "--"}</span>
            </div>
            <div className="technician-attribute">
              <span className="technician-attribute-name">{content.technician_detail_hub}</span>
              <span className="technician-attribute-value">{technician.hubCode || "--"}</span>
            </div>
          </div>
        </div>
      );

    const headerActions = [
      {
        label: content.technician_detail_edit_technician,
        onClick: () => this.setState({ showEditModal: true })
      },
      {
        label: content.technician_detail_delete_technician,
        onClick: () => this.setState({ showConfirmDeleteModal: true })
      },
      {
        label: content.technician_detail_associate_outlet,
        onClick: () => this.setState({ showModal: true })
      }
    ];

    return technicianWasDeleted ? (
      <Redirect to={Routes.technicians} />
    ) : (
      content && (
        <DetailPage className="technicians-header technician-detail-page">
          <Header
            title={technician?.name}
            breadcrumbs={[{ link: Routes.technicians, name: content.technician_detail_back_text }]}
            actions={headerActions}
          />
          {renderAttributes()}
          {technician && technicianId === technician.id ? (
            <DetailPageContent key="content">
              {(!technician.technicianRegisteredDevices ||
                technician.technicianRegisteredDevices.length === 0) && (
                <div className="associate-device">
                  <div className="associate-device-header">
                    <Icon name="error" className="associate-icon" />
                    <h2>{content.technician_detail_device_not_associated}</h2>
                  </div>
                  <div className="associate-device-subtitle">
                    <p className="mb-xs">
                      {content.technician_detail_device_not_associated_subtitle_1}
                    </p>
                    <p>{content.technician_detail_device_not_associated_subtitle_2}</p>
                  </div>
                  {this.props.requestingTechnicianDeviceCode === false && (
                    <div className="authenticator-code-area">
                      <h2 className="authenticator-code-title">
                        {content.common_authenticator_code}:{" "}
                        {technician.registerCode ? technician.registerCode : ""}
                      </h2>
                    </div>
                  )}
                </div>
              )}

              <div className="technician-devices">
                <div className="technician-devices-header">
                  <b className="technician-devices-associated">
                    {content.technician_detail_devices_associated} (
                    {technician.technicianRegisteredDevices &&
                      technician.technicianRegisteredDevices.length}
                    )
                  </b>
                  {this.props.requestingTechnicianDeviceCode === false && technician.registerCode && (
                    <p>
                      <span className="technician-add-device-text">
                        {content.technician_detail_add_device}
                      </span>
                      <span className="technician-add-device-code">{technician.registerCode}</span>
                    </p>
                  )}
                </div>
                <div className="technician-devices-grid">
                  {technician.technicianRegisteredDevices &&
                    technician.technicianRegisteredDevices.map(d => {
                      return (
                        <CardItem
                          key={d.id}
                          name={d.info}
                          details={[
                            {
                              title: content.common_authenticator_code,
                              value:
                                d.registerCode || content.technician_detail_device_code_unavailable
                            }
                          ]}
                          deleteAction={() =>
                            this.setState({
                              deviceToRemove: d,
                              showConfirmRemoveDevice: true
                            })
                          }
                          menuOptions={[
                            {
                              name: content.technician_detail_remove_device,
                              icon: "close",
                              onClick: () =>
                                this.setState({
                                  deviceToRemove: d,
                                  showConfirmRemoveDevice: true
                                })
                            }
                          ]}
                        />
                      );
                    })}
                </div>
              </div>
              <SetInternational technician={technician} />
              <div className="technician-outlets">
                <b>
                  {content.technician_detail_outlets_associated} (
                  {technician.technicianLocationAssociationResponse &&
                    technician.technicianLocationAssociationResponse.length}
                  )
                </b>
                <div className="technician-outlets-list">
                  {technician.technicianLocationAssociationResponse &&
                    technician.technicianLocationAssociationResponse.map(d => {
                      return (
                        <CardItem
                          extended
                          key={d.location.id}
                          name={d.location.name}
                          link={Routes.outletDetails.replace(":id", d.location.id)}
                          date={new Date(d.associationDate)}
                          changeAction={() =>
                            this.setState({
                              outletToRemove: d.location,
                              showModal: true
                            })
                          }
                          menuOptions={[
                            {
                              name: content.technician_detail_add_another,
                              icon: "refresh",
                              onClick: () =>
                                this.setState({
                                  outletToRemove: d.location,
                                  showModal: true
                                })
                            },
                            {
                              name: content.technician_detail_remove_association,
                              icon: "close",
                              onClick: () =>
                                this.props.removeAssociation(
                                  technicianId,
                                  d.location.id,
                                  true,
                                  false
                                )
                            }
                          ]}
                          details={[
                            {
                              title: content.common_assigned_in,
                              value: new Date(d.associationDate).toLocaleDateString(
                                process.env.REACT_APP_DEFAULT_LANG,
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric"
                                }
                              )
                            }
                          ]}
                          deleteAction={() => removeAssociationFromOutletFunction(d.location)}
                        />
                      );
                    })}
                </div>
                <Button
                  className="technician-outlets-associate-button"
                  secondary
                  onClick={() => this.setState({ showModal: true })}
                >
                  <Icon name="plus" />
                  {content.technician_detail_associate_outlet_to_technician}
                </Button>
              </div>
              <AssociateTechnicianModal
                technician={this.props.technician}
                visible={this.state.showModal}
                onClose={() => this.setState({ showModal: false, outletToRemove: undefined })}
                onSubmit={(tech, outlet) => {
                  const { outletToRemove } = this.state;

                  if (outletToRemove) {
                    removeAssociationFromOutletFunction(outletToRemove);
                    this.setState({ outletToRemove: undefined });
                  }

                  associateTechnicianToOutletFunction(tech, outlet);
                }}
              />
              <Modal
                visible={this.state.showEditModal}
                title={content.technician_detail_edit_technician}
                onClose={() => this.setState({ showEditModal: false })}
              >
                <CreateTechnicianForm
                  cancelCallback={() => this.setState({ showEditModal: false })}
                  createTechnicianCallback={(name: string, email: string, phoneNumber: string) =>
                    this.editTechnician({ name, email, phoneNumber })
                  }
                  isEdit
                  submitting={requestingEditTechnician || false}
                  currentName={technician.name}
                  currentEmail={technician.email}
                  currentPhone={technician.phoneNumber}
                />
              </Modal>
              <Modal
                visible={this.state.showConfirmRemoveDevice}
                title={content.technician_detail_remove_device}
                onClose={() =>
                  this.setState({ showConfirmRemoveDevice: false, deviceToRemove: undefined })
                }
              >
                <ConfirmationModal
                  question={content.technician_detail_confirm_remove_device}
                  confirmCallback={() => this.removeDevice()}
                  cancelCallback={() =>
                    this.setState({ showConfirmRemoveDevice: false, deviceToRemove: undefined })
                  }
                />
              </Modal>
              <Modal
                visible={this.state.showConfirmDeleteModal}
                title={content.technician_detail_delete_technician}
                onClose={() => this.setState({ showConfirmDeleteModal: false })}
              >
                <ConfirmationModal
                  question={content.technician_detail_delete_confirmation}
                  confirmCallback={() => this.deleteTechnician()}
                  cancelCallback={() => this.setState({ showConfirmDeleteModal: false })}
                />
              </Modal>
            </DetailPageContent>
          ) : requesting ? (
            <div>{content.technician_detail_loading}</div>
          ) : (
            <div>{content.technician_detail_not_found}</div>
          )}
        </DetailPage>
      )
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);

  return {
    content: content ? content.technicianDetail : null,
    requesting: state.technician.requestingCurrentTechnician,
    technician: state.technician.currentTechnician,
    requestingTechnicianDeviceCode: state.technician.requestingTechnicianDeviceCode,
    technicianWasDeleted: state.technician.technicianWasDeleted,
    requestingEditTechnician: state.technician.requestingEditTechnician
  };
};
const mapDispatchToProps = {
  getTechnician: actions.getTechnician,
  getTechnicianCode: actions.getTechnicianDeviceCodeRequest,
  deleteTechnician: actions.deleteTechnicianRequest,
  editTechnician: actions.editTechnicianRequest,
  removeAssociation: outletActions.removeAssociation,
  associate: outletActions.associateTechnicianToOutlet,
  unregisterDevice: actions.unregisterDevice
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianDetail);

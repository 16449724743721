import { ddmTheme } from "@ddm-design-system/tokens";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import history from "../history";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import {
  getCeLocations,
  getCeLocationsError,
  getCeLocationsSuccess,
  putCeLocationAddressSuccess,
  putCeLocationSuccess
} from "../store/ceLocations/actions";
import {
  DELETE_CELOCATION_PHOTO,
  DELETE_CELOCATION_REQUEST,
  GET_CELOCATIONS_REQUEST,
  ICELocation,
  IDeleteCeLocationRequest,
  IDeleteCePhoto,
  IPutCeLocationAddressRequest,
  IPutCeLocationFlexRequest,
  IPutCeLocationPhoto,
  IPutCeLocationRequest,
  PUT_CELOCATION_ADDRESS_REQUEST,
  PUT_CELOCATION_FLEX_REQUEST,
  PUT_CELOCATION_PHOTO,
  PUT_CELOCATION_REQUEST
} from "../store/ceLocations/types";
import { getCurrentContent } from "../store/content/selectors";

export function* getCeLocationsJcore(jCoreService: IJCoreService) {
  try {
    const { ceLocations: content } = (yield select(getCurrentContent)) || {};
    const result: ICELocation[] = yield call([jCoreService, jCoreService.fetchCeLocations]);

    yield put(getCeLocationsSuccess(result, content));
  } catch {
    yield put(getCeLocationsError());
  }
}

export function* putCeLocationJcore(
  { jCoreService, notificationService }: IAppContext,
  action: IPutCeLocationRequest
) {
  const { ceLocations: content } = (yield select(getCurrentContent)) || {};

  try {
    const result: ICELocation = yield call(
      [jCoreService, jCoreService.putCeLocation],
      action.payload.ceInstallationId,
      action.payload.ceLocationInfo
    );

    yield put(putCeLocationSuccess(result, content));
    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.celocation_edit_success
    });

    yield put(delay(500));
    yield put(getCeLocations());
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.celocation_edit_error
    });
  }
}

export function* putCeLocationFlexJcore(
  { jCoreService, notificationService }: IAppContext,
  action: IPutCeLocationFlexRequest
) {
  const { ceLocations: content } = (yield select(getCurrentContent)) || {};

  try {
    const result: ICELocation = yield call(
      [jCoreService, jCoreService.putCeLocationFlex],
      action.payload.ceInstallationId,
      action.payload.ceLocationInfo
    );

    yield put(putCeLocationSuccess(result, content));
    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.celocation_edit_success
    });

    yield put(delay(500));
    yield put(getCeLocations());
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.celocation_edit_error
    });
  }
}

export function* putCeLocationAddressJcore(
  { jCoreService, notificationService }: IAppContext,
  action: IPutCeLocationAddressRequest
) {
  const { ceLocations: content } = (yield select(getCurrentContent)) || {};

  try {
    yield call(
      [jCoreService, jCoreService.putCeLocationAddress],
      action.payload.ceLocationId,
      action.payload.ceLocationAddress
    );

    yield put(putCeLocationAddressSuccess(action.payload.ceLocationId));
    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.celocation_edit_success
    });

    yield put(delay(500));
    yield put(getCeLocations());
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.celocation_edit_error
    });
  }
}

export function* deleteCeLocationJcore(
  { jCoreService, notificationService }: IAppContext,
  action: IDeleteCeLocationRequest
) {
  const { ceLocations: content } = (yield select(getCurrentContent)) || {};

  try {
    yield call(
      [jCoreService, jCoreService.deleteCELocation],
      action.payload.ceInstallationId,
      action.payload.certificationNumber
    );
    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.celocation_delete_success
    });

    yield put(delay(500));
    yield put(getCeLocations());
    history.goBack();
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.celocation_delete_error
    });
  }
}

export function* putCeLocationPhotoJcore(
  { jCoreService, notificationService }: IAppContext,
  action: IPutCeLocationPhoto
) {
  const { ceLocations: content } = (yield select(getCurrentContent)) || {};

  try {
    yield call(
      [jCoreService, jCoreService.uploadCEPhoto],
      action.payload.ceInstallationId,
      action.payload.index,
      action.payload.file
    );
    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.celocation_upload_photo_success
    });

    yield put(delay(500));
    yield put(getCeLocations());
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.celocation_upload_photo_error
    });
  }
}

export function* deleteCeLocationPhotoJcore(
  { jCoreService, notificationService }: IAppContext,
  action: IDeleteCePhoto
) {
  const { ceLocations: content } = (yield select(getCurrentContent)) || {};

  try {
    yield call(
      [jCoreService, jCoreService.deleteCEPhoto],
      action.payload.ceInstallationId,
      action.payload.filename
    );
    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.celocation_delete_photo_success
    });

    yield put(delay(500));
    yield put(getCeLocations());
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.celocation_delete_photo_error
    });
  }
}

export function* ceLocationsWatcher(context: IAppContext) {
  yield takeLatest(GET_CELOCATIONS_REQUEST, getCeLocationsJcore, context.jCoreService);
  yield takeLatest(PUT_CELOCATION_REQUEST, putCeLocationJcore, context);
  yield takeLatest(PUT_CELOCATION_FLEX_REQUEST, putCeLocationFlexJcore, context);
  yield takeLatest(PUT_CELOCATION_ADDRESS_REQUEST, putCeLocationAddressJcore, context);
  yield takeLatest(DELETE_CELOCATION_REQUEST, deleteCeLocationJcore, context);
  yield takeLatest(PUT_CELOCATION_PHOTO, putCeLocationPhotoJcore, context);
  yield takeLatest(DELETE_CELOCATION_PHOTO, deleteCeLocationPhotoJcore, context);
}
export default ceLocationsWatcher;

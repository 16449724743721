import classnames from "classnames";
import React from "react";
import { IContent } from "../../../store/content/types";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import "./checkbox_group.scss";

export interface ICheckbox {
  id: string;
  text: string;
  selected?: boolean;
}

interface IProps<T extends ICheckbox> {
  title: string;
  modal?: boolean;
  checkboxes: T[];
  onCheckboxesChange: (checkboxes: T[], change: T) => void;
  content?: IContent;
  showAll?: boolean;
}

interface IState {
  modalVisible: boolean;
}

class CheckboxGroup<T extends ICheckbox> extends React.PureComponent<IProps<T>> {
  state: IState = {
    modalVisible: false
  };

  onCheckboxClick = (checkbox: T) => {
    const { onCheckboxesChange, checkboxes } = this.props;

    onCheckboxesChange(
      checkboxes.map(c => ({
        ...c,
        selected: c.id === checkbox.id ? !c.selected : c.selected
      })),
      checkbox
    );
  };

  checkAll = (checkbox: T) => {
    const { onCheckboxesChange, checkboxes } = this.props;

    onCheckboxesChange(
      checkboxes.map(c => ({
        ...c,
        selected: !checkbox.selected
      })),
      checkbox
    );
  };

  renderCheckboxes() {
    const { checkboxes, showAll, content } = this.props;
    const array: any = [...checkboxes];

    if (showAll) {
      array.unshift({
        id: "all",
        text: content && content.common_all,
        selected: checkboxes.every(c => !!c.selected)
      });
    }

    return array.map((checkbox: any) => (
      <div
        className={classnames("checkbox-group-item", checkbox.selected && "selected")}
        key={checkbox.id}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <input
            value={checkbox.text}
            type="checkbox"
            checked={checkbox.selected || false}
            onChange={() =>
              checkbox.id === "all" ? this.checkAll(checkbox) : this.onCheckboxClick(checkbox)
            }
          />
          {checkbox.text}
        </label>
      </div>
    ));
  }

  renderModal() {
    const { title, checkboxes, modal, content, showAll } = this.props;
    const { modalVisible } = this.state;
    const selectedCount = checkboxes.filter(c => c.selected).length;
    const selected = checkboxes.filter(c => c.selected).map(s => s.text);

    return (
      <div className={classnames("checkbox-group", modal && "checkbox-group-modal")}>
        {showAll && <span className="checkbox-group-label">{title}</span>}
        <Button
          primary={Boolean(selectedCount)}
          secondary={!selectedCount}
          inline
          className={classnames("checkbox-group-button", { selected: showAll })}
          onClick={() => this.setState({ modalVisible: true })}
        >
          {selectedCount
            ? showAll
              ? selectedCount === checkboxes.length
                ? content && content.common_all
                : selected.join(", ")
              : `${title}: ${
                  selectedCount === checkboxes.length
                    ? content && content.common_all
                    : selectedCount
                }`
            : `${showAll ? `${content && content.common_all}` : title}`}
        </Button>
        <Modal
          hasCloseOverlay
          visible={modalVisible}
          title={title}
          onClose={() => this.setState({ modalVisible: false })}
        >
          {this.renderCheckboxes()}
        </Modal>
      </div>
    );
  }

  render() {
    const { title, modal = false } = this.props;

    return modal ? (
      this.renderModal()
    ) : (
      <div className="checkbox-group">
        <p className="checkbox-group-title">{title}</p>
        <div className="checkbox-container">{this.renderCheckboxes()}</div>
      </div>
    );
  }
}

export default CheckboxGroup;

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/reducer";
import { LOGOUT } from "./auth/types";
import beveragesReducer from "./beverages/reducer";
import ceLocationsReducer from "./ceLocations/reducer";
import contentReducer from "./content/reducer";
import componentReducer from "./components/reducer";
import distributorsReducer from "./distributors/reducer";
import healthStatusReducer from "./healthStatus/reducer";
import outletsReducer from "./outlets/reducer";
import purchasingGroupsRedducer from "./purchasingGroups/reducer";
import sidebarReducer from "./sidebar/reducer";
import techniciansReducer from "./technicians/reducer";
import usersReducer from "./users/reducer";
import extra10OutletsReducer from "./extra10outlets/reducer";

const persistConfig = {
  key: "language",
  storage
};

const appReducer = combineReducers({
  authReducer,
  technician: techniciansReducer,
  outlet: outletsReducer,
  extra10outlet: extra10OutletsReducer,
  sidebar: sidebarReducer,
  content: persistReducer(persistConfig, contentReducer),
  users: usersReducer,
  beverages: beveragesReducer,
  ceLocations: ceLocationsReducer,
  component: componentReducer,
  healthStatus: healthStatusReducer,
  purchasingGroups: purchasingGroupsRedducer,
  distributors: distributorsReducer
});

export type IAppState = ReturnType<typeof appReducer>;

export const rootReducer = (state: IAppState | undefined, action: any) => {
  if (action.type !== LOGOUT) {
    return appReducer(state, action);
  }

  // Resets the redux state if user logs out, keeping only contentful data
  const content = state?.content;
  const combined = appReducer(undefined, action);

  if (content) {
    combined.content = content;
  }

  return combined;
};

import { AdalConfig, Authentication } from "adal-ts";

export interface IAdalService {
  login: () => void;
  checkLogin: () => boolean;
  getToken: () => string;
  getUser: () => string;
  logout: () => void;
  setLogoutRedirectUrl: (url: string) => void;
}

export const adalConfig: any = {
  tenant: "common",
  clientId: "1d44441f-418c-4741-ab8f-c6434496dcc0",
  endpoints: {
    api: "00000002-0000-0000-c000-000000000000"
  },
  cacheLocation: "localStorage",
  callback: () => {
    // console.log(errorDesc, token, error);
  }
};

export class AdalService implements IAdalService {
  private config = new AdalConfig(
    adalConfig.clientId,
    adalConfig.tenant,
    "",
    "",
    "token",
    `resource=${encodeURIComponent("00000002-0000-0000-c000-000000000000")}`
  );

  private adal: any = null;

  constructor() {
    this.config.postLogoutRedirectUrl = window.location.origin;
    this.config.redirectUri = window.location.origin;
    this.adal = Authentication.getContext(this.config);
  }

  login() {
    if (this.adal) {
      this.adal.login();
    }
  }

  setLogoutRedirectUrl(url: string) {
    this.adal = Authentication.getContext({
      ...this.config,
      postLogoutRedirectUrl: window.location.origin + url
    });
  }

  checkLogin() {
    const token = this.adal.getToken();

    return token !== null && token !== "";
  }

  getToken() {
    return this.adal.getToken();
  }

  getUser() {
    const user = this.adal.getUser();

    return user ? user.unique_name : "UNKNOWN_ACCOUNT";
  }

  logout() {
    localStorage.removeItem("azureUserToken");
    this.adal.logout();
  }
}

import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import { IDistributor } from "../../store/distributors/types";
import { getDistributors } from "../../store/distributors/actions";
import {
  getDistributor,
  getHasCreateDistributorError,
  getIsCreatingDistributor,
  getIsRequestingDistributors
} from "../../store/distributors/selectors";
import Header from "../common/header/Header";
import Tabs, { ITab } from "../common/tabs/Tabs";
import { LoadingIcon } from "../common/data_table/dataTable.styles";
import AssociateDistributorForm from "./AssociateDistributorForm";
import DistributorInfo from "./DistributorInfo";
import DistributorOutlets from "./DistributorOutlets";

interface IProps {
  distributorId: string;
}

const DistributorDetails: React.FC<IProps> = ({ distributorId }) => {
  const dispatch = useDispatch();
  const { common, distributors: content } = useContent();
  const distributor = useSelector(getDistributor(distributorId));
  const isLoading = useSelector(getIsRequestingDistributors);
  const isCreatingDistributor = useSelector(getIsCreatingDistributor);
  const hasCreateDistributorError = useSelector(getHasCreateDistributorError);
  const [showAssociateDistributorForm, setShowAssociateDistributorForm] = useState(false);

  useEffect(() => {
    if (!distributor) {
      dispatch(getDistributors());
    }
  }, []);

  // Close sidebar if distributor was successfully edited
  useEffect(() => {
    if (!isCreatingDistributor && !hasCreateDistributorError && showAssociateDistributorForm) {
      setShowAssociateDistributorForm(false);
    }
  }, [isCreatingDistributor]);

  const headerActions = useMemo(
    () =>
      distributor && [
        {
          label: content.distributors_associate_distributors,
          onClick: () => {
            setShowAssociateDistributorForm(true);
          }
        }
      ],
    [distributor, content]
  );

  const tabs = useMemo<ITab[]>(
    () => [
      {
        content: <DistributorInfo distributor={distributor} />,
        label: common.common_info,
        route: Routes.distributorInfo.replace(":id", distributorId)
      },
      {
        content: <DistributorOutlets distributor={distributor as IDistributor} />,
        label: common.common_outlets,
        route: Routes.distributorOutlets.replace(":id", distributorId)
      }
    ],
    [distributor, common]
  );

  return (
    <div>
      <Header
        title={distributor?.name}
        breadcrumbs={[{ link: Routes.distributors, name: common.common_distributors }]}
        actions={headerActions}
      />
      <div style={{ overflow: "auto", flex: 1 }}>
        {isLoading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingIcon />
          </div>
        ) : distributor ? (
          <Tabs tabs={tabs} />
        ) : (
          <div>{content.distributors_details_not_found.replace("%ID%", distributorId)}</div>
        )}
      </div>
      <AssociateDistributorForm
        distributor={distributor}
        visible={showAssociateDistributorForm}
        onClose={() => {
          setShowAssociateDistributorForm(false);
        }}
      />
    </div>
  );
};

export default DistributorDetails;

import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import { Link } from "../common/link/link.styles";
import { getIsLoadingOutlets } from "../../store/extra10outlets/selectors";
import DataTable from "../common/data_table/DataTable";
import { IFilter } from "../common/filter/Filter";
import { getCurrentLanguage } from "../../store/content/selectors";
import { IExtra10OutletStatus } from "../../store/extra10outlets/types";
import ExportDataModal from "../outlets/ExportDataModal";
import { exportAllData } from "../../store/outlets/actions";
import { IMenuAction } from "../common/menu/ActionsMenu";

interface IProps {
  filters?: IFilter[];
  outlets: IExtra10OutletStatus[];
  searchSelector?: string;
  showGroupingInfo?: boolean;
  title?: string;
}

const Extra10OutletList: React.FC<IProps> = ({ filters, outlets, searchSelector, title = "" }) => {
  const dispatch = useDispatch();
  const { outlets: content, common } = useContent();
  const language = useSelector(getCurrentLanguage);
  const isLoading = useSelector(getIsLoadingOutlets);
  const [showExportDataModal, setShowExportDataModal] = useState(false);

  const onExportData = useCallback(
    (start, end) => {
      setShowExportDataModal(false);

      if (!outlets) return;

      dispatch(exportAllData(true, start, end));
    },
    [dispatch, outlets]
  );

  const actionsDownload: IMenuAction[] = useMemo(() => {
    return [
      {
        icon: "Download",
        label: "Outlet info",
        onClick: () => setShowExportDataModal(true)
      }
    ];
  }, []);

  const formatOutlet = useCallback(
    ({ id, name }: IExtra10OutletStatus) => (
      <Link to={Routes.extra10OutletDetails.replace(":id", id)} title={name || id}>
        {name || id}
      </Link>
    ),
    []
  );

  const sortLevel = useCallback(
    (
      { chipSelectors: [{ sort: chipA }] }: IExtra10OutletStatus,
      { chipSelectors: [{ sort: chipB }] }: IExtra10OutletStatus
    ) => (chipA as number) - (chipB as number),
    []
  );

  const formatTimeEdit = useCallback(
    ({ lastEdit }: IExtra10OutletStatus) => {
      const date = lastEdit
        ? new Date(lastEdit).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const formatTimeCreate = useCallback(
    ({ createDateTime }: IExtra10OutletStatus) => {
      const date = createDateTime
        ? new Date(createDateTime).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const columns = useMemo(() => {
    const allColumns = [
      {
        format: formatOutlet,
        name: common.common_outlet,
        selector: (row: IExtra10OutletStatus) => row.name,
        sortable: true,
        sortFunction: (outletA: IExtra10OutletStatus, outletB: IExtra10OutletStatus) =>
          outletA.name.toLocaleLowerCase() > outletB.name.toLocaleLowerCase() ? 1 : -1
      },
      {
        name: content.outlets_status,
        selector: (row: IExtra10OutletStatus) => row.statusContent,
        sortable: true,
        sortFunction: sortLevel,
        selectorId: "statusContent"
      },
      {
        name: common.common_units,
        selector: (row: IExtra10OutletStatus) => row.components,
        sortable: true
      }
    ];

    allColumns.push(
      // @ts-ignore
      {
        format: formatTimeCreate,
        name: content.outlets_date_created,
        selector: (row: IExtra10OutletStatus) => row.createDateTime || 0,
        sortable: true
      }
    );

    return allColumns;
  }, [
    formatOutlet,
    common.common_outlet,
    common.common_units,
    content.outlets_status,
    content.outlets_date_created,
    sortLevel,
    formatTimeEdit,
    formatTimeCreate
  ]);

  return (
    <>
      <DataTable
        actions={actionsDownload}
        columns={columns}
        data={outlets}
        defaultSortAsc={false}
        defaultSortField="createDateTime"
        filters={filters}
        isLoading={isLoading}
        searchPlaceholder={content.outlets_search}
        searchSelector={searchSelector}
        title={title}
        pagination
        paginationPerPage={100}
      />
      <ExportDataModal
        visible={showExportDataModal}
        onClose={() => setShowExportDataModal(false)}
        onSubmit={onExportData}
      />
    </>
  );
};

export default Extra10OutletList;

import { Authentication } from "adal-ts";
import PullToRefresh from "pulltorefreshjs";
import React, { useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { Provider, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import createSagaMiddleware from "redux-saga";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider } from "styled-components";
import { SplashLoading } from "@ddm-design-system/icon";
import {
  INotificationStackRef,
  NotificationProvider,
  NotificationStack,
  useNotificationContext
} from "@ddm-design-system/notification";
import { ddmTheme } from "@ddm-design-system/tokens";
import { AnimatedDDMIcon, InnerAppContainer } from "./app.styles";
import { AuthProvider, useAuthContext } from "./components/auth/AuthContext";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import PrivateRoute from "./components/auth/PrivateRoute";
import CookiesForm from "./components/common/cookies_form/CookiesForm";
import Home from "./components/home/Home";
import ErrorBoundary from "./components/common/error_boundary/ErrorBoundary";
import GlobalStyles from "./global.styles";
import history from "./history";
import Routes from "./routes";
import rootSaga from "./sagas";
import appContext from "./services";
import { AnalyticsProvider } from "./services/analytics";
import { rootReducer } from "./store";
import { getCurrentLanguage, getIsLoadingContentful } from "./store/content/selectors";
import "./styles/index.scss";

const sagaMiddleware = createSagaMiddleware();
const initialState = {};
const middleware = [sagaMiddleware];

// Enable redux devtools chrome extension
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga, appContext);

history.listen(listener =>
  appContext.analyticsService.logPageView(listener.pathname + listener.search)
);

PullToRefresh.init({
  triggerElement: "#root",
  shouldPullToRefresh: () => !(document.querySelector("#root")?.scrollTop ?? true),
  instructionsReleaseToRefresh: " ",
  instructionsPullToRefresh: " ",
  instructionsRefreshing: " ",
  iconRefreshing: ReactDOMServer.renderToString(<AnimatedDDMIcon />)
});

const App: React.FC = () => {
  Authentication.getAadRedirectProcessor().process();
  const authValue = useAuthContext(appContext.jCoreService, () => {
    persistor.persist();
  });
  const notificationStackRef = useRef<INotificationStackRef>(null);
  const notificationContext = useNotificationContext(notificationStackRef);

  appContext.setNotificationService(notificationContext);

  return (
    <AuthProvider value={authValue}>
      <NotificationProvider value={notificationContext}>
        <ThemeProvider theme={ddmTheme}>
          <Provider store={store}>
            <ErrorBoundary>
              <Router history={history}>
                <AnalyticsProvider value={appContext.analyticsService}>
                  <PersistGate loading={null} persistor={persistor}>
                    <InnerApp />
                  </PersistGate>
                  <NotificationStack ref={notificationStackRef} />
                  <CookiesForm />
                  <GlobalStyles />
                </AnalyticsProvider>
              </Router>
            </ErrorBoundary>
          </Provider>
        </ThemeProvider>
      </NotificationProvider>
    </AuthProvider>
  );
};

const InnerApp: React.FC = () => {
  const language = useSelector(getCurrentLanguage);
  const loadingContentful = useSelector(getIsLoadingContentful);

  if (loadingContentful) return <SplashLoading />;

  return (
    <InnerAppContainer>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        language={language}
      >
        <Switch>
          <Route path={Routes.login} component={Login} />
          <Route path={Routes.logout} component={Logout} />
          <PrivateRoute component={Home} path={Routes.home} />
        </Switch>
      </GoogleReCaptchaProvider>
    </InnerAppContainer>
  );
};

export default App;

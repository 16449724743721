import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BodyHighlight, MainTitle } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import {
  getHasCreateDistributorError,
  getIsCreatingDistributor
} from "../../store/distributors/selectors";
import { IDistributor } from "../../store/distributors/types";
import { EditButton } from "../common/button/EditButton.styles";
import { FieldValue } from "../common/fieldValue/fieldValue.styles";
import CreateDistributorForm from "./CreateDistributorForm";
import Routes from "../../routes";
import { Link } from "../common/link/link.styles";

interface IProps {
  distributor: IDistributor | undefined;
}

const DistributorInfo: React.FC<IProps> = ({ distributor }) => {
  const { common, distributors: content } = useContent();
  const isCreatingDistributor = useSelector(getIsCreatingDistributor);
  const hasCreateDistributorError = useSelector(getHasCreateDistributorError);
  const [showEditDistributorForm, setShowEditDistributorForm] = useState(false);

  // Close sidebar if distributor was successfully edited
  useEffect(() => {
    if (!isCreatingDistributor && !hasCreateDistributorError && showEditDistributorForm) {
      setShowEditDistributorForm(false);
    }
  }, [isCreatingDistributor]);

  return (
    <div className="pt-xxl">
      <div className="flex items-center justify-between">
        <MainTitle>{common.common_information}</MainTitle>
        <div>
          <EditButton
            icon="Edit"
            onClick={() => {
              setShowEditDistributorForm(true);
            }}
          >
            {common.common_edit_info}
          </EditButton>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_name}</BodyHighlight>
                <FieldValue>{distributor?.name}</FieldValue>
              </div>
              <div className="field items-start">
                <BodyHighlight>{common.common_contact}</BodyHighlight>
                <FieldValue>{distributor?.email}</FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{content.distributors_details_id}</BodyHighlight>
                <FieldValue>{distributor?.id}</FieldValue>
              </div>
              <div className="field items-start">
                <BodyHighlight>{common.common_purchasing_group}</BodyHighlight>
                <FieldValue>
                  {distributor?.purchasingGroup ? (
                    <Link
                      to={Routes.purchasingGroupDetails.replace(
                        ":id",
                        distributor?.purchasingGroup?.id
                      )}
                      title={distributor?.purchasingGroup?.name || distributor?.purchasingGroup?.id}
                    >
                      {distributor?.purchasingGroup?.name}
                    </Link>
                  ) : (
                    "--"
                  )}
                </FieldValue>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateDistributorForm
        distributor={distributor}
        visible={showEditDistributorForm}
        onClose={() => {
          setShowEditDistributorForm(false);
        }}
      />
    </div>
  );
};

export default DistributorInfo;

import styled from "styled-components";
import { ddmTheme } from "@ddm-design-system/tokens";

export const InnerAppContainer = styled.div`
  background-color: var(--color-background);
  margin-right: calc(-1 * (100vw - 100%));
`;

export const AnimatedDDMIcon = styled(ddmTheme.icons.DmLogo)`
  animation: ${ddmTheme.animations.rotate} 1s infinite;
  height: ${ddmTheme.spaceUnit.lg};
  width: ${ddmTheme.spaceUnit.lg};
`;

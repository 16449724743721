import { all } from "redux-saga/effects";
import { IAppContext } from "../services";
import authWatcher from "./auth";
import beveragesWatcher from "./beverages";
import ceLocationsWatcher from "./ceLocations";
import contentWatcher from "./content";
import healthStatusWatcher from "./healthStatus";
import outletsWatcher from "./outlets";
import extra10OutletsWatcher from "./extra10outlets";
import purchasingGroupsWatcher from "./purchasingGroups";
import distributorsWatcher from "./distributors";
import componentsWatcher from "./components";
import technicianWatcher from "./technicians";
import usersWatcher from "./users";

export default function* rootSaga(context: IAppContext) {
  yield all([
    outletsWatcher(context),
    extra10OutletsWatcher(context),
    purchasingGroupsWatcher(context),
    distributorsWatcher(context),
    componentsWatcher(context),
    technicianWatcher(context),
    beveragesWatcher(context),
    contentWatcher(context),
    usersWatcher(context),
    authWatcher(context),
    ceLocationsWatcher(context),
    healthStatusWatcher(context)
  ]);
}

import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";

function arrowGenerator(color: string) {
  return {
    "&[x-placement*='bottom'] $arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    "&[x-placement*='top'] $arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    "&[x-placement*='right'] $arrow": {
      left: 0,
      marginLeft: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    "&[x-placement*='left'] $arrow": {
      right: 0,
      marginRight: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}
const useStyles = makeStyles(
  createStyles({
    tooltip: {
      position: "relative",
      backgroundColor: "var(--color-white)",
      borderRadius: "5px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      color: "var(--color-success-success100)",
      fontSize: "var(--text-xs)",
      lineHeight: "26px"
    },
    tooltipError: {
      position: "relative",
      backgroundColor: "var(--color-white)",
      borderRadius: "5px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      color: "var(--color-alert-alert100)",
      fontSize: "var(--text-xs)",
      lineHeight: "26px"
    },
    arrow: {
      position: "absolute",
      fontSize: 6,
      width: "3em",
      height: "3em",
      "&::before": {
        content: "",
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid"
      }
    },
    popper: arrowGenerator("white")
  })
);

interface IProps extends TooltipProps {
  type: string;
}
const ControlTowerTooltip: React.FC<IProps> = props => {
  const { arrow, ...classes } = useStyles();
  const { type } = props;
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);

  return (
    <Tooltip
      classes={{
        tooltip: type === "success" ? classes.tooltip : classes.tooltipError,
        popper: classes.popper
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef
            }
          }
        }
      }}
      {...props}
      disableHoverListener={!props.title}
      disableTouchListener={!props.title}
      title={
        <>
          {props.title}
          <span className={arrow} ref={setArrowRef} />
        </>
      }
    />
  );
};

export default ControlTowerTooltip;

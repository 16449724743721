import { NavbarStyles } from "@ddm-design-system/navbar";
import { Sidebar } from "@ddm-design-system/sidebar";
import styled, { css } from "styled-components";

export const NavigationMenuContainer = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    overflow: auto;

    ${!isMobile &&
    css`
      padding: 0 0 ${theme.spaceUnit.md} ${theme.spaceUnit.md};
    `};
  `
);

export const SidebarMobileContainer = styled(Sidebar)(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: ${theme.spaceUnit.md};
    padding-left: 24px;
    width: 281px;
  `
);

export const NavbarContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    max-width: 200px;
    //overflow-y: auto;

    ${NavbarStyles.Wrapper} {
      flex: 1 1 0%;
    }

    ${NavbarStyles.StyledNavButton} {
      text-align: left;
    }

    ${NavbarStyles.HeaderWrapperLogo} {
      margin: ${theme.spaceUnit.xl} 0;
    }
  `
);

export const LanguagePickerContainer = styled.div(
  ({ theme }) => css`
    margin: ${theme.spaceUnit.sm};
  `
);

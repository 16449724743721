import { IconNames } from "@ddm-design-system/icon";
import { EChipType, IChipSelector } from "../../components/common/data_table/DataTable";
import { ComponentLevelStatus, EOutletStatusBadges, IComponentStatus } from "./types";

const getStatusChipType = (status: EOutletStatusBadges) => {
  switch (status) {
    case EOutletStatusBadges.OK:
      return EChipType.SUCCESS;
    case EOutletStatusBadges.WARNING:
      return EChipType.WARNING;
    case EOutletStatusBadges.ERROR:
    case EOutletStatusBadges.INVALID_INSTALLATION:
      return EChipType.DANGER;
    default:
      return EChipType.DEFAULT;
  }
};

export const getStatusChipSelector = (status: EOutletStatusBadges): IChipSelector => ({
  name: "statusContent",
  sort: Object.values(EOutletStatusBadges).indexOf(status),
  type: getStatusChipType(status)
});

export const iconStatusMap: { [key: string]: IconNames } = {
  OFFLINE: "Wifi",
  SENSOR_NOT_CONNECTED: "Sensor",
  SENSOR_FAULTY: "Sensor",
  OPEN: "Unlocked",
  LEAK: "Leak",
  EMPTY: "Keg",
  EXPIRED_KEG: "CalendarCircle",
  STALE_KEG: "Sleep",
  TOO_MANY_KEG_CHANGES: "Warning",
  CLEANING_DUE: "Cleaning",
  OK: "Ok"
};

export const getComponentStatus = (
  componentStatus: IComponentStatus[] | undefined
): Array<{ level: ComponentLevelStatus; icon: IconNames; title?: string }> => {
  const status: Array<{ level: ComponentLevelStatus; icon: IconNames; title?: string }> = [];

  componentStatus?.forEach(s => {
    status.push({
      level: s.level,
      icon: iconStatusMap[s.message] || "Error",
      title: s.message?.toString()
    });
  });

  return status;
};

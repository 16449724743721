import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Body } from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import { AnalyticsContext } from "../../../services/analytics";
import { AcceptButton, Cookies, TextLink } from "./cookiesForm.styles";

const CookiesForm: React.FC = () => {
  const AnalyticsService = useContext(AnalyticsContext);
  const { common: contentCommon } = useContent();
  const [isVisible, setIsVisible] = useState(false);

  const setupTracking = useCallback(() => {
    AnalyticsService.setup();
  }, [AnalyticsService]);

  const saveConsent = useCallback(
    (consent: boolean) => () => {
      localStorage.setItem("cookieConsent", consent ? "true" : "false");

      if (consent) {
        setupTracking();
      }

      setIsVisible(false);
    },
    [setupTracking]
  );

  useEffect(() => {
    const openCookiesForm =
      Object.keys(contentCommon).length > 0 && localStorage.getItem("cookieConsent") === null;

    setIsVisible(openCookiesForm);
  }, [contentCommon]);

  useEffect(() => {
    if (localStorage.getItem("cookieConsent") === "true") {
      setupTracking();
    }
  }, [setupTracking]);

  const renderOutTokens = () => {
    const optOutTokens = (contentCommon.common_cookies_opt_out || "").split("%SPLIT%");

    if (optOutTokens.length < 3) return;

    const [initialSentence, linkText, finalSentence] = optOutTokens;

    return (
      <p>
        {initialSentence}
        <TextLink as="span" onClick={saveConsent(false)}>
          {linkText}
        </TextLink>
        {finalSentence}
      </p>
    );
  };

  return isVisible ? (
    <Cookies>
      <Body as="div">
        <ReactMarkdown
          components={{
            a: props => (
              <TextLink href={props.href} target="_blank" rel="noopener noreferrer">
                {props.children}
              </TextLink>
            )
          }}
        >
          {contentCommon.common_cookies_text}
        </ReactMarkdown>
        {renderOutTokens()}
      </Body>
      <AcceptButton onClick={saveConsent(true)}>{contentCommon.common_cookies_yes}</AcceptButton>
    </Cookies>
  ) : null;
};

export default CookiesForm;

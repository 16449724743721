import React, { useState } from "react";
import { ITechnician } from "../../../store/technicians/types";
import Modal from "../modal/Modal";
import AssociateTechnicianForm, { IProps } from "./AssociateTechnicianForm";
import "./AssociateTechnicianModal.scss";
import { ILocation } from "../../../store/outlets/types";

interface IWithModalProps extends IProps {
  visible: boolean;
  onClose: () => void;
}

const AssociateTechnicianModal: React.FC<IWithModalProps> = ({
  visible,
  onClose,
  onChange,
  onSubmit,
  ...props
}) => {
  const [title, setTitle] = useState("");
  const handleChange = (technician?: ITechnician, outlet?: ILocation) => {
    setTitle(
      `Associate ${technician ? technician.name : "Technican"} to ${
        outlet ? outlet.name : "an Outlet"
      }`
    );

    if (onChange) {
      onChange(technician, outlet);
    }
  };
  const handleSubmit = (technician: ITechnician, outlet: ILocation) => {
    onClose();

    if (onSubmit) {
      onSubmit(technician, outlet);
    }
  };

  return (
    <Modal className="modal-associate-technician" visible={visible} title={title} onClose={onClose}>
      <AssociateTechnicianForm {...props} onChange={handleChange} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default AssociateTechnicianModal;

import React, { useState } from "react";
import { MainTitle } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { getCurrentComponent } from "../../../store/components/selectors";
import Extra10Info from "./extra10/info/Info";
import { EComponentType, IComponentInfo } from "../../../store/components/types";
import { EditButton } from "../../common/button/EditButton.styles";
import EditExtra10Form from "../EditExtra10Form";
import { ICountry } from "../../../store/beverages/types";

const Info: React.FC<{ country?: ICountry }> = ({ country }) => {
  const { outletDetails: content, common } = useContent();
  const [isEdit, setIsEdit] = useState(false);
  const currentComponent = useSelector(getCurrentComponent());
  const infoComponentMap = {
    [EComponentType.X10]: Extra10Info
  };

  const InfoComponent = (infoComponentMap as any)[currentComponent?.type || ""];

  return (
    <div className="py-xxl">
      <div className="flex items-center justify-between">
        <MainTitle>{common.common_information}</MainTitle>
        <div>
          <EditButton icon="Edit" onClick={() => setIsEdit(true)}>
            {content.outlet_details_edit_info}
          </EditButton>
        </div>
      </div>
      {currentComponent && InfoComponent ? (
        <InfoComponent componentInfo={currentComponent} />
      ) : (
        <div />
      )}

      {currentComponent && (
        <EditExtra10Form
          x10={currentComponent as any}
          country={country}
          visible={isEdit}
          onClose={() => setIsEdit(false)}
        />
      )}
    </div>
  );
};

export default Info;

import { call, put, select, takeLatest } from "redux-saga/effects";
import { ddmTheme } from "@ddm-design-system/tokens";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { getCurrentContent } from "../store/content/selectors";
import {
  ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS,
  CREATE_PURCHASING_GROUP,
  CreatePurchasingGroupErrorCodes,
  EDIT_PURCHASING_GROUP,
  GET_PURCHASING_GROUPS_REQUEST,
  IAssociatePurchasingGroupDistributorsAction,
  ICreatePurchasingGroupAction,
  IEditPurchasingGroupAction,
  IPurchasingGroup,
  REFRESH_GROUPING_DATA
} from "../store/purchasingGroups/types";
import {
  getPurchasingGroups as getPurchasingGroupsAction,
  getPurchasingGroupsError,
  getPurchasingGroupsSuccess,
  createPurchasingGroupSuccess,
  editPurchasingGroupSuccess,
  editPurchasingGroupError,
  createPurchasingGroupError,
  associatePurchasingGroupDistributorsSuccess,
  associatePurchasingGroupDistributorsError,
  refreshGroupingData as refreshGroupingDataAction
} from "../store/purchasingGroups/actions";
import { getDistributors } from "../store/distributors/actions";
import { getOutlets } from "../store/outlets/actions";

export function* getPurchasingGroups(jCoreService: IJCoreService) {
  try {
    const result: IPurchasingGroup[] = yield call([
      jCoreService,
      jCoreService.fetchPurchasingGroups
    ]);

    yield put(getPurchasingGroupsSuccess(result));
  } catch {
    yield put(getPurchasingGroupsError());
  }
}

export function* createPurchasingGroup(
  { jCoreService, notificationService }: IAppContext,
  action: ICreatePurchasingGroupAction
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const newPG: IPurchasingGroup = yield call(
      [jCoreService, jCoreService.createPurchasingGroup],
      action.payload
    );
    yield put(createPurchasingGroupSuccess(newPG));
    yield put(refreshGroupingDataAction());
  } catch (e) {
    const { message } = (e as { response: { data: { message: string } } }).response.data;
    const errorMessage =
      message === CreatePurchasingGroupErrorCodes.DUPLICATE_PURCHASING_GROUP_NAME
        ? content.purchasing_group_create_duplicate_name_error
        : message === CreatePurchasingGroupErrorCodes.DUPLICATE_USERNAME
        ? content.purchasing_group_create_duplicate_email_error
        : content.purchasing_group_create_error;

    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: errorMessage
    });
    yield put(createPurchasingGroupError());
  }
}

export function* editPurchasingGroup(
  { jCoreService, notificationService }: IAppContext,
  action: IEditPurchasingGroupAction
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const editedPG: IPurchasingGroup = yield call(
      [jCoreService, jCoreService.editPurchasingGroup],
      action.payload.data,
      action.payload.id
    );
    yield put(editPurchasingGroupSuccess(editedPG));
    yield put(refreshGroupingDataAction());
  } catch (e) {
    const { message } = (e as { response: { data: { message: string } } }).response.data;
    const errorMessage =
      message === CreatePurchasingGroupErrorCodes.DUPLICATE_PURCHASING_GROUP_NAME
        ? content.purchasing_group_create_duplicate_name_error
        : message === CreatePurchasingGroupErrorCodes.DUPLICATE_USERNAME
        ? content.purchasing_group_create_duplicate_email_error
        : content.purchasing_group_edit_error;

    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: errorMessage
    });
    yield put(editPurchasingGroupError());
  }
}

export function* associatePurchasingGroupDistributors(
  { jCoreService, notificationService }: IAppContext,
  action: IAssociatePurchasingGroupDistributorsAction
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const editedPG: IPurchasingGroup = yield call(
      [jCoreService, jCoreService.associatePurchasingGroupDistributors],
      action.payload.pgId,
      typeof action.payload.distributorIds === "string"
        ? [action.payload.distributorIds]
        : action.payload.distributorIds
    );
    yield put(associatePurchasingGroupDistributorsSuccess(editedPG));
    yield put(refreshGroupingDataAction());
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.purchasing_group_edit_error
    });
    yield put(associatePurchasingGroupDistributorsError());
  }
}

export function* refreshGroupingData() {
  yield put(getPurchasingGroupsAction());
  yield put(getDistributors());
  yield put(getOutlets());
}

export function* purchasingGroupsWatcher(context: IAppContext) {
  yield takeLatest(GET_PURCHASING_GROUPS_REQUEST, getPurchasingGroups, context.jCoreService);
  yield takeLatest(CREATE_PURCHASING_GROUP, createPurchasingGroup, context);
  yield takeLatest(EDIT_PURCHASING_GROUP, editPurchasingGroup, context);
  yield takeLatest(
    ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS,
    associatePurchasingGroupDistributors,
    context
  );
  yield takeLatest(REFRESH_GROUPING_DATA, refreshGroupingData);
}

export default purchasingGroupsWatcher;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { getOutlets } from "../../store/outlets/selectors";
import { deleteUser, editUser, getUser } from "../../store/users/actions";
import { IInvitedUserParams, Roles } from "../../store/users/types";
import { DetailPage } from "../common/DetailPage/DetailPage";
import { DetailPageContent } from "../common/DetailPage/DetailPageContent";
import Header from "../common/header/Header";
import ConfirmationModal from "../common/modal/ConfirmationModal";
import Modal from "../common/modal/Modal";
import { ILocation } from "../../store/outlets/types";
import CreateUserForm from "./CreateUserForm";
import DataTable from "../common/data_table/DataTable";

interface IProps {
  userId: string;
}

export const UserDetail: React.FC<IProps> = ({ userId }) => {
  const {
    controlTowerUserDetail: content,
    common,
    outlets: contentOutlets,
    controlTowerCountries,
    controlTowerInvitedUsers
  } = useContent();
  const isLoadingUser: boolean = useSelector((state: IAppState) => state.users.isLoadingUser);
  const user = useSelector((state: IAppState) => state.users.selectedUser);
  const me = useSelector((state: IAppState) => state.authReducer.me);
  const allLocations = useSelector(getOutlets);
  const userWasDeleted = useSelector((state: IAppState) => state.users.userWasDeleted);
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const isUserSupervisor = useMemo(
    // @ts-ignore
    () => user && Roles[user && user.role] === Roles.ROLE_SUPERVISOR,
    [user]
  );

  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (user) {
      if (user.allLocationsVisibility || isUserSupervisor) {
        setLocations(allLocations);
      } else {
        setLocations(user.locations);
      }
    }
  }, [user, allLocations, isUserSupervisor]);

  const headerActions = [
    {
      label: content.control_tower_invited_user_delete,
      onClick: () => setShowConfirmDeleteModal(true)
    },
    {
      label: common.common_edit,
      onClick: () => setVisibleCreateModal(true)
    }
  ];

  const formatOutlet = useCallback(
    ({ id, name }: ILocation) => (
      <Link to={Routes.outletDetails.replace(":id", id)} title={name || id}>
        {name || id}
      </Link>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        format: formatOutlet,
        name: common.common_outlet,
        selector: (row: ILocation) => row.name,
        sortable: true,
        sortFunction: (outletA: ILocation, outletB: ILocation) =>
          outletA.name.toLocaleLowerCase() > outletB.name.toLocaleLowerCase() ? 1 : -1
      }
    ],
    [formatOutlet, common.common_outlet]
  );

  return (
    content &&
    (userWasDeleted ? (
      <Redirect to={Routes.users} />
    ) : (
      <DetailPage className="user-detail">
        <Header
          title={user?.username}
          breadcrumbs={[
            { link: Routes.users, name: controlTowerInvitedUsers.control_tower_invited_users_title }
          ]}
          actions={headerActions}
        />
        <DetailPageContent>
          {isLoadingUser ? (
            <span>{common.common_loading}</span>
          ) : (
            user &&
            controlTowerCountries &&
            me && (
              <>
                <div className="flex my-md">
                  {user.allLocationsVisibility || isUserSupervisor ? (
                    <span>
                      <ReactMarkdown>
                        {(content.control_tower_invited_user_all_access || "").replace(
                          "%COUNTRY%",
                          me && me.country
                            ? controlTowerCountries[
                                `countries_${me.country.code.toLocaleLowerCase()}`
                              ]
                            : ""
                        )}
                      </ReactMarkdown>
                    </span>
                  ) : (
                    <span>{content.control_tower_invited_user_has_access}</span>
                  )}
                </div>
                <div className="w-full mb-lg">
                  <DataTable
                    columns={columns}
                    data={locations}
                    defaultSortAsc={false}
                    defaultSortField="name"
                    searchPlaceholder={contentOutlets.outlets_search}
                    searchSelector="name"
                    pagination
                    paginationPerPage={100}
                    title=""
                  />
                </div>
              </>
            )
          )}
        </DetailPageContent>

        <Modal
          visible={showConfirmDeleteModal}
          title={content.control_tower_invited_user_deleting_user}
          onClose={() => setShowConfirmDeleteModal(false)}
        >
          <ConfirmationModal
            question={content.control_tower_invited_user_delete_confirm}
            confirmCallback={() => {
              if (user) {
                dispatch(deleteUser(user.id));
              }

              setShowConfirmDeleteModal(false);
            }}
            cancelCallback={() => setShowConfirmDeleteModal(false)}
          />
        </Modal>

        {user && (
          <CreateUserForm
            visible={visibleCreateModal}
            user={user}
            onClose={() => setVisibleCreateModal(false)}
            onCreateUser={(editedUser: IInvitedUserParams) => {
              setVisibleCreateModal(false);

              dispatch(
                editUser({
                  userId: user.id,
                  allVisibility: editedUser.allVisibility,
                  role: editedUser.role,
                  locationIds: editedUser.locationIds ? editedUser.locationIds : []
                })
              );
            }}
          />
        )}
      </DetailPage>
    ))
  );
};

export default UserDetail;

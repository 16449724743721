import classnames from "classnames";
import React from "react";
import "./toggleButtonGroup.scss";

export interface IToggleButton {
  id: string;
  text: string;
  disabled?: boolean;
  selected?: boolean;
}

interface IProps<T extends IToggleButton> {
  buttons: T[];
  multiple?: boolean;
  onButtonChange: (checkboxes: T[], change: T) => void;
}

class ToggleButtonGroup<T extends IToggleButton> extends React.PureComponent<IProps<T>> {
  onButtonClick = (button: T) => {
    const { onButtonChange, buttons, multiple } = this.props;

    onButtonChange(
      buttons.map(c => ({
        ...c,
        selected: c.id === button.id ? !c.selected : multiple ? c.selected : false
      })),
      button
    );
  };

  renderButtons() {
    const { buttons } = this.props;

    return buttons.map((button, i) => (
      <div
        className={classnames(
          "toggle-button-group-item",
          button.selected && "selected",
          button.disabled && "disabled"
        )}
        key={button.id || i}
        onClick={() => !button.disabled && this.onButtonClick(button)}
      >
        <span>{button.text}</span>
      </div>
    ));
  }

  render() {
    return <div className="toggle-button-group">{this.renderButtons()}</div>;
  }
}

export default ToggleButtonGroup;

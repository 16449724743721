import classnames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";
import { ReactComponent as add } from "../../../assets/icons/add.svg";
import { ReactComponent as arrow } from "../../../assets/icons/arrow.svg";
import { ReactComponent as block } from "../../../assets/icons/block.svg";
import { ReactComponent as camera } from "../../../assets/icons/camera.svg";
import { ReactComponent as checkmark } from "../../../assets/icons/checkmark.svg";
import { ReactComponent as close } from "../../../assets/icons/close.svg";
import { ReactComponent as cross } from "../../../assets/icons/cross.svg";
import { ReactComponent as erase } from "../../../assets/icons/delete.svg";
import { ReactComponent as dots } from "../../../assets/icons/dots.svg";
import { ReactComponent as edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as error } from "../../../assets/icons/error.svg";
import { ReactComponent as exit } from "../../../assets/icons/exit.svg";
import { ReactComponent as leftArrow } from "../../../assets/icons/leftArrow.svg";
import { ReactComponent as menu } from "../../../assets/icons/menu.svg";
import { ReactComponent as noInstallation } from "../../../assets/icons/noInstallation.svg";
import { ReactComponent as noInstallationCard } from "../../../assets/icons/noInstallationCard.svg";
import { ReactComponent as plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as play } from "../../../assets/icons/play.svg";
import { ReactComponent as refresh } from "../../../assets/icons/refresh.svg";
import { ReactComponent as search } from "../../../assets/icons/search.svg";
import { ReactComponent as warning } from "../../../assets/icons/warning.svg";
import "./icon.scss";

interface IProps {
  name: string;
  size?: number;
  fill?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  style?: React.CSSProperties;
}
const icons: { [key: string]: FunctionComponent<SVGProps<SVGSVGElement>> } = {
  arrow,
  checkmark,
  search,
  error,
  warning,
  close,
  refresh,
  edit,
  erase,
  block,
  cross,
  plus,
  play,
  add,
  menu,
  exit,
  leftArrow,
  dots,
  camera,
  noInstallation,
  noInstallationCard
};

export const Icon: React.FC<IProps> = ({ name, className, size, ...props }) => {
  const IconComponent = icons[name];
  const sizeProps = size !== undefined ? { height: size, width: size } : {};

  return (
    <IconComponent
      className={classnames("icon", `icon-${name}`, className)}
      {...sizeProps}
      height={size}
      {...props}
    />
  );
};

export default Icon;

import React, { useEffect, useMemo, useState } from "react";
import { Body, BodyHighlight, Description, MainTitle } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { FieldValue } from "../../common/fieldValue/fieldValue.styles";
import { EditButton } from "../../common/button/EditButton.styles";
import { getOutlet } from "../../../store/outlets/selectors";
import { getOutletInfo } from "../../../store/outlets/actions";
import { getCurrentLanguage } from "../../../store/content/selectors";
import Routes from "../../../routes";
import CreateOutletForm from "../CreateOutletForm";
import { Link } from "../../common/link/link.styles";

interface IProps {
  outletId: string;
}

// TODO add d3 with timeformat
const WEEK_DAY_MAP: any = {
  "1": "Sun",
  "2": "Mon",
  "3": "Tue",
  "4": "Wed",
  "5": "Thu",
  "6": "Fri",
  "7": "Sat"
};

const Info: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { common, outletDetails: content } = useContent();
  const [isEdit, setIsEdit] = useState(false);
  const outlet = useSelector(getOutlet(outletId));
  const language = useSelector(getCurrentLanguage);

  // const sendInvitation = useCallback(() => {}, []);

  useEffect(() => {
    dispatch(getOutletInfo(outletId));
  }, [dispatch, outletId]);

  const openingHours = useMemo(() => {
    if (!outlet?.locationData?.openingHoursList?.length) {
      return [];
    }

    const aggregated: any[] = [];

    outlet?.locationData?.openingHoursList.forEach(l => {
      const existHour = aggregated.find(
        a => a.startTime === l.startTime && a.endTime === l.endTime
      );

      if (!existHour) {
        aggregated.push({ ...l, weekdays: [l.weekday] });
      } else {
        existHour.weekdays.push(l.weekday);
        existHour.weekdays.sort();
      }
    });

    return aggregated.map(a => {
      const start = a.startTime / 60;
      const end = a.endTime / 60;

      return {
        weekdays: a.weekdays.map((w: number) => WEEK_DAY_MAP[w.toString()]),
        startTime: `${start.toFixed(0).padStart(2, "0")}:00`,
        endTime: `${end.toFixed(0).padStart(2, "0")}:00`
      };
    });
  }, [outlet]);

  const outletDistributors = useMemo(
    () =>
      outlet?.distributors?.map((distributor, idx) => (
        <Link
          to={Routes.distributorDetails.replace(":id", distributor?.id)}
          title={distributor?.name || distributor?.id}
        >
          {distributor?.name}
          {idx < (outlet.distributors?.length ?? 0) - 1 ? ", " : ""}
        </Link>
      )),
    [outlet]
  );

  const outletPGs = useMemo(() => {
    const uniquePGs = Array.from(
      outlet?.distributors?.reduce((acc, distributor) => {
        acc.add(distributor.purchasingGroup);
        return acc;
      }, new Set<{ id: string; name: string }>()) ?? new Set<{ id: string; name: string }>()
    );

    return uniquePGs.map((pg, idx) => (
      <Link to={Routes.purchasingGroupDetails.replace(":id", pg?.id)} title={pg?.name || pg?.id}>
        {pg?.name}
        {idx < (uniquePGs.length ?? 0) - 1 ? ", " : ""}
      </Link>
    ));
  }, [outlet]);

  return (
    <div className="py-xxl">
      <div className="flex items-center justify-between">
        <MainTitle>Information</MainTitle>
        <div>
          <EditButton icon="Edit" onClick={() => setIsEdit(true)}>
            {content.outlet_details_edit_info}
          </EditButton>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_name}</BodyHighlight>
                <FieldValue>{outlet?.name}</FieldValue>
              </div>
              <div className="field items-start">
                <BodyHighlight>{common.common_contact}</BodyHighlight>
                <FieldValue disabled={!outlet?.contacts?.length}>
                  {outlet?.contacts?.length
                    ? outlet?.contacts.join(", ")
                    : common.common_no_information}
                </FieldValue>
                {/* <TertiaryButton */}
                {/*  className="mt-xs" */}
                {/*  disabled={!outlet?.contacts?.length || true} */}
                {/*  icon="Feedback" */}
                {/*  onClick={sendInvitation} */}
                {/* > */}
                {/*  Send invitation to Manager App */}
                {/* </TertiaryButton> */}
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{content.outlet_details_id}</BodyHighlight>
                <FieldValue>{outlet?.id}</FieldValue>
              </div>
              <div className="field">
                <BodyHighlight>{content.outlet_details_installation_id}</BodyHighlight>
                <FieldValue>{outlet?.installationId || "--"}</FieldValue>
              </div>
            </div>
          </div>
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_google_business}</BodyHighlight>
                <FieldValue disabled={!outlet?.locationData?.googleBusinessName}>
                  {outlet?.locationData?.googleBusinessName || common.common_no_information}
                </FieldValue>
              </div>
              <div className="field">
                <BodyHighlight>{content.outlet_details_type}</BodyHighlight>
                <FieldValue
                  disabled={
                    !outlet?.locationData?.carlsbergCategory && !outlet?.questionnaire?.category
                  }
                >
                  {outlet?.locationData?.carlsbergCategory ||
                    outlet?.questionnaire?.category ||
                    common.common_no_information}
                </FieldValue>
              </div>
              <div className="field">
                <BodyHighlight>{common.common_date_created}</BodyHighlight>
                <FieldValue>
                  {new Date(outlet?.createDateTime as string).toLocaleDateString(language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                  })}
                </FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_country}</BodyHighlight>
                <FieldValue>{outlet?.locationData?.country?.name}</FieldValue>
              </div>
              <div className="field">
                <BodyHighlight>{common.common_timezone}</BodyHighlight>
                <FieldValue disabled={!outlet?.locationData?.timezone}>
                  {outlet?.locationData?.timezone ? outlet.locationData?.timezone : "--"}
                </FieldValue>
              </div>
              <div className="field">
                <BodyHighlight>{common.common_address}</BodyHighlight>
                <FieldValue disabled={!outlet?.locationData?.address}>
                  {outlet?.locationData?.address
                    ? outlet.locationData?.address
                    : common.common_no_information}
                </FieldValue>
              </div>
              <div className="field">
                <BodyHighlight>{common.common_opening_hours}</BodyHighlight>
                <FieldValue disabled={!outlet?.locationData?.openingHoursList?.length}>
                  {openingHours.map((l, i) => (
                    <div className="flex flex-1 items-center mb-xs" key={i}>
                      <Body>{l.weekdays.join(", ")}</Body>
                      <div className="mx-md bg-grey-grey50 h-lg w-px" />
                      <Body>{l.startTime}</Body>
                      <Description className="mx-xs text-grey-grey100">
                        {common.common_to}
                      </Description>
                      <Body>{l.endTime}</Body>
                    </div>
                  ))}
                  {!openingHours.length && common.common_no_information}
                </FieldValue>
              </div>
            </div>
          </div>
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_purchasing_groups}</BodyHighlight>
                <FieldValue>{outletPGs}</FieldValue>
              </div>
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight>{common.common_distributors}</BodyHighlight>
                <FieldValue>{outletDistributors}</FieldValue>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateOutletForm outlet={outlet} visible={isEdit} onClose={() => setIsEdit(false)} />
    </div>
  );
};

export default Info;

import styled, { css } from "styled-components";
import { Link as BaseLink } from "react-router-dom";

export const Link = styled(BaseLink)(
  ({ theme }) => css`
    color: ${theme.colors.black};
    white-space: normal !important;

    :hover {
      text-decoration: underline;
    }
  `
);

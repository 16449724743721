import React, { useCallback, useRef, useState } from "react";
import { debounce } from "lodash";
import { StyledSearchInput } from "./search.styles";

interface IProps {
  initialValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const Search: React.FC<IProps> = ({ onChange, placeholder, initialValue, ...props }) => {
  const [searchValue, setSearchValue] = useState<string>(initialValue || "");

  const debouncedOnChange = useRef(debounce((value: string) => onChange?.(value), 500)).current;

  const handleSetSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      debouncedOnChange(value);
    },
    [debouncedOnChange]
  );

  const handleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => handleSetSearchValue(value),
    [handleSetSearchValue]
  );

  return (
    <StyledSearchInput
      onChange={handleChange}
      placeholder={placeholder}
      value={searchValue}
      {...props}
    />
  );
};

export default Search;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { useCheckWindowSize } from "@ddm-design-system/hooks";
import useContent from "../../../hooks/useContent";
import Badge from "../badge/Badge";
import CheckboxGroup, { ICheckbox } from "../checkbox_group/CheckboxGroup";
import { Grid } from "../grid/Grid";
import Select from "../select/Select";
import "./filter.scss";
import { StyledSearch } from "./filter.styles";
import { IBadgeCheckbox } from "../../ceLocations/CeLocations";

// tslint:disable-next-line: no-empty-interface
export interface IFilterCategory extends ICheckbox {}

export type FilterCategory<T extends ICheckbox> = T;

export interface IFilterState<T extends ICheckbox> {
  text: string;
  sort?: string;
  categories: Array<FilterCategory<T>>;
  badges?: IBadgeCheckbox[];
  lists?: any;
}

interface IProps<T extends ICheckbox> {
  textPlaceholder?: string;
  text?: string;
  sort?: string;
  sortOptions?: string[];
  categories?: Array<FilterCategory<T>>;
  badges?: IBadgeCheckbox[];
  lists?: any;
  onFilterChange: (state: IFilterState<T>) => void;
  children?: React.ReactNode;
}

function Filter<T extends ICheckbox>(props: IProps<T>) {
  const [text, setText] = useState(props.text || "");
  const [sort, setSort] = useState(props.sort);
  const [categories, selectCategories] = useState(props.categories);
  const [lists, selectLists] = useState(props.lists);
  const [badges, selectBadges] = useState(props.badges);
  const { textPlaceholder, sortOptions, onFilterChange, children } = props;
  const isMobile = useCheckWindowSize(EBreakpoints.MOBILE2);

  const { common: content } = useContent();

  useEffect(() => {
    setSort(props.sort);
  }, [props.sort]);

  useEffect(() => {
    if (
      lists &&
      (lists.some((l: any) => l.options.length === 0) || lists.length !== props.lists.length)
    ) {
      selectLists(props.lists);
    }
  }, [lists, props.lists]);

  useEffect(() => {
    selectBadges(props.badges);
  }, [props.badges]);

  useEffect(() => {
    onFilterChange({
      text,
      sort,
      categories: categories || [],
      badges: badges || [],
      lists: lists || []
    });
  }, [text, sort, lists, categories, badges]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="filter">
      <div className="filter-header">
        <StyledSearch
          placeholder={textPlaceholder}
          initialValue={text}
          onChange={searchValue => setText(searchValue)}
        />
        {sortOptions && (
          <div className={classnames("filter-sort", { "beverage-filter": lists })}>
            <p>{content.common_sort_by}</p>
            <Select
              className="filter-select"
              options={sortOptions}
              selectedOption={sort}
              onOptionSelected={option => setSort(option)}
            />
          </div>
        )}
        {badges && badges.length > 0 && (
          <div className="filter-categories">
            <div
              className="checkbox-group"
              style={{
                marginTop: "var(--space-unit-md)",
                flexBasis: "100%",
                padding: "var(--space-unit-xs) var(--space-unit-md)"
              }}
            >
              <span
                className="checkbox-group-label"
                style={{ marginRight: "var(--space-unit-xs)" }}
              >
                {content.common_filters}
              </span>
              <div className="flex flex-row flex-wrap gap-xs">
                {badges.map((b, i) => (
                  <div key={i} className="flex">
                    <Badge
                      {...b}
                      text={content[b.text as string] || b.text}
                      outline={!b.selected}
                      fill={b.selected}
                      onClick={() =>
                        selectBadges([
                          ...badges.slice(0, i),
                          { ...badges[i], selected: !badges[i].selected },
                          ...badges.slice(i + 1)
                        ])
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {categories && categories.length > 0 && (
          <div className="filter-categories">
            <CheckboxGroup
              title={content.common_filters}
              modal={isMobile}
              checkboxes={categories}
              onCheckboxesChange={checkboxes => selectCategories(checkboxes)}
              content={content}
            />
          </div>
        )}
        {lists && (
          <div
            className="checkbox-group checkbox-group-show-all"
            style={{
              marginTop: "var(--space-unit-md)",
              order: 3,
              flexBasis: "100%"
            }}
          >
            <p className="checkbox-group-title">{content.common_filters}</p>
            <div className="checkbox-container" style={{ display: "flex", flexDirection: "row" }}>
              {lists
                .filter((l: any) => l.options.length)
                .map((l: any, i: number) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className="filter-categories"
                    style={{ flexBasis: "initial", margin: "var(--space-unit-xs)" }}
                  >
                    <CheckboxGroup
                      title={l.title}
                      modal
                      checkboxes={l.options}
                      onCheckboxesChange={checkboxes => {
                        selectLists([
                          ...lists.slice(0, i),
                          { ...lists[i], options: checkboxes },
                          ...lists.slice(i + 1)
                        ]);
                      }}
                      content={content}
                      showAll
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      <div className="filter-items">
        <Grid>{children}</Grid>
      </div>
    </div>
  );
}

export default Filter;

import styled, { css } from "styled-components";
import { SearchInput, TextInputStyles } from "@ddm-design-system/textinput";

export const StyledSearchInput = styled(SearchInput)(
  ({ theme }) => css`
    border-color: ${theme.colors.grey.grey100};
    padding-right: ${theme.spaceUnit.xs};

    ${TextInputStyles.InputContainer} {
      padding: 0 0 0 ${theme.spaceUnit.xxs};
    }

    ${TextInputStyles.PrefixIcon} {
      margin-right: ${theme.spaceUnit.xxs};
    }

    ${TextInputStyles.Input} {
      padding-left: ${theme.spaceUnit.sm};
      text-overflow: ellipsis;
      ${theme.typography.description};
    }
  `
);

import {
  ASSOCIATE_TECHNICIAN_TO_OUTLET,
  CREATE_OUTLET,
  CREATE_OUTLET_ERROR,
  CREATE_OUTLET_SUCCESS,
  EDIT_OUTLET,
  EDIT_OUTLET_ERROR,
  EDIT_OUTLET_SUCCESS,
  RESET_OUTLET_FORM_STATE,
  EXPORT_DATA,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_OUTLET_INFO,
  GET_OUTLET_INFO_SUCCESS,
  GET_OUTLET_INSTALLATION,
  GET_OUTLET_INSTALLATION_SUCCESS,
  GET_OUTLET_LINES,
  GET_OUTLET_LINES_SUCCESS,
  GET_OUTLET_NOTES,
  GET_OUTLET_NOTES_SUCCESS,
  GET_OUTLET_TECHNICIANS,
  GET_OUTLET_TECHNICIANS_SUCCESS,
  GET_OUTLET_UNITS,
  GET_OUTLET_UNITS_SUCCESS,
  GET_OUTLETS_ERROR,
  GET_OUTLETS_REQUEST,
  GET_OUTLETS_SUCCESS,
  IAssociatedTechnician,
  IAssociateTechnicianToOutlet,
  ICreateOutletAction,
  ICreateOutletErrorAction,
  IEditOutletAction,
  IEditOutletErrorAction,
  ICreateOutletObject,
  ICreateOutletSuccessAction,
  IEditOutletSuccessAction,
  IExportData,
  IGetCountriesAction,
  IGetCountriesSuccessAction,
  IGetOutletInfoAction,
  IGetOutletInfoSuccessAction,
  IGetOutletInstallationAction,
  IGetOutletInstallationSuccessAction,
  IGetOutletLinesAction,
  IGetOutletLinesSuccessAction,
  IGetOutletNotesAction,
  IGetOutletNotesSuccessAction,
  IGetOutletsErrorAction,
  IGetOutletsRequestAction,
  IGetOutletsSuccessAction,
  IGetOutletTechniciansAction,
  IGetOutletTechniciansSuccessAction,
  IGetOutletUnitsAction,
  IGetOutletUnitsSuccessAction,
  IResetOutletFormAction,
  IInstallation,
  ILine,
  ILocation,
  INote,
  IRemoveAssociation,
  IResetBeerDrive,
  ISaveOutletDescription,
  IUnit,
  REMOVE_ASSOCIATION,
  RESET_BEER_DRIVE,
  SAVE_OUTLET_DESCRIPTION,
  EXPORT_ALL_DATA,
  IExportAllData
} from "./types";
import { ICountry } from "../beverages/types";

export const getOutlets = (): IGetOutletsRequestAction => ({
  type: GET_OUTLETS_REQUEST
});

export const getOutletsSuccess = (payload: ILocation[]): IGetOutletsSuccessAction => ({
  type: GET_OUTLETS_SUCCESS,
  payload
});

export const getOutletsError = (): IGetOutletsErrorAction => ({
  type: GET_OUTLETS_ERROR
});

export const getOutletInfo = (outletId: string): IGetOutletInfoAction => ({
  type: GET_OUTLET_INFO,
  payload: outletId
});

export const getOutletInfoSuccess = (outlet: ILocation): IGetOutletInfoSuccessAction => ({
  type: GET_OUTLET_INFO_SUCCESS,
  payload: outlet
});

export const getOutletLines = (outletId: string): IGetOutletLinesAction => ({
  type: GET_OUTLET_LINES,
  payload: outletId
});

export const getOutletLinesSuccess = (lines: {
  id: string;
  lines: ILine[];
}): IGetOutletLinesSuccessAction => ({
  type: GET_OUTLET_LINES_SUCCESS,
  payload: lines
});

export const getOutletInstallation = (outletId: string): IGetOutletInstallationAction => ({
  type: GET_OUTLET_INSTALLATION,
  payload: outletId
});

export const getOutletInstallationSuccess = (payload: {
  id: string;
  installation: IInstallation;
}): IGetOutletInstallationSuccessAction => ({
  type: GET_OUTLET_INSTALLATION_SUCCESS,
  payload
});

export const getOutletUnits = (outletId: string): IGetOutletUnitsAction => ({
  type: GET_OUTLET_UNITS,
  payload: outletId
});

export const getOutletUnitsSuccess = (units: {
  id: string;
  units: IUnit[];
}): IGetOutletUnitsSuccessAction => ({
  type: GET_OUTLET_UNITS_SUCCESS,
  payload: units
});

export const getOutletTechnicians = (outletId: string): IGetOutletTechniciansAction => ({
  type: GET_OUTLET_TECHNICIANS,
  payload: outletId
});

export const getOutletTechniciansSuccess = (technicians: {
  id: string;
  associatedTechnicians: IAssociatedTechnician[];
}): IGetOutletTechniciansSuccessAction => ({
  type: GET_OUTLET_TECHNICIANS_SUCCESS,
  payload: technicians
});

export const getOutletNotes = (outletId: string): IGetOutletNotesAction => ({
  type: GET_OUTLET_NOTES,
  payload: outletId
});

export const getOutletNotesSuccess = (notes: {
  id: string;
  notes: INote;
}): IGetOutletNotesSuccessAction => ({
  type: GET_OUTLET_NOTES_SUCCESS,
  payload: notes
});

export const associateTechnicianToOutlet = (
  technicianId: string,
  outletId: string,
  refreshTechnician = false,
  refreshOutlet = false
): IAssociateTechnicianToOutlet => ({
  type: ASSOCIATE_TECHNICIAN_TO_OUTLET,
  payload: {
    technicianId,
    outletId,
    refreshTechnician,
    refreshOutlet
  }
});

export const removeAssociation = (
  technicianId: string,
  outletId: string,
  refreshTechnician = false,
  refreshOutlet = false
): IRemoveAssociation => ({
  type: REMOVE_ASSOCIATION,
  payload: {
    technicianId,
    outletId,
    refreshTechnician,
    refreshOutlet
  }
});

export const saveOutletDescription = (
  locationId: string,
  installationId: string,
  description: string
): ISaveOutletDescription => ({
  type: SAVE_OUTLET_DESCRIPTION,
  payload: {
    locationId,
    installationId,
    description
  }
});

export const resetBeerDrive = (outletId: string, beerDriveId: string): IResetBeerDrive => ({
  type: RESET_BEER_DRIVE,
  payload: {
    beerDriveId,
    outletId
  }
});

export const exportData = (
  outletId: string,
  outletName: string,
  start: number,
  end: number
): IExportData => ({
  type: EXPORT_DATA,
  payload: {
    outletId,
    outletName,
    start,
    end
  }
});

export const exportAllData = (x10: boolean, start: number, end: number): IExportAllData => ({
  type: EXPORT_ALL_DATA,
  payload: {
    x10,
    start,
    end
  }
});

export const getCountries = (): IGetCountriesAction => ({
  type: GET_COUNTRIES
});

export const getCountriesSuccess = (payload: ICountry[]): IGetCountriesSuccessAction => ({
  type: GET_COUNTRIES_SUCCESS,
  payload
});

export const createOutlet = (payload: ICreateOutletObject): ICreateOutletAction => ({
  type: CREATE_OUTLET,
  payload
});

export const createOutletSuccess = (): ICreateOutletSuccessAction => ({
  type: CREATE_OUTLET_SUCCESS
});

export const createOutletError = (): ICreateOutletErrorAction => ({
  type: CREATE_OUTLET_ERROR
});

export const editOutlet = (
  outletId: string,
  outletObj: ICreateOutletObject
): IEditOutletAction => ({
  type: EDIT_OUTLET,
  payload: {
    outletId,
    outletObj
  }
});

export const editOutletSuccess = (): IEditOutletSuccessAction => ({
  type: EDIT_OUTLET_SUCCESS
});

export const editOutletError = (): IEditOutletErrorAction => ({
  type: EDIT_OUTLET_ERROR
});

export const resetOutletFormState = (): IResetOutletFormAction => ({
  type: RESET_OUTLET_FORM_STATE
});

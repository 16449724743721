import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BodyHighlight,
  Description,
  DescriptionMedium,
  Label
} from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import Routes from "../../../routes";
import { Field, FieldContainer, FieldSection } from "./units.styles";
import { Link } from "../../common/link/link.styles";
import { FieldValue } from "../../common/fieldValue/fieldValue.styles";
import AnchorContainer from "../../common/anchor_container/AnchorContainer";
import { getOutlet } from "../../../store/outlets/selectors";
import { getOutletUnits } from "../../../store/outlets/actions";
import { getCurrentLanguage } from "../../../store/content/selectors";
import DataTable from "../../common/data_table/DataTable";
import { IUnit } from "../../../store/outlets/types";
import { hexToDec, outdatedFirmwareVersion } from "../../../helpers";

interface IProps {
  outletId: string;
}

const Units: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { common, outletDetails: content } = useContent();
  const language = useSelector(getCurrentLanguage);
  const outlet = useSelector(getOutlet(outletId));
  const [expandedId, setExpandedId] = useState("");

  useEffect(() => {
    const id = window.location.hash?.slice(1);

    if (id) {
      setExpandedId(id);
    }
  }, []);

  const formatPlacement = useCallback(
    (unit: IUnit) => (
      <Link
        to={Routes.outletComponentDetails.replace(":id", outletId).replace(":cid", unit.id)}
        title={`${common[`common_${unit.type}`]} ${unit.position}`}
      >
        {common[`common_${unit.type}`]} {unit.position}
      </Link>
    ),
    [common, outletId]
  );

  const formatDate = useCallback(
    (date: string, showHours = true) => (
      <span title={date}>
        {date
          ? new Date(date).toLocaleDateString(language, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              ...(showHours
                ? {
                    hour: "2-digit",
                    minute: "2-digit"
                  }
                : {})
            })
          : "--"}
      </span>
    ),
    [language]
  );

  const renderSecondaryUnit = useCallback(
    unit => {
      return (
        <div className="flex" key={unit.thingId}>
          <div className="flex-1">
            <FieldSection>
              <Field className="!pt-lg">
                <DescriptionMedium>{common.common_placement}</DescriptionMedium>
                <Description className="pt-xs">{formatPlacement(unit)}</Description>
              </Field>
              <Field>
                <DescriptionMedium>{content.outlet_details_thing_id}</DescriptionMedium>
                <Description className="pt-xs">{unit.thingId}</Description>
              </Field>
            </FieldSection>
            <FieldContainer>
              <FieldSection>
                <Field>
                  <DescriptionMedium>{common.common_serial_number}</DescriptionMedium>
                  <Description className="pt-xs">
                    {unit.boardSerialNumber ? hexToDec(unit.boardSerialNumber) : "--"}
                    {unit.boardSerialNumber && (
                      <Label className="text-grey-grey100 ml-xs">
                        ({unit.boardSerialNumber || "--"})
                      </Label>
                    )}
                  </Description>
                </Field>
                <Field>
                  <DescriptionMedium>{content.outlet_details_last_heartbeat}</DescriptionMedium>
                  <Description className="pt-xs">{formatDate(unit.lastHeartbeat)}</Description>
                </Field>
              </FieldSection>
              <FieldSection>
                <Field>
                  <DescriptionMedium>{common.common_date_created}</DescriptionMedium>
                  <Description className="pt-xs">
                    {formatDate(unit.createDateTime, false)}
                  </Description>
                </Field>
              </FieldSection>
            </FieldContainer>
          </div>
        </div>
      );
    },
    [formatDate, formatPlacement, common, content]
  );

  const ExpandedSecondary = useCallback(
    ({ data }) => {
      return <div className="bg-grey-grey25 pb-lg px-lg">{renderSecondaryUnit(data)}</div>;
    },
    [renderSecondaryUnit]
  );

  const renderPrimaryUnit = useCallback(
    unit => {
      const columns = [
        {
          name: common.common_name,
          selector: (row: IUnit) => row.name,
          sortable: true
        },
        {
          name: content.outlet_details_thing_id,
          selector: (row: IUnit) => row.thingId,
          sortable: true
        },
        {
          format: formatPlacement,
          name: common.common_placement,
          selector: (row: IUnit) => row.type,
          sortable: true
        }
      ];

      const secondaryUnits =
        outlet?.units
          ?.filter(u => u.primaryUnitId === unit.id)
          .map(u => (u.id === expandedId ? { expanded: true, ...u } : u)) || [];

      return (
        <div className="flex" key={unit.id}>
          <div className="flex-1">
            <FieldSection>
              <Field>
                <BodyHighlight>{common.common_placement}</BodyHighlight>
                <FieldValue>{formatPlacement(unit)}</FieldValue>
              </Field>
              <Field>
                <BodyHighlight>{content.outlet_details_thing_id}</BodyHighlight>
                <FieldValue>{unit.thingId}</FieldValue>
              </Field>
            </FieldSection>
            <FieldContainer>
              <FieldSection>
                <Field>
                  <BodyHighlight>{common.common_serial_number}</BodyHighlight>
                  <FieldValue>
                    {unit.boardSerialNumber ? hexToDec(unit.boardSerialNumber) : "--"}
                    {unit.boardSerialNumber && (
                      <Label className="text-grey-grey100 ml-xs">
                        ({unit.boardSerialNumber || "--"})
                      </Label>
                    )}
                  </FieldValue>
                </Field>
                <Field>
                  <BodyHighlight>{content.outlet_details_last_heartbeat}</BodyHighlight>
                  <FieldValue>{formatDate(unit.lastHeartbeat)}</FieldValue>
                </Field>
                <Field>
                  <BodyHighlight>{content.outlet_details_last_rssi}</BodyHighlight>
                  <FieldValue>{unit.lastRssi}</FieldValue>
                </Field>
              </FieldSection>
              <FieldSection>
                <Field>
                  <BodyHighlight>{content.outlet_details_firmware_version}</BodyHighlight>
                  <FieldValue>
                    {unit.firmwareVersion || "--"}
                    {unit.latestFirmwareVersion &&
                      outdatedFirmwareVersion(unit.firmwareVersion, unit.latestFirmwareVersion) && (
                        <Label className="ml-xs text-alert-alert100">
                          {`${common.common_outdated} | ${common.common_latest}: ${unit.latestFirmwareVersion}`}
                        </Label>
                      )}
                  </FieldValue>
                </Field>
                <Field>
                  <BodyHighlight>{content.outlet_details_last_bootup}</BodyHighlight>
                  <FieldValue>{formatDate(unit.lastBootup)}</FieldValue>
                </Field>
              </FieldSection>
            </FieldContainer>

            <FieldContainer>
              <FieldSection>
                <Field>
                  <BodyHighlight>{common.common_date_created}</BodyHighlight>
                  <FieldValue>{formatDate(unit.createDateTime, false)}</FieldValue>
                </Field>
              </FieldSection>
            </FieldContainer>
            <FieldContainer>
              <FieldSection>
                <Field>
                  <BodyHighlight>{content.outlet_details_secondary_units_associated}</BodyHighlight>
                  <FieldValue>
                    <DataTable
                      columns={columns}
                      data={secondaryUnits}
                      expandableRows
                      expandableRowsComponent={ExpandedSecondary}
                      expandableRowExpanded={(row: any) => {
                        if (row.expanded) {
                          setTimeout(() => {
                            document.getElementById(`row-${row.id}`)?.scrollIntoView();
                          }, 100);
                        }

                        return row.expanded;
                      }}
                      title=""
                      enableActions={false}
                    />
                  </FieldValue>
                </Field>
              </FieldSection>
            </FieldContainer>
          </div>
        </div>
      );
    },
    [formatPlacement, outlet, formatDate, ExpandedSecondary, expandedId, common, content]
  );
  const list = useMemo(() => {
    if (!outlet || !outlet?.units) {
      return [];
    }

    return outlet.units
      .filter(u => !u.primaryUnitId)
      .map((u: any) => ({
        title: u.name,
        href: u.id,
        renderContent: () => (!u.primaryUnitId ? renderPrimaryUnit(u) : renderSecondaryUnit(u))
      }));
  }, [outlet, renderPrimaryUnit, renderSecondaryUnit]);

  useEffect(() => {
    dispatch(getOutletUnits(outletId));
  }, [dispatch, outletId]);

  return (
    <div>
      <AnchorContainer
        list={list}
        emptyString={content.outlet_details_no_units}
        loading={!outlet?.units}
      />
    </div>
  );
};

export default Units;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { useDispatch } from "react-redux";
import CeLocationDetailEditItem from "./CeLocationDetailEditItem";
import { EditItem } from "./CeLocationDetailEditItem.styles";
import { ICELocation, ICELocationAddressEdit } from "../../../store/ceLocations/types";
import useContent from "../../../hooks/useContent";
import { StyledModal, StyledModalBody, StyledTextInput } from "./CeLocationDetailEdit.styles";
import { putCeLocationAddress } from "../../../store/ceLocations/actions";

interface ICeLocationFlexDetailEditProps {
  onClose: () => any;
  ceLocation: ICELocation;
}

const CeLocationFlexDetailEdit: React.FC<ICeLocationFlexDetailEditProps> = ({
  ceLocation,
  onClose
}) => {
  const { ceLocations: content, common } = useContent();
  const dispatch = useDispatch();

  const modalEl = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.getElementById("modal-root")?.appendChild(modalEl);
  }, [modalEl]);

  const [location, setLocation] = useState<ICELocationAddressEdit>(ceLocation.ceLocation);
  const handleSave = useCallback(() => {
    dispatch(putCeLocationAddress(location.id, location));
    onClose();
  }, [location, onClose, dispatch]);

  const children = (
    <StyledModal isOpen onRequestClose={onClose}>
      <ModalHeader headerTitle={content.celocation_edit_location} />
      <StyledModalBody>
        <CeLocationDetailEditItem title={`${content.celocation_address_title}*`}>
          <EditItem title={`${content.celocation_name}`}>
            <StyledTextInput
              placeholder={content.celocation_name}
              value={location.name}
              onChange={e => setLocation({ ...location, name: e.currentTarget.value })}
            />
          </EditItem>
          <EditItem title={`${content.celocation_address}`}>
            <StyledTextInput
              required
              placeholder={content.celocation_address}
              value={location.address}
              onChange={e => setLocation({ ...location, address: e.currentTarget.value })}
            />
          </EditItem>
          <EditItem title={`${content.celocation_city}`}>
            <StyledTextInput
              placeholder={content.celocation_city}
              value={location.city ?? ""}
              onChange={e => setLocation({ ...location, city: e.currentTarget.value })}
            />
          </EditItem>
          <EditItem title={`${content.celocation_zip}`}>
            <StyledTextInput
              placeholder={content.celocation_zip}
              value={location.zip ?? ""}
              onChange={e => setLocation({ ...location, zip: e.currentTarget.value })}
            />
          </EditItem>
        </CeLocationDetailEditItem>
      </StyledModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onClose}>{common.common_cancel}</SecondaryButton>
        <PrimaryButton disabled={!location.address} onClick={handleSave}>
          {common.common_save}
        </PrimaryButton>
      </ModalFooter>
    </StyledModal>
  );

  return createPortal(children, modalEl);
};

export default CeLocationFlexDetailEdit;

import React, { useCallback, useState } from "react";
import { Flipped } from "react-flip-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ButtonText } from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import { setCurrentLanguage } from "../../../store/content/actions";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { ILanguageInfo } from "../../../store/content/types";
import {
  ExtendedFlagContainer,
  Flag,
  FlagChoices,
  FlagElementContainer,
  FlagContainer
} from "./languagePicker.styles";

interface IProps {
  expandLeft?: boolean;
  isExtended?: boolean;
}

export const LanguagePicker: React.FC<IProps> = ({ expandLeft, isExtended }) => {
  const dispatch = useDispatch();
  const { languageInfo: languages } = useContent();
  const currentLanguageCode = useSelector(getCurrentLanguage);
  const [isOpen, setIsOpen] = useState(false);

  let sortedLanguages = [...languages].sort(
    (languageA: ILanguageInfo, languageB: ILanguageInfo) => {
      if (languageA.code === process.env.REACT_APP_DEFAULT_LANG) {
        return -1;
      }

      if (languageB.code === process.env.REACT_APP_DEFAULT_LANG) {
        return 1;
      }

      if (languageA.name < languageB.name) {
        return -1;
      }

      return 1;
    }
  );

  if (!isExtended) {
    sortedLanguages = sortedLanguages.filter(
      (language: ILanguageInfo) => language.code !== currentLanguageCode
    );
  }

  const currentLanguage = languages.find(
    (language: ILanguageInfo) => language.code === currentLanguageCode
  );

  const currentLanguageCountry = currentLanguage?.country.toLowerCase();

  const setLanguage = useCallback(
    (language: ILanguageInfo) => () => {
      setIsOpen(false);
      dispatch(setCurrentLanguage(language));
    },
    [dispatch]
  );

  return isExtended ? (
    <ExtendedFlagContainer>
      {sortedLanguages.map((lang: ILanguageInfo) => (
        <FlagElementContainer
          key={lang.code}
          onClick={setLanguage(lang)}
          selected={lang.code === currentLanguageCode}
        >
          <Flag country={lang.country.toLowerCase()} />
          <ButtonText>{lang.name}</ButtonText>
        </FlagElementContainer>
      ))}
    </ExtendedFlagContainer>
  ) : (
    <FlagContainer>
      <FlagChoices
        expandLeft={expandLeft}
        flipKey={isOpen}
        isHidden={!isOpen}
        spring={{ stiffness: 309, damping: 40 }}
      >
        {sortedLanguages.map((lang: ILanguageInfo) => (
          <Flipped key={lang.code} flipId={`lang-${lang.code}`}>
            <Flag country={lang.country.toLowerCase()} onClick={setLanguage(lang)} />
          </Flipped>
        ))}
      </FlagChoices>
      <Flag country={currentLanguageCountry} onClick={() => setIsOpen(!isOpen)} />
    </FlagContainer>
  );
};

export default LanguagePicker;

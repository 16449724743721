import React from "react";
import useContent from "../../hooks/useContent";
import { IPurchasingGroup } from "../../store/purchasingGroups/types";
import DistributorList from "../distributors/DistributorList";

interface IProps {
  purchasingGroup?: IPurchasingGroup;
}

const PurchasingGroupDistributors: React.FC<IProps> = ({ purchasingGroup }) => {
  const { common } = useContent();
  const distributors = purchasingGroup?.distributors;

  return (
    <div className="py-xxl">
      <DistributorList
        distributors={distributors ?? []}
        searchSelector="name"
        hideGroup
        title={common.common_distributors}
      />
    </div>
  );
};

export default PurchasingGroupDistributors;

import { createSelector } from "reselect";
import { getCurrentContent } from "../content/selectors";
import { getIsLoadingOutlets, getOutlets } from "../outlets/selectors";
import {
  getIsLoadingOutlets as getIsLoadingExtra10Outlets,
  getExtra10Outlets
} from "../extra10outlets/selectors";
import { EHealthStatusType, IHealthStatus, IHealthStatusState } from "./types";
import { IAppState } from "..";
import { ILocation } from "../outlets/types";
import { IExtra10Location } from "../extra10outlets/types";

const getHealthStatus = (state: IAppState) => state.healthStatus;

export const getIsLoadingHealthStatus = createSelector(
  getHealthStatus,
  getIsLoadingOutlets,
  ({ loading }: IHealthStatusState, isLoadingOutlets: boolean) => loading || isLoadingOutlets
);

export const getHealthStatuses = createSelector(
  getCurrentContent,
  getHealthStatus,
  getOutlets,
  (currentContent, { healthStatuses }: IHealthStatusState, locations: ILocation[]) => {
    const { common = {}, healthMonitor: content = {} } = currentContent || {};

    return healthStatuses.map((healthStatus: IHealthStatus): IHealthStatus => {
      const { name: locationName = "" } =
        locations.find((location: ILocation) => location.id === healthStatus.locationId) || {};
      const { position: targetPosition } = healthStatus.customFields;

      let info: string = content[`healthMonitor_${healthStatus.type}_info`] || healthStatus.type;

      if (healthStatus.type === EHealthStatusType.COMPRESSOR_LINE_LEAK) {
        const { componentsPositions = [] } = healthStatus.customFields;

        info = info?.replace("%ID%", componentsPositions.join(", "));
      }

      const targetContent =
        `${common[`common_${healthStatus.target}`] || healthStatus.target}${
          targetPosition ? ` ${targetPosition}` : ""
        }` || healthStatus.target;

      return {
        ...healthStatus,
        locationName,
        info,
        levelContent: content[`healthMonitor_level_${healthStatus.level}`] || healthStatus.level,
        targetContent,
        typeContent: content[`healthMonitor_${healthStatus.type}`] || healthStatus.type || "--"
      };
    });
  }
);

export const getHealthStatusesByOutlet = (outletId: string) =>
  createSelector(getHealthStatuses, (healthStatuses: IHealthStatus[]) => {
    return healthStatuses.filter(h => h.locationId === outletId);
  });

// X10 health status selectors

const getX10HealthStatus = (state: IAppState) => state.healthStatus;

export const getIsX10LoadingHealthStatus = createSelector(
  getX10HealthStatus,
  getIsLoadingExtra10Outlets,
  ({ loading }: IHealthStatusState, isLoadingOutlets: boolean) => loading || isLoadingOutlets
);

export const getX10HealthStatuses = createSelector(
  getCurrentContent,
  getX10HealthStatus,
  getExtra10Outlets,
  (currentContent, { x10HealthStatuses }: IHealthStatusState, locations: IExtra10Location[]) => {
    const { common = {}, healthMonitor: content = {} } = currentContent || {};

    return x10HealthStatuses.map((healthStatus: IHealthStatus): IHealthStatus => {
      const { name: locationName = "" } =
        locations.find((location: IExtra10Location) => location.id === healthStatus.locationId) ||
        {};
      const { position: targetPosition } = healthStatus.customFields;

      let info: string = content[`healthMonitor_${healthStatus.type}_info`] || healthStatus.type;

      if (healthStatus.type === EHealthStatusType.COMPRESSOR_LINE_LEAK) {
        const { componentsPositions = [] } = healthStatus.customFields;

        info = info?.replace("%ID%", componentsPositions.join(", "));
      }

      const targetContent =
        `${common[`common_${healthStatus.target}`]}${targetPosition ? ` ${targetPosition}` : ""}` ||
        healthStatus.target;

      return {
        ...healthStatus,
        locationName,
        info,
        levelContent: content[`healthMonitor_level_${healthStatus.level}`] || healthStatus.level,
        targetContent,
        typeContent: content[`healthMonitor_${healthStatus.type}`] || healthStatus.type || "--"
      };
    });
  }
);

export const getX10HealthStatusesByOutlet = (outletId: string) =>
  createSelector(getX10HealthStatuses, (healthStatuses: IHealthStatus[]) => {
    return healthStatuses.filter(h => h.locationId === outletId);
  });

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Body, Label, Subtitle } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import { SearchInput } from "@ddm-design-system/textinput";
import useContent from "../../../hooks/useContent";
import SidebarModal from "../../common/sidebar_modal/SidebarModal";
import { CloseIcon } from "../../common/icon/CloseIcon.styles";
import { IAssociatedTechnician } from "../../../store/outlets/types";
import { getTechnicians } from "../../../store/technicians/selectors";
import { getTechnicians as getTechniciansAction } from "../../../store/technicians/actions";
import { StyledPrimaryButton } from "./associatedTechnicians.styles";
import { associateTechnicianToOutlet } from "../../../store/outlets/actions";

interface IProps {
  visible: boolean;
  onClose: () => void;
  outletId: string;
  outletName: string;
  associatedTechnicians?: IAssociatedTechnician[];
}

const AssociateTechnicianSidebar: React.FC<IProps> = ({
  associatedTechnicians,
  outletId,
  outletName,
  visible,
  onClose
}) => {
  const { outletDetails: content, common } = useContent();
  const dispatch = useDispatch();
  const allTechnicians = useSelector(getTechnicians);
  const [searchText, setSearchText] = useState("");
  const [addingTech, setAddingTech] = useState("");

  useEffect(() => {
    if (visible) {
      dispatch(getTechniciansAction());
    }
  }, [dispatch, visible]);

  const filteredTechnicians = useMemo(() => {
    return allTechnicians
      .filter(
        t =>
          t.name?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()) ||
          t.email?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase())
      )
      .map(t => ({
        id: t.id,
        name: t.name,
        email: t.email,
        associated: associatedTechnicians?.map(l => l.id).includes(t.id)
      }))
      .sort((a, b) => (a.name?.toLocaleLowerCase() < b.name?.toLocaleLowerCase() ? -1 : 1));
  }, [searchText, allTechnicians, associatedTechnicians]);

  const associateTechnicianAux = useCallback(
    (id: string) => {
      dispatch(associateTechnicianToOutlet(id, outletId, false, true));
    },
    [dispatch, outletId]
  );

  return (
    <SidebarModal visible={visible} onClose={onClose}>
      <div className="flex flex-col h-full">
        <div className="h-[75px]">
          <div className="flex justify-between items-center">
            <Subtitle>{content.outlet_details_associate_technicians}</Subtitle>
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        <div className="pt-md">
          <Body>
            {content.outlet_details_associate_technicians_to} <strong>{outletName}</strong>
          </Body>
          <SearchInput
            className="mt-lg mb-md"
            placeholder={common.common_search_name_or_email}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
        <div>
          {filteredTechnicians.map(t => (
            <div
              key={t.id}
              className="flex items-center justify-between py-md border-b border-solid border-grey-grey25"
            >
              <div className="flex flex-col">
                <Body>{t.name}</Body>
                <Label className="text-grey-grey100">{t.email}</Label>
              </div>
              <StyledPrimaryButton
                disabled={t.associated}
                onClick={() => {
                  setAddingTech(t.id);
                  associateTechnicianAux(t.id);
                }}
              >
                {t.associated
                  ? common.common_added
                  : addingTech === t.id
                  ? `${common.common_adding}...`
                  : common.common_add}
              </StyledPrimaryButton>
            </div>
          ))}
        </div>
      </div>
    </SidebarModal>
  );
};

export default AssociateTechnicianSidebar;

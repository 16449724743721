import { FormControl, Input, InputLabel } from "@material-ui/core";
import classnames from "classnames";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "@ddm-design-system/hooks";
import { AnalyticsContext } from "../../../services/analytics";
import Icon from "../icon/Icon";
import "./select.scss";

interface IObjectOption {
  id: string;
  text: string;
  disabled?: boolean;
}

type SelectOption = string | IObjectOption;

export interface IProps<T extends SelectOption> {
  options: T[];
  onOptionSelected: (option?: T) => void;
  selectedOption?: T;
  visible?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  search?: boolean;
  style?: React.CSSProperties;
  onShow?: () => void;
  disabled?: boolean;
}

function isIObjectOption(option: SelectOption): option is IObjectOption {
  return (option as IObjectOption) && (option as IObjectOption).id !== undefined;
}
const optionId = (option: SelectOption) => (isIObjectOption(option) ? option.id : option);
const optionText = (option: SelectOption) => (isIObjectOption(option) ? option.text : option);
const optionDisabled = (option: SelectOption) =>
  isIObjectOption(option) ? option.disabled : false;

function Select<T extends SelectOption>(propsT: IProps<T>) {
  const {
    selectedOption,
    onOptionSelected,
    visible = false,
    label,
    placeholder,
    className,
    search,
    style,
    onShow,
    disabled,
    ...props
  } = propsT;
  const analytics = useContext(AnalyticsContext);
  const { options } = props;
  const [show, setShow] = useState(visible);
  const [text, setText] = useState<string>(selectedOption ? optionText(selectedOption) || "" : "");
  const toggleShow = () => setShow(!show);
  const isValidOption = (option: T) =>
    options.find(o => optionId(o) === optionId(option)) !== undefined;

  const handleOptionClick = (option?: T) => {
    setText(option ? optionText(option) : "");
    onOptionSelected(option && isValidOption(option) ? option : undefined);
    setShow(false);
  };
  const ref = useRef(null);

  useOnClickOutside([ref], () => {
    if (!text) {
      onOptionSelected(undefined);
    } else if (selectedOption) {
      setText(optionText(selectedOption));
    }

    setShow(false);
  });
  useEffect(() => {
    if (show && onShow) {
      onShow();
    }
  }, [onShow, show]);

  const inputRef = useRef<HTMLInputElement>();
  const [suggestionIndex, setSuggestionIndex] = useState(0);

  useEffect(() => {
    setSuggestionIndex(0);
  }, [text]);
  useEffect(() => {
    setText(selectedOption ? optionText(selectedOption) || "" : "");
  }, [selectedOption]);

  const availableOptions = useMemo(
    () =>
      search
        ? options.filter(o => !text || optionText(o).toLowerCase().includes(text.toLowerCase()))
        : options.filter(o => o !== selectedOption),
    [options, selectedOption, text, search]
  );
  const suggestion = useMemo(
    () => (text ? availableOptions[suggestionIndex] : undefined),
    [text, availableOptions, suggestionIndex]
  );

  useEffect(() => {
    if (search && text && availableOptions.length === 0) {
      analytics.logEvent("SEARCH_NOT_FOUND", text);
    }
  }, [analytics, availableOptions, search, text]);

  const handleKey = (key: number) => {
    switch (key) {
      case 13: // enter
        handleOptionClick(suggestion);

        if (inputRef.current) {
          inputRef.current.blur();
        }

        break;
      case 39: // right
        setText(suggestion ? optionText(suggestion) : text);
        break;
      case 40: // down
        setSuggestionIndex((suggestionIndex + 1) % availableOptions.length);
        break;
      case 38: // up
        const index = suggestionIndex === 0 ? availableOptions.length - 1 : suggestionIndex - 1;

        setSuggestionIndex(index);
        break;
      default:
        break;
    }
  };
  const suggestionText = suggestion ? optionText(suggestion).slice(text.length) : "";
  const searchHeader =
    show || text ? (
      <>
        <FormControl className="select-search-textfield">
          <InputLabel
            disabled={disabled}
            className="select-search-label"
            htmlFor="select-search-input"
            shrink
          >
            {label}
          </InputLabel>
          <Input
            ref={inputRef}
            id="select-search-input"
            className="select-search-input"
            autoFocus
            autoComplete="off"
            value={text}
            disableUnderline
            onChange={event => setText(event.target.value)}
            onKeyUp={event => handleKey(event.keyCode)}
            disabled={disabled}
          />
          {!disabled && show && (
            <div
              className="select-search-text"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }}
            >
              <span>{text}</span>
              <span className="select-search-suggestion">{suggestionText}</span>
            </div>
          )}
        </FormControl>
        <Icon name="search" />
      </>
    ) : (
      <>
        <div className="select-placeholder">{placeholder}</div>
        <Icon name="search" />
      </>
    );
  const defaultHeader = (
    <>
      {selectedOption ? (
        <div className="select-title">{selectedOption}</div>
      ) : (
        <div className="select-placeholder">{placeholder}</div>
      )}
      <Icon name="arrow" />
    </>
  );
  const header = search ? searchHeader : defaultHeader;

  return (
    <div
      className={classnames(
        "select",
        className,
        disabled && "disabled",
        !disabled && show && "active",
        availableOptions.length > 0 && "options",
        search && "select-search"
      )}
      ref={ref}
      style={style}
    >
      <div
        className="select-content"
        onClick={() => !(search && !disabled && show) && toggleShow()}
      >
        <div className={classnames("select-header", selectedOption && "selected")}>{header}</div>
        {availableOptions.length > 0 && (
          <div className={classnames("select-options", !disabled && show && "visible")}>
            {availableOptions.map(option => (
              <div
                className={classnames(
                  "select-options-item",
                  search && option === suggestion && "suggested",
                  option === selectedOption && "selected",
                  optionDisabled(option) && "disabled"
                )}
                key={optionId(option)}
                onClick={() => handleOptionClick(option)}
              >
                {optionText(option)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Select;

import produce from "immer";
import { Reducer } from "redux";
import {
  DELETE_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  IUsersState,
  UsersActionTypes
} from "./types";

const initialState: IUsersState = {
  users: [],
  isLoadingUser: false,
  isLoadingUsers: false,
  userWasDeleted: false
};
const reducer: Reducer<IUsersState, UsersActionTypes> = (
  state = initialState,
  action: UsersActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_USERS_REQUEST:
        draft.userWasDeleted = false;
        draft.isLoadingUsers = true;
        break;
      case GET_USERS_SUCCESS:
        draft.isLoadingUsers = false;
        draft.users = action.payload;
        break;
      case GET_USER_REQUEST:
        draft.userWasDeleted = false;
        draft.selectedUser = undefined;
        draft.isLoadingUser = true;
        break;
      case GET_USER_SUCCESS:
        draft.isLoadingUser = false;
        draft.selectedUser = action.payload;
        break;
      case DELETE_USER_SUCCESS:
        draft.userWasDeleted = true;
        break;
      default:
        break;
    }
  });

export default reducer;

export const GET_DISTRIBUTORS_REQUEST = "distributors/GET_DISTRIBUTORS_REQUEST";
export const GET_DISTRIBUTORS_SUCCESS = "distributors/GET_DISTRIBUTORS_SUCCESS";
export const GET_DISTRIBUTORS_ERROR = "distributors/GET_DISTRIBUTORS_ERROR";
export const CREATE_DISTRIBUTOR = "distributors/CREATE_DISTRIBUTOR";
export const CREATE_DISTRIBUTOR_SUCCESS = "distributors/CREATE_DISTRIBUTOR_SUCCESS";
export const CREATE_DISTRIBUTOR_ERROR = "distributors/CREATE_DISTRIBUTOR_ERROR";
export const EDIT_DISTRIBUTOR = "distributors/EDIT_DISTRIBUTOR";
export const EDIT_DISTRIBUTOR_SUCCESS = "distributors/EDIT_DISTRIBUTOR_SUCCESS";
export const EDIT_DISTRIBUTOR_ERROR = "distributors/EDIT_DISTRIBUTOR_ERROR";
export const ASSOCIATE_DISTRIBUTOR_OUTLETS = "distributor/ASSOCIATE_DISTRIBUTOR_OUTLETS";
export const ASSOCIATE_DISTRIBUTOR_OUTLETS_SUCCESS =
  "distributor/ASSOCIATE_DISTRIBUTOR_OUTLETS_SUCCESS";
export const ASSOCIATE_DISTRIBUTOR_OUTLETS_ERROR =
  "distributor/ASSOCIATE_DISTRIBUTOR_OUTLETS_ERROR";

export interface IDistributor {
  email: string;
  id: string;
  name: string;
  outletCount?: number;
  outlets: { id: string; name: string }[];
  purchasingGroup: {
    id: string;
    name: string;
  };
}

export enum CreateDistributorErrorCodes {
  DUPLICATE_DISTRIBUTOR_NAME = "DUPLICATE_DISTRIBUTOR_NAME",
  DUPLICATE_USERNAME = "DUPLICATE_USERNAME"
}

export interface ICreateDistributorObject {
  email: string;
  name: string;
  outlets?: string[];
  purchasingGroupId?: string;
}

export interface IDistributorsState {
  distributors: IDistributor[];
  requestingDistributors: boolean;
  requestDistributorsError: boolean;
  creatingDistributor: boolean;
  createDistributorError: boolean;
}

export interface IGetDistributorsRequestAction {
  type: typeof GET_DISTRIBUTORS_REQUEST;
}

export interface IGetDistributorsSuccessAction {
  type: typeof GET_DISTRIBUTORS_SUCCESS;
  payload: IDistributor[];
}

export interface IGetDistributorsErrorAction {
  type: typeof GET_DISTRIBUTORS_ERROR;
}

export interface ICreateDistributorAction {
  type: typeof CREATE_DISTRIBUTOR;
  payload: ICreateDistributorObject;
}

export interface ICreateDistributorSuccessAction {
  type: typeof CREATE_DISTRIBUTOR_SUCCESS;
  payload: IDistributor;
}

export interface ICreateDistributorErrorAction {
  type: typeof CREATE_DISTRIBUTOR_ERROR;
}

export interface IEditDistributorAction {
  type: typeof EDIT_DISTRIBUTOR;
  payload: {
    data: ICreateDistributorObject;
    distributorId: string;
  };
}

export interface IEditDistributorSuccessAction {
  type: typeof EDIT_DISTRIBUTOR_SUCCESS;
  payload: IDistributor;
}

export interface IEditDistributorErrorAction {
  type: typeof EDIT_DISTRIBUTOR_ERROR;
}

export interface IAssociateDistributorOutletsAction {
  type: typeof ASSOCIATE_DISTRIBUTOR_OUTLETS;
  payload: {
    distributorId: string;
    outletIds: string | string[];
  };
}

export interface IAssociateDistributorOutletsSuccessAction {
  type: typeof ASSOCIATE_DISTRIBUTOR_OUTLETS_SUCCESS;
  payload: IDistributor;
}

export interface IAssociateDistributorOutletsErrorAction {
  type: typeof ASSOCIATE_DISTRIBUTOR_OUTLETS_ERROR;
}

export type DistributorsActionTypes =
  | IGetDistributorsRequestAction
  | IGetDistributorsSuccessAction
  | IGetDistributorsErrorAction
  | ICreateDistributorAction
  | ICreateDistributorSuccessAction
  | ICreateDistributorErrorAction
  | IEditDistributorAction
  | IEditDistributorSuccessAction
  | IEditDistributorErrorAction
  | IAssociateDistributorOutletsAction
  | IAssociateDistributorOutletsSuccessAction
  | IAssociateDistributorOutletsErrorAction;

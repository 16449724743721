import { call, put, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { getMeSuccess } from "../store/auth/actions";
import { GET_ME } from "../store/auth/types";

export function* getMe(jCoreService: IJCoreService) {
  try {
    const user = yield call([jCoreService, jCoreService.fetchUser]);

    yield put(getMeSuccess(user));
  } catch {
    // console.error("Error getting user");
  }
}

export function* authWatcher(context: IAppContext) {
  yield takeLatest(GET_ME, getMe, context.jCoreService);
}

export default authWatcher;

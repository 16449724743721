import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { getCurrentContent } from "../../store/content/selectors";
import { IContent } from "../../store/content/types";
import * as outletActions from "../../store/outlets/actions";
import * as actions from "../../store/technicians/actions";
import { ITechnician, ITechnicianParams } from "../../store/technicians/types";
import { DetailPage, DetailPageContent } from "../common/DetailPage";
import Header from "../common/header/Header";
import CardItem from "../common/item/CardItem";
import Modal from "../common/modal/Modal";
import Filter, { IFilterCategory, IFilterState } from "../common/old_filter/Filter";
import AssociateTechnicianModal from "../common/technician/AssociateTechnicianModal";
import AuthenticatorCodeInfo from "../common/technician/AuthenticatorCodeInfo";
import CreateTechnicianForm from "../common/technician/CreateTechnicianForm";
import { ILocation } from "../../store/outlets/types";

export interface IProps {
  technicians: ITechnician[];
  requestingTechnician: boolean;
  requestTechnicianError: boolean;
  newTechnician: (params: ITechnicianParams) => void;
  resetNewTechnicianId: () => void;
  newTechnicianId: string;
  requestingTechnicianDeviceCode?: boolean;
  technicianDeviceCode?: string;
  requestingCreateTechnician?: boolean;
  content: IContent;
  getTechnicianDeviceCode: (technicianId: string) => void;
  associate: (
    technicianId: string,
    outletId: string,
    refreshTechnician?: boolean,
    refreshOutlet?: boolean,
    refreshTechnicians?: boolean,
    refreshOutlets?: boolean
  ) => void;
  sortOptions: {
    name: string;
    outlets_asc: string;
    outlets_desc: string;
  };
}

interface IState {
  showCreateModal: boolean;
  showAssociateModal: boolean;
  filterState?: IFilterState<ICategory>;
}

interface ICategory extends IFilterCategory {
  outlet?: boolean;
  device?: boolean;
}

export class Technicians extends React.Component<IProps, IState> {
  state: IState = {
    showCreateModal: false,
    showAssociateModal: false
  };

  technicianCategories: ICategory[] = [];

  constructor(props: IProps) {
    super(props);
    this.createTechnician = this.createTechnician.bind(this);
  }

  handleFilterChange = (state: IFilterState<ICategory>) => {
    this.setState({ filterState: state });
  };

  createTechnician(name: string, email: string, phoneNumber: string) {
    const newTechnicianParams: ITechnicianParams = {
      name,
      phoneNumber,
      email
    };

    this.props.newTechnician(newTechnicianParams);
  }

  associationCallback(technician: ITechnician, outlet: ILocation) {
    this.props.associate(technician.id, outlet.id, false, false);
  }

  render() {
    const {
      technicians,
      requestingTechnician,
      requestingCreateTechnician,
      newTechnicianId,
      sortOptions,
      content
    } = this.props;
    const sortOptionsFlat: string[] = [
      sortOptions.name,
      sortOptions.outlets_asc,
      sortOptions.outlets_desc
    ];

    const { filterState, showCreateModal, showAssociateModal } = this.state;
    let items = this.props.technicians;

    this.technicianCategories = [
      {
        id: "1",
        text: content ? content.common_with_outlet_assigned : "",
        outlet: true
      },
      {
        id: "2",
        text: content ? content.common_without_outlet_assigned : "",
        outlet: false
      },
      {
        id: "3",
        text: content ? content.common_device_associated : "",
        device: true
      },
      {
        id: "4",
        text: content ? content.common_device_not_associated : "",
        device: false
      }
    ];

    if (filterState) {
      const { text, sort, categories } = filterState;

      items = items
        .filter(technician => !text || technician.name.toLowerCase().includes(text.toLowerCase()))
        .filter(technician => {
          const selectedCategories = categories.filter(c => c.selected);

          if (selectedCategories.length === 0 || selectedCategories.length === categories.length) {
            return true;
          }

          const outlets = technician.associatedLocations || [];
          const devices = technician.technicianRegisteredDevices || [];

          // eslint-disable-next-line no-restricted-syntax
          for (const category of selectedCategories) {
            if (category.device !== undefined && category.device !== Boolean(devices.length)) {
              return false;
            }

            if (category.outlet !== undefined && category.outlet !== Boolean(outlets.length)) {
              return false;
            }
          }

          return true;
        })
        .sort((a, b) => {
          let aVal: string | number = "";
          let bVal: string | number = "";

          if (sort === sortOptions.name) {
            aVal = b.name.toLowerCase();
            bVal = a.name.toLowerCase();
          } else if (sort === sortOptions.outlets_asc) {
            const aOutlets = a.associatedLocations || [];
            const bOutlets = b.associatedLocations || [];

            aVal = bOutlets.length;
            bVal = aOutlets.length;
          } else if (sort === sortOptions.outlets_desc) {
            const aOutlets = a.associatedLocations || [];
            const bOutlets = b.associatedLocations || [];

            aVal = aOutlets.length;
            bVal = bOutlets.length;
          }

          return aVal < bVal ? 1 : -1;
        });
    }

    const handleCreateTechnician = () => {
      this.props.resetNewTechnicianId();
      this.setState({
        showCreateModal: true
      });
    };

    const headerActions = [
      {
        label: `+ ${content.technicians_create_technician}`,
        onClick: handleCreateTechnician
      },
      {
        label: content.common_associate_technician,
        onClick: () => this.setState({ showAssociateModal: true })
      }
    ];

    return !technicians && requestingTechnician ? (
      <div>Requesting technicians...</div>
    ) : (
      content && (
        <DetailPage className="technicians-header page-list">
          <Header title={content.technicians_title} actions={headerActions} />
          <DetailPageContent>
            <div className="technicians-page">
              <Filter
                textPlaceholder={content.technicians_search_placeholder}
                categories={this.technicianCategories}
                sort={sortOptions.name}
                sortOptions={sortOptionsFlat}
                onFilterChange={this.handleFilterChange}
              >
                {items.map(t => {
                  const technicianString =
                    t.associatedLocations && t.associatedLocations.length > 0
                      ? content.technicians_associated_to
                      : content.technicians_not_associated_to_outlets;
                  const technicianValue =
                    t.associatedLocations && t.associatedLocations.length
                      ? content.technicians_value_outlets.replace(
                          "%outlets%",
                          t.associatedLocations && t.associatedLocations.length.toString()
                        )
                      : "";

                  return (
                    <Link
                      className="grid-item"
                      key={t.id}
                      to={Routes.technicianDetails.replace(":id", t.id)}
                    >
                      <CardItem
                        name={t.name}
                        icon={
                          t.technicianRegisteredDevices && t.technicianRegisteredDevices.length > 0
                            ? "checkmark"
                            : "error"
                        }
                        details={[{ title: technicianString, value: technicianValue }]}
                        status={
                          t.technicianRegisteredDevices && t.technicianRegisteredDevices.length
                            ? "success"
                            : "error"
                        }
                        tooltipText={
                          t.technicianRegisteredDevices && t.technicianRegisteredDevices.length
                            ? content.technicians_has_device_tooltip
                            : content.technicians_no_device_tooltip
                        }
                      />
                    </Link>
                  );
                })}
              </Filter>
            </div>
          </DetailPageContent>
          <Modal
            visible={showCreateModal}
            title={
              newTechnicianId === ""
                ? content.technicians_create_technician
                : content.technicians_technician_created
            }
            onClose={() => this.setState({ showCreateModal: false })}
          >
            {newTechnicianId === "" ? (
              <CreateTechnicianForm
                submitting={requestingCreateTechnician || false}
                cancelCallback={() => this.setState({ showCreateModal: false })}
                createTechnicianCallback={this.createTechnician}
              />
            ) : this.props.requestingTechnicianDeviceCode ? (
              <div className="authenticator-code-modal-requesting">
                {content.technicians_requesting_authenticator_code}
              </div>
            ) : (
              <AuthenticatorCodeInfo
                authenticatorCode={this.props.technicianDeviceCode}
                showHelp
                gotItCallback={() => this.setState({ showCreateModal: false })}
              />
            )}
          </Modal>
          <AssociateTechnicianModal
            visible={showAssociateModal}
            onClose={() => this.setState({ showAssociateModal: false })}
            onSubmit={(technician, outlet) => this.associationCallback(technician, outlet)}
          />
        </DetailPage>
      )
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);
  const sortOptions = {
    name: content ? content.common.common_name : "",
    outlets_asc: content ? `${content.common.common_outlets_associated} ↑` : "",
    outlets_desc: content ? `${content.common.common_outlets_associated} ↓` : ""
  };

  return {
    technicians: state.technician.technicians,
    requestingTechnician: state.technician.requestingTechnicians,
    requestTechnicianError: state.technician.requestTechniciansError,
    newTechnicianId: state.technician.newTechnicianId,
    requestingTechnicianDeviceCode: state.technician.requestingTechnicianDeviceCode,
    technicianDeviceCode: state.technician.technicianDeviceCode,
    requestingCreateTechnician: state.technician.requestingCreateTechnician,
    content: content ? content.technicians : null,
    sortOptions
  };
};
const mapDispatchToProps = {
  newTechnician: actions.newTechnician,
  resetNewTechnicianId: actions.resetTechnicianId,
  getTechnicianDeviceCode: actions.getTechnicianDeviceCodeRequest,
  associate: outletActions.associateTechnicianToOutlet
};

export default connect(mapStateToProps, mapDispatchToProps)(Technicians);

import { BodyHighlight, Body } from "@ddm-design-system/typography";
import React, { useCallback } from "react";
import { Icon } from "@ddm-design-system/icon";
import { ddmTheme } from "@ddm-design-system/tokens";
import useContent from "../../../../../hooks/useContent";
import { IComponentHealthStatus } from "../../../../../store/components/types";
import { FieldValue } from "../../../../common/fieldValue/fieldValue.styles";

interface IProps {
  healthStatus: IComponentHealthStatus;
}

const Status: React.FC<IProps> = ({ healthStatus }) => {
  const { common, outletDetails: content } = useContent();

  const renderComponentStatus = useCallback(() => {
    return (
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon
                    name="Wifi"
                    fill={
                      healthStatus?.alive
                        ? ddmTheme.colors.success.success100
                        : ddmTheme.colors.alert.alert100
                    }
                  />
                  {content.outlet_component_wifi_connection}
                </BodyHighlight>
                <FieldValue>
                  {healthStatus?.alive ? common.common_good : common.common_offline}
                </FieldValue>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [common, healthStatus]);

  return renderComponentStatus();
};

export default Status;

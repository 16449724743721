export const TOGGLE_SIDEBAR = "sidebar/TOGGLE_SIDEBAR";
export const OPEN_SIDEBAR = "sidebar/OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "sidebar/CLOSE_SIDEBAR";

export interface IOpenSidebar {
  type: typeof OPEN_SIDEBAR;
}

export interface ICloseSidebar {
  type: typeof CLOSE_SIDEBAR;
}

export interface IToggleSidebar {
  type: typeof TOGGLE_SIDEBAR;
}

export interface ISidebarState {
  isOpen: boolean;
}
export type SidebarActionTypes = IToggleSidebar | IOpenSidebar | ICloseSidebar;

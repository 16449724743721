import styled, { css } from "styled-components";
import { BaseButtonStyles, PrimaryButton } from "@ddm-design-system/button";

export default styled(PrimaryButton)(
  ({ theme }) => css`
    border-color: ${theme.colors.grey.grey100};
    border-radius: ${theme.spaceUnit.xxs};
    color: ${theme.colors.grey.grey100};
    fill: ${theme.colors.grey.grey100};
    padding: ${theme.spaceUnit.sm} ${theme.spaceUnit.md};

    &:hover {
      border-color: ${theme.colors.black};
      color: ${theme.colors.black};
      fill: ${theme.colors.black};
    }

    &:active {
      border-color: ${theme.colors.black};
      color: ${theme.colors.white};
      fill: ${theme.colors.white};
    }

    &:disabled {
      background-color: transparent;
      border-color: ${theme.colors.grey.grey50};
      color: ${theme.colors.grey.grey50};
      fill: ${theme.colors.grey.grey50};

      & * {
        pointer-events: none;
      }
    }

    ${BaseButtonStyles.StyledButtonText} {
      ${theme.typography.description};
    }
  `
);

import React, { useCallback, useEffect, useState } from "react";
import { BodyHighlight, DescriptionMedium, Subtitle } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import { PrimaryButton } from "@ddm-design-system/button";
import { Radio, RadioGroup } from "@ddm-design-system/radio";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Divider } from "@ddm-design-system/divider";
import useContent from "../../hooks/useContent";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import { getOutlets } from "../../store/outlets/selectors";
import { CloseIcon } from "../common/icon/CloseIcon.styles";
import { CreateOutletFooter } from "../outlets/createOutletForm.styles";
import { IInvitedUser, IInvitedUserParams, Roles } from "../../store/users/types";
import SimpleDropdown, { IItem } from "../common/simple_dropdown/SimpleDropdown";
import { validateEmail } from "../../helpers";

interface IProps {
  visible: boolean;
  onClose: () => void;
  user?: IInvitedUser;
  onCreateUser: (newUser: IInvitedUserParams) => void;
}

const CreateUserForm: React.FC<IProps> = ({ user, onCreateUser, visible, onClose }) => {
  const {
    common,
    controlTowerInvitedUsers: contentUsers,
    controlTowerUserDetail: contentUser
  } = useContent();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined);
  const [allVisibility, setAllVisibility] = useState(true);
  const [role, setRole] = useState(user && Object(Roles)[user.role]);

  const [itemList, setItemList] = useState<IItem[] | null>(null);
  const outlets = useSelector(getOutlets);

  useEffect(() => {
    setItemList(
      outlets.map((d: any) => ({
        id: d.id,
        text: d.name,
        selected: user ? user.locations.map(d2 => d2.id).includes(d.id) : false
      }))
    );

    if (user) {
      setEmail(user.username);
      setRole(Object(Roles)[user.role]);
      setItemList(
        outlets.map((d: any) => ({
          id: d.id,
          text: d.name,
          selected: user ? user.locations.map(d2 => d2.id).includes(d.id) : false
        }))
      );
    }
  }, [outlets, user]);

  const handleRoleChange = useCallback((newRole: string) => {
    setRole(newRole);
  }, []);

  return (
    <SidebarModal visible={visible} onClose={onClose}>
      <div className="flex flex-col h-full">
        <div className="h-[120px]">
          <div className="flex justify-between items-center mb-xs">
            <Subtitle>
              {user
                ? contentUser.control_tower_invited_user_edit_internal
                : common.common_create_internal_user}
            </Subtitle>
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        <form className="flex flex-col flex-1">
          <ErrorTextInput
            disabled={user !== null && user !== undefined}
            label={common.common_form_email_placeholder}
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            error={errorEmail}
            onBlur={() => {
              if (errorEmail) {
                const error =
                  email && !validateEmail(email)
                    ? contentUsers.control_tower_invalid_email
                    : undefined;

                setErrorEmail(error);
              }
            }}
          />
          <BodyHighlight className="mt-lg mb-md">
            {contentUsers.control_tower_invited_user_permissions}
          </BodyHighlight>
          <RadioGroup name="permissions" initialValue={role} onValueSelect={handleRoleChange}>
            <Radio value={Roles.ROLE_MATRIX} label="Manager App">
              <>
                <DescriptionMedium className="text-grey-grey100 mt-xs mb-md">
                  {contentUsers.control_tower_invited_user_salesrep_description}
                </DescriptionMedium>
                <DescriptionMedium className="mb-xs">
                  {common.common_outlet_access}
                </DescriptionMedium>
                <div className="w-full mb-md">
                  <SimpleDropdown
                    disabled={role !== Roles.ROLE_MATRIX}
                    style={{ width: "100%" }}
                    items={itemList || []}
                    onItemSelected={item => {
                      setItemList(
                        itemList &&
                          itemList.map((b: IItem) => ({
                            ...b,
                            selected: b.id === item.id ? !b.selected : b.selected
                          }))
                      );
                      setAllVisibility(false);
                    }}
                    onSelectAll={() => {
                      const selectedLength = itemList
                        ? itemList.filter((b: IItem) => b.selected).length
                        : 0;

                      setItemList(
                        itemList &&
                          itemList.map((b: IItem) => ({
                            ...b,
                            selected: selectedLength !== itemList.length
                          }))
                      );
                      setAllVisibility(true);
                    }}
                    renderItem={item => item.text}
                    multiple
                    allDisplayText={common.common_all_in_country}
                  />
                </div>
              </>
            </Radio>
            <Divider className="my-lg" />
            <Radio
              value={Roles.ROLE_SUPERVISOR}
              label={contentUsers.control_tower_invited_user_both_apps}
            >
              <DescriptionMedium className="text-grey-grey100 mt-xs mb-md">
                {contentUsers.control_tower_invited_user_supervisor_description}
              </DescriptionMedium>
            </Radio>
          </RadioGroup>
        </form>
        <CreateOutletFooter error={errorEmail}>
          <PrimaryButton
            disabled={email.length === 0 || !role}
            onClick={() => {
              const error =
                email && !validateEmail(email)
                  ? contentUsers.control_tower_invalid_email
                  : undefined;

              setErrorEmail(error);

              if (!error) {
                onCreateUser({
                  username: email,
                  allVisibility,
                  role,
                  locationIds:
                    itemList && !allVisibility && role === Roles.ROLE_MATRIX
                      ? itemList.filter(f => f.selected).map(d => d.id)
                      : []
                });
              }
            }}
          >
            {user ? common.common_save : common.common_invite}
          </PrimaryButton>
        </CreateOutletFooter>
      </div>
    </SidebarModal>
  );
};

export default CreateUserForm;

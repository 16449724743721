import styled, { css } from "styled-components";
import { EBreakpoints } from "@ddm-design-system/tokens";

export const FieldContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      flex-direction: column;
    }
  `
);

export const FieldSection = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex: 1;
  `
);

export const Field = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.spaceUnit.xl};
    display: flex;
    flex-direction: column;
  `
);

import classnames from "classnames";
import React from "react";
import Icon from "../icon/Icon";
import "./modal.scss";

interface IProps {
  visible: boolean;
  title?: string;
  children?: React.ReactNode;
  hasCloseButton?: boolean;
  hasCloseOverlay?: boolean;
  onClose?: () => void;
  className?: string;
}

const Modal: React.FC<IProps> = ({
  visible,
  title,
  children,
  onClose,
  className,
  hasCloseButton = true,
  hasCloseOverlay = false
}) => (
  <div className={classnames("modal", visible && "visible", className)}>
    <div
      className="modal-overlay"
      onClick={() => (hasCloseOverlay || !hasCloseButton) && onClose?.()}
    />
    <div className="modal-container">
      <div className="modal-header">
        <h2 className="modal-header-title">{title}</h2>
        {hasCloseButton && (
          <div className="modal-header-close" onClick={onClose}>
            <Icon name="cross" />
          </div>
        )}
      </div>
      <div className="modal-content">{children}</div>
    </div>
  </div>
);

export default Modal;

import styled, { css } from "styled-components";
import { Divider } from "@ddm-design-system/divider";
import { Tab, TabStyles } from "@ddm-design-system/tab";

export const TabsContainer = styled.div``;

export const StyledTab = styled(Tab)(
  ({ theme }) => css`
    margin: 0;
    padding: 0 ${theme.spaceUnit.sm} ${theme.spaceUnit.sm};

    &:hover {
      border-bottom: 2px solid ${theme.colors.grey.grey100};

      &.selected {
        border-color: ${theme.colors.black};
      }
    }

    ${TabStyles.TabBorder} {
      height: 1px;
    }
  `
);

export const TabDivider = styled(Divider)(
  ({ theme }) => css`
    background-color: ${theme.colors.grey.grey50};
    margin-top: -1px;
  `
);

import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import Routes from "../../routes";
import { AnalyticsContext } from "../../services/analytics";
import { logout } from "../../store/auth/actions";
import AuthContext from "./AuthContext";

const Logout: React.FC = () => {
  const auth = useContext(AuthContext);
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();

  useEffect(() => {
    analytics.logEvent("LOGOUT");
    analytics.clearAuthenticatedUserContext();
    dispatch(logout());
    auth.logout();
  }, [analytics, auth, dispatch]);

  return <Redirect to={Routes.login} />;
};

export default Logout;

import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import { Link } from "../common/link/link.styles";
import { getIsLoadingOutlets } from "../../store/outlets/selectors";
import DataTable from "../common/data_table/DataTable";
import { IFilter } from "../common/filter/Filter";
import { IOutletStatus } from "../../store/outlets/types";
import { getCurrentLanguage } from "../../store/content/selectors";
import { IMenuAction } from "../common/menu/ActionsMenu";
import ExportDataModal from "./ExportDataModal";
import { exportAllData } from "../../store/outlets/actions";

interface IProps {
  filters?: IFilter[];
  outlets: IOutletStatus[];
  searchSelector?: string;
  showGroupingInfo?: boolean;
  title?: string;
}

const OutletList: React.FC<IProps> = ({
  filters,
  outlets,
  searchSelector,
  showGroupingInfo,
  title = ""
}) => {
  const [showExportDataModal, setShowExportDataModal] = useState(false);
  const dispatch = useDispatch();
  const { outlets: content, common } = useContent();
  const language = useSelector(getCurrentLanguage);
  const isLoading = useSelector(getIsLoadingOutlets);

  const onExportData = useCallback(
    (start, end) => {
      setShowExportDataModal(false);

      if (!outlets) return;

      dispatch(exportAllData(false, start, end));
    },
    [dispatch, outlets]
  );

  const formatOutlet = useCallback(
    ({ id, name }: IOutletStatus) => (
      <Link to={Routes.outletDetails.replace(":id", id)} title={name || id}>
        {name || id}
      </Link>
    ),
    []
  );

  const sortLevel = useCallback(
    (
      { chipSelectors: [{ sort: chipA }] }: IOutletStatus,
      { chipSelectors: [{ sort: chipB }] }: IOutletStatus
    ) => (chipA as number) - (chipB as number),
    []
  );

  const formatTimeEdit = useCallback(
    ({ lastEdit }: IOutletStatus) => {
      const date = lastEdit
        ? new Date(lastEdit).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const formatTimeCreate = useCallback(
    ({ createDateTime }: IOutletStatus) => {
      const date = createDateTime
        ? new Date(createDateTime).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const actionsDownload: IMenuAction[] = useMemo(() => {
    return [
      {
        icon: "Download",
        label: "Outlet info",
        onClick: () => setShowExportDataModal(true)
      }
    ];
  }, []);

  const columns = useMemo(() => {
    const allColumns = [
      {
        format: formatOutlet,
        name: common.common_outlet,
        selector: (row: IOutletStatus) => row.name,
        sortable: true,
        sortFunction: (outletA: IOutletStatus, outletB: IOutletStatus) =>
          outletA.name.toLocaleLowerCase() > outletB.name.toLocaleLowerCase() ? 1 : -1
      },
      {
        name: content.outlets_status,
        selector: (row: IOutletStatus) => row.statusContent,
        sortable: true,
        sortFunction: sortLevel,
        selectorId: "statusContent"
      },
      {
        name: common.common_units,
        selector: (row: IOutletStatus) => row.components,
        sortable: true
      },
      {
        name: common.common_taps,
        selector: (row: IOutletStatus) => row.taps,
        sortable: true
      },
      {
        name: content.outlets_technicians_associated,
        selector: (row: IOutletStatus) => row.technicians,
        sortable: true
      }
    ];

    if (showGroupingInfo) {
      allColumns.push(
        {
          name: common.common_distributor,
          selector: (row: IOutletStatus) => row.distributorCount || 0,
          sortable: true
        },
        {
          name: common.common_purchasing_group,
          selector: (row: IOutletStatus) => row.purchasingGroupCount || 0,
          sortable: true
        }
      );
    }

    allColumns.push(
      // @ts-ignore
      {
        format: formatTimeEdit,
        name: content.outlets_last_edit,
        selector: (row: IOutletStatus) => row.lastEdit || 0,
        sortable: true
      },
      {
        format: formatTimeCreate,
        name: content.outlets_date_created,
        selector: (row: IOutletStatus) => row.createDateTime || 0,
        sortable: true
      }
    );

    return allColumns;
  }, [
    formatOutlet,
    common.common_outlet,
    common.common_units,
    common.common_taps,
    content.outlets_status,
    content.outlets_technicians_associated,
    content.outlets_last_edit,
    content.outlets_date_created,
    sortLevel,
    formatTimeEdit,
    formatTimeCreate
  ]);

  return (
    <>
      <DataTable
        actions={actionsDownload}
        columns={columns}
        data={outlets}
        defaultSortAsc={false}
        defaultSortField="lastEdit"
        filters={filters}
        isLoading={isLoading}
        searchPlaceholder={content.outlets_search}
        searchSelector={searchSelector}
        title={title}
        pagination
        paginationPerPage={100}
      />

      <ExportDataModal
        visible={showExportDataModal}
        onClose={() => setShowExportDataModal(false)}
        onSubmit={onExportData}
      />
    </>
  );
};

export default OutletList;

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { createAndApproveBeverage } from "../../store/beverages/actions";
import { BeverageTranslation } from "../../store/beverages/types";
import Button from "../common/button/Button";
import { DetailPage } from "../common/DetailPage/DetailPage";
import { DetailPageContent } from "../common/DetailPage/DetailPageContent";
import Header from "../common/header/Header";
import Modal from "../common/modal/Modal";
import { BeverageForm } from "./BeverageForm";

let unblock: any = null;

export const BeverageCreate: React.FC<RouteComponentProps> = ({ history }) => {
  const me = useSelector((state: IAppState) => state.authReducer.me);
  const dispatch = useDispatch();
  const [discardModal, setDiscardModal] = useState(false);
  const [changesWereMade, setChangesWereMade] = useState(false);
  const [nextPath, setNextPath] = useState<any>();
  const beverage = useMemo(() => new BeverageTranslation(), []);
  const { controlTowerBeveragesPage: content, common } = useContent();

  useEffect(() => {
    if (changesWereMade) {
      if (unblock) {
        unblock();
      }

      // eslint-disable-next-line consistent-return
      unblock = history.block(path => {
        if (changesWereMade) {
          setDiscardModal(true);
          setNextPath(path);

          return false;
        }
      });
    }
  }, [changesWereMade, history]);

  return (
    <DetailPage className="page-list">
      <Header
        title={content.control_tower_creating_new_beverage}
        breadcrumbs={[{ link: Routes.beverages, name: content.control_tower_beverages }]}
      />
      <DetailPageContent>
        {me && (
          <BeverageForm
            isCreate
            myCountry={me.country}
            beverage={beverage}
            onChanges={setChangesWereMade}
            onSubmit={newBeverage => {
              setChangesWereMade(false);

              if (unblock) {
                unblock();
              }

              dispatch(createAndApproveBeverage(newBeverage));
            }}
          />
        )}
        <Modal
          visible={discardModal}
          title={content.control_tower_discard_changes}
          onClose={() => setDiscardModal(false)}
        >
          <div>{content.control_tower_discard_changes_description}</div>
          <div className="beverage-discard-guard">
            <Button ghost onClick={() => setDiscardModal(false)}>
              {common.common_cancel}
            </Button>
            <Button
              primary
              error
              onClick={() => {
                setDiscardModal(false);

                if (unblock) {
                  unblock();
                }

                history.push(nextPath);
              }}
            >
              {common.control_tower_exit_without_saving}
            </Button>
          </div>
        </Modal>
      </DetailPageContent>
    </DetailPage>
  );
};
export default withRouter(BeverageCreate);

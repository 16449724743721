import { BodyHighlight, Body } from "@ddm-design-system/typography";
import React, { useCallback } from "react";
import useContent from "../../../../../hooks/useContent";
import { IComponentHealthStatus } from "../../../../../store/components/types";

interface IProps {
  healthStatus: IComponentHealthStatus;
}

const Status: React.FC<IProps> = ({ healthStatus }) => {
  const { common } = useContent();

  const renderComponentStatus = useCallback(() => {
    return (
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper justify-start">
            <div className="flex flex-col mr-lg">
              <div className="flex">
                <div className="bg-[#D864B8] rounded-[4px] text-white h-lg w-lg text-center mr-xs">
                  1
                </div>
                <BodyHighlight>
                  {common[`common_${healthStatus?.temperatureReading0Position}`]}
                </BodyHighlight>
              </div>
              <Body className="mt-xs">
                {`${
                  healthStatus?.temperatureReading0 ? healthStatus?.temperatureReading0 / 100 : "--"
                }ºC`}
              </Body>
            </div>

            <div className="flex flex-col mr-lg">
              <div className="flex">
                <div className="bg-[#45A0FD] rounded-[4px] text-white h-lg w-lg text-center mr-xs">
                  2
                </div>
                <BodyHighlight>
                  {common[`common_${healthStatus?.temperatureReading1Position}`]}
                </BodyHighlight>
              </div>
              <Body className="mt-xs">
                {`${
                  healthStatus?.temperatureReading1 ? healthStatus?.temperatureReading1 / 100 : "--"
                }ºC`}
              </Body>
            </div>

            <div className="flex flex-col mr-lg">
              <div className="flex">
                <div className="bg-[#64D1D8] rounded-[4px] text-white h-lg w-lg text-center mr-xs">
                  3
                </div>
                <BodyHighlight>
                  {common[`common_${healthStatus?.temperatureReading2Position}`]}
                </BodyHighlight>
              </div>
              <Body className="mt-xs">
                {`${
                  healthStatus?.temperatureReading2 ? healthStatus?.temperatureReading2 / 100 : "--"
                }ºC`}
              </Body>
            </div>
          </div>
        </div>
      </div>
    );
  }, [common, healthStatus]);

  return renderComponentStatus();
};

export default Status;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-classes-per-file */
import React from "react";

export interface IAnalyticsService {
  setup(): void;

  logPageView(name: string): void;

  logEvent(category: string, action?: string, label?: string, value?: number): void;

  setAuthenticatedUserContext(userId: string): void;

  clearAuthenticatedUserContext(): void;
}

export class MockAnalyticsService implements IAnalyticsService {
  logPageView(name: string): void {}

  logEvent(
    category: string,
    action?: string | undefined,
    label?: string | undefined,
    value?: number | undefined
  ): void {}

  setAuthenticatedUserContext(): void {}

  clearAuthenticatedUserContext(): void {}

  setup() {}
}

export class AnalyticsService implements IAnalyticsService {
  parseProperties(properties?: { [name: string]: any }): { [name: string]: string } {
    const parsedProperties: { [name: string]: string } = {};

    if (properties) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(properties)) {
        const value = properties[key];

        parsedProperties[key] = JSON.stringify(value);
      }
    }

    return parsedProperties;
  }

  getCookieConsent() {
    const cc = localStorage.getItem("cookieConsent");

    return cc === "true";
  }

  setup() {
    // TODO setup a new analytics if needed
    // Google Analytics old key:
    // const gaKey = "UA-148889548-4";
  }

  logPageView(name: string) {
    if (this.getCookieConsent()) {
      // send page view
    }
  }

  logEvent(category: string, action?: string, label?: string, value?: number) {
    if (this.getCookieConsent()) {
      // send event
    }
  }

  public setAuthenticatedUserContext(userId: string) {
    if (this.getCookieConsent()) {
      // set user
    }
  }

  public clearAuthenticatedUserContext() {
    // remove user
  }
}

export const AnalyticsContext = React.createContext<IAnalyticsService>(new AnalyticsService());
export const AnalyticsProvider = AnalyticsContext.Provider;
export const AnalyticsConsumer = AnalyticsContext.Consumer;

import { AdalService, IAdalService } from "./adal";
import { AnalyticsService, IAnalyticsService } from "./analytics";
import { IJCoreService, JCoreService } from "./jcore";

export interface IAppContext {
  jCoreService: IJCoreService;
  adalService: IAdalService;
  analyticsService: IAnalyticsService;
  notificationService: any;
}

class AppContext implements IAppContext {
  jCoreService: IJCoreService;

  adalService: IAdalService;

  analyticsService: IAnalyticsService;

  notificationService: any;

  constructor() {
    const JCoreBaseUrl = process.env.REACT_APP_JCORE_BASE_URL;

    this.jCoreService = new JCoreService(JCoreBaseUrl);
    this.analyticsService = new AnalyticsService();
    this.adalService = new AdalService();
    this.notificationService = null;
  }

  public setNotificationService(notificationService: any) {
    this.notificationService = notificationService;
  }
}

export default new AppContext();

import React from "react";
import "./numberOutOf.scss";

interface IProps {
  value: number;
  outOf: number;
  subtitle?: string;
}
export const NumberOutOf: React.FC<IProps> = props => {
  return (
    <div className="number-out-of">
      <div className="number-out-of-container">
        <span className="number-out-of-value">{props.value}</span>
        <span className="number-out-of-slash">/</span>
        <span className="number-out-of-total">{props.outOf}</span>
      </div>
      <span className="number-out-of-subtitle">{props.subtitle}</span>
    </div>
  );
};
export default NumberOutOf;

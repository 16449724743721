import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {PrimaryButton, SecondaryButton} from "@ddm-design-system/button";
import {MultipleDropdown} from "@ddm-design-system/dropdown";
import {ErrorTextInput, TextInput} from "@ddm-design-system/textinput";
import {Subtitle} from "@ddm-design-system/typography";
import {IPurchasingGroup} from "../../store/purchasingGroups/types";
import {getDistributors as getDistributorsAction} from "../../store/distributors/actions";
import {createPurchasingGroup, editPurchasingGroup} from "../../store/purchasingGroups/actions";
import {getDistributors} from "../../store/distributors/selectors";
import {getIsCreatingPurchasingGroup} from "../../store/purchasingGroups/selectors";
import useContent from "../../hooks/useContent";
import {validateEmail} from "../../helpers";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import {CloseIcon} from "../common/icon/CloseIcon.styles";
import {IDistributor} from "../../store/distributors/types";

export const StyledDropdown = styled(MultipleDropdown)`
  background-color: white;
  // proper styled components aren't exported in ddm
  & > div:last-child {
    flex-grow: 1;
  }
`;

interface IProps {
    purchasingGroup?: IPurchasingGroup;
    visible: boolean;
    onClose: () => void;
}

const CreatePurchasingGroupForm: React.FC<IProps> = ({purchasingGroup, visible, onClose}) => {
    const dispatch = useDispatch();
    const {common, purchasingGroups: content} = useContent();
    const isCreatingPurchasingGroup = useSelector(getIsCreatingPurchasingGroup);
    const distributors = useSelector(getDistributors);
    const [pgName, setPgName] = useState(purchasingGroup?.name ?? "");
    const [pgEmail, setPgEmail] = useState(purchasingGroup?.email ?? "");
    const [pgDistributors, setPGDistributors] = useState<{ id: string; text: string }[]>([]);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [hasPgDistributorsChanged, setHasPgDistributorsChanged] = useState(false);

    useEffect(() => {
        if (!distributors.length) {
            dispatch(getDistributorsAction());
        }
    }, []);

    // set initial pgDistributors if applicable
    useEffect(() => {
        if (
            distributors.length > 0 &&
            purchasingGroup?.distributors &&
            purchasingGroup?.distributors.length > 0
        ) {
            setPGDistributors(
                purchasingGroup?.distributors.map(pgDistributor => {
                    const distributor = distributors.find(d => d.id === pgDistributor.id) as IDistributor;

                    return {id: distributor.id, text: distributor.name};
                })
            );
        }
    }, [distributors, purchasingGroup]);

    const dropdownItems = useMemo(
        () => [
            ...distributors
                .map(distributor => ({
                    id: distributor.id,
                    text: distributor.name,
                    disabled:
                        !!distributor.purchasingGroup && distributor.purchasingGroup.id !== purchasingGroup?.id
                }))
                .sort((distA, distB) =>
                    distA.text.toLocaleLowerCase() < distB.text.toLocaleLowerCase() ? -1 : 1
                )
        ],
        [distributors]
    );

    const handleCreatePurchasingGroup = () => {
        const distributorIds = pgDistributors.map(pgDistributor => pgDistributor.id);

        if (purchasingGroup) {
            dispatch(
                editPurchasingGroup({
                    data: {distributors: distributorIds, email: pgEmail, name: pgName},
                    id: purchasingGroup.id
                })
            );
        } else {
            dispatch(
                createPurchasingGroup({distributors: distributorIds, email: pgEmail, name: pgName})
            );
        }
    };

    const onCloseAux = () => {
        setPgName(purchasingGroup?.name ?? "");
        setPgEmail(purchasingGroup?.email ?? "");
        setIsEmailValid(true);
        setPGDistributors(
            purchasingGroup?.distributors?.map(pgDistributor => {
                const distributor = distributors.find(d => d.id === pgDistributor.id) as IDistributor;

                return {id: distributor.id, text: distributor.name};
            }) || []
        );
        setHasPgDistributorsChanged(false);
        onClose();
    };

    return (
      <SidebarModal visible={visible} onClose={onCloseAux}>
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-col">
            <div className="flex justify-between mb-xxxl">
              <Subtitle>
                {purchasingGroup
                                ? content.purchasing_groups_edit
                                : content.purchasing_groups_create_new}
              </Subtitle>
              <CloseIcon className="mt-sm" onClick={onCloseAux} />
            </div>
            <form>
              <TextInput
                className="mb-lg"
                disabled={isCreatingPurchasingGroup}
                label={common.common_purchasing_group}
                maxLength={100}
                value={pgName}
                onChange={e => setPgName(e.target.value)}
              />
              <ErrorTextInput
                className="mb-xxl"
                disabled={isCreatingPurchasingGroup}
                error={isEmailValid ? undefined : common.common_invalid_email}
                label={common.common_form_email_placeholder}
                value={pgEmail}
                type="email"
                onBlur={e => setIsEmailValid(!!validateEmail(e.target.value))}
                onChange={e => setPgEmail(e.target.value)}
              />
              {visible && (
                            // @ts-ignore
                <StyledDropdown
                  disabled={!distributors.length}
                  items={dropdownItems}
                  label={common.common_distributors}
                  placeholder={content.purchasing_groups_select_distributors}
                  selectedItems={pgDistributors}
                  onItemsSelected={items => {
                                    setHasPgDistributorsChanged(true);
                                    setPGDistributors(items);
                                }}
                />
                        )}
            </form>
          </div>
          <div className="flex justify-end">
            <SecondaryButton className="mr-lg" onClick={onCloseAux}>
              {common.common_cancel}
            </SecondaryButton>
            <PrimaryButton
              disabled={
                            isCreatingPurchasingGroup ||
                            !pgName ||
                            !pgEmail ||
                            !isEmailValid ||
                            (pgName === purchasingGroup?.name &&
                                pgEmail === purchasingGroup?.email &&
                                !hasPgDistributorsChanged)
                        }
              onClick={handleCreatePurchasingGroup}
            >
              {isCreatingPurchasingGroup
                            ? common.common_loading
                            : purchasingGroup
                                ? common.common_edit
                                : common.common_create}
            </PrimaryButton>
          </div>
        </div>
      </SidebarModal>
    );
};

export default CreatePurchasingGroupForm;

import { Radio, withStyles } from "@material-ui/core";
import React from "react";

export const DMRadio = withStyles({
  root: {
    color: "black"
  },

  checked: {
    color: "black"
  }
})(props => <Radio color="default" {...props} />);

import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  EDIT_USER,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  ICreateUser,
  ICreateUserSuccess,
  IDeleteUser,
  IDeleteUserSuccess,
  IEditUser,
  IGetUserRequest,
  IGetUsersRequest,
  IGetUsersSuccess,
  IGetUserSuccess,
  IInvitedUser,
  IInvitedUserEditParams,
  IInvitedUserParams
} from "./types";

export const getUsers = (): IGetUsersRequest => ({
  type: GET_USERS_REQUEST
});
export const getUsersSuccess = (payload: IInvitedUser[]): IGetUsersSuccess => ({
  type: GET_USERS_SUCCESS,
  payload
});
export const getUser = (id: string): IGetUserRequest => ({
  type: GET_USER_REQUEST,
  payload: id
});
export const getUserSuccess = (user: IInvitedUser): IGetUserSuccess => ({
  type: GET_USER_SUCCESS,
  payload: user
});
export const createUser = (user: IInvitedUserParams): ICreateUser => ({
  type: CREATE_USER,
  payload: user
});
export const createUserSuccess = (): ICreateUserSuccess => ({
  type: CREATE_USER_SUCCESS
});
export const editUser = (params: IInvitedUserEditParams): IEditUser => ({
  type: EDIT_USER,
  payload: params
});
export const deleteUser = (payload: string): IDeleteUser => ({
  type: DELETE_USER,
  payload
});
export const deleteUserSuccess = (): IDeleteUserSuccess => ({
  type: DELETE_USER_SUCCESS
});

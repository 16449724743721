import axios, { AxiosInstance, AxiosResponse } from "axios";
import { SyncCollection } from "contentful";
import Routes from "../routes";
import {
  BeverageTranslation,
  IBeverage,
  IBeverageCreatePayload,
  ICountry,
  IMergeBeverageProps
} from "../store/beverages/types";
import {
  ICELocation,
  ICELocationAddressEdit,
  IPutCeLocation,
  IPutCeLocationFlex
} from "../store/ceLocations/types";
import { IContent } from "../store/content/types";
import { ICreateDistributorObject, IDistributor } from "../store/distributors/types";
import { IHealthStatusResponse } from "../store/healthStatus/types";
import {
  IAssociatedTechnician,
  IConnectedComponent,
  ICreateOutletObject,
  IInstallation,
  ILine,
  ILocation,
  INote,
  IUnit
} from "../store/outlets/types";
import { ICreatePurchasingGroupObject, IPurchasingGroup } from "../store/purchasingGroups/types";
import { ITechnician, ITechnicianParams } from "../store/technicians/types";
import { IInvitedUser, IInvitedUserEditParams, IInvitedUserParams } from "../store/users/types";
import { sleep } from "../helpers";
import history from "../history";

export interface IUser {
  id: string;
  username: string;
  name: string;
  country: ICountry;
  role?: string;
  ceOnly?: boolean;
}

export interface IUserCredentials {
  username: string;
  password: string;
  recaptchaToken: string;
  rememberMe?: boolean;
}

export interface ITechniciansPayload {
  technicians: ITechnician[];
}

export interface ITechnicianOutletAssociation {
  associationDate: Date;
  location: ILocation;
  technician: ITechnician;
}

export interface IBeveragesPayload {
  beverages: IBeverage[];
}

export interface IBeverageTranslationsPayload {
  translations: BeverageTranslation[];
}

interface IConnectedComponentInput {
  thingId: string;
  position: number;
  serialNumber: number;
  name?: string;
}

interface IPressureChamberInput extends IConnectedComponentInput {
  tapPosition?: number;
  beverageId: string;
}

interface ICoolingUnitInput extends IConnectedComponentInput {} // tslint:disable-line: no-empty-interface

interface ICleaningUnitInput extends IConnectedComponentInput {} // tslint:disable-line: no-empty-interface

export type IUserId = string;

export interface IFirmwareVersions {
  firmware: string;
  firmwareNcu: string;
  firmwareOnomondo: string;
  firmwareOnomondoNcu: string;
}

export interface IRecentlyCreatedTechnician {
  id: string;
  name: string;
  registerCode: string;
}

export interface IConnectedComponentInputPayload {
  pressureChambers?: IPressureChamberInput[];
  coolingUnits?: ICoolingUnitInput[];
  cleaningUnits?: ICleaningUnitInput[];
}

export interface IJCoreService {
  login: (credentials: IUserCredentials) => Promise<{ token: string; id: string }>;
  isLoggedIn: () => boolean;
  logout: () => void;
  fetchUser: () => Promise<IUser>;
  fetchLocations: () => Promise<ILocation[]>;
  fetchExtra10Locations: () => Promise<ILocation[]>;
  getLatestFirmwareVersion: () => Promise<IFirmwareVersions>;
  getOutletInfo: (outletId: string) => Promise<ILocation>;
  getOutletLines: (outletId: string) => Promise<{ groupings: ILine[] }>;
  getOutletInstallation: (outletId: string) => Promise<IInstallation>;
  getOutletUnits: (outletId: string) => Promise<IUnit[]>;
  getOutletTechnicians: (outletId: string) => Promise<IAssociatedTechnician[]>;
  getOutletNotes: (outletId: string) => Promise<INote>;
  getComponentInfo: (componentId: string) => Promise<any>;
  getComponentLines: (componentId: string) => Promise<{ groupings: ILine[] }>;
  getComponentHealthStatus: (componentId: string) => Promise<any>;
  getExtra10OutletInfo: (outletId: string) => Promise<ILocation>;
  getExtra10OutletInstallation: (outletId: string) => Promise<IInstallation>;
  fetchLocation: (locationId: string) => Promise<ILocation>;
  fetchBeverages: () => Promise<IBeveragesPayload>;
  fetchBeveragesX10: () => Promise<IBeveragesPayload>;
  fetchBeverage: (beverageId: string) => Promise<{}>;
  fetchBeverageTranslations: (beverageId: string) => Promise<{}>;
  fetchBeveragesTranslations: () => Promise<IBeverageTranslationsPayload>;
  createAndApproveBeverage: (beverage: IBeverageCreatePayload) => Promise<void>;
  editBeverage: (beverage: IBeverageCreatePayload) => Promise<void>;
  mergeBeverage: (beverage: IMergeBeverageProps) => Promise<void>;
  fetchCeLocations: () => Promise<ICELocation[]>;
  putCeLocation: (ceInstallationId: string, data: IPutCeLocation) => Promise<ICELocation>;
  putCeLocationFlex: (ceInstallationId: string, data: IPutCeLocationFlex) => Promise<ICELocation>;
  putCeLocationAddress: (ceLocationId: string, data: ICELocationAddressEdit) => Promise<void>;
  fetchTechnicians: () => Promise<ITechnician[]>;
  fetchTechnician: (technicianId: string) => Promise<ITechnician>;
  uploadCEPhoto: (ceInstallationId: string, index: number, file: File) => Promise<void>;
  deleteCEPhoto: (ceInstallationId: string, filename: string) => Promise<void>;
  deleteCELocation: (ceInstallationId: string, certificationNumber: string) => Promise<void>;
  fetchPurchasingGroups: () => Promise<IPurchasingGroup[]>;
  createPurchasingGroup: (pgObject: ICreatePurchasingGroupObject) => Promise<IPurchasingGroup>;
  editPurchasingGroup: (
    pgObject: ICreatePurchasingGroupObject,
    pgId: string
  ) => Promise<IPurchasingGroup>;
  associatePurchasingGroupDistributors: (
    pgId: string,
    distributorIds: string[]
  ) => Promise<IPurchasingGroup>;
  fetchDistributors: () => Promise<IDistributor[]>;
  createDistributor: (distributorObject: ICreateDistributorObject) => Promise<IDistributor>;
  editDistributor: (
    distributorObject: ICreateDistributorObject,
    distributorId: string
  ) => Promise<IDistributor>;
  associateDistributorOutlets: (
    distributorId: string,
    outletIds: string[]
  ) => Promise<IDistributor>;

  fetchAzureUsers: () => Promise<IInvitedUser[]>;
  fetchAzureUser: (userId: string) => Promise<IInvitedUser>;
  createAzureUser: (user: IInvitedUserParams) => Promise<void>;
  editAzureUser: (user: IInvitedUserEditParams) => Promise<void>;
  deleteAzureUser: (userId: string) => Promise<void>;
  linkAzureAccount: (invite: string, token: string) => Promise<void>;
  isAzureAccount: (username: string) => Promise<any>;

  fetchConnectedComponent: (thingId: string) => Promise<IConnectedComponent>;
  unregisterDevice: (deviceId: string) => Promise<void>;
  createTechnician: (technicianParams: ITechnicianParams) => Promise<IRecentlyCreatedTechnician>;
  deleteTechnician: (technicianId: string) => Promise<void>;
  generateRegisterCode: (technicianId: string) => Promise<IRecentlyCreatedTechnician>;
  editTechnician: (params: ITechnicianParams, technicianId: string) => Promise<void>;
  associateTechnicianToOutlet: (technicianId: string, outletId: string) => Promise<void>;
  unassociateTechnicianToOutlet: (technicianId: string, outletId: string) => Promise<void>;
  setUserToken: (token: string) => void;
  setTechnicianInternational: (technicianId: string, international: boolean) => Promise<void>;

  azureAuth: (token: string) => Promise<string>;
  sendRecoveryEmail: (email: string) => Promise<AxiosResponse<boolean>>;

  uploadLogoBeverage: (beverageId: string, files: any) => Promise<void>;
  deleteLogoBeverage: (beverageId: string, files: string[]) => Promise<void>;
  uploadVideoBeverage: (beverageId: string, files: any) => Promise<void>;
  deleteVideoBeverage: (beverageId: string, files: string[]) => Promise<void>;
  saveOutletDescription: (installationId: string, description: string) => Promise<void>;
  resetBeerDrive: (outletId: string, beerDriveId: string) => Promise<void>;
  exportData: (locationId: string, start: number, end: number) => Promise<any>;
  exportAllData: (x10: boolean, start: number, end: number) => Promise<any>;
  getContentfulEntries: (
    contentfulToken?: SyncCollection["nextSyncToken"],
    dbTimestamp?: number,
    env?: string
  ) => Promise<AxiosResponse<IContent>>;

  getHealthStatus: () => Promise<IHealthStatusResponse[]>;
  getX10HealthStatus: () => Promise<IHealthStatusResponse[]>;
  getCountries: () => Promise<ICountry[]>;
  createOutlet: (outlet: ICreateOutletObject) => Promise<any>;
  editOutlet: (outletId: string, outlet: ICreateOutletObject) => Promise<any>;
  setComponentBeverage: (componentId: string, beverageId: string) => Promise<any>;
}

export class JCoreService implements IJCoreService {
  baseUrl: string;

  instance: AxiosInstance;

  loginInstance: AxiosInstance;

  publicInstance: AxiosInstance;

  temporaryToken: string | null = null;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: baseUrl,
      timeout: 15000
    });
    this.loginInstance = axios.create({
      baseURL: baseUrl,
      timeout: 15000
    });

    this.instance.interceptors.request.use(config => {
      const token = this.getUserToken();

      if (token) {
        // @ts-ignore
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    this.instance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const httpStatus = error.message && error.message.includes(401);

        if (httpStatus) {
          history.push(Routes.logout);
        }

        throw error;
      }
    );

    this.publicInstance = axios.create({
      baseURL: baseUrl,
      timeout: 6000
    });
  }

  async login(credentials: IUserCredentials) {
    const response = await this.loginInstance.post(
      `/v1/auth?g-recaptcha-response=${credentials.recaptchaToken}`,
      credentials
    );
    const token = response.headers.authorization.replace("Bearer ", "");
    const userId = response.headers["x-user"];

    if (credentials.rememberMe) {
      this.setUserToken(token);
    } else {
      this.temporaryToken = token;
    }

    return { token, id: userId };
  }

  isLoggedIn() {
    const token = this.getUserToken();

    return token !== null;
  }

  logout() {
    this.deleteUserTokens();
  }

  fetchUser() {
    return this.instance.get("/v1/users/me").then(result => result.data);
  }

  fetchTechnicians() {
    return this.instance.get("/v1/technician/all").then(result => result.data);
  }

  fetchCeLocations() {
    return this.instance.get("/v1/supervisor/ce/export").then(result => result.data);
  }

  putCeLocation(ceInstallationId: string, data: IPutCeLocation) {
    return this.instance
      .put(`/v1/supervisor/ce/installation/${ceInstallationId}`, data)
      .then(result => result.data);
  }

  putCeLocationFlex(ceInstallationId: string, data: IPutCeLocationFlex) {
    return this.instance
      .put(`/v1/supervisor/ce/installation/${ceInstallationId}/flex`, data)
      .then(result => result.data);
  }

  putCeLocationAddress(ceLocationId: string, data: ICELocationAddressEdit) {
    return this.instance
      .put(`/v1/supervisor/ce/location/${ceLocationId}`, data)
      .then(result => result.data);
  }

  uploadCEPhoto(ceInstallationId: string, index: number, file: File) {
    const formData = new FormData();

    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return this.instance
      .post(
        `/v1/supervisor/ce/installation/${ceInstallationId}/photoupload/${index}`,
        formData,
        config
      )
      .then(result => result.data);
  }

  deleteCEPhoto(ceInstallationId: string, filename: string) {
    return this.instance
      .delete(`/v1/supervisor/ce/installation/${ceInstallationId}/photoupload/${filename}`)
      .then(result => result.data);
  }

  deleteCELocation(ceInstallationId: string, certificationNumber: string) {
    return this.instance
      .delete(`/v1/supervisor/ce/installation/${ceInstallationId}`, {
        data: { certificationNumber }
      })
      .then(result => result.data);
  }

  fetchLocations() {
    return this.instance.get("/v1/controltower/locations/all").then(result => result.data);
  }

  fetchExtra10Locations() {
    return this.instance.get("/v1/controltower/extra10locations/all").then(result => result.data);
  }

  getLatestFirmwareVersion() {
    return this.instance.get("/v1/controltower/latestfirmware/primary").then(result => result.data);
  }

  getOutletInfo(outletId: string) {
    return this.instance
      .get(`/v1/controltower/locations/${outletId}/info`)
      .then(result => result.data);
  }

  getOutletLines(outletId: string) {
    return this.instance
      .get(`/v1/controltower/locations/${outletId}/lines`)
      .then(result => result.data);
  }

  getOutletInstallation(outletId: string) {
    return this.instance
      .get(`/v1/controltower/locations/${outletId}/installation`)
      .then(result => result.data);
  }

  getOutletUnits(outletId: string) {
    return this.instance
      .get(`/v1/controltower/locations/${outletId}/units`)
      .then(result => result.data);
  }

  getOutletTechnicians(outletId: string) {
    return this.instance
      .get(`/v1/controltower/locations/${outletId}/technicians`)
      .then(result => result.data);
  }

  getOutletNotes(outletId: string) {
    return this.instance
      .get(`/v1/controltower/locations/${outletId}/description`)
      .then(result => result.data);
  }

  getComponentInfo(componentId: string) {
    return this.instance
      .get(`/v1/controltower/component/${componentId}/info`)
      .then(result => result.data);
  }

  getComponentLines(componentId: string) {
    return this.instance
      .get(`/v1/controltower/component/${componentId}/lines`)
      .then(result => result.data);
  }

  getComponentHealthStatus(componentId: string) {
    return this.instance
      .get(`/v1/controltower/component/${componentId}/healthmonitor`)
      .then(result => result.data);
  }

  getExtra10OutletInfo(outletId: string) {
    return this.instance
      .get(`/v1/controltower/extra10locations/${outletId}/info`)
      .then(result => result.data);
  }

  getExtra10OutletInstallation(outletId: string) {
    return this.instance
      .get(`/v1/controltower/extra10locations/${outletId}/installation`)
      .then(result => result.data);
  }

  fetchLocation(locationId: string) {
    return this.instance.get(`/v1/locations/${locationId}/setup`).then(result => result.data);
  }

  fetchBeverages() {
    return this.instance.get("/v1/controltower/beverages/all").then(result => result.data);
  }

  fetchBeveragesX10() {
    return this.instance.get("/v1/controltower/beverages/allx10").then(result => result.data);
  }

  fetchBeverage(beverageId: string) {
    return this.instance.get(`/v1/controltower/beverage/${beverageId}`).then(result => result.data);
  }

  fetchBeveragesTranslations() {
    return this.instance.get("/v1/beverage/translation").then(result => result.data);
  }

  fetchBeverageTranslations(beverageId: string) {
    return this.instance.get(`/v1/beverage/translation/${beverageId}`).then(result => result.data);
  }

  createAndApproveBeverage(beverage: IBeverageCreatePayload) {
    return this.instance.post("/v1/controltower/beverage", beverage).then(result => result.data);
  }

  editBeverage(beverage: IBeverageCreatePayload) {
    return this.instance
      .put(`/v1/controltower/beverage/${beverage.id}`, beverage)
      .then(result => result.data);
  }

  async uploadLogoBeverage(beverageId: string, files: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const f of files) {
      const formData = new FormData();

      formData.append("file", f.file);
      // eslint-disable-next-line no-await-in-loop
      await this.instance.post(
        `/v1/beverage/translation/${beverageId}/logoupload?locale=${f.locale}`,
        formData
      );
    }
  }

  async deleteLogoBeverage(beverageId: string, files: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const f of files) {
      // eslint-disable-next-line no-await-in-loop
      await this.instance.delete(`/v1/beverage/translation/${beverageId}/logoupload?locale=${f}`);
    }
  }

  async uploadVideoBeverage(beverageId: string, files: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const f of files) {
      const formData = new FormData();

      formData.append("file", f.file);
      // eslint-disable-next-line no-await-in-loop
      await this.instance.post(
        `/v1/beverage/translation/${beverageId}/videoupload?locale=${f.locale}`,
        formData
      );
    }
  }

  async deleteVideoBeverage(beverageId: string, files: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const f of files) {
      // eslint-disable-next-line no-await-in-loop
      await this.instance.delete(`/v1/beverage/translation/${beverageId}/videoupload?locale=${f}`);
    }
  }

  async azureAuth(token: string) {
    // wait for access token to be processed
    await sleep(200);

    return this.loginInstance
      .post(
        "v1/azureauth",
        {},
        {
          headers: {
            authorization: `Azure ${token}`
          }
        }
      )
      .then(result => result.headers.authorization);
  }

  sendRecoveryEmail(email: string) {
    return this.loginInstance.post("v1/user/sendpasswordresetemail", {
      email: email.trim(),
      app: "control_tower"
    });
  }

  mergeBeverage(options: IMergeBeverageProps) {
    return this.instance
      .put("/v1/beverage/merge", {
        beverageToMergeId: options.beverageToMergeId,
        officialBeverageId: options.officialBeverageId
      })
      .then(result => result.data);
  }

  linkAzureAccount(invitationToken: string, azureToken: string) {
    return this.publicInstance
      .post("/v1/user/linkazureinvitation", {
        invitationToken,
        azureToken
      })
      .then(result => result.data);
  }

  isAzureAccount(username: string) {
    return this.loginInstance.get(`/v1/user/check/${username}`);
  }

  fetchConnectedComponent(thingId: string) {
    return this.instance
      .get(`/v1/technicians/connectedComponents/${thingId}`)
      .then(result => result.data);
  }

  createTechnician({ email, name, phoneNumber }: ITechnicianParams) {
    return this.instance
      .post("/v1/technician/", {
        email: email.trim(),
        name: name.trim(),
        phoneNumber: phoneNumber.trim()
      })
      .then(result => result.data);
  }

  fetchTechnician(technicianId: string) {
    return this.instance.get(`v1/technician/${technicianId}`).then(result => result.data);
  }

  fetchAzureUsers() {
    return this.instance.get("/v1/controltower/azureusers").then(result => result.data);
  }

  createAzureUser(user: IInvitedUserParams) {
    return this.instance
      .post("/v1/controltower/azureuser", {
        ...user
      })
      .then(result => result.data);
  }

  fetchAzureUser(userId: string) {
    return this.instance
      .get("/v1/controltower/azureuser", {
        params: {
          id: userId
        }
      })
      .then(result => result.data);
  }

  editAzureUser(user: IInvitedUserEditParams) {
    return this.instance.put("/v1/controltower/azureuser", user).then(result => result.data);
  }

  deleteAzureUser(userId: string) {
    return this.instance
      .delete("/v1/controltower/azureuser", {
        data: {
          userId
        }
      })
      .then(result => result.data);
  }

  generateRegisterCode(technicianId: string) {
    return this.instance
      .post(`v1/technician/generateregistercode/${technicianId}`)
      .then(result => result.data);
  }

  deleteTechnician(technicianId: string) {
    return this.instance.delete(`v1/technician/${technicianId}`).then(() => {});
  }

  editTechnician(params: ITechnicianParams, technicianId: string) {
    const { name, email, phoneNumber } = params;

    return this.instance
      .put(`v1/technician/${technicianId}`, {
        email: email.trim(),
        name: name.trim(),
        phoneNumber: phoneNumber.trim()
      })
      .then(() => {});
  }

  setTechnicianInternational(technicianId: string, international: boolean) {
    return this.instance
      .put(`v1/technician/${technicianId}/international`, { international })
      .then(() => {});
  }

  associateTechnicianToOutlet(technicianId: string, outletId: string) {
    return this.instance.post(`v1/technician/${technicianId}/associate/${outletId}`).then(() => {});
  }

  unassociateTechnicianToOutlet(technicianId: string, outletId: string) {
    return this.instance
      .put(`v1/technician/${technicianId}/unassociate/${outletId}`)
      .then(() => {});
  }

  unregisterDevice(deviceId: string) {
    return this.instance.post(`v1/technician/unregisterdevice/${deviceId}`).then(() => {});
  }

  saveOutletDescription(installationId: string, description: string) {
    return this.instance
      .put(`v1/locations/installation/${installationId}/description`, {
        description
      })
      .then(() => {});
  }

  resetBeerDrive(locationId: string, beerDriveId: string) {
    return this.instance
      .put("/v1/offshift/beerdrive/refill", {
        locationId,
        beerDriveId
      })
      .then(() => {});
  }

  exportData(locationId: string, start: number, end: number) {
    return this.instance.post("/v1/offshift/export", {
      locationId,
      start,
      end
    });
  }

  exportAllData(x10: boolean, start: number, end: number) {
    return this.instance.post(`/v1/offshift/export/${x10 ? "extra10" : "m20"}/all`, {
      start,
      end
    });
  }

  setUserToken(token: string) {
    return localStorage.setItem("jcoreUserToken", token);
  }

  getContentfulEntries(
    contentfulToken?: SyncCollection["nextSyncToken"],
    dbTimestamp?: number,
    env: string = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
  ) {
    return this.instance.get(
      `/v1/translation/all?contentfulToken=${contentfulToken || ""}&dbTimestamp=${
        dbTimestamp || ""
      }&environmentId=${env}`,
      { timeout: 30000 }
    );
  }

  getHealthStatus() {
    return this.instance
      .get<IHealthStatusResponse[]>("/v1/locations/healthstatus")
      .then(result => result.data);
  }

  getX10HealthStatus() {
    return this.instance
      .get<IHealthStatusResponse[]>("/v1/extra10locations/healthstatus")
      .then(result => result.data);
  }

  getCountries() {
    return this.instance.get<ICountry[]>("/v1/controltower/countries").then(result => result.data);
  }

  createOutlet(outlet: ICreateOutletObject) {
    return this.instance.post("/v1/controltower/createoutlet", outlet).then(result => result.data);
  }

  editOutlet(outletId: string, outlet: ICreateOutletObject) {
    return this.instance
      .put(`/v1/controltower/editoutlet/${outletId}`, outlet)
      .then(result => result.data);
  }

  fetchPurchasingGroups() {
    return this.instance.get("/v1/controltower/purchasingGroup/all").then(result => result.data);
  }

  createPurchasingGroup(purchasingGroup: ICreatePurchasingGroupObject) {
    return this.instance
      .post("v1/controltower/purchasingGroup/create", purchasingGroup)
      .then(result => result.data);
  }

  editPurchasingGroup(pgObject: ICreatePurchasingGroupObject, pgId: string) {
    return this.instance
      .put(`/v1/controltower/purchasingGroup/edit/${pgId}`, pgObject)
      .then(result => result.data);
  }

  associatePurchasingGroupDistributors(pgId: string, distributorIds: string[]) {
    return this.instance
      .put(`/v1/controltower/purchasingGroup/associateDistributors/${pgId}`, distributorIds)
      .then(result => result.data);
  }

  fetchDistributors() {
    return this.instance.get("/v1/controltower/distributor/all").then(result => result.data);
  }

  createDistributor(distributorObject: ICreateDistributorObject) {
    return this.instance
      .post("/v1/controltower/distributor/create", distributorObject)
      .then(result => result.data);
  }

  editDistributor(distributorObject: ICreateDistributorObject, distributorId: string) {
    return this.instance
      .put(`/v1/controltower/distributor/edit/${distributorId}`, distributorObject)
      .then(result => result.data);
  }

  associateDistributorOutlets(distributorId: string, outletIds: string[]) {
    return this.instance
      .put(`/v1/controltower/distributor/associateOutlets/${distributorId}`, outletIds)
      .then(result => result.data);
  }

  setComponentBeverage(thingId: string, beverageId: string) {
    return this.instance
      .post("/v1/controltower/component/changebrand", { thingId, beverageId })
      .then(result => result.data);
  }

  private deleteUserTokens() {
    localStorage.removeItem("jcoreUserToken");
    localStorage.removeItem("azureUserToken");
  }

  private getUserToken() {
    return localStorage.getItem("jcoreUserToken") || this.temporaryToken;
  }
}

import { createGlobalStyle, css } from "styled-components";

const GlobalStyles = createGlobalStyle(
  ({ theme }) => css`
    html {
      font-size: ${theme.fontSize}px;
      height: 100%;
    }

    body {
      font-family: "${theme.fontFamily}";
      height: 100%;
      margin: 0;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    #root {
      background-color: var(--color-background);
      height: 100%;
      overflow: hidden auto;
      overscroll-behavior: none;
      -webkit-overflow-scrolling: touch;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    }

    a {
      color: ${theme.colors.primary};
      text-decoration: none;
    }

    p {
      line-height: ${theme.spaceUnit.lg};
      margin: 0;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }
  `
);

export default GlobalStyles;

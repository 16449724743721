import StyleConstants from "./styles/constants.scss";

export default {
  mobileBreakpoint1: parseInt(StyleConstants["mobile-breakpoint-1"], undefined),
  mobileBreakpoint2: parseInt(StyleConstants["mobile-breakpoint-2"], undefined)
};

export const RECAPTCHA_KEY = "6LdKrtUUAAAAAGi1c1WAq0_7r7n4tBpRv5WtSNo6";
export const DEFAULT_LANG = "en-GB";
export const CONTENTFUL_ENVIRONMENT = "dev";
export const DEFAULT_CLOUDFRONT_URL = "https://d2qh8he7lthwda.cloudfront.net";
export const DEFAULT_LOGO_BUCKET = "dmbuddy-jcore-beverage-logos-staging";
export const DEFAULT_PHOTO_BUCKET = "dmbuddy-jcore-ce-photos-staging";

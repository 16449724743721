import { IComponentStatus } from "../outlets/types";
import { EHealthStatusLevel } from "../healthStatus/types";
import { IChipSelector } from "../../components/common/data_table/DataTable";

export type ComponentLevelStatus = "DEFAULT" | "SUCCESS" | "WARNING" | "ERROR";
export const GET_COMPONENT_INFO = "components/GET_COMPONENT_INFO";
export const GET_COMPONENT_INFO_SUCCESS = "components/GET_COMPONENT_INFO_SUCCESS";
export const GET_COMPONENT_INFO_ERROR = "components/GET_COMPONENT_INFO_ERROR";
export const GET_COMPONENT_LINES = "components/GET_COMPONENT_LINES";
export const GET_COMPONENT_LINES_SUCCESS = "components/GET_COMPONENT_LINES_SUCCESS";
export const GET_COMPONENT_LINES_ERROR = "components/GET_COMPONENT_LINES_ERROR";
export const GET_COMPONENT_HEALTH_STATUS = "components/GET_COMPONENT_HEALTH_STATUS";
export const GET_COMPONENT_HEALTH_STATUS_SUCCESS = "components/GET_COMPONENT_HEALTH_STATUS_SUCCESS";
export const GET_COMPONENT_HEALTH_STATUS_ERROR = "components/GET_COMPONENT_HEALTH_STATUS_ERROR";

export interface IComponentInfo {
  id: string;
  thingId: string;
  name?: string;
  type: string;
  typeContent?: string;
  position: number;
  boardSerialNumber: string;
  primaryUnitId?: string;
  createDateTime: string;
  tapsPositions?: string[];
  beverageId?: string;
  volumeLeft?: number;
  volume?: number;
  hasPressureSwitcher?: boolean;
  status?: IComponentStatus[];
  alive?: boolean;
  unit?: string;
  createdByTechnician?: ICreatedByTechnician;
  brand?: {
    photo: string;
    title: string;
  };
}

export interface IControlUnitInfo extends IComponentInfo {
  detVolume?: number;
}

export interface ICoolingUnitInfo extends IComponentInfo {
  line?: {
    id: string;
    type: string;
  };
  affectedTapsPositions?: number[];
  temperatureReading0?: number;
  temperatureReading1?: number;
  temperatureReading2?: number;
}

export interface ICreatedByTechnician {
  id: string;
  name: string;
}

export enum EComponentType {
  ControlUnit = "ControlUnit",
  PressureChamber = "PressureChamber",
  SteelKegUnit = "SteelKegUnit",
  CoolingUnit = "CoolingUnit",
  BeerDrive = "BeerDrive",
  GenericComponent = "GenericComponent",
  Compressor = "Compressor",
  X10 = "X10"
}

export enum EAlertType {
  PRESSURE_CHAMBER_NO_HEARTBEATS = "PRESSURE_CHAMBER_NO_HEARTBEATS",
  PRESSURE_CHAMBER_DISCONNECTED_SENSOR = "PRESSURE_CHAMBER_DISCONNECTED_SENSOR",
  PRESSURE_CHAMBER_FAULTY_SENSOR = "PRESSURE_CHAMBER_FAULTY_SENSOR",
  PRESSURE_CHAMBER_FAULTY_LEAK = "PRESSURE_CHAMBER_FAULTY_LEAK"
}

export enum ECleaningStep {
  BEER_POUR = "BEER_POUR",
  STANDBY = "STANDBY",
  PRESSURE_TEST = "PRESSURE_TEST",
  AIR_FLUSH = "AIR_FLUSH",
  WATER_FLUSH = "WATER_FLUSH",
  CLEAN_CONFIRM = "CLEAN_CONFIRM",
  CLEAN_LOAD_DETERGENT = "CLEAN_LOAD_DETERGENT",
  CLEAN_PRE_RINSE_WATER = "CLEAN_PRE_RINSE_WATER",
  CLEAN_PRIME_DETERGENT = "CLEAN_PRIME_DETERGENT",
  CLEAN_DISPENSE = "CLEAN_DISPENSE",
  CLEAN_SOAK = "CLEAN_SOAK",
  CLEAN_POST_RINSE_DETERGENT = "CLEAN_POST_RINSE_DETERGENT",
  CLEAN_POST_RINSE_DETERGENT_SAFETY = "CLEAN_POST_RINSE_DETERGENT_SAFETY",
  CLEAN_POST_RINSE_WATER = "CLEAN_POST_RINSE_WATER",
  CLEAN_POST_RINSE_AIR = "CLEAN_POST_RINSE_AIR",
  CLEAN_VALIDATE = "CLEAN_VALIDATE",
  CLEAN_STOP = "CLEAN_STOP",
  SOFT_FAULT = "SOFT_FAULT",
  HARD_FAULT = "HARD_FAULT",
  CLEAN_PAUSE = "CLEAN_PAUSE"
}

export interface IComponentHealthStatus {
  alive: boolean;
  bestBefore: string;
  componentId: string;
  eventHistory?: IEventHistory[];
  existsLeak: boolean;
  faulty: boolean;
  healthStatus?: IHealthStatus[];
  kegChanges?: string[];
  open: boolean;
  sensorConnected: boolean;
  timeOnKegStatus?: string;
  temperatureReading0?: number;
  temperatureReading1?: number;
  temperatureReading2?: number;
  temperatureReading0Position?: string;
  temperatureReading1Position?: string;
  temperatureReading2Position?: string;
  state?: ECleaningStep;
  cleaningDue?: number;
  cleaningCyclesFromLast90days?: number;
}

export interface IEventHistory {
  type: string;
  date: string;
}

export interface IHealthStatus {
  chipSelectors: IChipSelector[];
  createDateTime: string;
  customFields: string;
  level: EHealthStatusLevel;
  levelContent: string;
  locationId: string;
  target: string;
  type: string;
  typeContent: string;
  infoContent: string;
}

export interface ILine {
  id: string;
  type: "COMPRESSOR" | "CLEANING" | "COOLING";
  number: number;
  components: { id: string; type: IConnectedComponentType; position: number }[];
}

export type IConnectedComponentType =
  | "ControlUnit"
  | "PressureChamber"
  | "CoolingUnit"
  | "BeerDrive"
  | "GenericComponent";

export interface IComponentState {
  currentComponent?: IComponentInfo | IControlUnitInfo | ICoolingUnitInfo;
  componentLines?: ILine[];
  componentHealthStatus?: IComponentHealthStatus;
  requestingComponent: boolean;
  requestingComponentLines: boolean;
  requestingComponentHealthStatus: boolean;
  requestComponentError: boolean;
  requestLinesError: boolean;
  requestHealthStatusError: boolean;
}

export interface IGetComponentInfoAction {
  type: typeof GET_COMPONENT_INFO;
  payload: string;
}

export interface IGetComponentInfoSuccessAction {
  type: typeof GET_COMPONENT_INFO_SUCCESS;
  payload: IComponentInfo;
}

export interface IGetComponentInfoErrorAction {
  type: typeof GET_COMPONENT_INFO_ERROR;
  payload: string;
}

export interface IGetComponentLinesAction {
  type: typeof GET_COMPONENT_LINES;
  payload: string;
}

export interface IGetComponentLinesSuccessAction {
  type: typeof GET_COMPONENT_LINES_SUCCESS;
  payload: { lines: ILine[]; id: string };
}

export interface IGetComponentLinesErrorAction {
  type: typeof GET_COMPONENT_LINES_ERROR;
  payload: string;
}

export interface IGetComponentHealthStatusAction {
  type: typeof GET_COMPONENT_HEALTH_STATUS;
  payload: string;
}

export interface IGetComponentHealthStatusSuccessAction {
  type: typeof GET_COMPONENT_HEALTH_STATUS_SUCCESS;
  payload: IComponentHealthStatus;
}

export interface IGetComponentHealthStatusErrorAction {
  type: typeof GET_COMPONENT_HEALTH_STATUS_ERROR;
  payload: string;
}
export type ComponentActionTypes =
  | IGetComponentInfoAction
  | IGetComponentInfoSuccessAction
  | IGetComponentInfoErrorAction
  | IGetComponentLinesAction
  | IGetComponentLinesSuccessAction
  | IGetComponentLinesErrorAction
  | IGetComponentHealthStatusAction
  | IGetComponentHealthStatusSuccessAction
  | IGetComponentHealthStatusErrorAction;

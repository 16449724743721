import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import { getPurchasingGroups } from "../../store/purchasingGroups/actions";
import {
  getHasCreatePurchasingGroupError,
  getIsCreatingPurchasingGroup,
  getIsRequestingPurchasingGroups,
  getPurchasingGroup
} from "../../store/purchasingGroups/selectors";
import Header from "../common/header/Header";
import Tabs, { ITab } from "../common/tabs/Tabs";
import { LoadingIcon } from "../common/data_table/dataTable.styles";
import PurchasingGroupInfo from "./PurchasingGroupInfo";
import PurchasingGroupDistributors from "./PurchasingGroupDistributors";
import AssociatePurchasingGroupForm from "./AssociatePurchasingGroupForm";

interface IProps {
  purchasingGroupId: string;
}

const PurchasingGroupDetails: React.FC<IProps> = ({ purchasingGroupId }) => {
  const dispatch = useDispatch();
  const { common, purchasingGroups: content } = useContent();
  const isLoading = useSelector(getIsRequestingPurchasingGroups);
  const purchasingGroup = useSelector(getPurchasingGroup(purchasingGroupId));
  const isCreatingPurchasingGroup = useSelector(getIsCreatingPurchasingGroup);
  const hasCreatePurchasingGroupError = useSelector(getHasCreatePurchasingGroupError);
  const [showAssociatePgForm, setShowAssociatePgForm] = useState(false);

  useEffect(() => {
    if (!purchasingGroup) {
      dispatch(getPurchasingGroups());
    }
  }, []);

  // Close sidebar if distributor was successfully edited
  useEffect(() => {
    if (!isCreatingPurchasingGroup && !hasCreatePurchasingGroupError && showAssociatePgForm) {
      setShowAssociatePgForm(false);
    }
  }, [isCreatingPurchasingGroup]);

  const headerActions = useMemo(
    () =>
      purchasingGroup && [
        {
          label: content.purchasing_groups_associate,
          onClick: () => {
            setShowAssociatePgForm(true);
          }
        }
      ],
    [purchasingGroup, content]
  );

  const tabs = useMemo<ITab[]>(
    () => [
      {
        content: <PurchasingGroupInfo purchasingGroup={purchasingGroup} />,
        label: common.common_info,
        route: Routes.purchasingGroupInfo.replace(":id", purchasingGroupId)
      },
      {
        content: <PurchasingGroupDistributors purchasingGroup={purchasingGroup} />,
        label: common.common_distributors,
        route: Routes.purchasingGroupDistributors.replace(":id", purchasingGroupId)
      }
    ],
    [purchasingGroup, common]
  );

  return (
    <div>
      <Header
        title={purchasingGroup?.name}
        breadcrumbs={[{ link: Routes.purchasingGroups, name: content.purchasing_groups_title }]}
        actions={headerActions}
      />
      <div style={{ overflow: "auto", flex: 1 }}>
        {isLoading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingIcon />
          </div>
        ) : purchasingGroup ? (
          <Tabs tabs={tabs} />
        ) : (
          <div>
            {content.purchasing_groups_details_not_found.replace("%ID%", purchasingGroupId)}
          </div>
        )}
      </div>
      <AssociatePurchasingGroupForm
        purchasingGroup={purchasingGroup}
        visible={showAssociatePgForm}
        onClose={() => {
          setShowAssociatePgForm(false);
        }}
      />
    </div>
  );
};

export default PurchasingGroupDetails;

import React, { useContext, useState } from "react";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { NotificationContext } from "@ddm-design-system/notification";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { BodyHighlight } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";
import { AnalyticsContext } from "../../services/analytics";
import { validateEmail } from "../../helpers";

interface IProps {
  username: string;
  setUsername: (s: string) => void;
  setForgotPassword: (b: boolean) => void;
}

export const RecoverPassword: React.FC<IProps> = ({ username, setUsername, setForgotPassword }) => {
  const { loginControlTower: content, common: commonContent } = useContent();
  const [emailError, setEmailError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const auth = useContext(AuthContext);
  const analytics = useContext(AnalyticsContext);
  const notificationContext = useContext(NotificationContext);

  const sendRecoveryEmail = async (email: string) => {
    setEmailError(false);
    if (
      // eslint-disable-next-line no-useless-escape
      !validateEmail(email)
    ) {
      setEmailError(true);
      return false;
    }
    try {
      await auth.sendRecoveryEmail(email);
      analytics.logEvent("FORGOT_PASSWORD", email);
      notificationContext.addNotification({
        iconProps: { name: "Ok" },
        text: content.control_tower_forgot_password_success
      });
      return true;
    } catch (e) {
      notificationContext.addNotification({
        iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
        text: content.control_tower_forgot_password_error
      });
      return false;
    }
  };

  const auxForgotPassword = async () => {
    if (emailSent) {
      return;
    }
    setEmailSent(true);
    setEmailError(false);
    const result = await sendRecoveryEmail(username);
    if (result) {
      setEmailSent(false);
      setForgotPassword(false);
    } else {
      setEmailSent(false);
      setEmailError(true);
    }
  };

  return (
    <>
      <BodyHighlight className="mt-lg xs:mt-md">
        {content.control_tower_forgot_password_title}
      </BodyHighlight>
      <form className="flex flex-col mt-xxl w-full xs:mt-lg" onSubmit={e => e.preventDefault()}>
        <ErrorTextInput
          autoComplete="username"
          error={emailError ? content.control_tower_forgot_password_error : undefined}
          label={content.control_tower_login_email_label}
          name="username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <div className="flex justify-end items-center mt-xxl gap-md">
          <SecondaryButton disabled={emailSent} onClick={() => setForgotPassword(false)}>
            {commonContent.common_cancel}
          </SecondaryButton>
          <PrimaryButton
            disabled={username === "" || emailSent}
            type="submit"
            onClick={auxForgotPassword}
          >
            Confirm
          </PrimaryButton>
        </div>
      </form>
    </>
  );
};
export default RecoverPassword;

import React, { useCallback, useState } from "react";
import { Description, Subtitle } from "@ddm-design-system/typography";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "@ddm-design-system/button";
import styled, { css } from "styled-components";
import useContent from "../../../hooks/useContent";
import SidebarModal from "../../common/sidebar_modal/SidebarModal";
import { CloseIcon } from "../../common/icon/CloseIcon.styles";
import { saveOutletDescription } from "../../../store/outlets/actions";

interface IProps {
  visible: boolean;
  onClose: () => void;
  outletId: string;
  installationId: string;
}

const StyledTextArea = styled.textarea(
  ({ theme }) => css`
    height: 300px;
    resize: none;
    padding: ${theme.spaceUnit.md};
    border: 1px solid ${theme.colors.grey.grey50};
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    ${theme.typography.body};

    &:focus,
    &:active {
      border-color: ${theme.colors.black};
    }
  `
);

const AddNewNoteSidebar: React.FC<IProps> = ({ outletId, installationId, visible, onClose }) => {
  const { outletDetails: content, common } = useContent();
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");

  const saveDescription = useCallback(() => {
    dispatch(saveOutletDescription(outletId, installationId, description));
  }, [dispatch, outletId, installationId, description]);

  return (
    <SidebarModal visible={visible} onClose={onClose}>
      <div className="flex flex-col h-full">
        <div className="h-[75px]">
          <div className="flex justify-between items-center">
            <Subtitle>{content.outlet_details_add_new_note}</Subtitle>
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        <div className="pt-md">
          <Description>{content.outlet_details_note_replace}</Description>
        </div>
        <div className="w-full mt-xl">
          <StyledTextArea
            placeholder={content.outlet_details_write_something_about}
            value={description}
            onChange={e => setDescription(e.target.value)}
            onBlur={() => saveDescription()}
          />
        </div>
        <div className="flex flex-col items-end mt-md">
          <PrimaryButton onClick={saveDescription}>{common.common_save}</PrimaryButton>
        </div>
      </div>
    </SidebarModal>
  );
};

export default AddNewNoteSidebar;

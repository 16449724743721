import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useContent from "../../hooks/useContent";
import { getHealthStatus } from "../../store/healthStatus/actions";
import Header from "../common/header/Header";
import Alerts from "./Alerts";

const HealthMonitor: React.FC = () => {
  const { healthMonitor: content } = useContent();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHealthStatus());
  }, [dispatch]);

  return (
    <div className="pb-xl">
      <Header title={content.healthMonitor_title} />
      <Alerts />
    </div>
  );
};

export default HealthMonitor;

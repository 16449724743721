import Time from "./Time";

export function day8(date: any) {
  const newDate = new Time(date);

  if (newDate.getHour() >= 8) {
    newDate.set({
      hour: 8,
      minute: 0,
      second: 0,
      millisecond: 0
    });
  } else {
    newDate.sub({ day: 1 }).set({
      hour: 8,
      minute: 0,
      second: 0,
      millisecond: 0
    });
  }

  return newDate;
}

import { Icon } from "@ddm-design-system/icon";
import { ddmTheme } from "@ddm-design-system/tokens";
import { BodyHighlight } from "@ddm-design-system/typography";
import React, { useCallback } from "react";
import useContent from "../../../../../hooks/useContent";
import { IComponentHealthStatus } from "../../../../../store/components/types";
import { FieldValue } from "../../../../common/fieldValue/fieldValue.styles";

interface IProps {
  healthStatus: IComponentHealthStatus;
}

const Status: React.FC<IProps> = ({ healthStatus }) => {
  const { common, outletDetails: content } = useContent();

  const renderComponentStatus = useCallback(() => {
    return (
      <div className="flex">
        <div className="flex-1">
          <div className="field-wrapper">
            <div className="field-section">
              <div className="field">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon
                    name="Wifi"
                    fill={
                      healthStatus?.alive
                        ? ddmTheme.colors.success.success100
                        : ddmTheme.colors.alert.alert100
                    }
                  />
                  {content.outlet_component_wifi_connection}
                </BodyHighlight>
                <FieldValue>
                  {healthStatus?.alive ? common.common_good : common.common_offline}
                </FieldValue>
              </div>
              <div className="field items-start">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon
                    name="Sleep"
                    fill={
                      healthStatus?.timeOnKegStatus === "NEUTRAL"
                        ? ddmTheme.colors.success.success100
                        : healthStatus?.timeOnKegStatus === "WARNING"
                        ? ddmTheme.colors.warning.warning100
                        : ddmTheme.colors.alert.alert100
                    }
                  />
                  {content.outlet_component_time_on_keg}
                </BodyHighlight>
                <FieldValue>
                  {healthStatus?.timeOnKegStatus === "NEUTRAL"
                    ? common.common_good
                    : healthStatus?.timeOnKegStatus === "WARNING"
                    ? common.common_warning
                    : common.common_error}
                </FieldValue>
              </div>
              {healthStatus?.bestBefore ? (
                <div className="field">
                  <BodyHighlight className="flex items-center gap-xs">
                    <Icon
                      name="CalendarCircle"
                      className="w-md h-md"
                      fill={
                        new Date() < new Date(healthStatus.bestBefore)
                          ? ddmTheme.colors.success.success100
                          : ddmTheme.colors.alert.alert100
                      }
                    />
                    {content.outlet_component_expiration_date}
                  </BodyHighlight>
                  <FieldValue>
                    {new Date() < new Date(healthStatus.bestBefore)
                      ? common.common_within_recommended_values
                      : common.common_expired}
                  </FieldValue>
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="field-section">
              <div className="field">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon
                    name="Sensor"
                    fill={
                      healthStatus?.sensorConnected && !healthStatus?.faulty
                        ? ddmTheme.colors.success.success100
                        : ddmTheme.colors.alert.alert100
                    }
                  />
                  {content.outlet_component_sensor_status}
                </BodyHighlight>
                <FieldValue>
                  {!healthStatus?.sensorConnected
                    ? common.common_not_connected
                    : healthStatus?.faulty
                    ? common.common_problem_detected
                    : common.common_good}
                </FieldValue>
              </div>
              <div className="field">
                <BodyHighlight className="flex items-center gap-xs">
                  <Icon
                    name={!healthStatus?.open && !healthStatus?.existsLeak ? "Closed" : "Opened"}
                    fill={
                      !healthStatus?.open && !healthStatus?.existsLeak
                        ? ddmTheme.colors.success.success100
                        : ddmTheme.colors.alert.alert100
                    }
                  />
                  {content.outlet_component_lid_status_or_leaks}
                </BodyHighlight>
                <FieldValue>
                  {healthStatus?.open
                    ? common.common_open
                    : healthStatus?.existsLeak
                    ? content.outlet_component_pressure_chamber_leak
                    : common.common_closed}
                </FieldValue>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [content, common, healthStatus]);

  return renderComponentStatus();
};

export default Status;

import { createSelector } from "reselect";
import { IAppState } from "..";
import { ICELocation } from "./types";

export const getCeLocations = (state: IAppState) => state.ceLocations.ceLocations;

export const getLoadingCeLocations = (state: IAppState) => state.ceLocations.requestingCeLocations;

export const getCeLocation = (id: string) =>
  createSelector(getCeLocations, (ceLocations: ICELocation[]) =>
    ceLocations.find(ceLocation => ceLocation.id === id)
  );

import React from "react";
import { BodyHighlight, Description, Label } from "@ddm-design-system/typography";
import useContent from "../../../../../hooks/useContent";
import { FieldValue } from "../../../../common/fieldValue/fieldValue.styles";
import { ICoolingUnitInfo } from "../../../../../store/components/types";
import { hexToDec } from "../../../../../helpers";

interface IProps {
  componentInfo: ICoolingUnitInfo;
}

const Info: React.FC<IProps> = ({ componentInfo }) => {
  const { outletDetails: content, common } = useContent();

  return (
    <div className="flex">
      <div className="flex-1">
        <div className="field-wrapper">
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{content.outlet_details_thing_id}</BodyHighlight>
              <FieldValue>{componentInfo?.thingId || "--"}</FieldValue>
            </div>
            <div className="field">
              <BodyHighlight>{common.common_line}</BodyHighlight>
              <FieldValue>
                {componentInfo?.line?.id
                  ? `${common.common_coolingLine} ${componentInfo?.position}`
                  : content.outlet_component_no_lines}
              </FieldValue>
            </div>
          </div>
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{common.common_unit}</BodyHighlight>
              <FieldValue>{componentInfo?.unit}</FieldValue>
            </div>
            <div className="field">
              <BodyHighlight>{common.common_taps}</BodyHighlight>
              <FieldValue>{componentInfo?.affectedTapsPositions?.join(", ") || "--"}</FieldValue>
            </div>
          </div>
        </div>
        <div className="field-wrapper">
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{common.common_stick_number}</BodyHighlight>
              <FieldValue>{componentInfo?.position}</FieldValue>
            </div>
            <div className="field">
              <BodyHighlight>{common.common_serial_number}</BodyHighlight>
              <FieldValue className="flex flex-col">
                <Description>
                  {componentInfo?.boardSerialNumber !== "--"
                    ? hexToDec(componentInfo?.boardSerialNumber)
                    : componentInfo?.boardSerialNumber}
                </Description>
                <Label className="text-grey-grey100 mt-xs">
                  ({componentInfo?.boardSerialNumber})
                </Label>
              </FieldValue>
            </div>
          </div>
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{common.common_status}</BodyHighlight>
              <FieldValue>{componentInfo?.alive}</FieldValue>
            </div>
            <div className="field">
              <BodyHighlight>{content.outlet_details_sensor_temperature}</BodyHighlight>
              <FieldValue>
                {`${common.common_sensor} 1 • ${
                  componentInfo?.temperatureReading0
                    ? componentInfo?.temperatureReading0 / 100
                    : "--"
                }ºC`}
              </FieldValue>
              <FieldValue>
                {`${common.common_sensor} 2 • ${
                  componentInfo?.temperatureReading1
                    ? componentInfo?.temperatureReading1 / 100
                    : "--"
                }ºC`}
              </FieldValue>
              <FieldValue>
                {`${common.common_sensor} 3 • ${
                  componentInfo?.temperatureReading2
                    ? componentInfo?.temperatureReading2 / 100
                    : "--"
                }ºC`}
              </FieldValue>
            </div>
            <div className="field">
              <BodyHighlight>{common.common_installed_on}</BodyHighlight>
              <FieldValue>{componentInfo?.createDateTime}</FieldValue>
              <FieldValue className="!text-sm !text-grey-grey100">
                {componentInfo?.createdByTechnician?.name
                  ? `${common.common_by} ${componentInfo?.createdByTechnician?.name}`
                  : ""}
              </FieldValue>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;

import { IconNames } from "@ddm-design-system/icon";
import { IChipSelector } from "../../components/common/data_table/DataTable";
import { IBeverage, ICountry } from "../beverages/types";
import { iconStatusMap } from "./helpers";

export const GET_X10_OUTLETS_REQUEST = "extra10outlets/GET_X10_OUTLETS_REQUEST";
export const GET_X10_OUTLETS_SUCCESS = "extra10outlets/GET_X10_OUTLETS_SUCCESS";
export const GET_X10_OUTLETS_ERROR = "extra10outlets/GET_X10_OUTLETS_ERROR";
// new Outlet Requests
export const GET_X10_OUTLET_INFO = "extra10outlets/GET_X10_OUTLET_INFO";
export const GET_X10_OUTLET_INFO_SUCCESS = "extra10outlets/GET_X10_OUTLET_INFO_SUCCESS";
export const GET_X10_OUTLET_INSTALLATION = "extra10outlets/GET_X10_OUTLET_INSTALLATION";
export const GET_X10_OUTLET_INSTALLATION_SUCCESS =
  "extra10outlets/GET_X10_OUTLET_INSTALLATION_SUCCESS";
export const SET_COMPONENT_BEVERAGE = "extra10outlets/SET_COMPONENT_BEVERAGE";
export const SET_COMPONENT_BEVERAGE_ERROR = "components/SET_COMPONENT_BEVERAGE_ERROR";

export enum EExtra10OutletStatusLevel {
  NEUTRAL = "NEUTRAL",
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS"
}

export enum EExtra10OutletStatus {
  NO_INSTALLATION = "NO_INSTALLATION",
  OFFLINE_INSTALLATION = "OFFLINE_INSTALLATION",
  OFFLINE_COMPONENT = "OFFLINE_COMPONENT",
  OK = "OK"
}

export enum EExtra10OutletStatusBadges {
  NO_INSTALLATION = "NO_INSTALLATION",
  OFFLINE_INSTALLATION = "OFFLINE_INSTALLATION",
  ERROR = "ERROR",
  WARNING = "WARNING",
  OK = "OK"
}

export interface IExtra10OutletsState {
  outlets: IExtra10Location[];
  requestingOutlets: boolean;
  requestOutletsError: boolean;
  errorChangingBeverage: boolean;
}

export interface IExtra10OutletStatus {
  id: string;
  name: string;
  chipSelectors: IChipSelector[];
  status: EExtra10OutletStatusBadges;
  statusContent: string;
  components: number;
  lastEdit?: number;
  createDateTime?: number;
}

export interface IExtra10Status {
  id: string;
  name: string;
  boardSerialNumber: string;
  brand: { photo: string; title: string }[];
  volume: string;
  status: Array<{ level: ComponentLevelStatus; icon: IconNames }>;
}

export interface IExtra10Location {
  id: string;
  installationId: string;
  name: string;
  status: Array<{
    level: EExtra10OutletStatusLevel;
    message: EExtra10OutletStatus;
  }>;
  updateDateTime: string;
  components: number;
  createDateTime: string;
  contacts?: string[];
  locationData?: {
    country?: ICountry;
  };
  installation?: IExtra10Installation;
  distributorName?: string;
  clientNumberDistributor?: string;
}

export interface IExtra10Installation {
  id: string;
  x10s: IExtra10[];
}

export interface IConnectedComponent {
  id: string;
  name: string;
  boardSerialNumber: string;
  status?: IComponentStatus[];

  lastHeartbeat?: string | null;
  lastBootup?: string | null;
  firmware?: string | null;
  serialNumber?: string | null;
  alive: boolean;
  thingId: string;
  createDateTime: string | number;
  locationName?: string;
  sensorConnected?: boolean;
  existsLeak?: boolean;
}

export type ComponentLevelStatus = "DEFAULT" | "SUCCESS" | "WARNING" | "ERROR";

export interface IComponentStatus {
  level: ComponentLevelStatus;
  message: keyof typeof iconStatusMap;
}

export interface IKeg {
  id: string;
  volume: number;
  volumeLeft: number;
  beverage: IBeverage;
}

export interface IExtra10 extends IConnectedComponent {
  volumeLeft?: number;
  beverageId?: string;

  open?: boolean;
  keg?: IKeg | null;
}

export interface IGetExtra10OutletsRequestAction {
  type: typeof GET_X10_OUTLETS_REQUEST;
}

export interface IGetExtra10OutletsSuccessAction {
  type: typeof GET_X10_OUTLETS_SUCCESS;
  payload: IExtra10Location[];
}

export interface IGetExtra10OutletsErrorAction {
  type: typeof GET_X10_OUTLETS_ERROR;
}

export interface IGetExtra10OutletInfoAction {
  type: typeof GET_X10_OUTLET_INFO;
  payload: string;
}

export interface IGetExtra10OutletInfoSuccessAction {
  type: typeof GET_X10_OUTLET_INFO_SUCCESS;
  payload: IExtra10Location;
}

export interface IGetExtra10OutletInstallationAction {
  type: typeof GET_X10_OUTLET_INSTALLATION;
  payload: string;
}

export interface IGetExtra10OutletInstallationSuccessAction {
  type: typeof GET_X10_OUTLET_INSTALLATION_SUCCESS;
  payload: { id: string; installation: IExtra10Installation };
}

export interface ISetComponentBeverageAction {
  type: typeof SET_COMPONENT_BEVERAGE;
  payload: { thingId: string; beverageId: string; componentId: string };
}

export interface ISetComponentBeverageError {
  type: typeof SET_COMPONENT_BEVERAGE_ERROR;
}

export type Extra10OutletsActionTypes =
  | IGetExtra10OutletsRequestAction
  | IGetExtra10OutletsSuccessAction
  | IGetExtra10OutletsErrorAction
  | IGetExtra10OutletInfoSuccessAction
  | IGetExtra10OutletInstallationSuccessAction
  | ISetComponentBeverageAction
  | ISetComponentBeverageError;

import { IEntries } from "./index";
import { translate, translateString } from "./translate";

interface IParsedString {
  [key: string]: string;
}
export interface ITranslatableString {
  fields: {
    content: { [key: string]: string };
    contentLong: { [key: string]: string };
    stringKey: { [key: string]: string };
  };
}
export interface IContentfulPage {
  fields: {
    pageStrings: { [key: string]: ITranslatableString[] };
    pageId: { [key: string]: string };
  };
}
export default function mapPage(entries: IEntries, lang: string) {
  const parsedPages = entries.controlTowerPage.ordered.map((page: IContentfulPage) => {
    // translate page strings

    const strings: ITranslatableString[] = translate(page.fields.pageStrings) || [];
    const parsedStrings: IParsedString = {};

    strings.forEach(s => {
      if (!s.fields) {
        return;
      }

      const key = translateString(s.fields.stringKey);
      const contentLong = translateString(s.fields.contentLong, lang);

      parsedStrings[key] =
        contentLong !== "" ? contentLong : translateString(s.fields.content, lang);
    });

    // const images: any[] = translate(page.fields.pageImages) || [];
    // const parsedImages = {};

    // images.forEach(s => {
    //   const image = translateImage(s, lang, true);
    //   parsedImages[image.title] = image;
    // });

    return {
      pageId: translateString(page.fields.pageId, lang),
      ...parsedStrings
      // ...parsedSteps,
      // pageImages: parsedImages
    };
  });

  const pages: {
    [key: string]: { [key: string]: string };
  } = {};

  parsedPages.forEach(p => {
    pages[p.pageId] = p;
  });

  return {
    ...pages
  };
}

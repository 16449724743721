import React from "react";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { Radio, RadioGroup } from "@ddm-design-system/radio";
import classnames from "classnames";
import { StyledDropdown, StyledTextInput } from "./createOutletForm.styles";
import { IQuestion, QuestionType } from "../../hooks/useGetQuestionnaire";

interface IProps {
  questions: IQuestion[];
  values: any;
  onSet: (values: any) => void;
}

const CreateOutletFormQuestion: React.FC<IProps> = ({ questions, values, onSet }) => {
  return (
    <form className="flex-1 overflow-auto">
      {questions?.map(q => (
        <React.Fragment key={q.field}>
          <div className="flex flex-col mb-xxl">
            <SectionTitle>{q.question}</SectionTitle>
            <Body className="text-grey-grey100 mb-lg">{q.hint}</Body>
            <div>
              {q.type === QuestionType.INPUT ? (
                <StyledTextInput
                  label={q.placeholder}
                  value={values[q.field]}
                  maxLength={100}
                  onChange={e => onSet({ ...values, [q.field]: e.target.value })}
                  disabled={q.disabled}
                />
              ) : q.type === QuestionType.DROPDOWN ? (
                <StyledDropdown
                  placeholder={q.placeholder}
                  items={q.options}
                  selectedItem={values[q.field]}
                  onItemSelected={item => onSet({ ...values, [q.field]: item })}
                />
              ) : q.type === QuestionType.RADIO ? (
                <RadioGroup
                  name={q.field}
                  selectedValue={values[q.field]}
                  onValueSelect={e => {
                    onSet({ ...values, [q.field]: e });
                  }}
                >
                  {q.options?.map((option: any) => (
                    <Radio
                      key={`${option.id}`}
                      className={classnames(
                        "cursor-pointer py-sm px-lg rounded-10 border-[1px] border-solid ",
                        {
                          "border-grey-grey50": values[q.field] !== option.id,
                          "border-black": values[q.field] === option.id
                        }
                      )}
                      value={option.id}
                      label={option.text}
                    />
                  ))}
                </RadioGroup>
              ) : (
                <></>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
    </form>
  );
};

export default CreateOutletFormQuestion;

import { call, put, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import {
  getComponentInfoSuccess,
  getComponentLinesSuccess,
  getComponentHealthStatusSuccess,
  getComponentInfoError,
  getComponentLinesError,
  getComponentHealthStatusError
} from "../store/components/actions";
import {
  GET_COMPONENT_INFO,
  GET_COMPONENT_LINES,
  GET_COMPONENT_HEALTH_STATUS,
  IGetComponentInfoAction,
  IGetComponentLinesAction,
  IGetComponentHealthStatusAction
} from "../store/components/types";

export function* getComponentInfo(jCoreService: IJCoreService, action: IGetComponentInfoAction) {
  try {
    const result = yield call([jCoreService, jCoreService.getComponentInfo], action.payload);

    yield put(getComponentInfoSuccess({ ...result }));
  } catch {
    yield put(getComponentInfoError(action.payload));
  }
}

export function* getComponentLines(jCoreService: IJCoreService, action: IGetComponentLinesAction) {
  try {
    const result = yield call([jCoreService, jCoreService.getComponentLines], action.payload);

    yield put(getComponentLinesSuccess({ lines: result?.groupings, id: action.payload }));
  } catch {
    yield put(getComponentLinesError(action.payload));
  }
}

export function* getComponentHealthStatus(
  jCoreService: IJCoreService,
  action: IGetComponentHealthStatusAction
) {
  try {
    const result = yield call(
      [jCoreService, jCoreService.getComponentHealthStatus],
      action.payload
    );

    yield put(getComponentHealthStatusSuccess({ ...result, componentId: action.payload }));
  } catch {
    yield put(getComponentHealthStatusError(action.payload));
  }
}

export function* componentsWatcher(context: IAppContext) {
  yield takeLatest(GET_COMPONENT_INFO, getComponentInfo, context.jCoreService);
  yield takeLatest(GET_COMPONENT_LINES, getComponentLines, context.jCoreService);
  yield takeLatest(GET_COMPONENT_HEALTH_STATUS, getComponentHealthStatus, context.jCoreService);
}

export default componentsWatcher;

import styled, { css } from "styled-components";
import { Sidebar } from "@ddm-design-system/sidebar";
import { EBreakpoints } from "@ddm-design-system/tokens";

export const StyledSidebar = styled(Sidebar)(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: auto;
    padding: ${theme.spaceUnit.xxl} ${theme.spaceUnit.lg} ${theme.spaceUnit.lg};
    position: fixed !important;
    width: 369px;

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      width: 100%;
    }
  `
);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Body, Subtitle } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import useContent from "../../hooks/useContent";
import SidebarModal from "../common/sidebar_modal/SidebarModal";
import { CreateOutletFooter } from "./createOutletForm.styles";
import { getCountriesForMe, getCreateOrEditOutletState } from "../../store/outlets/selectors";
import { createOutlet, editOutlet, resetOutletFormState } from "../../store/outlets/actions";
import { ILocation } from "../../store/outlets/types";
import { CloseIcon } from "../common/icon/CloseIcon.styles";
import CreateOutletFormContent from "./CreateOutletFormContent";
import useGetQuestionnaire from "../../hooks/useGetQuestionnaire";

interface IProps {
  visible: boolean;
  onClose: () => void;
  outlet?: ILocation;
}

const CreateOutletForm: React.FC<IProps> = ({ outlet, visible, onClose }) => {
  const { outlets: content, common } = useContent();
  const dispatch = useDispatch();
  const countryList = useSelector(getCountriesForMe);
  const createOrEditOutletState = useSelector(getCreateOrEditOutletState);
  const [values, setValues] = useState<any>({});
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useGetQuestionnaire(outlet, values);

  useEffect(() => {
    if (!outlet) {
      return;
    }

    const initValues = {
      name: outlet.name,
      countryId: outlet.locationData?.country?.id || "",
      ...outlet?.questionnaire
    };
    setValues(initValues);
  }, [outlet]);

  useEffect(() => {
    const own = countryList.find(c => c.selected);

    if (own) {
      setValues({ ...values, countryId: own });
    }
  }, [countryList]);

  const createOutletAux = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      return;
    }

    const outletObj: any = {
      name: values.name?.trim(),
      countryId: values.countryId?.id || values.countryId
    };

    const temp = { ...values };
    delete temp.name;
    delete temp.countryId;
    outletObj.questionnaire = JSON.stringify({ ...temp });

    if (outlet) {
      if (outletObj.name === outlet.name) {
        delete outletObj.name;
      }
      dispatch(editOutlet(outlet.id, outletObj));
    } else {
      dispatch(createOutlet(outletObj));
    }
  }, [outlet, values, dispatch, steps, currentStep]);

  const onCloseAux = (force = false) => {
    if (!force && currentStep > 0) {
      setCurrentStep(currentStep - 1);
      return;
    }
    const own = countryList.find(c => c.selected);
    const initValues = {
      name: outlet?.name || "",
      countryId: outlet?.locationData?.country?.id || own || "",
      ...outlet?.questionnaire
    };
    setValues(initValues);

    setCurrentStep(0);
    dispatch(resetOutletFormState());

    onClose();
  };

  const validForm = useMemo(() => {
    if (outlet) {
      return values.name?.trim().length > 0;
    }

    return !!(values.name?.trim() !== "" && values.countryId);
  }, [values, outlet]);

  const canAdvance = useMemo(() => {
    if (createOrEditOutletState.loading) {
      return false;
    }
    if (!steps[currentStep]?.every(q => values[q.field] !== undefined)) {
      return false;
    }

    if (currentStep === steps.length - 1) {
      return validForm;
    }
    return true;
  }, [steps, currentStep, values, validForm, createOrEditOutletState]);

  // useEffect(() => {
  //   if (currentStep > steps.length - 1) {
  //     setCurrentStep(steps.length - 1);
  //   }
  // }, [currentStep, steps]);

  return (
    <SidebarModal visible={visible} onClose={onCloseAux}>
      <div className="flex flex-col h-full">
        <div className="h-[120px]">
          <div className="flex justify-between items-center mb-xs">
            <Subtitle>
              {outlet ? content.outlets_edit_outlet_info : content.outlets_create_new_outlet}
            </Subtitle>
            <CloseIcon onClick={() => onCloseAux(true)} />
          </div>
          {outlet && <Body>{content.outlets_create_fill_survey}</Body>}
        </div>
        <CreateOutletFormContent
          questions={steps[currentStep]}
          values={values}
          onSet={values => {
            setValues(values);
            // if (
            //   steps[currentStep].length === 1 &&
            //   steps[currentStep]?.[0]?.type === QuestionType.RADIO &&
            //   currentStep < steps.length - 1
            // ) {
            //   setCurrentStep(currentStep + 1);
            // }
          }}
        />

        {createOrEditOutletState.error && (
          <span className="text-alert-alert100">{common.common_error_during_request}</span>
        )}
        <CreateOutletFooter error={createOrEditOutletState.error}>
          <SecondaryButton onClick={() => onCloseAux()}>
            {currentStep === 0 ? common.common_cancel : "Previous"}
          </SecondaryButton>
          <PrimaryButton disabled={!canAdvance} onClick={createOutletAux}>
            {createOrEditOutletState.loading
              ? common.common_loading
              : currentStep === steps.length - 1
              ? outlet
                ? common.common_save
                : common.common_create
              : "Next"}
          </PrimaryButton>
        </CreateOutletFooter>
      </div>
    </SidebarModal>
  );
};

export default CreateOutletForm;

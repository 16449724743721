import { IContent } from "../../../store/content/types";

export const mapToDropdownList = (item: IContent): { id: string; text: string } => ({
  id: item.key,
  text: item.name
});

export const generateArrayOfYears = (): Array<{ id: string; text: string }> => {
  const max = new Date().getFullYear();
  const min = 2000;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ id: `${i}`, text: `${i}` });
  }

  return years;
};

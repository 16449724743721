import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group";
import ReactMarkdown from "react-markdown";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTheme } from "styled-components";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { PasswordInput , ErrorTextInput } from "@ddm-design-system/textinput";
import { useCheckWindowSize } from "@ddm-design-system/hooks";
import { NotificationContext } from "@ddm-design-system/notification";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { Body, DescriptionMedium, PageTitle } from "@ddm-design-system/typography";
import { Checkbox } from "@ddm-design-system/checkbox";
import { getContentfulError } from "../../store/content/selectors";
import useContent from "../../hooks/useContent";
import { AnalyticsContext } from "../../services/analytics";
import Routes from "../../routes";
import AuthContext from "./AuthContext";
import LanguageAndPrivacy from "./LanguageAndPrivacy";
import {
  AzureButton,
  BackgroundImage,
  ContentfulError,
  DDMLogo,
  LoginBackground,
  LoginForm,
  LoginFormButton,
  LoginFormButtons,
  LoginPage,
  LoginPanel,
  LoginPanelBottom,
  LoginPanelBottomAnimation,
  LoginPanelContent,
  LoginPanelMiddle,
  LoginPanelMiddleSubtitle,
  LoginPanelTop,
  NotificationContent,
  NotificationLink,
  RecaptchaText,
  RedirectText,
  UsernameBadge
} from "./login.styles";
import RecoverPassword from "./RecoverPassword";

const AZURE_REDIRECT_TIMEOUT_MS = 3000;

export const Login: React.FC<RouteComponentProps> = ({ location, history }) => {
  const analytics = useContext(AnalyticsContext);
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const { executeRecaptcha = () => "" } = useGoogleReCaptcha();
  const { common, emailDomains, loginControlTower: content, mediaContainer: media } = useContent();
  const isMobile = useCheckWindowSize(EBreakpoints.MOBILE2);
  const theme = useTheme();
  const intervalRef = useRef<any>(null);
  const hasContentfulError = useSelector(getContentfulError);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(auth.isAuthenticated);
  const [currentPicture, setCurrentPicture] = useState(0);
  const [showPasswordStep, setShowPasswordStep] = useState(false);
  const [isAzureAccount, setIsAzureAccount] = useState(false);
  const [reverseAnimationDirection, setReverseAnimationDirection] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const getIsAzureAccount = useCallback(async () => {
    const { isAzure } = await auth.isAzureAccount(username);

    setIsAzureAccount(isAzure);

    if (!isAzure) {
      setShowPasswordStep(true);
    } else {
      setTimeout(() => auth.adal?.login(), AZURE_REDIRECT_TIMEOUT_MS);
    }
  }, [auth, username]);

  const login = useCallback(
    async (user = "", pass = "") => {
      setError(false);

      try {
        const recaptchaToken = await executeRecaptcha();
        const userId = await auth.login(
          user || username,
          pass || password,
          recaptchaToken,
          rememberMe
        );

        analytics.logEvent("LOGIN", username);
        analytics.setAuthenticatedUserContext(userId);
        const { search } = location;
        const params = new URLSearchParams(search);
        const route = params.get("route");

        if (route) {
          history.push(route);
        }
      } catch (e) {
        analytics.logEvent("LOGIN_ERROR", username, JSON.stringify(e));
        setError(true);
      }
    },
    [executeRecaptcha, analytics, auth, history, location, password, rememberMe, username]
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      setRedirect(true);
    } else if (auth.error && auth.error.body) {
      switch (auth.error.type) {
        case "NO_ACCESS_AZURE":
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: theme.colors.alert.alert100 },
            displayDuration: 10000,
            text: (
              <NotificationContent>
                <Body>
                  {(content.control_tower_adal_error || "").replace("%ACCOUNT%", auth.error.body)}
                </Body>
                <NotificationLink
                  onClick={() => {
                    if (auth.adal) {
                      localStorage.removeItem("ddm-azureinvitationtoken");
                      auth.adal.logout();
                    }
                  }}
                >
                  {content.control_tower_adal_logout}
                </NotificationLink>
              </NotificationContent>
            )
          });
          break;
        case "ERROR_LINKING_ACCOUNT":
          if (auth.error.body === "registered") {
            history.push(Routes.login);

            setTimeout(() => {
              auth.adal?.login();
            }, 500);
          } else {
            notificationContext.addNotification({
              iconProps: { name: "Warning", color: theme.colors.alert.alert100 },
              displayDuration: 6000,
              text: content.control_tower_error_linking
            });
          }

          localStorage.removeItem("ddm-azureinvitationtoken");
          break;
        default:
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: theme.colors.alert.alert100 },
            displayDuration: 6000,
            text: content.control_tower_unknown_error
          });
          localStorage.removeItem("ddm-azureinvitationtoken");
          break;
      }
    }
  }, [
    auth.isAuthenticated,
    auth.error,
    content,
    history,
    notificationContext,
    theme.colors.alert.alert100,
    auth.adal
  ]);

  const from = useMemo(() => {
    return (location.state as { from: Location })?.from || { pathname: "/" };
  }, [location.state]);

  // watch url variables
  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const azureToken = params.get("azureinvitationtoken");

    if (azureToken) {
      const localStorageInvitationToken = localStorage.getItem("ddm-azureinvitationtoken");

      if (localStorageInvitationToken !== azureToken) {
        localStorage.setItem("ddm-azureinvitationtoken", azureToken);

        if (!auth.error) {
          if (auth.adal) {
            auth.adal.login();

            return;
          }
        }
      }
    }
    // else {
    //   localStorage.removeItem("ddm-azureinvitationtoken");
    // }

    const user = params.get("username");
    const pass = params.get("password");

    if (user) {
      setUsername(user);
    }

    if (user && pass && user.length > 0 && pass.length > 0) {
      login(user, pass);
    }
  }, [auth, content, location, login]);

  // validate azure token
  useEffect(() => {
    const localStorageInvitationToken = localStorage.getItem("ddm-azureinvitationtoken");

    if (!localStorageInvitationToken || auth.error || auth.isAuthenticated) {
      return;
    }

    if (auth.adal && emailDomains) {
      const userAdal = auth.adal.getUser()?.unique_name;

      if (!userAdal) {
        return;
      }

      const tokenEmail = userAdal.split("@")[1] || null;

      if (tokenEmail) {
        if (emailDomains.split("\n").includes(tokenEmail)) {
          auth.validateAzureInvitationToken(localStorageInvitationToken);
        } else {
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: theme.colors.alert.alert100 },
            text: (
              <NotificationContent>
                <Body>
                  {(content.control_tower_email_domain_error || "").replace("%EMAIL%", tokenEmail)}
                </Body>
                <NotificationLink
                  onClick={() => {
                    if (auth.adal) {
                      localStorage.removeItem("ddm-azureinvitationtoken");
                      auth.adal.logout();
                    }
                  }}
                >
                  {content.control_tower_adal_logout}
                </NotificationLink>
              </NotificationContent>
            )
          });
        }
      } else {
        localStorage.removeItem("ddm-azureinvitationtoken");
      }
    }
  }, [auth, content, emailDomains, notificationContext, theme.colors.alert.alert100]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    setCurrentPicture(Math.floor(Math.random() * media.length));

    if (media.length > 0) {
      intervalRef.current = setInterval(
        () => setCurrentPicture(picture => (picture + 1) % media.length),
        15000
      );

      return () => clearInterval(intervalRef.current);
    }
  }, [media]);

  const contentfulError = useMemo(
    () => (
      <ContentfulError>
        There was an error loading content... <br />
        Please try again later.
      </ContentfulError>
    ),
    []
  );

  const loginBackground = useMemo(
    () => (
      <LoginBackground noImage={!media.length}>
        {media.length > 0 && (
          <TransitionGroup component={null}>
            <CSSTransition key={currentPicture} classNames="image-anim" timeout={2000}>
              <BackgroundImage
                alt="background img"
                src={`${media[currentPicture]}?fm=jpg&fl=progressive`}
              />
            </CSSTransition>
          </TransitionGroup>
        )}
        <DDMLogo />
      </LoginBackground>
    ),
    [currentPicture, media]
  );

  const emailStep = useMemo(
    () => (
      <LoginForm
        onSubmit={e => {
          e.preventDefault();
          setReverseAnimationDirection(false);

          if (username !== "") {
            getIsAzureAccount();
          }
        }}
      >
        <ErrorTextInput
          autoComplete="username"
          label={content.control_tower_login_email_label}
          name="username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <LoginFormButton type="submit" disabled={username === ""}>
          {common.common_continue}
        </LoginFormButton>
      </LoginForm>
    ),
    [common.common_continue, content.control_tower_login_email_label, getIsAzureAccount, username]
  );

  const handleBackClick = useCallback(() => {
    setReverseAnimationDirection(true);

    // only trigger section change after animation direction is set
    setTimeout(() => setShowPasswordStep(false));
  }, []);

  const passwordStep = useMemo(
    () => (
      <LoginForm
        onSubmit={e => {
          e.preventDefault();

          if (!auth.isAuthenticating) {
            login();
          }
        }}
      >
        <UsernameBadge>{username}</UsernameBadge>
        <PasswordInput
          autoComplete="current-password"
          className="mt-lg"
          error={error ? content.control_tower_login_wrong_password : undefined}
          label={content.control_tower_login_password_label}
          name="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <div className="flex justify-between items-center mt-sm">
          <Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)}>
            {content.control_tower_login_remember_me}
          </Checkbox>

          <div className="flex">
            <div onClick={() => setForgotPassword(true)} className="cursor-pointer">
              <DescriptionMedium>{content.control_tower_forgot_password}</DescriptionMedium>
            </div>
          </div>
        </div>

        <LoginFormButtons>
          <SecondaryButton type="button" onClick={handleBackClick}>
            {common.common_back}
          </SecondaryButton>
          <PrimaryButton type="submit" disabled={password === "" || auth.isAuthenticating}>
            {content.control_tower_login_button}
          </PrimaryButton>
        </LoginFormButtons>
      </LoginForm>
    ),
    [
      auth.isAuthenticating,
      common.common_back,
      content.control_tower_login_button,
      content.control_tower_login_password_label,
      content.control_tower_login_remember_me,
      content.control_tower_login_wrong_password,
      error,
      handleBackClick,
      login,
      password,
      rememberMe,
      username
    ]
  );

  const forgotPasswordStep = useMemo(
    () => (
      <RecoverPassword
        username={username}
        setForgotPassword={setForgotPassword}
        setUsername={setUsername}
      />
    ),
    [username, setForgotPassword, setUsername]
  );

  const redirectStep = useMemo(
    () => (
      <RedirectText>
        <ReactMarkdown>{content.control_tower_login_redirect}</ReactMarkdown>
      </RedirectText>
    ),
    [content.control_tower_login_redirect]
  );

  const loginPanelMiddle = useMemo(
    () => (
      <LoginPanelMiddle>
        <PageTitle>{content.control_tower_login_title}</PageTitle>
        {!isAzureAccount && !forgotPassword && (
          <LoginPanelMiddleSubtitle>
            {content.control_tower_login_subtitle}
          </LoginPanelMiddleSubtitle>
        )}
        <SwitchTransition>
          <CSSTransition
            key={isAzureAccount ? "redirect" : showPasswordStep ? "password" : "email"}
            classNames={reverseAnimationDirection ? "background-inverse" : "background"}
            timeout={400}
          >
            {isAzureAccount
              ? redirectStep
              : forgotPassword
              ? forgotPasswordStep
              : showPasswordStep
              ? passwordStep
              : emailStep}
          </CSSTransition>
        </SwitchTransition>
      </LoginPanelMiddle>
    ),
    [
      content.control_tower_login_subtitle,
      content.control_tower_login_title,
      emailStep,
      isAzureAccount,
      passwordStep,
      redirectStep,
      reverseAnimationDirection,
      showPasswordStep,
      forgotPassword,
      forgotPasswordStep
    ]
  );

  const loginPanelBottom = useMemo(
    () => (
      <LoginPanelBottom>
        <LoginPanelBottomAnimation>
          {!isAzureAccount && !showPasswordStep && (
            <AzureButton>
              <Body as="div" onClick={() => auth.adal?.login()}>
                <ReactMarkdown>{content.control_tower_carlsberg_account}</ReactMarkdown>
              </Body>
            </AzureButton>
          )}
          {isMobile && <LanguageAndPrivacy />}
        </LoginPanelBottomAnimation>
      </LoginPanelBottom>
    ),
    [auth.adal, content.control_tower_carlsberg_account, isAzureAccount, isMobile, showPasswordStep]
  );

  return redirect ? (
    <Redirect to={from} />
  ) : (
    <LoginPage>
      {!isMobile && loginBackground}
      <LoginPanel>
        {hasContentfulError ? (
          contentfulError
        ) : (
          <>
            <LoginPanelContent>
              <LoginPanelTop>{isMobile ? <DDMLogo /> : <LanguageAndPrivacy />}</LoginPanelTop>
              {loginPanelMiddle}
              {loginPanelBottom}
            </LoginPanelContent>
            <RecaptchaText>
              <ReactMarkdown>{common.common_recaptcha_text}</ReactMarkdown>
            </RecaptchaText>
          </>
        )}
      </LoginPanel>
    </LoginPage>
  );
};

export default Login;

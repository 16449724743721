import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { useDispatch, useSelector } from "react-redux";
import CeLocationDetailEditItem from "./CeLocationDetailEditItem";
import { EditItem } from "./CeLocationDetailEditItem.styles";
import { ICELocation } from "../../../store/ceLocations/types";
import useContent from "../../../hooks/useContent";
import { getAllBrands, getTranslatedBrandsAsObject } from "../helpers";
import { IItem } from "../../common/simple_dropdown/SimpleDropdown";
import {
  StyledDivider,
  StyledDropdown,
  StyledInputSpinner,
  StyledModal,
  StyledModalBody,
  StyledMultipleDropdown,
  StyledTextInput
} from "./CeLocationDetailEdit.styles";
import { putCeLocation } from "../../../store/ceLocations/actions";
import { getUserCountryCode } from "../../../store/auth/selectors";
import { generateArrayOfYears, mapToDropdownList } from "./utils";

interface ICeLocationDetailEditProps {
  onClose: () => any;
  ceLocation: ICELocation;
}

const CeLocationDetailEdit: React.FC<ICeLocationDetailEditProps> = ({ ceLocation, onClose }) => {
  const { ceLocations: content, valueList, common, forms } = useContent();
  const countryCode = useSelector(getUserCountryCode);
  const dispatch = useDispatch();

  const modalEl = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.getElementById("modal-root")?.appendChild(modalEl);
  }, [modalEl]);

  const [cleaningUnits, setCleaningUnits] = useState<number>(ceLocation.cleaningUnits);
  const [cleaningUnitBrands, setCleaningUnitBrands] = useState<IItem>(
    getTranslatedBrandsAsObject(ceLocation, "cleaningUnitBrands", valueList).map(
      mapToDropdownList
    )[0]
  );

  const [oldestKegModuleYear, setOldestKegModuleYear] = useState<IItem | undefined>(
    ceLocation.oldestKegModuleYear
      ? {
          id: `${ceLocation.oldestKegModuleYear}`,
          text: `${ceLocation.oldestKegModuleYear}`
        }
      : undefined
  );
  const [kegModuleType, setKegModuleType] = useState<IItem | undefined>(
    ceLocation.kegModuleType
      ? {
          id: ceLocation.kegModuleType,
          text: ceLocation.kegModuleType
        }
      : undefined
  );
  const [pressureChambers, setPressureChambers] = useState<number>(ceLocation.pressureChambers);

  const [coolingUnits, setCoolingUnits] = useState<number>(ceLocation.coolingUnits);
  const [coolingUnitBrands, setCoolingUnitBrands] = useState<IItem>(
    getTranslatedBrandsAsObject(ceLocation, "coolingUnitBrands", valueList).map(
      mapToDropdownList
    )[0]
  );

  const [beerBoostPumpQuantity, setBeerBoostPumpQuantity] = useState<number>(
    ceLocation.beerBoostPumpQuantity
  );
  const [beerBoostPumpBrand, setBeerBoostPumpBrand] = useState<IItem>(
    getTranslatedBrandsAsObject(ceLocation, "beerBoostPumpBrand", valueList).map(
      mapToDropdownList
    )[0]
  );

  const [pythonBrand, setPythonBrand] = useState<IItem>(
    getTranslatedBrandsAsObject(ceLocation, "pythonBrand", valueList).map(mapToDropdownList)[0]
  );

  const [compressorBrand, setCompressorBrand] = useState<IItem>(
    getTranslatedBrandsAsObject(ceLocation, "compressorBrand", valueList).map(mapToDropdownList)[0]
  );

  const [fobDetectorBrand, setFobDetectorBrand] = useState<IItem>(
    getTranslatedBrandsAsObject(ceLocation, "fobDetectorBrand", valueList).map(mapToDropdownList)[0]
  );

  const [couplingBrand, setCouplingBrand] = useState<IItem[]>(
    getTranslatedBrandsAsObject(ceLocation, "couplingBrand", valueList).map(mapToDropdownList)
  );

  const [customFields, setCustomFields] = useState<{ [key: string]: string }>(
    JSON.parse(ceLocation?.customFields) || {}
  );

  const handleSave = useCallback(() => {
    dispatch(
      putCeLocation(ceLocation.id, {
        description: ceLocation.description,
        cleaningUnits,
        cleaningUnitBrands: [cleaningUnitBrands?.id],
        pressureChambers,
        coolingUnits,
        coolingUnitBrands: coolingUnitBrands?.id ? [coolingUnitBrands?.id] : [],
        beerBoostPumpBrand: beerBoostPumpBrand?.id,
        beerBoostPumpQuantity,
        pythonBrand: pythonBrand?.id,
        compressorBrand: compressorBrand?.id,
        fobDetectorBrand: fobDetectorBrand?.id,
        couplingBrand: (couplingBrand || []).map(cb => cb.id).join(","),
        taps: ceLocation.taps,
        oldestKegModuleYear: oldestKegModuleYear ? Number(oldestKegModuleYear?.id) : undefined,
        customFields: JSON.stringify(customFields),
        kegModuleType: kegModuleType ? kegModuleType.id : undefined
      })
    );
    onClose();
  }, [
    beerBoostPumpBrand,
    beerBoostPumpQuantity,
    ceLocation.description,
    ceLocation.id,
    ceLocation.taps,
    cleaningUnitBrands,
    cleaningUnits,
    compressorBrand,
    coolingUnitBrands,
    coolingUnits,
    couplingBrand,
    customFields,
    dispatch,
    fobDetectorBrand,
    kegModuleType,
    oldestKegModuleYear,
    onClose,
    pressureChambers,
    pythonBrand
  ]);

  const children = (
    <StyledModal isOpen onRequestClose={onClose}>
      <ModalHeader headerTitle={content.celocation_edit_location} />
      <StyledModalBody>
        <CeLocationDetailEditItem title={`${content.celocation_cleaning_modules}*`}>
          <EditItem title={content.celocation_quantity}>
            <StyledInputSpinner min={1} value={cleaningUnits} onChange={setCleaningUnits} />
          </EditItem>
          <EditItem title={content.celocation_brand}>
            <StyledDropdown
              required
              placeholder={content.celocation_brand}
              selectedItem={cleaningUnitBrands}
              onItemSelected={setCleaningUnitBrands}
              items={getAllBrands(ceLocation, "cleaningUnitBrands", valueList).map(
                mapToDropdownList
              )}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        <StyledDivider />

        <CeLocationDetailEditItem title={`${content.celocation_keg_modules}*`}>
          <EditItem title={content.celocation_quantity}>
            <StyledInputSpinner min={1} value={pressureChambers} onChange={setPressureChambers} />
          </EditItem>
          <EditItem title={content.celocation_oldest_keg}>
            <StyledDropdown
              required
              placeholder={content.celocation_oldest_keg}
              selectedItem={oldestKegModuleYear}
              onItemSelected={setOldestKegModuleYear}
              items={generateArrayOfYears()}
            />
          </EditItem>
          <EditItem title={content.celocation_keg_modules_type}>
            <StyledDropdown
              placeholder={content.celocation_keg_modules_type}
              selectedItem={kegModuleType}
              onItemSelected={setKegModuleType}
              items={[
                { id: "standard", text: content.celocation_keg_modules_type_standard },
                { id: "tropical", text: content.celocation_keg_modules_type_tropical }
              ]}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        <StyledDivider />

        <CeLocationDetailEditItem title={`${content.celocation_beer_cooler}`}>
          <EditItem title={content.celocation_quantity}>
            <StyledInputSpinner min={1} value={coolingUnits} onChange={setCoolingUnits} />
          </EditItem>
          <EditItem title={content.celocation_brand}>
            <StyledDropdown
              placeholder={content.celocation_brand}
              selectedItem={coolingUnitBrands}
              onItemSelected={setCoolingUnitBrands}
              items={getAllBrands(ceLocation, "coolingUnitBrands", valueList).map(
                mapToDropdownList
              )}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        <StyledDivider />

        <CeLocationDetailEditItem title={content.celocation_beer_boost_pump}>
          <EditItem title={content.celocation_quantity}>
            <StyledInputSpinner
              min={0}
              value={beerBoostPumpQuantity}
              onChange={setBeerBoostPumpQuantity}
            />
          </EditItem>
          <EditItem title={content.celocation_brand}>
            <StyledDropdown
              placeholder={content.celocation_brand}
              selectedItem={beerBoostPumpBrand}
              onItemSelected={setBeerBoostPumpBrand}
              items={getAllBrands(ceLocation, "coolingUnitBrands", valueList).map(
                mapToDropdownList
              )}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        <StyledDivider />

        <CeLocationDetailEditItem title={`${content.celocation_python_cable}*`}>
          <EditItem title={content.celocation_brand}>
            <StyledDropdown
              required
              placeholder={content.celocation_brand}
              selectedItem={pythonBrand}
              onItemSelected={setPythonBrand}
              items={getAllBrands(ceLocation, "pythonBrand", valueList).map(mapToDropdownList)}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        <StyledDivider />

        <CeLocationDetailEditItem title={content.celocation_compressor}>
          <EditItem title={content.celocation_brand}>
            <StyledDropdown
              placeholder={content.celocation_brand}
              selectedItem={compressorBrand}
              onItemSelected={setCompressorBrand}
              items={getAllBrands(ceLocation, "compressorBrand", valueList).map(mapToDropdownList)}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        <StyledDivider />

        <CeLocationDetailEditItem title={content.celocation_fob_detector}>
          <EditItem title={content.celocation_brand}>
            <StyledDropdown
              placeholder={content.celocation_brand}
              selectedItem={fobDetectorBrand}
              onItemSelected={setFobDetectorBrand}
              items={getAllBrands(ceLocation, "fobDetectorBrand", valueList).map(mapToDropdownList)}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        <StyledDivider />

        <CeLocationDetailEditItem title={`${content.celocation_coupling}*`}>
          <EditItem title={content.celocation_brand}>
            {/* @ts-ignore */}
            <StyledMultipleDropdown
              placeholder={content.celocation_brand}
              selectedItems={couplingBrand}
              onItemsSelected={setCouplingBrand}
              items={getAllBrands(ceLocation, "couplingBrand", valueList).map(mapToDropdownList)}
            />
          </EditItem>
        </CeLocationDetailEditItem>

        {forms && forms[`CEForm_${countryCode}`] && forms[`CEForm_${countryCode}`].length > 0 && (
          <>
            <StyledDivider />
            <CeLocationDetailEditItem title={`${content.celocation_additional_information}*`}>
              {forms[`CEForm_${countryCode}`].map(customAttribute => {
                const customField = Object.keys(customFields).find((key: string) =>
                  customAttribute?.translations?.includes(key)
                );

                return (
                  <EditItem title={customAttribute.name} key={customAttribute.name}>
                    <StyledTextInput
                      required={customAttribute.required}
                      key={customAttribute.name}
                      value={customFields[customField as string]}
                      onChange={e =>
                        setCustomFields({
                          ...customFields,
                          [customField as string]: e.currentTarget.value
                        })
                      }
                    />
                  </EditItem>
                );
              })}
            </CeLocationDetailEditItem>
          </>
        )}
      </StyledModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onClose}>{common.common_cancel}</SecondaryButton>
        <PrimaryButton
          disabled={
            !couplingBrand.length ||
            !pythonBrand ||
            !cleaningUnitBrands ||
            !oldestKegModuleYear ||
            !!(forms?.[`CEForm_${countryCode}`] || []).find(customAttr => {
              const customField = Object.keys(customFields).find((key: string) =>
                customAttr?.translations?.includes(key)
              );

              return customAttr.required === true && customField && !customFields[customField];
            })
          }
          onClick={handleSave}
        >
          {common.common_save}
        </PrimaryButton>
      </ModalFooter>
    </StyledModal>
  );

  return createPortal(children, modalEl);
};

export default CeLocationDetailEdit;

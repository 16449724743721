import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../routes";
import { getOutlet, getIsLoadingOutlets } from "../../../store/outlets/selectors";
import useContent from "../../../hooks/useContent";
import Header from "../../common/header/Header";
import Tabs, { ITab } from "../../common/tabs/Tabs";
import Info from "./ComponentInfo";
import HealthMonitor from "./ComponentHealthMonitor";
import Lines from "./ComponentLines";
import { getOutletInfo } from "../../../store/outlets/actions";
import { getComponentInfo } from "../../../store/components/actions";
import { getCurrentComponent, getIsLoadingComponent } from "../../../store/components/selectors";
import { LoadingIcon } from "../../common/data_table/dataTable.styles";
import { ConnectedComponentTypes } from "../../../store/outlets/types";

interface IProps {
  outletId: string;
  componentId: string;
}

const ComponentDetails: React.FC<IProps> = ({ outletId, componentId }) => {
  const { outletDetails: content, common } = useContent();
  const dispatch = useDispatch();
  const outlet = useSelector(getOutlet(outletId));
  const component = useSelector(getCurrentComponent());
  const loadingOutlet = useSelector(getIsLoadingOutlets);
  const loadingComponent = useSelector(getIsLoadingComponent);

  const type = (component?.type?.[0].toLocaleLowerCase() || "") + component?.type?.slice(1);

  useEffect(() => {
    dispatch(getOutletInfo(outletId));
    dispatch(getComponentInfo(componentId));
  }, [dispatch, outletId, componentId]);

  // TODO: Switch to another content based on component.type
  const tabs = useMemo<ITab[]>(() => {
    const tabsTemp = [
      {
        content: <Info />,
        label: common.common_info,
        route: Routes.outletComponentDetailsInfo
          .replace(":id", outletId)
          .replace(":cid", componentId)
      }
    ];
    if (
      type !== ConnectedComponentTypes.GENERIC_COMPONENT &&
      type !== ConnectedComponentTypes.COMPRESSOR
    ) {
      tabsTemp.push({
        content: <HealthMonitor componentId={componentId} />,
        label: common.common_health_monitor,
        route: Routes.outletComponentDetailsHealthMonitor
          .replace(":id", outletId)
          .replace(":cid", componentId)
      });
    }
    if (
      type === ConnectedComponentTypes.COOLING_UNIT ||
      type === ConnectedComponentTypes.PRESSURE_CHAMBER
    ) {
      tabsTemp.push({
        content: <Lines componentId={componentId} outletId={outletId} />,
        label: common.common_lines,
        route: Routes.outletComponentDetailsLines
          .replace(":id", outletId)
          .replace(":cid", componentId)
      });
    }
    return tabsTemp;
  }, [outletId, type, componentId, common]);

  return loadingOutlet || loadingComponent ? (
    <div className="w-full flex items-center justify-center">
      <LoadingIcon />
    </div>
  ) : (
    <div>
      <Header
        title={component ? component.typeContent : ""}
        breadcrumbs={[
          { link: Routes.outlets, name: content.outlet_details_back_text },
          {
            link: Routes.outletDetails.replace(":id", outletId),
            name: outlet?.name || ""
          }
        ]}
      />
      <div style={{ overflow: "auto", flex: 1 }}>
        {outlet ? (
          component ? (
            <Tabs tabs={tabs} />
          ) : (
            <div>{content.outlet_component_not_found.replace("%ID%", componentId)}</div>
          )
        ) : (
          <div>{content.outlet_details_not_found.replace("%ID%", outletId)}</div>
        )}
      </div>
    </div>
  );
};

export default ComponentDetails;

import MaterialButton, { ButtonProps } from "@material-ui/core/Button";
import classNames from "classnames";
import React, { forwardRef } from "react";
import "./button.scss";

export interface IButtonProps extends ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  ghost?: boolean;
  inverse?: boolean;
  header?: boolean;
  inline?: boolean;
  warning?: boolean;
  error?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      primary,
      secondary,
      ghost,
      inverse,
      header,
      className,
      disabled,
      variant,
      inline,
      warning,
      error,
      children,
      ...rest
    },
    ref
  ) => {
    const classList = classNames(
      className,
      "button",
      primary && "button-primary",
      secondary && "button-secondary",
      ghost && "button-ghost",
      header && "button-header",
      disabled && "disabled",
      inverse && "inverse",
      inline && "inline",
      warning && "warning",
      error && "error"
    );
    let buttonVariant = variant;

    if (!variant) {
      if (primary && !error) {
        buttonVariant = "contained";
      } else if (secondary) {
        buttonVariant = "outlined";
      } else if (ghost) {
        buttonVariant = "text";
      }
    }

    return (
      <MaterialButton
        ref={ref}
        className={classList}
        variant={buttonVariant}
        disabled={disabled}
        {...rest}
      >
        {children || <div />}
      </MaterialButton>
    );
  }
);

export default Button;

import React from "react";
import classnames from "classnames";
import useContent from "../../hooks/useContent";
import Icon from "../common/icon/Icon";
import "./celocations.scss";

interface IProps {
  title: string;
  value?: string | number;
}

const CeLocationDetailItem: React.FC<IProps> = ({ title, value }) => {
  const { ceLocations: content } = useContent();
  const hasValue = !!value;
  const iconName = hasValue ? "checkmark" : "noInstallationCard";

  return (
    <div
      className={classnames(
        "celocation-detail-item",
        !hasValue && "celocation-detail-item--missing-info"
      )}
    >
      <span className="celocation-detail-key">{title}</span>
      <div className="celocation-detail-value-wrapper">
        <span className="celocation-detail-value">
          {hasValue ? value : content.celocation_none}
        </span>
        <Icon name={iconName} size={16} />
      </div>
    </div>
  );
};

export default CeLocationDetailItem;

import styled, { css } from "styled-components";

export const Anchor = styled.a<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    padding-bottom: ${theme.spaceUnit.md};
    color: ${theme.colors.grey.grey100};
    cursor: pointer;

    &:hover {
      color: ${theme.colors.grey.grey50};
    }

    ${selected &&
    css`
      color: ${theme.colors.black};

      &:hover {
        color: ${theme.colors.black};
      }
    `};
  `
);

export const Content = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    scroll-behavior: smooth;
    padding-bottom: 100px;
  `
);

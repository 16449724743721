import { createSelector } from "reselect";
import { IContentState, IContentfulSyncInfo } from "./types";
import { IAppState } from "../index";

const getContent = (state: IAppState) => state.content;

const getLanguages = (state: IAppState) => state.content.entries;

export const getIsLoadingContentful = (state: IAppState) => state.content.loadingContentful;

export const getContentfulError = (state: IAppState) => state.content.contentfulError;

export const getCurrentLanguage = (state: IAppState) => state.content.currentLanguage;

export const getContentfulSyncInfo: (state: IAppState) => IContentfulSyncInfo = createSelector(
  getContent,
  ({ contentfulToken, dbTimestamp }: IContentState) => ({ contentfulToken, dbTimestamp })
);

export const getCurrentContent: (state: IAppState) => { [language: string]: any } = createSelector(
  getLanguages,
  getCurrentLanguage,
  (langs, currentLang) => {
    return langs[currentLang];
  }
);

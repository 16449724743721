import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useContent from "../../../../../hooks/useContent";
import { getCurrentLanguage } from "../../../../../store/content/selectors";
import DataTable from "../../../../common/data_table/DataTable";

interface IProps {
  kegChanges?: string[];
  componentPosition?: number;
}

const KegChanges: React.FC<IProps> = ({ kegChanges, componentPosition }) => {
  const { common } = useContent();
  const language = useSelector(getCurrentLanguage);

  const formatDate = useCallback(
    (date: string) => {
      const dateFormatted = date
        ? new Date(date).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short"
          })
        : "--";

      return <span title={dateFormatted}>{dateFormatted}</span>;
    },
    [language]
  );

  const renderComponentKegChanges = useCallback(() => {
    if (!kegChanges) return <div />;

    const columns = [
      {
        name: common.common_component,
        selector: () =>
          componentPosition ? `${common.common_pressureChamber} ${componentPosition}` : "",
        sortable: true
      },
      {
        format: formatDate,
        name: common.common_time,
        selector: (row: string) => row,
        sortable: true
      }
    ];

    return <DataTable columns={columns} data={kegChanges} title="" enableActions={false} />;
  }, [common, componentPosition, formatDate, kegChanges]);

  return renderComponentKegChanges();
};

export default KegChanges;

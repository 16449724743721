import React, { useState } from "react";
import DateRangePicker from "react-daterange-picker";
import Modal from "../common/modal/Modal";
import Button from "../common/button/Button";
import useContent from "../../hooks/useContent";
import "react-daterange-picker/dist/css/react-calendar.css";
import "./outlets.scss";

interface IProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (start: number, end: number) => void;
}

const ExportDataModal: React.FC<IProps> = ({ visible, onClose, onSubmit }) => {
  const { outletDetails: content } = useContent();

  const [dates, setDates] = useState<any>();

  return (
    <Modal
      className="modal-component-item export-data-modal"
      hasCloseButton
      title={content.outlet_details_export_data}
      visible={visible}
      onClose={onClose}
    >
      <div className="export-data-modal-content">
        <p>{content.outlet_details_export_data_time_range}</p>
        <div className="export-picker">
          <DateRangePicker
            numberOfCalendars={2}
            selectionType="range"
            singleDateRange
            value={dates}
            onSelect={(value: any) => {
              setDates(value);
            }}
          />
        </div>
        <div className="export-data-modal-footer">
          {dates?.start && (
            <div className="export-data-dates">
              {`${dates?.start?.format("LL")} - ${dates?.end?.format("LL")}`}
            </div>
          )}
          <Button
            secondary
            disabled={!dates?.start}
            onClick={() => {
              onSubmit?.(+dates.start?.toDate(), +dates.end?.toDate());
            }}
          >
            {content.outlet_details_export_data}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExportDataModal;

import { IRecentlyCreatedTechnician } from "../../services/jcore";
import {
  DELETE_TECHNICIAN_ERROR,
  DELETE_TECHNICIAN_REQUEST,
  DELETE_TECHNICIAN_SUCCESS,
  EDIT_TECHNICIAN_ERROR,
  EDIT_TECHNICIAN_REQUEST,
  EDIT_TECHNICIAN_SUCCESS,
  GET_TECHNICIAN_DEVICE_CODE_ERROR,
  GET_TECHNICIAN_DEVICE_CODE_REQUEST,
  GET_TECHNICIAN_DEVICE_CODE_SUCCESS,
  GET_TECHNICIAN_ERROR,
  GET_TECHNICIAN_REQUEST,
  GET_TECHNICIAN_SUCCESS,
  GET_TECHNICIANS_ERROR,
  GET_TECHNICIANS_REQUEST,
  GET_TECHNICIANS_SUCCESS,
  IDeleteTechnicianError,
  IDeleteTechnicianRequest,
  IDeleteTechnicianSuccess,
  IEditTechnicianError,
  IEditTechnicianRequest,
  IEditTechnicianSuccess,
  IGetTechnicianDeviceCodeError,
  IGetTechnicianDeviceCodeRequest,
  IGetTechnicianDeviceCodeSuccess,
  IGetTechnicianError,
  IGetTechnicianRequest,
  IGetTechniciansError,
  IGetTechniciansRequest,
  IGetTechniciansSuccess,
  IGetTechnicianSuccess,
  INewTechnicianError,
  INewTechnicianRequest,
  INewTechnicianSuccess,
  IResetTechnicianId,
  IResetTechnicians,
  ITechnician,
  ITechnicianParams,
  IUnregisterDevice,
  NEW_TECHNICIAN_ERROR,
  NEW_TECHNICIAN_REQUEST,
  NEW_TECHNICIAN_SUCCESS,
  RESET_TECHNICIAN_ID,
  RESET_TECHNICIANS,
  UNREGISTER_DEVICE,
  SET_TECHNICIAN_INTERNATIONAL_REQUEST,
  ISetTechnicianInternationalRequest,
  ISetTechnicianInternationalSuccess,
  ISetTechnicianInternationalError,
  SET_TECHNICIAN_INTERNATIONAL_SUCCESS,
  SET_TECHNICIAN_INTERNATIONAL_ERROR
} from "./types";

export const getTechnicians = (): IGetTechniciansRequest => ({
  type: GET_TECHNICIANS_REQUEST
});
export const getTechniciansSuccess = (technicians: ITechnician[]): IGetTechniciansSuccess => ({
  type: GET_TECHNICIANS_SUCCESS,
  payload: technicians
});

export const getTechniciansError = (): IGetTechniciansError => ({
  type: GET_TECHNICIANS_ERROR
});

export const newTechnician = (params: ITechnicianParams): INewTechnicianRequest => ({
  type: NEW_TECHNICIAN_REQUEST,
  payload: params
});
export const newTechnicianSuccess = (
  technician: IRecentlyCreatedTechnician
): INewTechnicianSuccess => ({
  type: NEW_TECHNICIAN_SUCCESS,
  payload: technician
});
export const newTechnicianError = (): INewTechnicianError => ({
  type: NEW_TECHNICIAN_ERROR
});
export const resetTechnicianId = (): IResetTechnicianId => ({
  type: RESET_TECHNICIAN_ID
});

export const getTechnician = (technicianId: string): IGetTechnicianRequest => ({
  type: GET_TECHNICIAN_REQUEST,
  payload: technicianId
});
export const getTechnicianError = (): IGetTechnicianError => ({
  type: GET_TECHNICIAN_ERROR
});
export const getTechnicianSuccess = (technician: ITechnician): IGetTechnicianSuccess => ({
  type: GET_TECHNICIAN_SUCCESS,
  payload: technician
});

/* Technician Device Actions */
export const getTechnicianDeviceCodeRequest = (
  technicianId: string
): IGetTechnicianDeviceCodeRequest => ({
  type: GET_TECHNICIAN_DEVICE_CODE_REQUEST,
  payload: technicianId
});
export const getTechnicianDeviceCodeError = (): IGetTechnicianDeviceCodeError => ({
  type: GET_TECHNICIAN_DEVICE_CODE_ERROR
});
export const getTechnicianDeviceCodeSuccess = (
  technician: IRecentlyCreatedTechnician
): IGetTechnicianDeviceCodeSuccess => ({
  type: GET_TECHNICIAN_DEVICE_CODE_SUCCESS,
  payload: technician
});

/* Delete technician */
export const deleteTechnicianRequest = (technicianId: string): IDeleteTechnicianRequest => ({
  type: DELETE_TECHNICIAN_REQUEST,
  payload: technicianId
});
export const deleteTechnicianSuccess = (technicianId: string): IDeleteTechnicianSuccess => ({
  type: DELETE_TECHNICIAN_SUCCESS,
  payload: technicianId
});
export const deleteTechnicianError = (technicianId: string): IDeleteTechnicianError => ({
  type: DELETE_TECHNICIAN_ERROR,
  payload: technicianId
});

/* Edit Technician */
/* Delete technician */
export const editTechnicianRequest = (
  params: ITechnicianParams,
  id: string
): IEditTechnicianRequest => ({
  type: EDIT_TECHNICIAN_REQUEST,
  payload: {
    params,
    id
  }
});
export const editTechnicianSuccess = (
  technicianId: string,
  params: ITechnicianParams
): IEditTechnicianSuccess => ({
  type: EDIT_TECHNICIAN_SUCCESS,
  payload: { technicianId, params }
});
export const editTechnicianError = (technicianId: string): IEditTechnicianError => ({
  type: EDIT_TECHNICIAN_ERROR,
  payload: technicianId
});
export const unregisterDevice = (deviceId: string): IUnregisterDevice => ({
  type: UNREGISTER_DEVICE,
  payload: deviceId
});

export const resetTechnicians = (): IResetTechnicians => ({
  type: RESET_TECHNICIANS
});

/* set international technician */
export const setTechnicianInternationalRequest = (
  technicianId: string,
  international: boolean
): ISetTechnicianInternationalRequest => ({
  type: SET_TECHNICIAN_INTERNATIONAL_REQUEST,
  payload: { id: technicianId, international }
});
export const setTechnicianInternationalSuccess = (
  technicianId: string,
  international: boolean
): ISetTechnicianInternationalSuccess => ({
  type: SET_TECHNICIAN_INTERNATIONAL_SUCCESS,
  payload: { id: technicianId, international }
});
export const setTechnicianInternationalError = (
  technicianId: string,
  international: boolean
): ISetTechnicianInternationalError => ({
  type: SET_TECHNICIAN_INTERNATIONAL_ERROR,
  payload: { id: technicianId, international }
});

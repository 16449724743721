import { IconNames } from "@ddm-design-system/icon";
import { IChipSelector } from "../../components/common/data_table/DataTable";
import { IBeverage, ICountry } from "../beverages/types";
import { IDistributor } from "../distributors/types";
import { iconStatusMap } from "./helpers";

export const GET_OUTLETS_REQUEST = "outlets/GET_OUTLETS_REQUEST";
export const GET_OUTLETS_SUCCESS = "outlets/GET_OUTLETS_SUCCESS";
export const GET_OUTLETS_ERROR = "outlets/GET_OUTLETS_ERROR";
// new Outlet Requests
export const GET_OUTLET_INFO = "outlets/GET_OUTLET_INFO";
export const GET_OUTLET_INFO_SUCCESS = "outlets/GET_OUTLET_INFO_SUCCESS";
export const GET_OUTLET_LINES = "outlets/GET_OUTLET_LINES";
export const GET_OUTLET_LINES_SUCCESS = "outlets/GET_OUTLET_LINES_SUCCESS";
export const GET_OUTLET_INSTALLATION = "outlets/GET_OUTLET_INSTALLATION";
export const GET_OUTLET_INSTALLATION_SUCCESS = "outlets/GET_OUTLET_INSTALLATION_SUCCESS";
export const GET_OUTLET_UNITS = "outlets/GET_OUTLET_UNITS";
export const GET_OUTLET_UNITS_SUCCESS = "outlets/GET_OUTLET_UNITS_SUCCESS";
export const GET_OUTLET_TECHNICIANS = "outlets/GET_OUTLET_TECHNICIANS";
export const GET_OUTLET_TECHNICIANS_SUCCESS = "outlets/GET_OUTLET_TECHNICIANS_SUCCESS";
export const GET_OUTLET_NOTES = "outlets/GET_OUTLET_NOTES";
export const GET_OUTLET_NOTES_SUCCESS = "outlets/GET_OUTLET_NOTES_SUCCESS";

export const ASSOCIATE_TECHNICIAN_TO_OUTLET = "outlets/ASSOCIATE_TECHNICIAN_TO_OUTLET";
export const REMOVE_ASSOCIATION = "outlets/REMOVE_ASSOCIATION";
export const SAVE_OUTLET_DESCRIPTION = "outlets/SAVE_OUTLET_DESCRIPTION";
export const RESET_BEER_DRIVE = "outlets/RESET_BEER_DRIVE";
export const EXPORT_DATA = "outlets/EXPORT_DATA";
export const EXPORT_ALL_DATA = "outlets/EXPORT_ALL_DATA";
export const GET_COUNTRIES = "outlets/GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "outlets/GET_COUNTRIES_SUCCESS";
export const CREATE_OUTLET = "outlets/CREATE_OUTLET";
export const CREATE_OUTLET_SUCCESS = "outlets/CREATE_OUTLET_SUCCESS";
export const CREATE_OUTLET_ERROR = "outlets/CREATE_OUTLET_ERROR";
export const EDIT_OUTLET = "outlets/EDIT_OUTLET";
export const EDIT_OUTLET_SUCCESS = "outlets/EDIT_OUTLET_SUCCESS";
export const EDIT_OUTLET_ERROR = "outlets/EDIT_OUTLET_ERROR";
export const RESET_OUTLET_FORM_STATE = "outlets/RESET_OUTLET_FORM_STATE";

export enum ConnectedComponentTypes {
  BEER_DRIVE = "beerDrive",
  CLEANING_UNIT = "cleaningUnit",
  COOLING_UNIT = "coolingUnit",
  PRESSURE_CHAMBER = "pressureChamber",
  GENERIC_COMPONENT = "genericComponent",
  CONTROL_UNIT = "controlUnit",
  COMPRESSOR = "compressor"
}

export enum EOutletStatusLevel {
  NEUTRAL = "NEUTRAL",
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS"
}

export enum EOutletStatus {
  NO_INSTALLATION = "NO_INSTALLATION",
  INVALID_INSTALLATION = "INVALID_INSTALLATION",
  OFFLINE_INSTALLATION = "OFFLINE_INSTALLATION",
  OFFLINE_COMPONENT = "OFFLINE_COMPONENT",
  MISSING_COMPRESSOR_LINES = "MISSING_COMPRESSOR_LINES",
  MISSING_COOLING_LINE = "MISSING_COOLING_LINE",
  MISSING_CLEANING_LINE = "MISSING_CLEANING_LINE",
  TOO_MANY_KEG_CHANGES = "TOO_MANY_KEG_CHANGES",
  OK = "OK"
}

export enum EOutletStatusBadges {
  NO_INSTALLATION = "NO_INSTALLATION",
  OFFLINE_INSTALLATION = "OFFLINE_INSTALLATION",
  INVALID_INSTALLATION = "INVALID_INSTALLATION",
  ERROR = "ERROR",
  WARNING = "WARNING",
  OK = "OK"
}

export interface IBadge {
  type: "default" | "success" | "warning" | "danger";
  fill?: boolean;
  fillOpacity?: boolean;
  outline?: boolean;
  count?: number | string;
  text: string | JSX.Element;
  id: string;
}

export interface IOutletsState {
  outlets: ILocation[];
  countries: ICountry[];
  requestingOutlets: boolean;
  requestOutletsError: boolean;
  creatingOutlet: boolean;
  creatingOutletError: boolean;
  editingOutlet: boolean;
  editingOutletError: boolean;
}

export interface IAssociation {
  technicianId: string;
  outletId: string;
  refreshTechnician?: boolean;
  refreshOutlet?: boolean;
}

export interface IOutletStatus {
  id: string;
  name: string;
  chipSelectors: IChipSelector[];
  status: EOutletStatusBadges;
  statusContent: string;
  components: number;
  taps: number;
  technicians: number;
  lastEdit?: number;
  createDateTime?: number;
  distributorCount: number;
  purchasingGroupCount: number;
  locationData?: ILocationData;
}

export interface IPressureChamberStatus {
  id: string;
  position: number;
  boardSerialNumber: string;
  unit: string;
  tapsPositions: string;
  brand: { photo: string; title: string }[];
  volume: string;
  hasPressureSwitcher: boolean;
  status: Array<{ level: ComponentLevelStatus; icon: IconNames }>;
}

export interface ISteelKegUnitStatus {
  id: string;
  position: number;
  boardSerialNumber: string;
  unit: string;
  tapsPositions: string;
  brand: { photo: string; title: string }[];
  status: Array<{ level: ComponentLevelStatus; icon: IconNames }>;
}

export interface IBeerDriveStatus {
  id: string;
  position: number;
  boardSerialNumber: string;
  unit: string;
  tapsPositions: string;
  brand: { photo: string; title: string }[];
  volume: string;
  status: Array<{ level: ComponentLevelStatus; icon: IconNames }>;
}

export interface IControlUnitStatus {
  id: string;
  position: number;
  boardSerialNumber: string;
  unit: string;
  affectedTapsPositions: string;
  status: Array<{ level: ComponentLevelStatus; icon: IconNames }>;
}

export interface ICoolingUnitStatus {
  id: string;
  position: number;
  boardSerialNumber: string;
  unit: string;
  temp1: number | null;
  temp2: number | null;
  temp3: number | null;
  status: Array<{ level: ComponentLevelStatus; icon: IconNames }>;
}

export interface ICompressorStatus {
  id: string;
  position: number;
  boardSerialNumber: string;
  unit: string;
  status: Array<{ level: ComponentLevelStatus; icon: IconNames }>;
}

export interface ILocation {
  id: string;
  installationId: string;
  name: string;
  status: Array<{
    level: EOutletStatusLevel;
    message: EOutletStatus;
  }>;
  statusMessage: string;
  taps: number;
  technicians: number;
  updateDateTime: string;
  components: number;
  createDateTime: string;
  contacts?: string[];
  locationData?: ILocationData;
  lines?: ILine[];
  installation?: IInstallation;
  units?: IUnit[];
  associatedTechnicians?: IAssociatedTechnician[];
  notes?: INote;
  questionnaire?: any;
  distributors?: IDistributor[];
  isImported: boolean;
}

export interface ILocationData {
  address?: string;
  country?: ICountry;
  carlsbergCategory?: string;
  googleBusinessId?: string;
  googleBusinessName?: string;
  googleBusinessCategory?: string;
  openingHoursList: ILocationOpeningHour[];
  timezone?: string;
}

export interface ILocationOpeningHour {
  startTime: number;
  endTime: number;
  weekday: number;
}

export interface ILine {
  id: string;
  type: "COMPRESSOR" | "CLEANING" | "COOLING";
  number: number;
  components: { id: string; type: IConnectedComponentType; position: number }[];
}

export interface IUnit {
  id: string;
  name: string;
  thingId: string;
  type: IConnectedComponentType;
  position: number;
  boardSerialNumber: string;
  primaryUnitId: string | null;
  lastRssi: number;
  onomondo: boolean;
  lastHeartbeat: string;
  lastBootup: string;
  firmwareVersion: string;
  latestFirmwareVersion: string;
  createDateTime: string;
}

export type IConnectedComponentType =
  | "ControlUnit"
  | "PressureChamber"
  | "SteelKegUnit"
  | "CoolingUnit"
  | "BeerDrive"
  | "GenericComponent"
  | "X10";

export interface IInstallation {
  id: string;
  pressureChambers: IPressureChamber[];
  beerDrives: IBeerDrive[];
  coolingUnits: ICoolingUnit[];
  genericComponents: IGenericComponent[];
  controlUnits: IControlUnit[];
  compressors: ICompressor[];
  steelKegUnits: ISteelKegUnit[];
}

export interface IAssociatedTechnician {
  id: string;
  name: string;
  dateAssociated: string;
  hasRegisteredDevice: boolean;
  outletsAssociatedLength: number;
}

export interface INote {
  installationId: string;
  description: string;
  descriptionUpdateDateTime: string;
}

export interface IConnectedComponent {
  id: string;
  position: number;
  boardSerialNumber: string;
  primaryUnitId?: string;
  status?: IComponentStatus[];

  // TODO check with units page
  name?: string | null;
  lastHeartbeat?: string | null;
  lastBootup?: string | null;
  firmware?: string | null;
  serialNumber?: string | null;
  alive: boolean;
  thingId: string;
  createDateTime: string | number;
  locationName?: string;
  sensorConnected?: boolean;
  group: [];
  existsLeak?: boolean;
}

export type ComponentLevelStatus = "DEFAULT" | "SUCCESS" | "WARNING" | "ERROR";

export interface IComponentStatus {
  level: ComponentLevelStatus;
  message: keyof typeof iconStatusMap;
}

export interface IKeg {
  id: string;
  volume: number;
  volumeLeft: number;
  beverage: IBeverage;
}

export interface IPressureChamber extends IConnectedComponent {
  volumeLeft?: number;
  hasPressureSwitcher: boolean;
  beverageId?: string;
  tapsPositions?: number[];

  // TODO check if needed on units page
  open?: boolean;
  used?: boolean;
  keg?: IKeg | null;
  kegChangeEvents: number;
}

export interface ISteelKegUnit extends IConnectedComponent {
  beverageId?: string;
  tapsPositions?: number[];

  steelKeg?: IKeg | null;
}

export interface IBeerDrive extends IConnectedComponent {
  volumeLeft?: number;
  beverageId?: string;
  tapsPositions: string[];

  // TODO check if needed on units page
  tank?: IKeg | null;
  capacity?: number;
}

export interface ICoolingUnit extends IConnectedComponent {
  temperatureReading0?: number;
  temperatureReading1?: number;
  temperatureReading2?: number;
}

export interface IControlUnit extends IConnectedComponent {
  affectedTapsPositions: string[];
}

export interface ICompressor extends IConnectedComponent {}

export interface IGenericComponent extends IConnectedComponent {}

export interface IGetOutletsRequestAction {
  type: typeof GET_OUTLETS_REQUEST;
}

export interface IGetOutletsSuccessAction {
  type: typeof GET_OUTLETS_SUCCESS;
  payload: ILocation[];
}

export interface IGetOutletsErrorAction {
  type: typeof GET_OUTLETS_ERROR;
}

export interface IGetOutletInfoAction {
  type: typeof GET_OUTLET_INFO;
  payload: string;
}

export interface IGetOutletInfoSuccessAction {
  type: typeof GET_OUTLET_INFO_SUCCESS;
  payload: ILocation;
}

export interface IGetOutletLinesAction {
  type: typeof GET_OUTLET_LINES;
  payload: string;
}

export interface IGetOutletLinesSuccessAction {
  type: typeof GET_OUTLET_LINES_SUCCESS;
  payload: { lines: ILine[]; id: string };
}

export interface IGetOutletInstallationAction {
  type: typeof GET_OUTLET_INSTALLATION;
  payload: string;
}

export interface IGetOutletInstallationSuccessAction {
  type: typeof GET_OUTLET_INSTALLATION_SUCCESS;
  payload: { id: string; installation: IInstallation };
}

export interface IGetOutletUnitsAction {
  type: typeof GET_OUTLET_UNITS;
  payload: string;
}

export interface IGetOutletUnitsSuccessAction {
  type: typeof GET_OUTLET_UNITS_SUCCESS;
  payload: { units: IUnit[]; id: string };
}

export interface IGetOutletTechniciansAction {
  type: typeof GET_OUTLET_TECHNICIANS;
  payload: string;
}

export interface IGetOutletTechniciansSuccessAction {
  type: typeof GET_OUTLET_TECHNICIANS_SUCCESS;
  payload: { associatedTechnicians: IAssociatedTechnician[]; id: string };
}

export interface IGetOutletNotesAction {
  type: typeof GET_OUTLET_NOTES;
  payload: string;
}

export interface IGetOutletNotesSuccessAction {
  type: typeof GET_OUTLET_NOTES_SUCCESS;
  payload: { notes: INote; id: string };
}

export interface IAssociateTechnicianToOutlet {
  type: typeof ASSOCIATE_TECHNICIAN_TO_OUTLET;
  payload: IAssociation;
}

export interface IRemoveAssociation {
  type: typeof REMOVE_ASSOCIATION;
  payload: IAssociation;
}

export interface ISaveOutletDescription {
  type: typeof SAVE_OUTLET_DESCRIPTION;
  payload: {
    locationId: string;
    installationId: string;
    description: string;
  };
}

export interface IResetBeerDrive {
  type: typeof RESET_BEER_DRIVE;
  payload: {
    beerDriveId: string;
    outletId: string;
  };
}

export interface IExportData {
  type: typeof EXPORT_DATA;
  payload: {
    outletId: string;
    outletName: string;
    start: number;
    end: number;
  };
}

export interface IExportAllData {
  type: typeof EXPORT_ALL_DATA;
  payload: {
    x10: boolean;
    start: number;
    end: number;
  };
}

export interface IGetCountriesAction {
  type: typeof GET_COUNTRIES;
}

export interface IGetCountriesSuccessAction {
  type: typeof GET_COUNTRIES_SUCCESS;
  payload: ICountry[];
}

export interface ICreateOutletObject {
  name: string;
  type?: string;
  countryId: string;
  email?: string;
}

export interface ICreateOutletAction {
  type: typeof CREATE_OUTLET;
  payload: ICreateOutletObject;
}

export interface ICreateOutletSuccessAction {
  type: typeof CREATE_OUTLET_SUCCESS;
}

export interface ICreateOutletErrorAction {
  type: typeof CREATE_OUTLET_ERROR;
}

export interface IEditOutletAction {
  type: typeof EDIT_OUTLET;
  payload: {
    outletId: string;
    outletObj: ICreateOutletObject;
  };
}

export interface IEditOutletSuccessAction {
  type: typeof EDIT_OUTLET_SUCCESS;
}

export interface IEditOutletErrorAction {
  type: typeof EDIT_OUTLET_ERROR;
}

export interface IResetOutletFormAction {
  type: typeof RESET_OUTLET_FORM_STATE;
}

export type OutletsActionTypes =
  | IGetOutletsRequestAction
  | IGetOutletsSuccessAction
  | IGetOutletsErrorAction
  | IGetOutletInfoSuccessAction
  | IGetOutletLinesSuccessAction
  | IGetOutletInstallationSuccessAction
  | IGetOutletUnitsSuccessAction
  | IGetOutletTechniciansSuccessAction
  | IGetOutletNotesSuccessAction
  | IAssociateTechnicianToOutlet
  | IRemoveAssociation
  | ISaveOutletDescription
  | IExportData
  | IGetCountriesSuccessAction
  | ICreateOutletAction
  | ICreateOutletSuccessAction
  | ICreateOutletErrorAction
  | IEditOutletAction
  | IEditOutletSuccessAction
  | IEditOutletErrorAction
  | IResetOutletFormAction;

import produce from "immer";
import { Reducer } from "redux";
import {
  Extra10OutletsActionTypes,
  GET_X10_OUTLET_INFO_SUCCESS,
  GET_X10_OUTLET_INSTALLATION_SUCCESS,
  GET_X10_OUTLETS_ERROR,
  GET_X10_OUTLETS_REQUEST,
  GET_X10_OUTLETS_SUCCESS,
  IExtra10OutletsState,
  SET_COMPONENT_BEVERAGE,
  SET_COMPONENT_BEVERAGE_ERROR
} from "./types";

const initalState: IExtra10OutletsState = {
  outlets: [],
  requestingOutlets: false,
  requestOutletsError: false,
  errorChangingBeverage: false
};

const reducer: Reducer<IExtra10OutletsState, Extra10OutletsActionTypes> = (
  state = initalState,
  action: Extra10OutletsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_X10_OUTLETS_REQUEST:
        draft.requestingOutlets = true;
        draft.requestOutletsError = false;
        break;
      case GET_X10_OUTLETS_SUCCESS:
        draft.requestingOutlets = false;
        draft.outlets = action.payload.sort((a, b) => (a.name < b.name ? -1 : 1));
        break;
      case GET_X10_OUTLETS_ERROR:
        draft.requestOutletsError = true;
        draft.requestingOutlets = false;
        break;
      case GET_X10_OUTLET_INFO_SUCCESS:
      case GET_X10_OUTLET_INSTALLATION_SUCCESS:
        draft.outlets = state.outlets.map(o =>
          o.id === action.payload.id
            ? {
                ...o,
                ...action.payload
              }
            : o
        );
        break;
      case SET_COMPONENT_BEVERAGE:
        draft.errorChangingBeverage = false;
        break;
      case SET_COMPONENT_BEVERAGE_ERROR:
        draft.errorChangingBeverage = true;
        break;
      default:
        break;
    }
  });

export default reducer;

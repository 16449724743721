import produce from "immer";
import { Reducer } from "redux";
import {
  CLOSE_SIDEBAR,
  ISidebarState,
  OPEN_SIDEBAR,
  SidebarActionTypes,
  TOGGLE_SIDEBAR
} from "./types";

const initialState: ISidebarState = {
  isOpen: false
};

const reducer: Reducer<ISidebarState, SidebarActionTypes> = (
  state = initialState,
  action: SidebarActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case TOGGLE_SIDEBAR:
        draft.isOpen = !state.isOpen;
        break;
      case CLOSE_SIDEBAR:
        draft.isOpen = false;
        break;
      case OPEN_SIDEBAR:
        draft.isOpen = true;
        break;
      default:
        break;
    }
  });

export default reducer;

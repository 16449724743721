import {
  BeverageTranslation,
  CREATE_AND_APPROVE_BEVERAGE,
  EDIT_BEVERAGE,
  GET_BEVERAGE_DETAIL,
  GET_BEVERAGE_DETAIL_ERROR,
  GET_BEVERAGE_DETAIL_SUCCESS,
  GET_BEVERAGES,
  GET_BEVERAGES_ERROR,
  GET_BEVERAGES_SUCCESS,
  GET_BEVERAGES_TRANSLATIONS,
  GET_BEVERAGES_TRANSLATIONS_ERROR,
  GET_BEVERAGES_TRANSLATIONS_SUCCESS,
  IBeverageTranslation,
  ICreateAndApproveBeverage,
  IEditBeverage,
  IGetBeverageDetail,
  IGetBeverageDetailError,
  IGetBeverageDetailSuccess,
  IGetBeverages,
  IGetBeveragesError,
  IGetBeveragesSuccess,
  IGetBeveragesTranslations,
  IGetBeveragesTranslationsError,
  IGetBeveragesTranslationsSuccess,
  IMergeBeverageProps,
  ISetCurrentBeverage,
  MERGE_BEVERAGE,
  SET_CURRENT_BEVERAGE
} from "./types";

export const getBeverages = (): IGetBeverages => ({
  type: GET_BEVERAGES
});
export const getBeveragesSuccess = (
  beverages: BeverageTranslation[],
  beveragesX10: BeverageTranslation[]
): IGetBeveragesSuccess => ({
  type: GET_BEVERAGES_SUCCESS,
  payload: { beverages, beveragesX10 }
});
export const getBeveragesError = (): IGetBeveragesError => ({
  type: GET_BEVERAGES_ERROR
});

export const getBeveragesTranslations = (): IGetBeveragesTranslations => ({
  type: GET_BEVERAGES_TRANSLATIONS
});
export const getBeveragesTranslationsSuccess = (
  translations: BeverageTranslation[]
): IGetBeveragesTranslationsSuccess => ({
  type: GET_BEVERAGES_TRANSLATIONS_SUCCESS,
  payload: translations
});
export const getBeveragesTranslationsError = (): IGetBeveragesTranslationsError => ({
  type: GET_BEVERAGES_TRANSLATIONS_ERROR
});
export const getBeverageDetail = (payload: string): IGetBeverageDetail => ({
  type: GET_BEVERAGE_DETAIL,
  payload
});
export const getBeverageDetailSuccess = (
  payload: BeverageTranslation
): IGetBeverageDetailSuccess => ({
  type: GET_BEVERAGE_DETAIL_SUCCESS,
  payload
});
export const getBeverageDetailError = (): IGetBeverageDetailError => ({
  type: GET_BEVERAGE_DETAIL_ERROR
});
export const createAndApproveBeverage = (
  payload: IBeverageTranslation
): ICreateAndApproveBeverage => ({
  type: CREATE_AND_APPROVE_BEVERAGE,
  payload
});
export const editBeverage = (payload: IBeverageTranslation): IEditBeverage => ({
  type: EDIT_BEVERAGE,
  payload
});

export const setCurrentBeverage = (payload: BeverageTranslation): ISetCurrentBeverage => ({
  type: SET_CURRENT_BEVERAGE,
  payload
});

export const mergeBeverage = (payload: IMergeBeverageProps) => ({
  type: MERGE_BEVERAGE,
  payload
});

import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Routes from "../../routes";
import { ICELocation } from "../../store/ceLocations/types";
import "./celocations.scss";
import useContent from "../../hooks/useContent";
import { getCurrentLanguage } from "../../store/content/selectors";

interface IProps {
  ceLocation?: ICELocation;
  isLink?: boolean;
}

const CeLocationListItem: React.FC<IProps> = ({ ceLocation, isLink = false }) => {
  const { ceLocations: content } = useContent();
  const language = useSelector(getCurrentLanguage);

  if (!ceLocation) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const item = useMemo(
    () => (
      <>
        <div
          className="item-name-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}
        >
          <span>
            {ceLocation.ceLocation.name &&
            ceLocation.ceLocation.name !==
              ceLocation.ceLocation.address.substring(0, ceLocation.ceLocation.address.indexOf(","))
              ? `${ceLocation.ceLocation.name}, `
              : ""}
            {ceLocation.ceLocation.address}
            {ceLocation.ceLocation.international ? ` - ${content.celocations_international}` : ""}
          </span>
          <span className="celocations-list-description truncate">
            {ceLocation.type} {ceLocation.customType && `: ${ceLocation.customType}`}
          </span>
        </div>
        <div className="item-rest">
          <div className="celocations-table-title">
            <span>{ceLocation.technician.name}</span>
          </div>
        </div>
        <div className="item-rest">
          <div className="celocations-table-title ">
            {ceLocation.createDateTime && (
              <span>
                {new Date(ceLocation.createDateTime).toLocaleDateString(language, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                })}
              </span>
            )}
          </div>
        </div>
        <div className="item-rest">
          <div className="celocations-table-title">
            <span>{ceLocation.certificationNumber}</span>
          </div>
        </div>
      </>
    ),
    [ceLocation, content, language]
  );

  return isLink ? (
    <Link to={Routes.ceLocationDetails.replace(":id", ceLocation.id)} className="item extended">
      {item}
    </Link>
  ) : (
    <div className="item extended">{item}</div>
  );
};

export default CeLocationListItem;

import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { useCheckWindowSize } from "@ddm-design-system/hooks";
import { IAppState } from "../../../store";
import { getCurrentContent } from "../../../store/content/selectors";
import { IContent } from "../../../store/content/types";
import Icon from "../icon/Icon";
import CustomMenu from "../menu/Menu";
import ControlTowerTooltip from "../tooltip/Tooltip";
import "./carditem.scss";
import "./item.scss";

interface IProps {
  name: string;
  link?: string;
  date?: Date;
  details?: Array<{
    title: string;
    value: string;
  }>;
  icon?: string;
  status?: string;
  tooltipText?: string;
  extended?: boolean;
  changeAction?: () => void;
  deleteAction?: () => void;
  content?: IContent;
  currentLanguage: string;
  menuOptions?: IMenuOption[];
  className?: string;
}
export interface IMenuOption {
  name: string;
  icon: string;
  onClick?: () => void;
}

export const CardItem: React.FC<IProps> = ({
  name,
  link,
  extended,
  currentLanguage,
  details,
  date,
  changeAction,
  deleteAction,
  icon,
  status,
  tooltipText,
  content,
  menuOptions,
  className
}) => {
  const isMobile = useCheckWindowSize(EBreakpoints.MOBILE2);

  return !isMobile && extended && content ? (
    <div className={classnames("item extended", className)}>
      <div className="item-name-container">
        {icon && (
          <ControlTowerTooltip
            title={tooltipText || ""}
            type={status || "success"}
            placement="top-start"
          >
            <div className="item-icon">
              <Icon name={icon} />
            </div>
          </ControlTowerTooltip>
        )}
        {link ? (
          <Link to={link || ""} className="item-name truncate">
            <h2>{name}</h2>
          </Link>
        ) : (
          <h2 className="item-name truncate">{name}</h2>
        )}
      </div>
      <div className="item-rest">
        {date && (
          <div className="item-rest-details">
            <span className="item-rest-detail-title">{content.common_assigned_in}</span>
            <span className="item-rest-detail-value">
              {date.toLocaleDateString(currentLanguage, {
                year: "numeric",
                month: "short",
                day: "numeric"
              })}
            </span>
          </div>
        )}
        {(changeAction || deleteAction) && (
          <div className="item-rest-actions">
            {changeAction && (
              <div onClick={changeAction}>
                <Icon name="refresh" className="gray" />
              </div>
            )}
            {deleteAction && (
              <div onClick={deleteAction}>
                <Icon name="close" className="gray" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={classnames("card-item", className)}>
      <div className="card-item-header">
        {link ? (
          <Link to={link || ""} className="card-item-header-left card-item-header-name truncate">
            <h2>{name}</h2>
          </Link>
        ) : (
          <h2 className="card-item-header-left card-item-header-name truncate">{name}</h2>
        )}
        <div className="card-icons-container">
          {icon && (
            <div className="card-item-header-right">
              <ControlTowerTooltip
                title={tooltipText || ""}
                type={status || "success"}
                placement="top-start"
              >
                <div className="icon">
                  <Icon name={icon} />
                </div>
              </ControlTowerTooltip>
            </div>
          )}
          {!isMobile && (changeAction || deleteAction) && (
            <div className="card-item-actions">
              {changeAction && (
                <div onClick={changeAction}>
                  <Icon name="refresh" className="gray" />
                </div>
              )}
              {deleteAction && (
                <div onClick={deleteAction}>
                  <Icon name="close" className="gray" />
                </div>
              )}
            </div>
          )}
          {menuOptions && menuOptions.length > 0 && (
            <div className="card-item-mobile-menu">
              <CustomMenu>
                {menuOptions.map((option, index) => {
                  return (
                    <div
                      className="menu-option"
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      onClick={event => {
                        event.preventDefault();

                        if (option.onClick) {
                          option.onClick();
                        }
                      }}
                    >
                      <Icon name={option.icon} />
                      <span>{option.name}</span>
                    </div>
                  );
                })}
              </CustomMenu>
            </div>
          )}
        </div>
      </div>
      <div className="card-item-details">
        <div className="card-item-detail">
          {details &&
            details.map((detail, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                <span className="card-item-detail-title">{detail.title}</span>
                <span className="card-item-detail-value">{detail.value}</span>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);

  return {
    content: content ? content.common : null,
    currentLanguage: state.content.currentLanguage
  };
};

export default connect<any, any, any, any>(mapStateToProps, null)(CardItem);

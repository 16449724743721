import React from "react";
import styled, { css } from "styled-components";
import { Body } from "@ddm-design-system/typography";

export const EditSection = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spaceUnit.lg};
  `
);

interface IEditItemProps {
  title: string;
}

export const EditItem = styled.div.attrs<IEditItemProps>(({ title, children }) => ({
  children: (
    <>
      <Body>{title}</Body>
      {children}
    </>
  )
}))<IEditItemProps>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spaceUnit.xs};
    justify-content: space-between;
  `
);

import styled, { css } from "styled-components";
import Modal, { ModalBody } from "@ddm-design-system/modal";
import { Divider } from "@ddm-design-system/divider";
import { InputSpinner, InputSpinnerStyles } from "@ddm-design-system/input-spinner";
import { Dropdown, MultipleDropdown, DropdownStyles } from "@ddm-design-system/dropdown";
import { TextInput } from "@ddm-design-system/textinput";

export const StyledTextInput = styled(TextInput)<{ required?: boolean }>(
  ({ theme, required, value }) => css`
    width: 220px;

    ${required &&
    !value &&
    css`
      border-color: ${theme.colors.alert.alert100};
    `}
  `
);

export const StyledInputSpinner = styled(InputSpinner)`
  width: 220px;

  ${InputSpinnerStyles.Value} {
    width: auto !important;
  }
`;

export const StyledDropdown = styled(Dropdown)<{ required?: boolean }>(
  ({ theme, selectedItem, required }) => css`
    width: 220px;

    ${!selectedItem &&
    required &&
    css`
      ${DropdownStyles.TextWrapper} {
        border-color: ${theme.colors.alert.alert100};
      }
    `}
  `
);

export const StyledMultipleDropdown = styled(MultipleDropdown)(
  ({ theme, selectedItems }) => css`
    width: 220px;

    ${selectedItems.length === 0 &&
    css`
      ${DropdownStyles.TextWrapper} {
        border-color: ${theme.colors.alert.alert100};
      }
    `}
  `
);

export const StyledModal = styled(Modal)(
  ({ theme }) => css`
    max-height: calc(100vh - ${theme.spaceUnit.xxl});
  `
);

export const StyledModalBody = styled(ModalBody)(
  () => css`
    overflow: auto;
    max-height: calc(100vh - 20%);
  `
);

export const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    margin: ${theme.spaceUnit.lg} 0;
  `
);

import { IAppState } from "..";

export const getPurchasingGroups = (state: IAppState) => state.purchasingGroups.purchasingGroups;

export const getIsRequestingPurchasingGroups = (state: IAppState) =>
  state.purchasingGroups.requestingPurchasingGroups;

export const getHasPurchasingGroupsError = (state: IAppState) =>
  state.purchasingGroups.requestPurchasingGroupsError;

export const getIsCreatingPurchasingGroup = (state: IAppState) =>
  state.purchasingGroups.creatingPurchasingGroup;

export const getHasCreatePurchasingGroupError = (state: IAppState) =>
  state.purchasingGroups.createPurchasingGroupError;

export const getPurchasingGroup = (id?: string) => (state: IAppState) =>
  getPurchasingGroups(state).find(pg => pg.id === id);

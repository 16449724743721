import { translateString, translate } from "./translate";
import { IEntries } from "./index";
import { ValueList } from "./types";

export default function mapValueList(entries: IEntries, lang = process.env.REACT_APP_DEFAULT_LANG) {
  const valueList: any = {};

  entries.valueList.ordered.forEach(list => {
    const name = translate(list.fields.name);
    const values: any[] = translate(list.fields.values) || [];
    const parsedValues: ValueList = values.map(v => ({
      key: translateString(v.fields.stringKey),
      name: translateString(v.fields.content, lang)
    }));

    valueList[name] = parsedValues;
  });

  return { valueList };
}

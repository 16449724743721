import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import { Link } from "../common/link/link.styles";
import DataTable from "../common/data_table/DataTable";
import { IFilter } from "../common/filter/Filter";
import { getCurrentLanguage } from "../../store/content/selectors";
import { ICELocation } from "../../store/ceLocations/types";
import { getLoadingCeLocations } from "../../store/ceLocations/selectors";

interface IProps {
  filters?: IFilter[];
  locations: ICELocation[];
  searchSelector?: string | string[];
  downloadCSV?: any;
  downloadExcel?: any;
}

const CeLocationList: React.FC<IProps> = ({
  filters,
  locations,
  searchSelector,
  downloadCSV,
  downloadExcel
}) => {
  const { ceLocations: content, common: contentCommon } = useContent();
  const language = useSelector(getCurrentLanguage);
  const isLoading = useSelector(getLoadingCeLocations);

  const formatCeLocation = useCallback(
    ({ id, title }: ICELocation) => {
      return (
        <Link to={Routes.ceLocationDetails.replace(":id", id)} title={title}>
          {title}
        </Link>
      );
    },
    [content]
  );

  const formatType = useCallback(({ type, customType }: ICELocation) => {
    return (
      <span>
        {type} {customType && `: ${customType}`}
      </span>
    );
  }, []);

  const formatTimeCreate = useCallback(
    ({ createDateTime }: ICELocation) => {
      const date = createDateTime
        ? new Date(createDateTime).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const columns = useMemo(
    () => [
      {
        format: formatCeLocation,
        name: content.celocations_address_title,
        selector: (row: ICELocation) => row.ceLocation.name,
        sortable: true,
        grow: 6,
        sortFunction: (locationA: ICELocation, locationB: ICELocation) =>
          (locationA.title || "").toLocaleLowerCase() > (locationB.title || "").toLocaleLowerCase()
            ? 1
            : -1
      },
      {
        format: formatType,
        name: "Type",
        selector: (row: ICELocation) => row.type
      },
      {
        name: content.celocations_technician_title,
        selector: (row: ICELocation) => row.technician?.name,
        sortable: true
      },
      {
        format: formatTimeCreate,
        name: content.celocations_date_created_title,
        selector: (row: ICELocation) => row.createDateTime || 0,
        sortable: true
      },
      {
        name: content.celocations_ce_number_title,
        selector: (row: ICELocation) => row.certificationNumber,
        sortable: true
      }
    ],
    [formatCeLocation, formatType, content, formatTimeCreate]
  );

  return (
    <DataTable
      columns={columns}
      data={locations}
      defaultSortAsc={false}
      defaultSortField="createDateTime"
      filters={filters}
      isLoading={isLoading}
      searchPlaceholder={content.celocations_search_placeholder}
      searchSelector={searchSelector}
      pagination
      paginationPerPage={100}
      title=""
      actions={[
        {
          icon: "Download",
          label: (locs: ICELocation[]) => downloadExcel(locs),
          onClick: () => {}
        },
        {
          icon: "Download",
          label: contentCommon.common_download_csv,
          onClick: locs => downloadCSV(locs)
        }
      ]}
    />
  );
};

export default CeLocationList;

import { ICountry } from "../beverages/types";
import { ITechnician } from "../technicians/types";

export const GET_CELOCATIONS_REQUEST = "celocations/GET_CELOCATIONS_REQUEST";
export const GET_CELOCATIONS_SUCCESS = "celocations/GET_CELOCATIONS_SUCCESS";
export const GET_CELOCATIONS_ERROR = "celocations/GET_CELOCATIONS_ERROR";
export const PUT_CELOCATION_REQUEST = "celocations/PUT_CELOCATION_REQUEST";
export const PUT_CELOCATION_FLEX_REQUEST = "celocations/PUT_CELOCATION_FLEX_REQUEST";
export const PUT_CELOCATION_SUCCESS = "celocations/PUT_CELOCATION_SUCCESS";
export const PUT_CELOCATION_ADDRESS_REQUEST = "celocations/PUT_CELOCATION_ADDRESS_REQUEST";
export const PUT_CELOCATION_ADDRESS_SUCCESS = "celocations/PUT_CELOCATION_ADDRESS_SUCCESS";
export const DELETE_CELOCATION_PHOTO = "celocations/DELETE_CELOCATION_PHOTO";
export const PUT_CELOCATION_PHOTO = "celocations/PUT_CELOCATION_PHOTO";
export const DELETE_CELOCATION_REQUEST = "celocations/DELETE_CELOCATION_REQUEST";
export const DELETE_CELOCATION_SUCCESS = "celocations/DELETE_CELOCATION_SUCCESS";

export enum CEInstallationType {
  basement = "basement",
  coldroom = "coldroom",
  underCounter = "under_counter",
  separateRoom = "separate_room",
  other = "other"
}

export interface ICELocationState {
  ceLocations: ICELocation[];
  ceLocationsDeleted: ICELocation[];
  requestingCeLocations: boolean;
  requestCeLocationsError: boolean;
}

export interface ICELocation {
  id: string;
  certificationNumber: string;
  type: string;
  title?: string;
  country?: string;
  customType: string;
  description: string;
  pressureChambers: number;
  oldestKegModuleYear: number;
  coolingUnits: number;
  coolingUnitBrands: string[];
  cleaningUnits: number;
  cleaningUnitBrands: string[];
  beerBoostPumpBrand: string;
  beerBoostPumpQuantity: number;
  couplingBrand: string;
  fobDetectorBrand: string;
  compressorBrand: string;
  pythonBrand: string;
  taps: number;
  newInstallation: boolean;
  photoUrls: string[];
  customFields: string;
  technician: ITechnician;
  ceLocation: ICELocationAddress;
  createDateTime: string;
  deleted: boolean;
  flex20Year: number;
  flex20Serial: string;
  flex20: boolean;
  kegModuleType: string;
}

export interface ICELocationAddress {
  id: string;
  address: string;
  city: string;
  zip: string;
  coordinates: string;
  international: boolean;
  name: string;
  country: ICountry;
  createDateTime: string;
  ceInstallations: any;
}

export interface ICELocationAddressEdit {
  id: string;
  address?: string;
  city?: string;
  zip?: string;
  name?: string;
}

export interface IPutCeLocation {
  description: string;
  pressureChambers: number;
  oldestKegModuleYear?: number;
  coolingUnits: number;
  coolingUnitBrands?: string[];
  cleaningUnits: number;
  cleaningUnitBrands: string[];
  beerBoostPumpQuantity?: number;
  beerBoostPumpBrand?: string;
  couplingBrand: string;
  fobDetectorBrand?: string;
  compressorBrand?: string;
  pythonBrand: string;
  taps: number;
  customFields?: string;
  kegModuleType?: string;
}
export interface IPutCeLocationFlex {
  flex20Year: number;
  flex20Serial: string;
}

export interface IGetCeLocationsRequest {
  type: typeof GET_CELOCATIONS_REQUEST;
}
export interface IGetCeLocationsSuccess {
  type: typeof GET_CELOCATIONS_SUCCESS;
  payload: {
    ceLocations: ICELocation[];
    content: any;
  };
}
export interface IGetCeLocationsError {
  type: typeof GET_CELOCATIONS_ERROR;
}

export interface IPutCeLocationRequest {
  type: typeof PUT_CELOCATION_REQUEST;
  payload: {
    ceInstallationId: string;
    ceLocationInfo: IPutCeLocation;
  };
}

export interface IPutCeLocationFlexRequest {
  type: typeof PUT_CELOCATION_FLEX_REQUEST;
  payload: {
    ceInstallationId: string;
    ceLocationInfo: IPutCeLocationFlex;
  };
}

export interface IPutCeLocationSuccess {
  type: typeof PUT_CELOCATION_SUCCESS;
  payload: {
    ceLocation: ICELocation;
    content: any;
  };
}

export interface IPutCeLocationAddressRequest {
  type: typeof PUT_CELOCATION_ADDRESS_REQUEST;
  payload: {
    ceLocationId: string;
    ceLocationAddress: ICELocationAddressEdit;
  };
}

export interface IPutCeLocationAddressSuccess {
  type: typeof PUT_CELOCATION_ADDRESS_SUCCESS;
  payload: {
    ceLocationId: string;
  };
}

export interface IPutCeLocationPhoto {
  type: typeof PUT_CELOCATION_PHOTO;
  payload: {
    ceInstallationId: string;
    index: number;
    file: File;
  };
}

export interface IDeleteCePhoto {
  type: typeof DELETE_CELOCATION_PHOTO;
  payload: {
    ceInstallationId: string;
    filename: string;
  };
}

export interface IDeleteCeLocationRequest {
  type: typeof DELETE_CELOCATION_REQUEST;
  payload: {
    ceInstallationId: string;
    certificationNumber: string;
  };
}

export interface IDeleteCeLocationSuccess {
  type: typeof DELETE_CELOCATION_SUCCESS;
  payload: {
    ceInstallationId: string;
    certificationNumber: string;
  };
}

export type CeLocationsActionTypes =
  | IGetCeLocationsRequest
  | IGetCeLocationsSuccess
  | IGetCeLocationsError
  | IPutCeLocationRequest
  | IPutCeLocationSuccess
  | IPutCeLocationAddressRequest
  | IPutCeLocationAddressSuccess
  | IPutCeLocationPhoto
  | IDeleteCePhoto
  | IDeleteCeLocationRequest
  | IDeleteCeLocationSuccess;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { editBeverage, getBeverageDetail } from "../../store/beverages/actions";
import Button from "../common/button/Button";
import { DetailPage } from "../common/DetailPage/DetailPage";
import { DetailPageContent } from "../common/DetailPage/DetailPageContent";
import Header from "../common/header/Header";
import Modal from "../common/modal/Modal";
import { BeverageForm } from "./BeverageForm";
import BeverageMerge from "./BeverageMerge";

interface IProps extends RouteComponentProps {
  beverageId: string;
}

let unblock: any = null;

export const BeverageDetails: React.FC<IProps> = ({ beverageId, history }) => {
  const dispatch = useDispatch();
  const me = useSelector((state: IAppState) => state.authReducer.me);
  const [discardModal, setDiscardModal] = useState(false);
  const [changesWereMade, setChangesWereMade] = useState(false);
  const [nextPath, setNextPath] = useState<any>();

  const { controlTowerBeveragesPage: content, common } = useContent();

  useEffect(() => {
    if (changesWereMade) {
      if (unblock) {
        unblock();
      }

      // eslint-disable-next-line consistent-return
      unblock = history.block(path => {
        if (changesWereMade) {
          setDiscardModal(true);
          setNextPath(path);

          return false;
        }
      });
    }
  }, [changesWereMade, history]);

  useEffect(() => {
    dispatch(getBeverageDetail(beverageId));
  }, [beverageId, dispatch]);

  const beverage = useSelector((state: IAppState) => state.beverages.currentBeverage);

  const [redirect, setRedirect] = useState<string>();
  const redirectAux = (id: string) => {
    setRedirect(id);
  };

  return redirect ? (
    <Redirect to={Routes.beverages} />
  ) : (
    <DetailPage className="page-list beverage-details">
      <Header
        title={beverage?.name || content.control_tower_loading_beverage}
        breadcrumbs={[{ link: Routes.beverages, name: content.control_tower_beverages }]}
      />
      <DetailPageContent>
        {me &&
          beverage &&
          (beverage.active ? (
            <BeverageForm
              myCountry={me.country}
              beverage={beverage}
              onChanges={setChangesWereMade}
              onSubmit={newBeverage => {
                setChangesWereMade(false);

                if (unblock) {
                  unblock();
                }

                dispatch(editBeverage(newBeverage));
              }}
            />
          ) : (
            <BeverageMerge beverage={beverage} onMerge={(id: string) => redirectAux(id)} />
          ))}
        <Modal
          visible={discardModal}
          title={content.control_tower_discard_changes}
          onClose={() => setDiscardModal(false)}
        >
          <div>{content.control_tower_discard_changes_description}</div>
          <div className="beverage-discard-guard">
            <Button ghost onClick={() => setDiscardModal(false)}>
              {common.common_cancel || ""}
            </Button>
            <Button
              error
              onClick={() => {
                setDiscardModal(false);

                if (unblock) {
                  unblock();
                }

                history.push(nextPath);
              }}
            >
              {common.control_tower_exit_without_saving || ""}
            </Button>
          </div>
        </Modal>
      </DetailPageContent>
    </DetailPage>
  );
};
export default withRouter(BeverageDetails);

import { useSelector } from "react-redux";
import { getCurrentContent } from "../store/content/selectors";
import { IContent, ILanguageInfo, IForm } from "../store/content/types";

interface IContentfulMap {
  languageInfo: ILanguageInfo[];
  controlTowerBeveragesPage: IContent;
  controlTowerCountries: IContent;
  mediaContainer: string[];
  loginControlTower: IContent;
  controlTowerInvitedUsers: IContent;
  controlTowerUserDetail: IContent;
  controlTowerError: IContent;
  common: IContent;
  outlets: IContent;
  purchasingGroups: IContent;
  distributors: IContent;
  emailDomains: string;
  outletDetails: IContent;
  ceLocations: IContent;
  healthMonitor: IContent;
  forms: { [key: string]: IForm[] };
  valueList: IContent;
  technicianDetail: IContent;
  sidebar: IContent;
}

const initialContent: IContentfulMap = {
  languageInfo: [],
  controlTowerBeveragesPage: {},
  controlTowerInvitedUsers: {},
  controlTowerCountries: {},
  controlTowerError: {},
  loginControlTower: {},
  mediaContainer: [],
  emailDomains: "",
  controlTowerUserDetail: {},
  common: {},
  outlets: {},
  purchasingGroups: {},
  distributors: {},
  outletDetails: {},
  ceLocations: {},
  healthMonitor: {},
  forms: {},
  valueList: {},
  sidebar: {},
  technicianDetail: {}
};

const useContent: () => IContentfulMap = () => {
  const content = useSelector(getCurrentContent);

  return {
    ...initialContent,
    ...((content as IContentfulMap) || {})
  };
};

export default useContent;

import styled, { css } from "styled-components";
import { TextInput } from "@ddm-design-system/textinput";
import { Dropdown } from "@ddm-design-system/dropdown";

export const CreateOutletFooter = styled.div<any>(
  ({ theme, error }) => css`
    padding-top: ${theme.spaceUnit.lg};
    border-top: 1px solid ${error ? theme.colors.alert.alert50 : theme.colors.grey.grey50};
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `
);

export const StyledTextInput = styled(TextInput)(
  () => css`
    border-radius: 4px;
  `
);

export const StyledDropdown = styled(Dropdown)(
  () => css`
    width: 100%;
  `
);

import React from "react";
import { MainTitle } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { getCurrentComponent } from "../../../store/components/selectors";
import BeerDriveInfo from "./beer-drive/info/Info";
import ControlUnitInfo from "./control-unit/info/Info";
import CoolingUnitInfo from "./cooling-unit/info/Info";
import GenericComponentInfo from "./generic-component/info/Info";
import PressureChamberInfo from "./pressure-chamber/info/Info";
import CompressorInfo from "./compressor/info/Info";
import SteelKegUnitInfo from "./steel-keg-unit/info/Info";
import { EComponentType } from "../../../store/components/types";

const Info: React.FC = () => {
  const { common } = useContent();
  const currentComponent = useSelector(getCurrentComponent());
  const infoComponentMap = {
    [EComponentType.PressureChamber]: PressureChamberInfo,
    [EComponentType.SteelKegUnit]: SteelKegUnitInfo,
    [EComponentType.BeerDrive]: BeerDriveInfo,
    [EComponentType.ControlUnit]: ControlUnitInfo,
    [EComponentType.CoolingUnit]: CoolingUnitInfo,
    [EComponentType.GenericComponent]: GenericComponentInfo,
    [EComponentType.Compressor]: CompressorInfo
  };

  const InfoComponent = (infoComponentMap as any)[currentComponent?.type || ""];

  return (
    <div className="py-xxl">
      <div className="flex items-center justify-between">
        <MainTitle>{common.common_information}</MainTitle>
      </div>
      {currentComponent && InfoComponent ? (
        <InfoComponent componentInfo={currentComponent} />
      ) : (
        <div />
      )}
    </div>
  );
};

export default Info;

import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Body } from "@ddm-design-system/typography";
import { Link } from "react-router-dom";
import useContent from "../../../hooks/useContent";
import AnchorContainer from "../../common/anchor_container/AnchorContainer";
import { getOutletLines } from "../../../store/outlets/actions";
import { getOutlet } from "../../../store/outlets/selectors";
import { ILine } from "../../../store/outlets/types";
import Routes from "../../../routes";

interface IProps {
  outletId: string;
}

const Lines: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { outletDetails: content, common } = useContent();
  const outlet = useSelector(getOutlet(outletId));

  const list = useMemo(() => {
    if (!outlet || !outlet.lines) {
      return [];
    }

    let counterLines = 0;

    return (
      outlet.lines
        ?.filter(
          l => l.type === "COMPRESSOR" || l.components.some(c => c.type !== "PressureChamber")
        )
        .map((l: ILine) => {
          const mainComponent = l.components.find(c => c.type !== "PressureChamber");

          return {
            title: `${l.type?.toLocaleLowerCase()} line ${
              mainComponent?.position || ++counterLines
            }`,
            href: l.type + l.id,
            renderContent: () => (
              <div className="flex flex-row flex-wrap">
                {l.components
                  .filter(c => c.type === "PressureChamber")
                  .sort((a, b) => a.position - b.position)
                  .map((c: ILine["components"][0]) => (
                    <Link
                      to={Routes.outletComponentDetails
                        .replace(":id", outletId)
                        .replace(":cid", c.id)}
                      className="py-xs px-sm mr-md mb-md rounded border border-grey-grey100"
                      key={c.type + c.position}
                    >
                      {common[`common_${c.type[0].toLocaleLowerCase()}${c.type.slice(1)}`]}{" "}
                      {c.position}
                    </Link>
                  ))}
                {!l.components.length && (
                  <Body className="text-grey-grey100">{content.outlet_component_no_lines}</Body>
                )}
              </div>
            )
          };
        }) || []
    );
  }, [outlet, content]);

  useEffect(() => {
    dispatch(getOutletLines(outletId));
  }, [dispatch, outletId]);

  return (
    <div>
      <AnchorContainer
        list={list}
        emptyString={content.outlet_details_no_lines}
        loading={!outlet?.lines}
      />
    </div>
  );
};

export default Lines;

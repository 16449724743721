export const Routes = {
  home: "/",
  overview: "/overview",
  beverages: "/beverages",
  beverageDetail: "/beverage/:id",
  newBeverage: "/beverages/new",
  users: "/users",
  usersDetail: "/users/:id",
  technicians: "/technicians",
  technicianDetails: "/technicians/:id",
  purchasingGroups: "/purchasing-groups",
  purchasingGroupDetails: "/purchasing-groups/:id",
  purchasingGroupInfo: "/purchasing-groups/:id/info",
  purchasingGroupDistributors: "/purchasing-groups/:id/distributors",
  distributors: "/distributors",
  distributorDetails: "/distributors/:id",
  distributorInfo: "/distributors/:id/info",
  distributorOutlets: "/distributors/:id/outlets",
  outlets: "/outlets",
  outletDetails: "/outlets/:id",
  outletDetailsInfo: "/outlets/:id/info",
  outletDetailsLines: "/outlets/:id/lines",
  outletDetailsInstallation: "/outlets/:id/installation",
  outletDetailsUnits: "/outlets/:id/units",
  outletDetailsAssociatedTechnicians: "/outlets/:id/technicians",
  outletDetailsHealthMonitor: "/outlets/:id/healthmonitor",
  outletDetailsNotes: "/outlets/:id/notes",
  outletComponentDetails: "/outlets/:id/component/:cid",
  outletComponentDetailsInfo: "/outlets/:id/component/:cid/info",
  outletComponentDetailsHealthMonitor: "/outlets/:id/component/:cid/healthmonitor",
  outletComponentDetailsLines: "/outlets/:id/component/:cid/lines",
  extra10Outlets: "/extra10outlets",
  extra10OutletDetails: "/extra10outlets/:id",
  extra10OutletDetailsInfo: "/extra10outlets/:id/info",
  extra10OutletDetailsInstallation: "/extra10outlets/:id/installation",
  extra10OutletDetailsHealthMonitor: "/extra10outlets/:id/healthmonitor",
  extra10OutletDetailsNotes: "/extra10outlets/:id/notes",
  extra10OutletComponentDetails: "/extra10outlets/:id/component/:cid",
  extra10OutletComponentDetailsInfo: "/extra10outlets/:id/component/:cid/info",
  extra10OutletComponentDetailsHealthMonitor: "/extra10outlets/:id/component/:cid/healthmonitor",
  ceLocations: "/celocations",
  ceLocationDetails: "/celocations/:id",
  healthMonitor: "/healthmonitor",
  login: "/login",
  logout: "/logout"
};

export default Routes;

import React from "react";
import { Link } from "react-router-dom";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ddmTheme } from "@ddm-design-system/tokens";
import { Body } from "@ddm-design-system/typography";
import Routes from "../routes";
import { IAppContext } from "../services";
import { IJCoreService, IRecentlyCreatedTechnician } from "../services/jcore";
import { getCurrentContent } from "../store/content/selectors";
import {
  deleteTechnicianError,
  deleteTechnicianSuccess,
  editTechnicianError,
  editTechnicianSuccess,
  getTechnician as getCurrentTechnician,
  getTechnicianDeviceCodeError,
  getTechnicianDeviceCodeSuccess,
  getTechnicianError,
  getTechnicians,
  getTechniciansError,
  getTechniciansSuccess,
  getTechnicianSuccess,
  newTechnicianError,
  newTechnicianSuccess,
  setTechnicianInternationalError,
  setTechnicianInternationalSuccess
} from "../store/technicians/actions";
import {
  getTechnician as getTechnicianSelector,
  getCurrentTechnician as getCurrentTechnicianSelector
} from "../store/technicians/selectors";
import {
  DELETE_TECHNICIAN_REQUEST,
  EDIT_TECHNICIAN_REQUEST,
  GET_TECHNICIAN_DEVICE_CODE_REQUEST,
  GET_TECHNICIAN_REQUEST,
  GET_TECHNICIANS_REQUEST,
  IDeleteTechnicianRequest,
  IEditTechnicianRequest,
  IGetTechnicianDeviceCodeRequest,
  IGetTechnicianRequest,
  INewTechnicianRequest,
  ITechnician,
  IUnregisterDevice,
  NEW_TECHNICIAN_REQUEST,
  UNREGISTER_DEVICE,
  ISetTechnicianInternationalRequest,
  SET_TECHNICIAN_INTERNATIONAL_REQUEST
} from "../store/technicians/types";

const renderNotification = (
  first: string,
  center: string,
  centerLinkTo?: string,
  last?: string
) => (
  <Body>
    {first}
    {centerLinkTo ? (
      <Link style={{ color: "inherit" }} to={centerLinkTo}>
        <b>&nbsp;{center}</b>
      </Link>
    ) : (
      <b>&nbsp;{center}</b>
    )}
    {last && <>&nbsp;{last}</>}
  </Body>
);

export function* getTechniciansJcore(jCoreService: IJCoreService) {
  try {
    const result = yield call([jCoreService, jCoreService.fetchTechnicians]);

    yield put(getTechniciansSuccess(result));
  } catch {
    yield put(getTechniciansError());
  }
}

export function* getTechnician(jcoreService: IJCoreService, action: IGetTechnicianRequest) {
  try {
    const result: ITechnician = yield call(
      [jcoreService, jcoreService.fetchTechnician],
      action.payload
    );

    yield put(getTechnicianSuccess(result));
  } catch {
    yield put(getTechnicianError());
  }
}

export function* newTechnician(
  { jCoreService, notificationService }: IAppContext,
  action: INewTechnicianRequest
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const [first, last] = (content.technician_created || "").split("%TECHNICIAN%");

  try {
    const result: IRecentlyCreatedTechnician = yield call(
      [jCoreService, jCoreService.createTechnician],
      action.payload
    );

    yield put(getTechnicianDeviceCodeSuccess(result));
    yield put(newTechnicianSuccess(result));
    yield put(getTechnicians());

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: renderNotification(
        first,
        result.name,
        Routes.technicianDetails.replace(":id", result.id),
        last
      )
    });
  } catch {
    yield put(newTechnicianError());

    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: renderNotification(content.technician_create_error, action.payload.name)
    });
  }
}

export function* getTechnicianCode(
  jCoreService: IJCoreService,
  action: IGetTechnicianDeviceCodeRequest
) {
  try {
    const technician = yield call(
      [jCoreService, jCoreService.generateRegisterCode],
      action.payload
    );

    yield put(getTechnicianDeviceCodeSuccess(technician));
  } catch {
    yield put(getTechnicianDeviceCodeError());
  }
}

export function* deleteTechnician(
  { jCoreService, notificationService }: IAppContext,
  action: IDeleteTechnicianRequest
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const technician: ITechnician = yield select(getTechnicianSelector(action.payload));

  try {
    yield call([jCoreService, jCoreService.deleteTechnician], action.payload);
    yield put(deleteTechnicianSuccess(action.payload));
    yield put(getTechnicians());

    if (technician) {
      const [first, last] = (content.technician_deleted || "").split("%TECHNICIAN%");

      notificationService.addNotification({
        iconProps: { name: "ok" },
        text: renderNotification(first, technician.name, undefined, last)
      });
    }
  } catch {
    yield put(deleteTechnicianError(action.payload));

    if (technician) {
      const [first, last] = (content.technician_delete_error || "").split("%TECHNICIAN%");

      notificationService.addNotification({
        iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
        text: renderNotification(
          first,
          technician.name,
          Routes.technicianDetails.replace(":id", action.payload),
          last
        )
      });
    }
  }
}

export function* editTechnician(
  { jCoreService, notificationService }: IAppContext,
  action: IEditTechnicianRequest
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};
  const [first, last] = (content.technician_edited || "").split("%TECHNICIAN%");
  const technician: ITechnician = yield select(getTechnicianSelector(action.payload.id));

  try {
    yield call(
      [jCoreService, jCoreService.editTechnician],
      action.payload.params,
      action.payload.id
    );
    yield put(editTechnicianSuccess(action.payload.id, action.payload.params));

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: renderNotification(
        first,
        action.payload.params.name,
        Routes.technicianDetails.replace(":id", action.payload.id),
        last
      )
    });
  } catch {
    yield put(editTechnicianError(action.payload.id));

    if (technician) {
      notificationService.addNotification({
        iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
        text: renderNotification(
          content.technician_edit_error,
          technician.name,
          Routes.technicianDetails.replace(":id", action.payload.id)
        )
      });
    }
  }
}

export function* unregisterDevice(jCoreService: IJCoreService, action: IUnregisterDevice) {
  yield call([jCoreService, jCoreService.unregisterDevice], action.payload);
  const current: ITechnician = yield select(getCurrentTechnicianSelector);
  const reload =
    current &&
    current.technicianRegisteredDevices &&
    current.technicianRegisteredDevices.find(d => d.id === action.payload) !== undefined;

  if (reload) {
    yield put(getCurrentTechnician(current.id));
  }
}
export function* setTechnicianInternational(
  jCoreService: IJCoreService,
  action: ISetTechnicianInternationalRequest
) {
  try {
    yield call(
      [jCoreService, jCoreService.setTechnicianInternational],
      action.payload.id,
      action.payload.international
    );
    yield put(setTechnicianInternationalSuccess(action.payload.id, action.payload.international));
  } catch (error) {
    yield put(setTechnicianInternationalError(action.payload.id, action.payload.international));
  }
}

export function* technicianWatcher(context: IAppContext) {
  yield takeLatest(GET_TECHNICIANS_REQUEST, getTechniciansJcore, context.jCoreService);
  yield takeLatest(NEW_TECHNICIAN_REQUEST, newTechnician, context);
  yield takeLatest(GET_TECHNICIAN_REQUEST, getTechnician, context.jCoreService);
  yield takeLatest(GET_TECHNICIAN_DEVICE_CODE_REQUEST, getTechnicianCode, context.jCoreService);
  yield takeLatest(DELETE_TECHNICIAN_REQUEST, deleteTechnician, context);
  yield takeLatest(EDIT_TECHNICIAN_REQUEST, editTechnician, context);
  yield takeLatest(UNREGISTER_DEVICE, unregisterDevice, context.jCoreService);
  yield takeLatest(
    SET_TECHNICIAN_INTERNATIONAL_REQUEST,
    setTechnicianInternational,
    context.jCoreService
  );
}
export default technicianWatcher;

import React, { useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Routes from "../../routes";
import { IAppState } from "../../store";
import { BeverageTranslation } from "../../store/beverages/types";
import { getCurrentContent } from "../../store/content/selectors";
import { IContent } from "../../store/content/types";
import { associateTechnicianToOutlet } from "../../store/outlets/actions";
import * as actions from "../../store/technicians/actions";
import { ITechnician, ITechnicianParams } from "../../store/technicians/types";
import Button from "../common/button/Button";
import { DetailPage } from "../common/DetailPage/DetailPage";
import Modal from "../common/modal/Modal";
import NumberOutOf from "../common/numberOutOf/NumberOutOf";
import AssociateTechnicianForm from "../common/technician/AssociateTechnicianForm";
import CreateTechnicianForm from "../common/technician/CreateTechnicianForm";
import "./overview.scss";
import Header from "../common/header/Header";
import { ILocation } from "../../store/outlets/types";

interface IProps {
  technicians: ITechnician[];
  outlets: ILocation[];
  beverages: BeverageTranslation[];
  content: IContent;
  newTechnician: (params: ITechnicianParams) => void;
  requestingCreateTechnician: boolean;
  associate: (
    technicianId: string,
    outletId: string,
    refreshTechnician?: boolean,
    refreshOutlet?: boolean
  ) => void;
}

const Overview: React.FC<IProps> = ({
  technicians,
  outlets,
  beverages,
  content,
  associate,
  newTechnician,
  requestingCreateTechnician
}) => {
  const techniciansBusy = technicians
    ? technicians.filter(t => t.associatedLocations && t.associatedLocations.length > 0).length
    : 0;
  const [showModal, setShowModal] = useState(false);

  const me = useSelector((state: IAppState) => state.authReducer.me);
  const beveragesInMyCountry = useMemo(() => {
    return me && beverages
      ? beverages.filter(f => f.countries.some(d => me.country?.code === d.code))
      : [];
  }, [me, beverages]);

  const beveragesApproved = useMemo(
    () => beveragesInMyCountry.filter(b => b.active).length,
    [beveragesInMyCountry]
  );

  const headerActions = [
    {
      label: `+ ${content.overview_create_new_technician}`,
      onClick: () => setShowModal(true)
    }
  ];

  return content ? (
    <DetailPage className="overview-page">
      <Header title={content.overview_title} actions={headerActions} />
      <div className="overview-assign mb-xl">
        <div className="overview-assign-header">
          <h2 className="overview-assign-title">
            {content.overview_associate_technician_to_outlet}
          </h2>
        </div>
        <div className="overview-assign-content">
          <AssociateTechnicianForm
            onSubmit={(technician, outlet) => associate(technician.id, outlet.id)}
          />
        </div>
      </div>
      <div className="overview-cards flex">
        <div className="overview-card">
          <h2>{content.overview_technicians}</h2>
          <NumberOutOf
            value={techniciansBusy}
            outOf={technicians ? technicians.length : 0}
            subtitle={content.overview_technicians_subtitle}
          />
          <Link to={Routes.technicians}>
            <Button secondary inline>
              {content.overview_see_all_technicians}
            </Button>
          </Link>
        </div>
        <div className="overview-card">
          <h2>{content.overview_outlets}</h2>
          <NumberOutOf
            value={outlets.filter(o => o.status?.some(l => l.level === "ERROR")).length}
            outOf={outlets.length}
            subtitle={content.overview_outlets_subtitle}
          />
          <Link to={Routes.outlets}>
            <Button secondary inline>
              {content.overview_see_all_outlets}
            </Button>
          </Link>
        </div>
        <div className="overview-card">
          <h2>{content.overview_beverages}</h2>
          <NumberOutOf
            value={beveragesApproved}
            outOf={beveragesInMyCountry.length}
            subtitle={content.overview_beverages_subtitle}
          />
          <Link to={Routes.beverages}>
            <Button secondary inline>
              {content.overview_see_all_beverages}
            </Button>
          </Link>
        </div>
      </div>
      <Modal
        visible={showModal}
        title={content.technician_detail_edit_technician}
        onClose={() => setShowModal(false)}
      >
        <CreateTechnicianForm
          cancelCallback={() => setShowModal(false)}
          createTechnicianCallback={(name: string, email: string, phoneNumber: string) =>
            newTechnician({ name, email, phoneNumber })
          }
          submitting={requestingCreateTechnician}
        />
      </Modal>
    </DetailPage>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);

  return {
    content: content ? content.overview : null,
    technicians: state.technician.technicians,
    outlets: state.outlet.outlets,
    beverages: state.beverages.beverages,
    requestingCreateTechnician: state.technician.requestingCreateTechnician || false
  };
};
const mapDispatchToProps = {
  associate: associateTechnicianToOutlet,
  newTechnician: actions.newTechnician
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);

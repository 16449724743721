import React, { useCallback, useEffect, useState } from "react";
import { AdalConfig, Authentication, AuthenticationContext } from "adal-ts";
import { AxiosResponse } from "axios";
import { adalConfig } from "../../adalConfig";
import { IJCoreService, IUserId } from "../../services/jcore";

interface IAuthContext {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isAzureAccount: (username: string) => Promise<{ isAzure: boolean; isRegistered: boolean }>;
  error: null | { type: string; body: string };
  adal?: AuthenticationContext;
  login: (
    username: string,
    password: string,
    recaptchaToken: string,
    rememberMe?: boolean
  ) => Promise<IUserId>;
  getAzureTokenFunction: () => string;
  validateAzureInvitationToken: (token: string) => void;
  logout: () => void;
  sendRecoveryEmail: (email: string) => Promise<AxiosResponse<boolean>>;
}

const defaultAuth: IAuthContext = {
  isAuthenticating: false,
  isAuthenticated: false,
  async isAzureAccount() {
    return new Promise(resolve =>
      setTimeout(() => resolve({ isAzure: false, isRegistered: true }), 300)
    );
  },
  error: null,
  async login() {
    this.isAuthenticating = true;
    await new Promise(resolve => setTimeout(resolve, 300));
    this.isAuthenticating = false;
    this.isAuthenticated = true;

    return "";
  },
  logout() {
    this.isAuthenticated = false;
  },
  getAzureTokenFunction() {
    return "";
  },
  validateAzureInvitationToken() {
    return "";
  },
  sendRecoveryEmail() {
    return new Promise(resolve => setTimeout(resolve, 300));
  }
};

export const useAuthContext: (jcore: IJCoreService, handleLogout?: () => void) => IAuthContext = (
  jcore: IJCoreService,
  handleLogout?: () => void
) => {
  const config = new AdalConfig(
    adalConfig.clientId,
    adalConfig.tenant,
    window.location.origin,
    window.location.origin,
    "token",
    `resource=${encodeURIComponent("00000002-0000-0000-c000-000000000000")}`
  );
  const adal = Authentication.getContext(config);
  const getAzureTokenFunction = () => adal.getToken();
  const [isAuthenticated, setAuthenticated] = useState(jcore.isLoggedIn());
  const [isAuthenticating, setAuthenticating] = useState(false);
  const [error, setError] = useState<null | { type: string; body: string }>(null);

  /* Check if adal.getToken returns anything, if it does, do azureauth request */
  const azureToken = getAzureTokenFunction();

  const refAzureToken = useCallback(() => {
    if (azureToken !== null && azureToken !== "") {
      const ddmInvitationToken = localStorage.getItem("ddm-azureinvitationtoken");

      if (!ddmInvitationToken) {
        jcore
          .azureAuth(azureToken)
          .then(async (d: any) => {
            const token = d.replace("Bearer ", "") as string;

            jcore.setUserToken(token);
            const me = await jcore.fetchUser();

            if (me.role === "ROLE_MATRIX") {
              // TODO check for sub-supervisor
              throw new Error("SALESREP");
            } else {
              setAuthenticated(true);
            }
          })
          .catch(() => {
            const user = adal.getUser();

            setError({
              type: "NO_ACCESS_AZURE",
              body: user && user.unique_name
            });
            jcore.logout();
          })
          .finally(() => {
            localStorage.removeItem("adal.accesstoken");
            localStorage.removeItem("ddm-azureinvitationtoken");
          });
      }
    }
  }, [adal, azureToken, jcore]);

  useEffect(() => {
    refAzureToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAzureAccount = async (username: string) => {
    let isAzure = false;
    let isRegistered = true;

    try {
      const { data } = await jcore.isAzureAccount(username);

      isAzure = data.azure;
    } catch (e) {
      isRegistered = false;
    }

    return { isAzure, isRegistered };
  };

  const login = async (
    username: string,
    password: string,
    recaptchaToken: string,
    rememberMe?: boolean
  ) => {
    let userId = "";

    setAuthenticating(true);

    try {
      const { id } = await jcore.login({
        username: username.trim(),
        password,
        recaptchaToken,
        rememberMe
      });

      userId = id;
      setAuthenticated(true);
    } finally {
      setAuthenticating(false);
    }

    return userId;
  };

  const validateAzureInvitationToken = async (token: string) => {
    try {
      const result = await jcore.linkAzureAccount(token, getAzureTokenFunction());

      localStorage.removeItem("ddm-azureinvitationtoken");
      refAzureToken();

      return result;
    } catch (e) {
      const user = adal.getUser()?.unique_name;
      let text = "error";

      try {
        const azure = await jcore.isAzureAccount(user);

        if (azure?.data?.azure) {
          text = "registered";
        }
      } catch {
        // eslint-disable-next-line no-console
        console.log("Account not registed in azure");
      }

      setError({
        type: "ERROR_LINKING_ACCOUNT",
        body: text
      });
    }
  };

  const sendRecoveryEmail = async (email: string) => {
    try {
      return await jcore.sendRecoveryEmail(email);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    }
  };

  const logout = () => {
    jcore.logout();
    setAuthenticated(false);

    if (handleLogout) {
      handleLogout();
    }
  };

  return {
    isAuthenticated,
    isAuthenticating,
    adal,
    getAzureTokenFunction,
    validateAzureInvitationToken,
    login,
    isAzureAccount,
    sendRecoveryEmail,
    logout,
    error
  };
};

export const AuthContext = React.createContext<IAuthContext>(defaultAuth);
export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
export default AuthContext;

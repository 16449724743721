import { createSelector } from "reselect";
import { IUser } from "../../services/jcore";
import { IAppState } from "..";
import {
  EOutletStatus,
  EOutletStatusBadges,
  EOutletStatusLevel,
  IBeerDrive,
  IBeerDriveStatus,
  ICompressor,
  ICompressorStatus,
  IControlUnit,
  IControlUnitStatus,
  ICoolingUnit,
  ICoolingUnitStatus,
  ILocation,
  IOutletStatus,
  IPressureChamber,
  IPressureChamberStatus,
  ISteelKegUnit,
  ISteelKegUnitStatus
} from "./types";
import { getCurrentContent } from "../content/selectors";
import { getUser } from "../auth/selectors";
import { getComponentStatus, getStatusChipSelector } from "./helpers";
import { BeverageTranslation, IBeverageTranslation } from "../beverages/types";
import { getBeverages } from "../beverages/selectors";

export const getOutlets = (state: IAppState) => state.outlet.outlets;

export const getIsRequestingOutlets = (state: IAppState) => state.outlet.requestingOutlets;

export const getHasOutlets = (state: IAppState) => getOutlets(state).length > 0;

export const getIsLoadingOutlets = (state: IAppState) => state.outlet.requestingOutlets;

export const getOutlet = (id: string) =>
  createSelector(getOutlets, (outlets: ILocation[]) => outlets.find(outlet => outlet.id === id));

export const getOutletStatuses = createSelector(
  getCurrentContent,
  getOutlets,
  (currentContent, locations: ILocation[]) => {
    const { outlets: content = {} } = currentContent || {};

    return locations.map((location: ILocation): IOutletStatus => {
      const status = location.status?.[0];
      const statusBadge: EOutletStatusBadges = (status.level === EOutletStatusLevel.NEUTRAL ||
      status.level === EOutletStatusLevel.SUCCESS
        ? status.message
        : status.message === EOutletStatus.INVALID_INSTALLATION
        ? EOutletStatusBadges.INVALID_INSTALLATION
        : status.level) as unknown as EOutletStatusBadges;

      return {
        id: location.id,
        name: location.name,
        chipSelectors: [getStatusChipSelector(statusBadge)],
        status: statusBadge,
        statusContent: content[`outlets_status_${statusBadge.toLocaleLowerCase()}`],
        components: location.components,
        taps: location.taps,
        technicians: location.technicians,
        lastEdit: location.updateDateTime ? +new Date(location.updateDateTime) : undefined,
        createDateTime: location.createDateTime ? +new Date(location.createDateTime) : undefined,
        distributorCount: location.distributors?.length ?? 0,
        locationData: location.locationData,
        purchasingGroupCount: location.distributors?.length
          ? location.distributors.reduce((acc, distributor) => {
              acc.add(distributor?.purchasingGroup?.id);
              return acc;
            }, new Set()).size
          : 0
      };
    });
  }
);

export const getCountries = (state: IAppState) => state.outlet.countries;

export const getCountriesForMe = createSelector(
  getCountries,
  getUser,
  (countries, user: IUser | undefined) => {
    return countries.map(c => ({
      text: c.name,
      id: c.id,
      selected: c.id === user?.country?.id
    }));
  }
);

export const getCreateOrEditOutletState = (state: IAppState) => ({
  loading: state.outlet.creatingOutlet || state.outlet.editingOutlet,
  error: state.outlet.creatingOutletError || state.outlet.editingOutletError
});

export const getOutletPCStatuses = (id: string) =>
  createSelector(
    getCurrentContent,
    getOutlet(id),
    getBeverages,
    (currentContent, location: ILocation | undefined, beverages: BeverageTranslation[]) => {
      const { common = {} } = currentContent || {};

      return (location?.installation?.pressureChambers || []).map(
        (pressureChamber: IPressureChamber): IPressureChamberStatus => {
          const bev = beverages.find(b => b.id === pressureChamber.beverageId);

          return {
            id: pressureChamber.id,
            position: pressureChamber.position,
            boardSerialNumber: pressureChamber.boardSerialNumber || "--",
            unit: pressureChamber.primaryUnitId
              ? common.common_secondary_unit
              : common.common_primary_unit,
            tapsPositions: (pressureChamber.tapsPositions || []).sort().join(", ") || "--",
            brand: [
              {
                photo: bev?.logoUrl() || "",
                title: bev?.name || ""
              }
            ],
            volume: ((pressureChamber.volumeLeft || 0) / 1000).toFixed(2),
            hasPressureSwitcher: pressureChamber.hasPressureSwitcher,
            status: getComponentStatus(pressureChamber.status)
          };
        }
      );
    }
  );

export const getOutletSKStatuses = (id: string) =>
  createSelector(
    getCurrentContent,
    getOutlet(id),
    getBeverages,
    (currentContent, location: ILocation | undefined, beverages: BeverageTranslation[]) => {
      const { common = {} } = currentContent || {};

      return (location?.installation?.steelKegUnits || []).map(
        (steelKegUnit: ISteelKegUnit): ISteelKegUnitStatus => {
          const bev = beverages.find(b => b.id === steelKegUnit.beverageId);

          return {
            id: steelKegUnit.id,
            position: steelKegUnit.position,
            boardSerialNumber: steelKegUnit.boardSerialNumber || "--",
            unit: steelKegUnit.primaryUnitId
              ? common.common_secondary_unit
              : common.common_primary_unit,
            tapsPositions: (steelKegUnit.tapsPositions || []).sort().join(", ") || "--",
            brand: [
              {
                photo: bev?.logoUrl() || "",
                title: bev?.name || ""
              }
            ],
            status: getComponentStatus(steelKegUnit.status)
          };
        }
      );
    }
  );

export const getOutletBDStatuses = (id: string) =>
  createSelector(
    getCurrentContent,
    getOutlet(id),
    getBeverages,
    (currentContent, location: ILocation | undefined, beverages: BeverageTranslation[]) => {
      const { common = {} } = currentContent || {};

      return (location?.installation?.beerDrives || []).map(
        (beerDrive: IBeerDrive): IBeerDriveStatus => {
          const bev = beverages.find(b => b.id === beerDrive.beverageId);

          return {
            id: beerDrive.id,
            position: beerDrive.position,
            boardSerialNumber: beerDrive.boardSerialNumber || "--",
            unit: beerDrive.primaryUnitId
              ? common.common_secondary_unit
              : common.common_primary_unit,
            tapsPositions: (beerDrive.tapsPositions || []).sort().join(", ") || "--",
            brand: [
              {
                photo: bev?.logoUrl() || "",
                title: bev?.name || ""
              }
            ],
            volume: ((beerDrive.volumeLeft || 0) / 1000).toFixed(2),
            status: getComponentStatus(beerDrive.status)
          };
        }
      );
    }
  );

export const getOutletCUStatuses = (id: string) =>
  createSelector(
    getCurrentContent,
    getOutlet(id),
    (currentContent, location: ILocation | undefined) => {
      const { common = {} } = currentContent || {};

      return (location?.installation?.controlUnits || []).map(
        (controlUnit: IControlUnit): IControlUnitStatus => {
          return {
            id: controlUnit.id,
            position: controlUnit.position,
            boardSerialNumber: controlUnit.boardSerialNumber || "--",
            unit: controlUnit.primaryUnitId
              ? common.common_secondary_unit
              : common.common_primary_unit,
            affectedTapsPositions:
              (controlUnit?.affectedTapsPositions || []).sort().join(", ") || "--",
            status: getComponentStatus(controlUnit.status)
          };
        }
      );
    }
  );

export const getOutletCoolUStatuses = (id: string) =>
  createSelector(
    getCurrentContent,
    getOutlet(id),
    (currentContent, location: ILocation | undefined) => {
      const { common = {} } = currentContent || {};

      return (location?.installation?.coolingUnits || []).map(
        (coolingUnit: ICoolingUnit): ICoolingUnitStatus => {
          return {
            id: coolingUnit.id,
            position: coolingUnit.position,
            boardSerialNumber: coolingUnit.boardSerialNumber || "--",
            unit: coolingUnit.primaryUnitId
              ? common.common_secondary_unit
              : common.common_primary_unit,
            temp1: coolingUnit.temperatureReading0
              ? Math.round(coolingUnit.temperatureReading0 / 100 || 0)
              : null,
            temp2: coolingUnit.temperatureReading1
              ? Math.round(coolingUnit.temperatureReading1 / 100 || 0)
              : null,
            temp3: coolingUnit.temperatureReading2
              ? Math.round(coolingUnit.temperatureReading2 / 100 || 0)
              : null,
            status: getComponentStatus(coolingUnit.status)
          };
        }
      );
    }
  );

export const getOutletCompressorStatuses = (id: string) =>
  createSelector(
    getCurrentContent,
    getOutlet(id),
    (currentContent, location: ILocation | undefined) => {
      const { common = {} } = currentContent || {};

      return (location?.installation?.compressors || []).map(
        (compressor: ICompressor): ICompressorStatus => {
          return {
            id: compressor.id,
            position: compressor.position,
            boardSerialNumber: compressor.boardSerialNumber || "--",
            unit: compressor.primaryUnitId
              ? common.common_secondary_unit
              : common.common_primary_unit,
            status: getComponentStatus(compressor.status)
          };
        }
      );
    }
  );

import { IEntries } from "./index";
import { translateString } from "./translate";

export default function mapLanguageInfo(
  entries: IEntries,
  lang = process.env.REACT_APP_DEFAULT_LANG
) {
  const parsedLanguages = entries.languageInfo.ordered.map(languageInfo => ({
    code: translateString(languageInfo.fields.languageCode, lang),
    name: translateString(languageInfo.fields.languageName, process.env.REACT_APP_DEFAULT_LANG),
    country: translateString(languageInfo.fields.languageCountry, lang),
    selected:
      translateString(languageInfo.fields.languageCode, lang) === process.env.REACT_APP_DEFAULT_LANG
  }));

  return {
    languageInfo: parsedLanguages
  };
}

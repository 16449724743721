import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IDistributor } from "../../store/distributors/types";
import { getOutletStatuses } from "../../store/outlets/selectors";
import OutletList from "../outlets/OutletList";
import useContent from "../../hooks/useContent";

interface IProps {
  distributor: IDistributor;
}

const DistributorOutlets: React.FC<IProps> = ({ distributor }) => {
  const allOutlets = useSelector(getOutletStatuses);
  const { common } = useContent();

  const distributorOutlets = useMemo(
    () =>
      allOutlets.filter(outlet =>
        distributor.outlets.some(distributorOutlet => distributorOutlet.id === outlet.id)
      ),
    [allOutlets, distributor]
  );

  return (
    <div className="py-xxl">
      <OutletList outlets={distributorOutlets} title={common.common_outlets} />
    </div>
  );
};

export default DistributorOutlets;

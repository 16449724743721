import { withStyles } from "@material-ui/core";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import classnames from "classnames";
import React, { useState } from "react";
import Icon from "../icon/Icon";

import "./menu.scss";

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "var(--color-black)",
    color: "var(--color-white)",
    padding: "var(--space-unit-xxs)",
    borderRadius: "5px"
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const StyledMenuInverted = withStyles({
  paper: {
    backgroundColor: "var(--color-white)",
    color: "var(--color-black)",
    padding: "var(--space-unit-xxs)",
    borderRadius: "5px"
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

interface IProps {
  invertColor?: boolean;
}
export const CustomMenu: React.FC<IProps> = ({ invertColor, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (event: React.MouseEvent<Element>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <Icon onClick={handleClick} name="dots" className={classnames({ inverted: invertColor })} />
      {anchorEl &&
        (invertColor ? (
          <StyledMenuInverted
            className="custom-menu"
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <div className="menu-container inverted" onClick={handleClose}>
              {children}
            </div>
          </StyledMenuInverted>
        ) : (
          <StyledMenu
            className="custom-menu"
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <div className="menu-container" onClick={handleClose}>
              {children}
            </div>
          </StyledMenu>
        ))}
    </>
  );
};

export default CustomMenu;

import { IDistributor } from "../distributors/types";

export const GET_PURCHASING_GROUPS_REQUEST = "purchasing_groups/GET_PURCHASING_GROUPS_REQUEST";
export const GET_PURCHASING_GROUPS_SUCCESS = "purchasing_groups/GET_PURCHASING_GROUPS_SUCCESS";
export const GET_PURCHASING_GROUPS_ERROR = "purchasing_groups/GET_PURCHASING_GROUPS_ERROR";
export const CREATE_PURCHASING_GROUP = "purchasing_groups/CREATE_PURCHASING_GROUP";
export const CREATE_PURCHASING_GROUP_SUCCESS = "purchasing_groups/CREATE_PURCHASING_GROUP_SUCCESS";
export const CREATE_PURCHASING_GROUP_ERROR = "purchasing_groups/CREATE_PURCHASING_GROUP_ERROR";
export const EDIT_PURCHASING_GROUP = "purchasing_groups/EDIT_PURCHASING_GROUP";
export const EDIT_PURCHASING_GROUP_SUCCESS = "purchasing_groups/EDIT_PURCHASING_GROUP_SUCCESS";
export const EDIT_PURCHASING_GROUP_ERROR = "purchasing_groups/EDIT_PURCHASING_GROUP_ERROR";
export const ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS =
  "purchasing_groups/ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS";
export const ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_SUCCESS =
  "purchasing_groups/ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_SUCCESS";
export const ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_ERROR =
  "purchasing_groups/ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_ERROR";
export const REFRESH_GROUPING_DATA = "purchasing_groups/REFRESH_GROUPING_DATA";

export interface IPurchasingGroup {
  id: string;
  distributors: IDistributor[];
  email: string;
  name: string;
  outletCount: number;
}

export enum CreatePurchasingGroupErrorCodes {
  DUPLICATE_PURCHASING_GROUP_NAME = "DUPLICATE_PURCHASING_GROUP_NAME",
  DUPLICATE_USERNAME = "DUPLICATE_USERNAME"
}

export interface ICreatePurchasingGroupObject {
  distributors?: string[];
  email: string;
  name: string;
}

export interface IPurchasingGroupsState {
  purchasingGroups: IPurchasingGroup[];
  requestingPurchasingGroups: boolean;
  requestPurchasingGroupsError: boolean;
  creatingPurchasingGroup: boolean;
  createPurchasingGroupError: boolean;
}

export interface IGetPurchasingGroupsRequestAction {
  type: typeof GET_PURCHASING_GROUPS_REQUEST;
}

export interface IGetPurchasingGroupsSuccessAction {
  type: typeof GET_PURCHASING_GROUPS_SUCCESS;
  payload: IPurchasingGroup[];
}

export interface IGetPurchasingGroupsErrorAction {
  type: typeof GET_PURCHASING_GROUPS_ERROR;
}

export interface ICreatePurchasingGroupAction {
  type: typeof CREATE_PURCHASING_GROUP;
  payload: ICreatePurchasingGroupObject;
}

export interface ICreatePurchasingGroupSuccessAction {
  type: typeof CREATE_PURCHASING_GROUP_SUCCESS;
  payload: IPurchasingGroup;
}

export interface ICreatePurchasingGroupErrorAction {
  type: typeof CREATE_PURCHASING_GROUP_ERROR;
}

export interface IEditPurchasingGroupAction {
  type: typeof EDIT_PURCHASING_GROUP;
  payload: {
    data: ICreatePurchasingGroupObject;
    id: string;
  };
}

export interface IEditPurchasingGroupSuccessAction {
  type: typeof EDIT_PURCHASING_GROUP_SUCCESS;
  payload: IPurchasingGroup;
}

export interface IEditPurchasingGroupErrorAction {
  type: typeof EDIT_PURCHASING_GROUP_ERROR;
}

export interface IAssociatePurchasingGroupDistributorsAction {
  type: typeof ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS;
  payload: {
    pgId: string;
    distributorIds: string | string[];
  };
}

export interface IAssociatePurchasingGroupDistributorsSuccessAction {
  type: typeof ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_SUCCESS;
  payload: IPurchasingGroup;
}

export interface IAssociatePurchasingGroupDistributorsErrorAction {
  type: typeof ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_ERROR;
}

export interface IRefreshGroupingData {
  type: typeof REFRESH_GROUPING_DATA;
}

export type PurchasingGroupsActionTypes =
  | IGetPurchasingGroupsRequestAction
  | IGetPurchasingGroupsSuccessAction
  | ICreatePurchasingGroupAction
  | IGetPurchasingGroupsErrorAction
  | ICreatePurchasingGroupSuccessAction
  | ICreatePurchasingGroupErrorAction
  | IEditPurchasingGroupAction
  | IEditPurchasingGroupSuccessAction
  | IEditPurchasingGroupErrorAction
  | IAssociatePurchasingGroupDistributorsAction
  | IAssociatePurchasingGroupDistributorsSuccessAction
  | IAssociatePurchasingGroupDistributorsErrorAction;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { SplashLoading } from "@ddm-design-system/icon";
import { RouteComponentProps } from "react-router-dom";
import Routes from "../../routes";
import { getMe } from "../../store/auth/actions";
import { getOutlets } from "../../store/outlets/actions";
import Logout from "../auth/Logout";
import BeverageCreate from "../beverages/BeverageCreate";
import BeverageDetails from "../beverages/BeverageDetails";
import Beverages from "../beverages/Beverages";
import CeLocations from "../ceLocations/CeLocations";
import CeLocationDetails from "../ceLocations/CeLocationDetails";
import ComponentDetails from "../outlets/component/ComponentDetails";
import ComponentX10Details from "../extra10s/component/ComponentDetails";
import Distributors from "../distributors/Distributors";
import DistributorDetails from "../distributors/DistributorDetails";
import HealthMonitor from "../healthMonitor/HealthMonitor";
import OutletDetails from "../outlets/OutletDetails";
import Outlets from "../outlets/Outlets";
import Extra10Outlets from "../extra10s/Extra10Outlets";
import Overview from "../overview/Overview";
import PurchasingGroups from "../purchasingGroups/PurchasingGroups";
import PurchasingGroupDetails from "../purchasingGroups/PurchasingGroupDetails";
import TechnicianDetail from "../technicians/TechnicianDetail";
import Technicians from "../technicians/Technicians";
import UserDetail from "../users/UserDetail";
import Users from "../users/Users";
import { getTechnicians } from "../../store/technicians/actions";
import { getBeverages } from "../../store/beverages/actions";
import { getIsLoadingContentful } from "../../store/content/selectors";
import { getCeLocations } from "../../store/ceLocations/actions";
import { HomeContainer, NavigationMenuStyled, ScrollToTopStyled } from "./home.styles";
import { getUser } from "../../store/auth/selectors";
import Extra10OutletDetails from "../extra10s/Extra10OutletDetails";
import { getExtra10Outlets } from "../../store/extra10outlets/actions";

type Params = {
  id: string;
  cid: string;
};

type MatchParams = RouteComponentProps<Params>;

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const isLoadingContentful = useSelector(getIsLoadingContentful);
  const me = useSelector(getUser);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (me) {
      dispatch(getCeLocations());
      if (!me.ceOnly) {
        dispatch(getOutlets());
        dispatch(getExtra10Outlets());
        dispatch(getTechnicians());
        dispatch(getBeverages());
      }
    }
  }, [me, dispatch]);

  const renderUserDetail = ({ match }: MatchParams) => <UserDetail userId={match.params.id} />;

  const renderPurchasingGroupDetails = ({ match }: MatchParams) => (
    <PurchasingGroupDetails purchasingGroupId={match.params.id} />
  );

  const renderDistributorDetails = ({ match }: MatchParams) => (
    <DistributorDetails distributorId={match.params.id} />
  );

  const renderOutletDetails = ({ match }: MatchParams) => (
    <OutletDetails outletId={match.params.id} />
  );
  const renderExtra10OutletDetails = ({ match }: MatchParams) => (
    <Extra10OutletDetails outletId={match.params.id} />
  );

  const renderComponentDetails = ({ match }: MatchParams) => (
    <ComponentDetails outletId={match.params.id} componentId={match.params.cid} />
  );

  const renderX10ComponentDetails = ({ match }: MatchParams) => (
    <ComponentX10Details outletId={match.params.id} componentId={match.params.cid} />
  );

  const renderTechnicianDetail = ({ match }: MatchParams) => (
    <TechnicianDetail technicianId={match.params.id} />
  );
  const renderBeverageDetails = ({ match }: MatchParams) => (
    <BeverageDetails beverageId={match.params.id} />
  );
  const renderCeLocationDetails = ({ match }: MatchParams) => (
    <CeLocationDetails ceLocationId={match.params.id} />
  );

  if (isLoadingContentful) return <SplashLoading infinite />;

  return (
    <HomeContainer>
      <NavigationMenuStyled />
      <ScrollToTopStyled>
        <Switch>
          <Route component={Overview} path={Routes.overview} />
          <Route path={Routes.technicianDetails} render={renderTechnicianDetail} />
          <Route component={Technicians} path={Routes.technicians} />
          <Route path={Routes.purchasingGroupDetails} render={renderPurchasingGroupDetails} />
          <Route component={PurchasingGroups} path={Routes.purchasingGroups} />
          <Route path={Routes.distributorDetails} render={renderDistributorDetails} />
          <Route component={Distributors} path={Routes.distributors} />
          <Route path={Routes.extra10OutletComponentDetails} render={renderX10ComponentDetails} />
          <Route path={Routes.extra10OutletDetails} render={renderExtra10OutletDetails} />
          <Route component={Extra10Outlets} path={Routes.extra10Outlets} />
          <Route path={Routes.outletComponentDetails} render={renderComponentDetails} />
          <Route path={Routes.outletDetails} render={renderOutletDetails} />
          <Route component={Outlets} path={Routes.outlets} />
          <Route path={Routes.newBeverage} render={() => <BeverageCreate />} />
          <Route path={Routes.beverageDetail} render={renderBeverageDetails} />
          <Route component={Beverages} path={Routes.beverages} />
          <Route path={Routes.usersDetail} render={renderUserDetail} />
          <Route component={Users} path={Routes.users} />
          <Route exact component={CeLocations} path={Routes.ceLocations} />
          <Route path={Routes.ceLocationDetails} render={renderCeLocationDetails} />
          <Route exact component={HealthMonitor} path={Routes.healthMonitor} />
          <Route component={Logout} path={Routes.logout} />
          <Redirect to={Routes.overview} />
        </Switch>
      </ScrollToTopStyled>
    </HomeContainer>
  );
};

export default Home;

import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import DataTable from "../../../../common/data_table/DataTable";
import useContent from "../../../../../hooks/useContent";
import { IHealthStatus } from "../../../../../store/components/types";
import { getCurrentLanguage } from "../../../../../store/content/selectors";

interface IProps {
  alerts?: IHealthStatus[];
}

interface IAlerts {
  level: string;
  target: string;
  type: string;
  createDateTime: string;
  levelContent: string;
  infoContent: string;
  typeContent: string;
}

const Alerts: React.FC<IProps> = ({ alerts }) => {
  const { common, healthMonitor } = useContent();
  const language = useSelector(getCurrentLanguage);

  const formatDate = useCallback(
    ({ createDateTime }: IAlerts) => {
      const dateFormatted = createDateTime
        ? new Date(createDateTime).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZoneName: "short"
          })
        : "--";

      return <span title={dateFormatted}>{dateFormatted}</span>;
    },
    [language]
  );

  const renderComponentAlerts = useCallback(() => {
    if (!alerts) return <div />;

    const columns = [
      {
        name: healthMonitor.healthMonitor_impact,
        selector: (row: IAlerts) => row.levelContent,
        sortable: true,
        selectorId: "levelContent"
      },
      {
        name: common.common_component,
        selector: () => common.common_beerDrive,
        sortable: true
      },
      {
        name: healthMonitor.healthMonitor_alert_type,
        selector: (row: IAlerts) => row.typeContent,
        sortable: true
      },
      {
        name: common.common_info,
        selector: (row: IAlerts) => row.infoContent,
        sortable: true
      },
      {
        format: formatDate,
        name: common.common_time,
        selector: (row: IAlerts) => row.createDateTime,
        sortable: false
      }
    ];

    return <DataTable columns={columns} data={alerts} title="" enableActions={false} />;
  }, [alerts, common, healthMonitor, formatDate]);

  return renderComponentAlerts();
};

export default Alerts;

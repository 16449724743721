import React from "react";
import { useDispatch } from "react-redux";
import { useIsMobile } from "@ddm-design-system/hooks";
import { Icon } from "@ddm-design-system/icon";
import { PrimaryButton } from "@ddm-design-system/button";
import { PageTitle } from "@ddm-design-system/typography";
import Breadcrumbs, { IBreadCrumb } from "../breadcrumbs/Breadcrumbs";
import { toggleSidebar } from "../../../store/sidebar/actions";
import ActionsMenu, { IMenuAction } from "../menu/ActionsMenu";

interface IProps {
  breadcrumbs?: IBreadCrumb[];
  title?: string;
  actions?: IMenuAction[];
}

const Header: React.FC<IProps> = ({ breadcrumbs, title, actions }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const handleOnClickMenu = () => dispatch(toggleSidebar());

  const renderHeaderHead = () => {
    if (isMobile) {
      return <Icon name="Menu" onClick={handleOnClickMenu} />;
    }

    return <Breadcrumbs current={title} items={breadcrumbs} />;
  };

  const renderActionMenu = (action: IMenuAction, actionIndex: number) => {
    return (
      <PrimaryButton className="ml-md" key={actionIndex} onClick={action.onClick}>
        {action.label}
      </PrimaryButton>
    );
  };

  const renderHeaderActions = () => {
    if (!actions) {
      return;
    }

    if (isMobile && actions.length) {
      return <ActionsMenu actions={actions} />;
    }

    return actions.map(renderActionMenu);
  };

  return (
    <div className="mt-xl lg:mt-sm xs:mt-0">
      {renderHeaderHead()}
      <div className="flex pt-xl pb-xxl xs:py-lg">
        <PageTitle
          className="flex-1 overflow-ellipsis overflow-hidden whitespace-nowrap"
          style={{ lineHeight: "130%" }}
          title={title}
        >
          {title}
        </PageTitle>
        {renderHeaderActions()}
      </div>
    </div>
  );
};

export default Header;

import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import Modal from "../common/modal/Modal";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import {
  deleteCeLocationRequest,
  deleteCePhoto,
  getCeLocations,
  putCeLocationPhoto
} from "../../store/ceLocations/actions";
import { getCeLocation } from "../../store/ceLocations/selectors";
import { getUserCountryCode } from "../../store/auth/selectors";
import { getPhotoURL } from "../../helpers";
import { getTranslatedBrands } from "./helpers";
import { DetailPage, DetailPageContent } from "../common/DetailPage";
import CeLocationListItem from "./CeLocationListItem";
import CeLocationDetailItem from "./CeLocationDetailItem";
import "./celocations.scss";
import CeLocationDetailEdit from "./edit/CeLocationDetailEdit";
import Header from "../common/header/Header";
import CeLocationFlexDetailEdit from "./edit/CeLocationFlexDetailEdit";
import CeLocationDetailEditAddress from "./edit/CeLocationDetailEditAddress";
import Button from "../common/button/Button";
import ConfirmationModal from "../common/modal/ConfirmationModal";

interface IProps {
  ceLocationId: string;
}

const CeLocationDetails: React.FC<IProps> = ({ ceLocationId }) => {
  const dispatch = useDispatch();
  const ceLocation = useSelector(getCeLocation(ceLocationId));
  const countryCode = useSelector(getUserCountryCode);
  const { ceLocations: content, common: commonContent, forms, valueList } = useContent();
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const addPhotoRef = useRef<HTMLInputElement>(null);
  const [editAddress, setEditAddress] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [deleteLocation, setDeleteLocation] = useState(false);
  const customFieldsList = ceLocation?.customFields
    ? Object.entries(JSON.parse(ceLocation?.customFields))
    : [];

  const updateLocation = useCallback(() => dispatch(getCeLocations()), [dispatch]);

  useEffect(() => {
    if (!ceLocation) {
      updateLocation();
    }
  }, [ceLocation, updateLocation, commonContent]);

  const handlePhotoClick = useCallback((url: string) => setPhotoUrl(url), []);

  const handlePhotoModalClose = useCallback(() => setPhotoUrl(null), []);

  const handleAddPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!ceLocation) {
      return;
    }

    if (event.target.files) {
      const file = event.target.files[0];

      if (file?.name.match(/(?:.jpg)|(?:.png)|(?:.jpeg)/i)) {
        setPhotoUrl(null);
        dispatch(putCeLocationPhoto(ceLocation.id, ceLocation.photoUrls.length, file));
      } else {
        // TODO: Handle error (Incorrect image format extension)
      }
    }
  };

  const handleDeletePhoto = (url: string | null) => {
    if (!ceLocation || !url) {
      return;
    }

    const filename = url.substr(url.lastIndexOf("/") + 1);

    dispatch(deleteCePhoto(ceLocation.id, filename));
    setPhotoUrl(null);
  };

  const handleDownloadPhoto = async () => {
    const a = document.createElement("a");

    try {
      a.href = await fetch(photoUrl ?? "")
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob));
      a.download = "photo";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      // TODO: Handle photo fetch error
    }
  };

  const handleCEEditAddress = useCallback(() => {
    setEditAddress(true);
  }, []);

  const handleCEEditInfo = useCallback(() => {
    setEditLocation(true);
  }, []);

  const handleCEDelete = useCallback(() => {
    setDeleteLocation(true);
  }, []);

  const dispatchDeleteLocation = useCallback(() => {
    if (ceLocation) {
      dispatch(deleteCeLocationRequest(ceLocation.id, ceLocation.certificationNumber));
    }
  }, [ceLocation, dispatch]);

  const headerActions = [
    {
      label: content.celocation_edit_address,
      onClick: handleCEEditAddress
    },
    {
      label: content.celocation_edit_info,
      onClick: handleCEEditInfo
    },
    {
      label: commonContent.common_delete,
      onClick: handleCEDelete
    }
  ];

  const CeLocationDetailEditComponent = ceLocation?.flex20
    ? CeLocationFlexDetailEdit
    : CeLocationDetailEdit;

  return (
    <DetailPage className="celocations-header page-list">
      <Header
        title={
          ceLocation
            ? (ceLocation.ceLocation.name &&
              ceLocation.ceLocation.name !==
                ceLocation.ceLocation.address.substring(
                  0,
                  ceLocation.ceLocation.address.indexOf(",")
                )
                ? `${ceLocation.ceLocation.name}, `
                : "") + ceLocation.ceLocation.address
            : commonContent.common_loading
        }
        breadcrumbs={[{ link: Routes.ceLocations, name: content.celocations_title }]}
        actions={headerActions}
      />
      <DetailPageContent>
        {ceLocation ? (
          <div className="celocations-page celocation-details">
            <h4>{content.celocation_details_title}</h4>
            <div style={{ overflowX: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  minWidth: "600px"
                }}
              >
                <div className="celocations-table-header">
                  <span style={{ flex: 1, textAlign: "left" }}>
                    {content.celocations_address_title}
                  </span>
                  <span className="celocations-table-title">
                    {content.celocations_technician_title}
                  </span>
                  <span className="celocations-table-title">
                    {content.celocations_date_created_title}
                  </span>
                  <span className="celocations-table-title">
                    {content.celocations_ce_number_title}
                  </span>
                </div>
                <div className="celocations-list">
                  <CeLocationListItem ceLocation={ceLocation} />
                </div>
              </div>
            </div>

            {!ceLocation.flex20 ? (
              <div className="celocation-details-list">
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_cleaning_modules}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_quantity}
                    value={ceLocation?.cleaningUnits}
                  />
                  <CeLocationDetailItem
                    title={content.celocation_brand}
                    value={getTranslatedBrands(ceLocation, "cleaningUnitBrands", valueList)}
                  />
                </div>
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_keg_modules}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_quantity}
                    value={ceLocation?.pressureChambers}
                  />
                  <CeLocationDetailItem
                    title={content.celocation_oldest_keg}
                    value={ceLocation?.oldestKegModuleYear}
                  />
                  <CeLocationDetailItem
                    title={content.celocation_keg_modules_type}
                    value={
                      ceLocation?.kegModuleType === "standard"
                        ? content.celocation_keg_modules_type_standard
                        : ceLocation?.kegModuleType === "tropical"
                        ? content.celocation_keg_modules_type_tropical
                        : undefined
                    }
                  />
                </div>
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_beer_cooler}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_quantity}
                    value={ceLocation?.coolingUnits}
                  />
                  <CeLocationDetailItem
                    title={content.celocation_brand}
                    value={getTranslatedBrands(ceLocation, "coolingUnitBrands", valueList)}
                  />
                </div>
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_beer_boost_pump}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_quantity}
                    value={ceLocation?.beerBoostPumpQuantity}
                  />
                  <CeLocationDetailItem
                    title={content.celocation_brand}
                    value={getTranslatedBrands(ceLocation, "beerBoostPumpBrand", valueList)}
                  />
                </div>
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_python_cable}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_brand}
                    value={getTranslatedBrands(ceLocation, "pythonBrand", valueList)}
                  />
                </div>
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_compressor}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_brand}
                    value={getTranslatedBrands(ceLocation, "compressorBrand", valueList)}
                  />
                </div>
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_fob_detector}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_brand}
                    value={getTranslatedBrands(ceLocation, "fobDetectorBrand", valueList)}
                  />
                </div>
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">{content.celocation_coupling}</h4>
                  <CeLocationDetailItem
                    title={content.celocation_brand}
                    value={getTranslatedBrands(ceLocation, "couplingBrand", valueList)}
                  />
                </div>
                {forms &&
                  forms[`CEForm_${countryCode}`] &&
                  forms[`CEForm_${countryCode}`].length > 0 && (
                    <div className="celocation-detail-group">
                      <h4 className="celocation-detail-title">
                        {content.celocation_additional_information}
                      </h4>
                      {forms[`CEForm_${countryCode}`].map(customAttribute => {
                        const customField = customFieldsList.find(([key]) =>
                          customAttribute?.translations?.includes(key)
                        );
                        const value = customField ? customField[1] : "";

                        return (
                          <CeLocationDetailItem
                            key={customAttribute.name}
                            title={customAttribute.name}
                            value={value as string}
                          />
                        );
                      })}
                    </div>
                  )}
              </div>
            ) : (
              <div className="celocation-details-list">
                <div className="celocation-detail-group">
                  <h4 className="celocation-detail-title">Flex 20</h4>
                  <CeLocationDetailItem title="Year*" value={ceLocation?.flex20Year} />
                  <CeLocationDetailItem title="Serial Number*" value={ceLocation?.flex20Serial} />
                </div>
              </div>
            )}
            <div className="celocation-detail-group">
              <h4 className="celocation-detail-title">{content.celocation_photos}</h4>
              <div className="celocation-photos">
                {ceLocation?.photoUrls.map(url => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <img
                    className="celocation-photo"
                    key={url}
                    src={getPhotoURL(url)}
                    alt="ce location"
                    onClick={() => handlePhotoClick(url)}
                  />
                ))}
                <div className="celocation-add-photo" onClick={() => addPhotoRef.current?.click()}>
                  <input
                    ref={addPhotoRef}
                    type="file"
                    alt="celocation-add-photo"
                    accept=".jpg,.jpeg,.png"
                    className="celocation-add-photo-hidden"
                    onChange={handleAddPhoto}
                  />
                  <Icon name="More" color="var(--color-white)" size={24} />
                </div>
              </div>
            </div>
            <Modal
              className="celocation-photo-modal"
              visible={!!photoUrl}
              title={content.celocation_photo}
              onClose={handlePhotoModalClose}
            >
              <img
                className="celocation-photo"
                src={photoUrl ? getPhotoURL(photoUrl) : ""}
                alt="ce location"
              />
              <div className="celocation-button-wrapper">
                <Button
                  onClick={() => handleDeletePhoto(photoUrl)}
                  className="celocation-danger-button"
                >
                  {commonContent.common_delete}
                </Button>
                <Button onClick={handleDownloadPhoto} className="celocation-button">
                  {commonContent.common_download}
                </Button>
              </div>
            </Modal>
            {editLocation && (
              <CeLocationDetailEditComponent
                ceLocation={ceLocation}
                onClose={() => {
                  setEditLocation(false);
                }}
              />
            )}
            {editAddress && (
              <CeLocationDetailEditAddress
                ceLocation={ceLocation}
                onClose={() => {
                  setEditAddress(false);
                }}
              />
            )}
            <Modal
              visible={deleteLocation}
              title={content.celocation_delete_location}
              onClose={() => setDeleteLocation(false)}
            >
              <ConfirmationModal
                question={content.celocation_delete_location_confirmation}
                confirmCallback={dispatchDeleteLocation}
                cancelCallback={() => setDeleteLocation(false)}
              />
            </Modal>
          </div>
        ) : (
          <span>{commonContent.common_loading}</span>
        )}
      </DetailPageContent>
    </DetailPage>
  );
};

export default CeLocationDetails;

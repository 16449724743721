import React, { useCallback, useEffect, useState } from "react";
import { ButtonText, Subtitle } from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import SidebarModal from "../sidebar_modal/SidebarModal";
import { CloseIcon } from "../icon/CloseIcon.styles";
import {
  FilterButton,
  FilterCheckbox,
  FilterClearButton,
  FilterContainer,
  FilterDivider,
  FilterGroup,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterMoreButton
} from "./filter.styles";

export interface IFilterCheckbox {
  id: string;
  label: string;
  selected?: boolean;
}

export interface IFilter {
  id: string;
  options: IFilterCheckbox[];
  title: string;
}

interface IFilterGroupExpandable {
  [id: string]: boolean;
}

interface IProps {
  filters: IFilter[];
  onChange: (filter: IFilter[]) => void;
}

const Filter: React.FC<IProps> = ({ filters, onChange, ...props }) => {
  const { common: content } = useContent();
  const [filter, setFilter] = useState<IFilter[]>(filters);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const getFilterGroupsExpandable = useCallback(
    (oldFilterGroupsExpandable?: IFilterGroupExpandable) =>
      filters.reduce(
        (result: IFilterGroupExpandable, { id, options }: IFilter) =>
          options.length > 8
            ? { ...result, [id]: oldFilterGroupsExpandable?.[id] || false }
            : result,
        {}
      ),
    [filters]
  );

  const [openFilterGroup, setOpenFilterGroup] = useState<IFilterGroupExpandable>(
    getFilterGroupsExpandable()
  );

  const getUpdatedFilter = useCallback(
    (oldFilter: IFilter[]) =>
      filters.map((filterGroup: IFilter, filterGroupIndex: number) => ({
        ...filterGroup,
        options: filterGroup.options.map((option: IFilterCheckbox) => {
          const oldOption = oldFilter[filterGroupIndex].options.find(
            ({ id }: IFilterCheckbox) => id === option.id
          );

          const selected = oldOption?.selected || option.selected;

          return {
            ...option,
            ...(selected !== undefined && { selected })
          };
        })
      })),
    [filters]
  );

  useEffect(() => {
    setFilter(getUpdatedFilter);
    setOpenFilterGroup(getFilterGroupsExpandable);
  }, [getFilterGroupsExpandable, getUpdatedFilter]);

  const handleFilterButton = useCallback(() => setOpenSidebar(true), []);

  const handleCloseSidebar = useCallback(() => {
    setOpenSidebar(false);
    onChange(filter);
  }, [filter, onChange]);

  const handleClearFilters = useCallback(() => {
    const clearFilter = filter.map((filterGroup: IFilter) => {
      const clearOptions = filterGroup.options.map((checkbox: IFilterCheckbox) => ({
        ...checkbox,
        selected: false
      }));

      return { ...filterGroup, options: clearOptions };
    });

    setFilter(clearFilter);
  }, [filter]);

  const handleCheckboxChange = useCallback(
    (selectedGroupId: string, selectedCheckboxId: string) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFilter = filter.map((filterGroup: IFilter) => {
          if (filterGroup.id === selectedGroupId) {
            const updatedOptions = filterGroup.options.map((checkbox: IFilterCheckbox) =>
              checkbox.id === selectedCheckboxId
                ? { ...checkbox, selected: event.target.checked }
                : checkbox
            );

            return { ...filterGroup, options: updatedOptions };
          }

          return filterGroup;
        });

        setFilter(updatedFilter);
      },
    [filter]
  );

  const handleShowMore = useCallback(
    (groupId: string) => () =>
      setOpenFilterGroup({ ...openFilterGroup, [groupId]: !openFilterGroup[groupId] }),
    [openFilterGroup]
  );

  const renderFilterGroupCheckboxes = (groupId: string, options: IFilterCheckbox[]) => {
    const optionsShown =
      openFilterGroup[groupId] !== undefined && !openFilterGroup[groupId]
        ? options.filter(({ selected }: IFilterCheckbox, index: number) => index < 8 || selected)
        : options;

    return optionsShown.map(({ id, label, selected }: IFilterCheckbox, index: number) => (
      <React.Fragment key={index}>
        <FilterCheckbox checked={!!selected} onChange={handleCheckboxChange(groupId, id)}>
          {label}
        </FilterCheckbox>
        <FilterDivider />
      </React.Fragment>
    ));
  };

  const renderFilterGroups = () =>
    filter.map(({ id, title, options }: IFilter, index: number) => (
      <FilterGroup key={index}>
        <ButtonText>{title}</ButtonText>
        <FilterDivider />
        {renderFilterGroupCheckboxes(id, options)}
        {openFilterGroup[id] !== undefined && (
          <FilterMoreButton isOpen={openFilterGroup[id]} onClick={handleShowMore(id)}>
            {openFilterGroup[id] ? content.common_show_less : content.common_show_more}
          </FilterMoreButton>
        )}
      </FilterGroup>
    ));

  const selectedFiltersCount = filter.reduce((acc: number, filterGroup: IFilter) => {
    const selectedOptionsCount = filterGroup.options.filter(
      (checkbox: IFilterCheckbox) => !!checkbox.selected
    ).length;

    return acc + selectedOptionsCount;
  }, 0);

  const filterLabel =
    content.common_filters + (selectedFiltersCount ? ` • ${selectedFiltersCount}` : "");

  const filterHeader = (
    <FilterHeaderContainer>
      <FilterHeaderTitle>
        <Subtitle>{filterLabel}</Subtitle>
        <CloseIcon onClick={handleCloseSidebar} />
      </FilterHeaderTitle>
      {!!selectedFiltersCount && (
        <FilterClearButton icon="Delete" onClick={handleClearFilters}>
          {content.common_clear_filters}
        </FilterClearButton>
      )}
    </FilterHeaderContainer>
  );

  return (
    <FilterContainer {...props}>
      <FilterButton active={!!selectedFiltersCount} onClick={handleFilterButton}>
        {filterLabel}
      </FilterButton>
      <SidebarModal visible={openSidebar} onClose={handleCloseSidebar}>
        {filterHeader}
        {renderFilterGroups()}
      </SidebarModal>
    </FilterContainer>
  );
};

export default Filter;

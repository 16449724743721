import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { IFilter, IFilterCheckbox } from "../common/filter/Filter";
import { EExtra10OutletStatusBadges } from "../../store/extra10outlets/types";
import Header from "../common/header/Header";
import Extra10OutletList from "./Extra10OutletList";
import { getExtra10OutletStatuses } from "../../store/extra10outlets/selectors";

const Extra10Outlets: React.FC = () => {
  const { outlets: content } = useContent();
  const outlets = useSelector(getExtra10OutletStatuses);

  const getFilterOptions = useCallback(
    (options: string[]) =>
      options.map(
        (option: string): IFilterCheckbox => ({
          id: option,
          label: content[`outlets_status_${option?.toLocaleLowerCase()}`]
        })
      ),
    [content]
  );

  const filters = useMemo<IFilter[]>(
    () => [
      {
        id: "status",
        options: getFilterOptions(Object.values(EExtra10OutletStatusBadges)),
        title: content.outlets_status
      }
    ],
    [content.outlets_status, getFilterOptions]
  );

  return (
    <div className="pb-xl">
      <Header title={content.extra10outlets_title} />
      <Extra10OutletList filters={filters} outlets={outlets} searchSelector="name" />
    </div>
  );
};

export default Extra10Outlets;

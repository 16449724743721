import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import AnchorContainer, { IAnchorItem } from "../../common/anchor_container/AnchorContainer";
import { getComponentHealthStatus } from "../../../store/components/actions";
import {
  getCurrentComponent,
  getCurrentComponentHealthStatus,
  getIsLoadingHealthStatus
} from "../../../store/components/selectors";
import {
  PressureChamberAlerts,
  PressureChamberKegChanges,
  PressureChamberStatus,
  PressureChamberStatusHistory
} from "./pressure-chamber/health-monitor";
import { EComponentType } from "../../../store/components/types";
import { CoolingUnitAlerts, CoolingUnitStatus } from "./cooling-unit/health-monitor";
import {
  BeerDriveAlerts,
  BeerDriveStatus,
  BeerDriveStatusHistory
} from "./beer-drive/health-monitor";
import {
  ControlUnitAlerts,
  ControlUnitStatus,
  ControlUnitStatusHistory
} from "./control-unit/health-monitor";
import { GenericComponentStatus } from "./generic-component/health-monitor";
import { CompressorStatus } from "./compressor/health-monitor";
import {
  SteelKegUnitAlerts,
  SteelKegUnitStatus,
  SteelKegUnitStatusHistory
} from "./steel-keg-unit/health-monitor";

interface IProps {
  componentId: string;
}

const ComponentHealthMonitor: React.FC<IProps> = ({ componentId }) => {
  const dispatch = useDispatch();
  const { common, outletDetails: content } = useContent();
  const healthStatus = useSelector(getCurrentComponentHealthStatus());
  const component = useSelector(getCurrentComponent());
  const loading = useSelector(getIsLoadingHealthStatus);

  useEffect(() => {
    dispatch(getComponentHealthStatus(componentId));
  }, [dispatch, componentId]);

  const list = useMemo(() => {
    if (!healthStatus || healthStatus.componentId !== componentId) {
      return [];
    }

    const contentList: IAnchorItem[] = [];
    // TODO: Switch to each component render

    if (healthStatus) {
      const statusComponentMap = {
        [EComponentType.PressureChamber]: PressureChamberStatus,
        [EComponentType.SteelKegUnit]: SteelKegUnitStatus,
        [EComponentType.BeerDrive]: BeerDriveStatus,
        [EComponentType.ControlUnit]: ControlUnitStatus,
        [EComponentType.CoolingUnit]: CoolingUnitStatus,
        [EComponentType.GenericComponent]: GenericComponentStatus,
        [EComponentType.Compressor]: CompressorStatus
      };
      const StatusComponent = (statusComponentMap as any)[component?.type || ""];

      contentList.push({
        title: common.common_status,
        href: "status",
        renderContent: () => <StatusComponent healthStatus={healthStatus} />
      });
    }

    if (healthStatus?.healthStatus) {
      const alertsComponentMap = {
        [EComponentType.PressureChamber]: PressureChamberAlerts,
        [EComponentType.SteelKegUnit]: SteelKegUnitAlerts,
        [EComponentType.BeerDrive]: BeerDriveAlerts,
        [EComponentType.ControlUnit]: ControlUnitAlerts,
        [EComponentType.CoolingUnit]: CoolingUnitAlerts
      };
      const AlertsComponent = (alertsComponentMap as any)[component?.type || ""];

      contentList.push({
        title: common.common_alerts,
        href: "alerts",
        renderContent: () => <AlertsComponent alerts={healthStatus.healthStatus} />
      });
    }

    if (healthStatus?.eventHistory) {
      const eventsComponentMap = {
        [EComponentType.PressureChamber]: PressureChamberStatusHistory,
        [EComponentType.SteelKegUnit]: SteelKegUnitStatusHistory,
        [EComponentType.BeerDrive]: BeerDriveStatusHistory,
        [EComponentType.ControlUnit]: ControlUnitStatusHistory
      };
      const StatusHistoryComponent = (eventsComponentMap as any)[component?.type || ""];

      contentList.push({
        title: content.outlet_component_status_history,
        href: "statusHistory",
        renderContent: () => <StatusHistoryComponent statusHistory={healthStatus.eventHistory} />
      });
    }

    if (healthStatus?.kegChanges) {
      contentList.push({
        title: content.outlet_component_keg_changes,
        href: "kegChanges",
        renderContent: () => (
          <PressureChamberKegChanges
            kegChanges={healthStatus.kegChanges}
            componentPosition={component?.position}
          />
        )
      });
    }

    return contentList;
  }, [healthStatus, componentId, common, content, component]);

  return (
    <div>
      <AnchorContainer
        list={list}
        emptyString={content.outlet_component_not_found.replace("%ID%", componentId)}
        loading={loading}
      />
    </div>
  );
};

export default ComponentHealthMonitor;

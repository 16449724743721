import React from "react";
import "./grid.scss";

export const Grid: React.FC<{}> = props => {
  return (
    <div className="grid-list">
      {props.children}
      <span className="grid-item" />
      <span className="grid-item" />
      <span className="grid-item" />
      <span className="grid-item" />
    </div>
  );
};

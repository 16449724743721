import React, { useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { ESidebarDirection, ESidebarModes, ISidebarProps } from "@ddm-design-system/sidebar";
import { StyledSidebar } from "./sidebarModal.styles";

interface IProps extends ISidebarProps {
  visible: boolean;
  onClose?: () => void;
}

const SidebarModal: React.FC<IProps> = ({ visible, onClose, children, ref, ...props }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const sidebarElement = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.getElementById("modal-root")?.appendChild(sidebarElement);

    return () => {
      document.getElementById("modal-root")?.removeChild(sidebarElement);
    };
  }, [sidebarElement]);

  useEffect(() => {
    const element = sidebarRef.current;

    if (element?.scrollTop && visible) {
      element.scrollTop = 0;
    }
  }, [visible]);

  const sidebar = (
    <StyledSidebar
      direction={ESidebarDirection.RIGHT}
      mode={ESidebarModes.OVER}
      onClose={onClose}
      open={visible}
      ref={sidebarRef}
      {...props}
    >
      {children}
    </StyledSidebar>
  );

  return createPortal(sidebar, sidebarElement);
};

export default SidebarModal;

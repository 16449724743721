import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Description } from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import { getOutlet } from "../../../store/outlets/selectors";
import DataTable from "../../common/data_table/DataTable";
import { INote } from "../../../store/outlets/types";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { IMenuAction } from "../../common/menu/ActionsMenu";
import { getOutletNotes } from "../../../store/outlets/actions";
import AddNewNoteSidebar from "./AddNewNoteSidebar";

interface IProps {
  outletId: string;
}

const Notes: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { outletDetails: content, common } = useContent();
  const [openNewNote, setOpenNewNote] = useState(false);
  const language = useSelector(getCurrentLanguage);
  const outlet = useSelector(getOutlet(outletId));

  const headerActions: IMenuAction[] = [
    {
      label: content.outlet_details_add_new_note,
      onClick: () => {
        setOpenNewNote(true);
      }
    }
  ];

  const formatDescription = useCallback(
    ({ description }: INote) => {
      return (
        <Description className="text-grey-greyDarker whitespace-normal">
          {description || content.outlet_details_no_notes}
        </Description>
      );
    },
    [content.outlet_details_no_notes]
  );

  const formatLastDate = useCallback(
    ({ descriptionUpdateDateTime }: INote) => {
      const date = descriptionUpdateDateTime
        ? new Date(descriptionUpdateDateTime).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const columns = useMemo(
    () => [
      {
        format: formatDescription,
        name: common.common_name,
        selector: (row: INote) => row.description
      },
      {
        format: formatLastDate,
        name: common.common_last_update,
        selector: (row: INote) => row.descriptionUpdateDateTime,
        width: "200px"
        // sortable: true
      }
    ],
    [formatDescription, formatLastDate, common]
  );

  useEffect(() => {
    dispatch(getOutletNotes(outletId));
  }, [dispatch, outletId]);

  return (
    <div className="py-xxl">
      <DataTable
        columns={columns}
        data={[
          outlet?.notes || { installationId: "", description: "", descriptionUpdateDateTime: "" }
        ]}
        isLoading={!outlet?.notes}
        title={common.common_notes}
        actions={headerActions}
        showOnlyFirstAction
      />
      <AddNewNoteSidebar
        visible={openNewNote}
        onClose={() => setOpenNewNote(false)}
        outletId={outlet?.id || ""}
        installationId={outlet?.notes?.installationId || ""}
      />
    </div>
  );
};

export default Notes;

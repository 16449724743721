import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { getCurrentLanguage } from "../../store/content/selectors";
import LanguagePicker from "../common/language_picker/LanguagePicker";
import { LanguageAndPrivacyContainer, PrivacyLink } from "./login.styles";

export const LanguageAndPrivacy: React.FC = () => {
  const { loginControlTower: content } = useContent();
  const lang = useSelector(getCurrentLanguage);

  const privacyLink = useMemo(
    () => `https://legal.dmbuddy.io/?locale=${lang}#privacy_policy__cookies`,
    [lang]
  );

  return (
    <LanguageAndPrivacyContainer>
      <LanguagePicker />
      <PrivacyLink as="a" href={privacyLink} target="_blank" rel="noopener noreferrer">
        {content.control_tower_login_privacy_policy}
      </PrivacyLink>
    </LanguageAndPrivacyContainer>
  );
};

export default LanguageAndPrivacy;

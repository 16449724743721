import styled, { css } from "styled-components";
import { ModalBody } from "@ddm-design-system/modal";
import { ReactComponent as ErrorLineIcon } from "../../../assets/icons/error-line.svg";

export const ErrorModalContent = styled(ModalBody)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 565px;
`;

export const ErrorModalGraphic = styled.div(
  ({ theme }) => css`
    margin: ${theme.spaceUnit.xxl} 0;
    position: relative;
  `
);

export const StyledErrorLineIcon = styled(ErrorLineIcon)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -55%);
`;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import Routes from "../../../routes";
import useContent from "../../../hooks/useContent";
import { getOutlet } from "../../../store/outlets/selectors";
import DataTable from "../../common/data_table/DataTable";
import { IAssociatedTechnician } from "../../../store/outlets/types";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { IMenuAction } from "../../common/menu/ActionsMenu";
import { Link } from "../../common/link/link.styles";
import { getOutletTechnicians, removeAssociation } from "../../../store/outlets/actions";
import AssociateTechnicianSidebar from "./AssociateTechnicianSidebar";

interface IProps {
  outletId: string;
}

const AssociatedTechnicians: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const { outletDetails: content, common } = useContent();
  const [openAssociateTechnician, setOpenAssociateTechnician] = useState(false);
  const [removeTech, setRemoveTech] = useState("");
  const language = useSelector(getCurrentLanguage);
  const outlet = useSelector(getOutlet(outletId));

  useEffect(() => {
    dispatch(getOutletTechnicians(outletId));
  }, [dispatch, outletId]);

  const headerActions: IMenuAction[] = [
    {
      label: content.outlet_details_associate_new_technician,
      onClick: () => {
        setOpenAssociateTechnician(true);
      }
    }
  ];

  const removeAssociatedTechnician = useCallback(
    (id: string) => {
      setRemoveTech(id);
      dispatch(removeAssociation(id, outletId, false, true));
    },
    [dispatch, outletId]
  );

  const formatTechnician = useCallback(
    ({ id, name }: IAssociatedTechnician) => (
      <Link to={Routes.technicianDetails.replace(":id", id)} title={name || id}>
        {name || id}
      </Link>
    ),
    []
  );

  // const formatRegisteredDevice = useCallback(({ hasRegisteredDevice }: IAssociatedTechnician) => {
  //   return (
  //     <Icon
  //       name={hasRegisteredDevice ? "Ok" : "Warning"}
  //       className={`${
  //         hasRegisteredDevice ? "text-success-success100" : "text-alert-alert100"
  //       } fill-current`}
  //     />
  //   );
  // }, []);

  const formatDateAssociated = useCallback(
    ({ dateAssociated }: IAssociatedTechnician) => {
      const date = dateAssociated
        ? new Date(dateAssociated).toLocaleDateString(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        : "--";

      return <span title={date}>{date}</span>;
    },
    [language]
  );

  const formatRemove = useCallback(
    ({ id }: IAssociatedTechnician) => {
      return removeTech === id ? (
        `${common.common_removing}...`
      ) : (
        <Icon
          className="cursor-pointer"
          name="Close"
          onClick={() => removeAssociatedTechnician(id)}
        />
      );
    },
    [removeAssociatedTechnician, removeTech, common]
  );

  const columns = useMemo(
    () => [
      {
        format: formatTechnician,
        name: common.common_name,
        selector: (row: IAssociatedTechnician) => row.name,
        sortable: true
      },
      // {
      //   format: formatRegisteredDevice,
      //   name: "Registered device",
      //   selector: (row: IAssociatedTechnician) => row.hasRegisteredDevice,
      //   width: "150px"
      // },
      {
        name: content.outlet_details_number_of_outlets,
        selector: (row: IAssociatedTechnician) => row.outletsAssociatedLength,
        sortable: true,
        width: "160px"
      },
      {
        format: formatDateAssociated,
        name: content.outlet_details_date_associated,
        selector: (row: IAssociatedTechnician) => row.dateAssociated,
        sortable: true,
        width: "150px"
      },
      {
        format: formatRemove,
        name: common.common_remove,
        selector: (row: IAssociatedTechnician) => row.id,
        width: "100px"
      }
    ],
    [formatTechnician, formatDateAssociated, formatRemove, common, content]
  );

  return (
    <div className="py-xxl">
      <DataTable
        columns={columns}
        data={outlet?.associatedTechnicians || []}
        defaultSortAsc={false}
        defaultSortField="dateAssociated"
        isLoading={!outlet?.associatedTechnicians}
        title={common.common_technicians}
        pagination
        paginationPerPage={100}
        actions={headerActions}
        showOnlyFirstAction
      />
      <AssociateTechnicianSidebar
        associatedTechnicians={outlet?.associatedTechnicians || []}
        visible={openAssociateTechnician}
        onClose={() => setOpenAssociateTechnician(false)}
        outletId={outlet?.id || ""}
        outletName={outlet?.name || ""}
      />
    </div>
  );
};

export default AssociatedTechnicians;

import {
  ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS,
  ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_ERROR,
  ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_SUCCESS,
  CREATE_PURCHASING_GROUP,
  CREATE_PURCHASING_GROUP_ERROR,
  CREATE_PURCHASING_GROUP_SUCCESS,
  EDIT_PURCHASING_GROUP,
  EDIT_PURCHASING_GROUP_SUCCESS,
  EDIT_PURCHASING_GROUP_ERROR,
  GET_PURCHASING_GROUPS_ERROR,
  GET_PURCHASING_GROUPS_REQUEST,
  GET_PURCHASING_GROUPS_SUCCESS,
  IAssociatePurchasingGroupDistributorsAction,
  IAssociatePurchasingGroupDistributorsSuccessAction,
  IAssociatePurchasingGroupDistributorsErrorAction,
  ICreatePurchasingGroupAction,
  ICreatePurchasingGroupErrorAction,
  ICreatePurchasingGroupObject,
  ICreatePurchasingGroupSuccessAction,
  IEditPurchasingGroupAction,
  IEditPurchasingGroupSuccessAction,
  IEditPurchasingGroupErrorAction,
  IGetPurchasingGroupsErrorAction,
  IGetPurchasingGroupsRequestAction,
  IGetPurchasingGroupsSuccessAction,
  IPurchasingGroup,
  IRefreshGroupingData,
  REFRESH_GROUPING_DATA
} from "./types";

export const getPurchasingGroups = (): IGetPurchasingGroupsRequestAction => ({
  type: GET_PURCHASING_GROUPS_REQUEST
});

export const getPurchasingGroupsSuccess = (
  payload: IPurchasingGroup[]
): IGetPurchasingGroupsSuccessAction => ({
  type: GET_PURCHASING_GROUPS_SUCCESS,
  payload
});

export const getPurchasingGroupsError = (): IGetPurchasingGroupsErrorAction => ({
  type: GET_PURCHASING_GROUPS_ERROR
});

export const createPurchasingGroup = (
  payload: ICreatePurchasingGroupObject
): ICreatePurchasingGroupAction => ({
  type: CREATE_PURCHASING_GROUP,
  payload
});

export const createPurchasingGroupSuccess = (
  payload: IPurchasingGroup
): ICreatePurchasingGroupSuccessAction => ({
  type: CREATE_PURCHASING_GROUP_SUCCESS,
  payload
});

export const createPurchasingGroupError = (): ICreatePurchasingGroupErrorAction => ({
  type: CREATE_PURCHASING_GROUP_ERROR
});

export const editPurchasingGroup = (payload: {
  data: ICreatePurchasingGroupObject;
  id: string;
}): IEditPurchasingGroupAction => ({
  type: EDIT_PURCHASING_GROUP,
  payload
});

export const editPurchasingGroupSuccess = (
  payload: IPurchasingGroup
): IEditPurchasingGroupSuccessAction => ({
  type: EDIT_PURCHASING_GROUP_SUCCESS,
  payload
});

export const editPurchasingGroupError = (): IEditPurchasingGroupErrorAction => ({
  type: EDIT_PURCHASING_GROUP_ERROR
});

export const associatePurchasingGroupDistributors = (payload: {
  pgId: string;
  distributorIds: string | string[];
}): IAssociatePurchasingGroupDistributorsAction => ({
  type: ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS,
  payload
});

export const associatePurchasingGroupDistributorsSuccess = (
  payload: IPurchasingGroup
): IAssociatePurchasingGroupDistributorsSuccessAction => ({
  type: ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_SUCCESS,
  payload
});

export const associatePurchasingGroupDistributorsError =
  (): IAssociatePurchasingGroupDistributorsErrorAction => ({
    type: ASSOCIATE_PURCHASING_GROUP_DISTRIBUTORS_ERROR
  });

export const refreshGroupingData = (): IRefreshGroupingData => ({
  type: REFRESH_GROUPING_DATA
});

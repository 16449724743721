import { ICELocation } from "./types";

export const mapCeLocation = (ceLocation: ICELocation, content: any): ICELocation => ({
  ...ceLocation,
  ...(ceLocation.flex20 ? { type: content.celocation_flex_20_title } : {}),
  title: `
      ${
        ceLocation.ceLocation.name &&
        ceLocation.ceLocation.name !==
          ceLocation.ceLocation.address.substring(0, ceLocation.ceLocation.address.indexOf(","))
          ? `${ceLocation.ceLocation.name?.trim()}, `
          : ""
      }
      ${ceLocation.ceLocation.address.trim()}
      ${ceLocation.ceLocation.international ? " - intl" : ""}
   `,
  country: ceLocation.ceLocation.international
    ? "international"
    : ceLocation.ceLocation.country.code
});

import React, { useState } from "react";
import { BodyHighlight, Description, Label } from "@ddm-design-system/typography";
import useContent from "../../../../../hooks/useContent";
import { FieldValue } from "../../../../common/fieldValue/fieldValue.styles";
import { IComponentInfo } from "../../../../../store/components/types";

interface IProps {
  componentInfo: IComponentInfo;
}

const Info: React.FC<IProps> = ({ componentInfo }) => {
  const { outletDetails: content, common } = useContent();

  return (
    <div className="flex">
      <div className="flex-1">
        <div className="field-wrapper">
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{content.outlet_details_thing_id}</BodyHighlight>
              <FieldValue>{componentInfo?.thingId || "--"}</FieldValue>
            </div>
            <div className="field items-start">
              <BodyHighlight>{common.common_beverage}</BodyHighlight>
              <FieldValue>
                <div className="flex flex-row items-center gap-xs">
                  {componentInfo?.brand?.photo && (
                    <img
                      className="w-[44px] h-[44px]"
                      src={componentInfo?.brand?.photo}
                      alt="logo"
                    />
                  )}
                  <span>{(componentInfo?.brand?.title || "")?.trim() || "--"}</span>
                </div>
              </FieldValue>
            </div>
          </div>
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{common.common_current_keg_level}</BodyHighlight>
              <FieldValue>{`${componentInfo?.volume}% • ${componentInfo?.volumeLeft}L`}</FieldValue>
            </div>
          </div>
        </div>
        <div className="field-wrapper">
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{common.common_name}</BodyHighlight>
              <FieldValue>{componentInfo?.name}</FieldValue>
            </div>
            <div className="field">
              <BodyHighlight>{common.common_serial_number}</BodyHighlight>
              <FieldValue className="flex flex-col">
                <Description>{componentInfo?.boardSerialNumber}</Description>
              </FieldValue>
            </div>
          </div>
          <div className="field-section">
            <div className="field">
              <BodyHighlight>{common.common_status}</BodyHighlight>
              <FieldValue>{componentInfo?.alive}</FieldValue>
            </div>

            <div className="field">
              <BodyHighlight>{common.common_installed_on}</BodyHighlight>
              <FieldValue>{componentInfo?.createDateTime}</FieldValue>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;

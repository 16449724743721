import MaterialTextfield, { TextFieldProps as Props } from "@material-ui/core/TextField";
import classNames from "classnames";
import React from "react";
import "./textfield.scss";

export type TextFieldProps = Props;

const TextField: React.FC<TextFieldProps> = ({ className, ref, ...rest }) => {
  const classList = classNames(className, "textfield");

  return <MaterialTextfield ref={ref} className={classList} {...rest} />;
};

export default TextField;
